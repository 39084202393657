import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FontAwesomeIcon,
  PageContainer,
  Row,
  faArrowLeft,
  faPlus,
  faUser,
} from "../../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../../Context/AppContext";
import { useApplicationData } from "../../../../../Context/ApplicationContext";
import { COLOR, FEATURES } from "../../../../../Hooks/utils/config";
import { useNavigate } from "@reach/router";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Lists from "./Lists";
import CustomerForm from "./CustomerForm";

const Customers = ({ id }) => {
  const { user, getLoginSession } = useAppData();

  const { getApplicationByID, application } = useApplicationData();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [openCustomerForm, setOpenCustomerForm] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState([]);

  useEffect(() => {
    getApplicationByID(
      { id },
      () =>
        setLoading(true) ||
        setTimeout(() => {
          setLoading(false);
        }, 1000)
    );
  }, [id, user, getLoginSession]);

  return (
    <PageContainer noAds={FEATURES.NO_ADS}>
      <CustomerForm
        data={{
          openCustomerForm,
          setOpenCustomerForm,
          editCustomerData,
          setEditCustomerData,
        }}
      />
      <Row>
        <Col md={12}>
          <Button
            onClick={() => navigate(-1)}
            theme="secondary"
            style={{ marginRight: 10 }}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </Button>
          <Button
            onClick={() => setOpenCustomerForm(true)}
            backgroundColor={COLOR.primary}
          >
            <FontAwesomeIcon icon={faUserPlus} /> Customer
          </Button>
        </Col>
        <Col md={12} style={{ marginTop: 10 }}>
          <Lists
            data={{
              openCustomerForm,
              setOpenCustomerForm,
              editCustomerData,
              setEditCustomerData,
            }}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Customers;
