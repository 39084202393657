import React, { useState } from "react";
import Empty from "../../../Basic/Empty";
import {
  Button,
  Col,
  Row,
  AppCard,
  AppListCard,
  Pagination,
} from "../../../../traveltayo-ui-kit";
import { useApplicationData } from "../../../../Context/ApplicationContext";
import { FEATURES } from "../../../../Hooks/utils/config";
import userEvent from "@testing-library/user-event";
import { useAppData } from "../../../../Context/AppContext";

const AllApps = ({ data }) => {
  const { user } = useAppData();
  const { setEditApplication, setOpenAppForm } = data;
  const { applications } = useApplicationData();

  const [gridView, setGrideView] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  console.log(
    [...new Map(applications.map((app) => [app.id, app])).values()]
      .sort((a, b) => a.priority - b.priority)
      .filter((app) =>
        FEATURES.ALL_APPS_VIEW
          ? app
          : app.onwer === user.username || app.target === user.username
      )
  );

  return (
    <>
      {applications.length !== 0 ? (
        <>
          <Col md={12}>
            

            <Row>
              <Col md={10} xs={6} sm={6} style={{ padding: 0 }}>
                <h5>All Apps</h5>
              </Col>
              <Col md={2} xs={6} sm={6} style={{ padding: 0 }}>
                <Button
                  theme="light"
                  onClick={() => setGrideView(!gridView)}
                  block
                >
                  {gridView ? "List View" : "Grid View"}
                </Button>
              </Col>
            </Row>
          </Col>

          <Col md={12} style={{ paddingTop: 10 }}>
            {gridView ? (
              <Row style={{ marginTop: 20 }}>
                <Pagination
                  list={[
                    ...new Map(
                      applications.map((app) => [app.id, app])
                    ).values(),
                  ]
                    .sort((a, b) => a.priority - b.priority)
                    .filter((app) =>
                      FEATURES.ALL_APPS_VIEW
                        ? app
                        : app.onwer === user.username ||
                          app.target === user.username
                    )}
                  Card={AppCard}
                  colCount={2}
                  cardType="card"
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                />
              </Row>
            ) : (
              <Pagination
                list={[
                  ...new Map(applications.map((app) => [app.id, app])).values(),
                ]
                  .sort((a, b) => a.priority - b.priority)
                  .filter((app) =>
                    FEATURES.ALL_APPS_VIEW
                      ? app
                      : app.onwer === user.username ||
                        app.target === user.username
                  )}
                Card={AppListCard}
                colCount={2}
                cardType="list"
                setEditApplication={setEditApplication}
                setOpenAppForm={setOpenAppForm}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />
            )}
          </Col>
        </>
      ) : (
        <Col md={12}>
          <Empty />
        </Col>
      )}
    </>
  );
};

export default AllApps;
