import React, { useEffect, useState } from "react";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import UpdateBudget from "../../../../traveltayo-ui-kit/templates/Forms/Plan/UpdateBudget";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col as MyCol,
  Progress,
  ListGroup,
  ListGroupItem,
  Badge,
  ListGroupItemHeading,
  CardFooter,
  faCheck,
  faGear,
  faLocationDot,
  faTicket,
  faUpload,
  FontAwesomeIcon,
} from "../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../Hooks/utils/config";
import { useAppData } from "../../../../Context/AppContext";
import { usePlanData } from "../../../../Context/PlanContext";
import ExpensesList from "../Components/ExpensesList";

const PlanBudget = () => {
  const { isOnline } = useAppData();

  const { plan, buddies, getBuddiesPerPlan, budgetDetails } = usePlanData();
  const { numberWithCommas } = useCustomFunctions();

  const [isEditingBudget, setEditingBudget] = useState(false);

  const BUDGET_LIMIT = 75;

  useEffect(() => {
    if (buddies.length === 0) {
      getBuddiesPerPlan(plan);
    }
  }, [plan]);

  const Col = ({ children, ...props }) => {
    return (
      <MyCol {...props} style={{ padding: 0, ...props.style }}>
        {children}
      </MyCol>
    );
  };

  return (
    <>
      <UpdateBudget
        isEditingBudget={isEditingBudget}
        setEditingBudget={setEditingBudget}
      />

      <Card className="main-card" style={{ marginBottom: 20 }}>
        <CardHeader style={{ padding: 10 }}>Budget & Expenses</CardHeader>
        <CardBody style={{ padding: 10 }}>
          <Row>
            <Col md={8}>
              <h5>P {plan.budget}</h5>
            </Col>
            <Col md={4}>
              <Button
                theme="light"
                style={{ float: "right" }}
                onClick={() => setEditingBudget(true)}
                disabled={!isOnline}
              >
                <FontAwesomeIcon icon={faGear} />
              </Button>
            </Col>
            {plan.budget !== 0 && (
              <Col md={12}>
                <Progress
                  theme={
                    budgetDetails.budgetLeftPercentage > BUDGET_LIMIT
                      ? COLOR.danger
                      : COLOR.primary
                  }
                  value={budgetDetails.budgetLeftPercentage}
                />

                {budgetDetails.budgetLeftPercentage > BUDGET_LIMIT ? (
                  <span style={{ color: "red", fontSize: "10px" }}>
                    You've already spend{" "}
                    {budgetDetails.budgetLeftPercentage.toFixed(2)}% of your
                    estimated budget.
                  </span>
                ) : (
                  ""
                )}
              </Col>
            )}
            {/* chr */}
          </Row>
        </CardBody>
        {budgetDetails.unpaidExpenses.length !== 0 && (
          <ExpensesList
            label="Unpaid Expenses"
            list={budgetDetails.unpaidExpenses}
            total={budgetDetails.totalUnpaidExpenses}
          />
        )}

        {budgetDetails.paidExpenses.length !== 0 && (
          <ExpensesList
            label="Paid Expenses"
            list={budgetDetails.paidExpenses}
            total={budgetDetails.totalPaidExpenses}
          />
        )}

        <CardFooter style={{ padding: 10 }}>
          <Row>
            <Col
              md={6}
              xs={6}
              sm={6}
              style={{ paddingLeft: 0, textAlign: "left" }}
            >
              <p
                style={{
                  fontSize: "15px",
                  margin: 0,
                  fontWeight: 600,
                  color: COLOR.primary,
                }}
              >
                TOTAL
              </p>
            </Col>
            <Col
              md={6}
              xs={6}
              sm={6}
              style={{ paddingLeft: 0, textAlign: "right" }}
            >
              <p
                style={{
                  fontSize: "15px",
                  margin: 0,
                  fontWeight: 600,
                  color: COLOR.primary,
                }}
              >
                &#8369;
                {numberWithCommas(budgetDetails.totalExpense.toFixed(2))}
              </p>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default PlanBudget;
