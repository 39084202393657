import React from "react";
import {
  Button,
  FontAwesomeIcon,
  faCopy,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  CopyToClipboard
} from "../../traveltayo-ui-kit";

const ShareButtons = (props) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <FacebookShareButton url={props.link}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={props.link}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>

      <ViberShareButton url={props.link}>
        <ViberIcon size={32} round={true} />
      </ViberShareButton>
      <EmailShareButton url={props.link}>
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
      <CopyToClipboard
        text={props.link}
        onCopy={() =>
          alert(
            "Copied! Share the link to your freinds and family! Tara! Travle Tayo!"
          )
        }
      >
        <Button pill>
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default ShareButtons;
