import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "@reach/router";
import {
  Alert,
  Button,
  faAt,
  faEnvelope,
  faUser,
  faPhone,
  FormInput,
  FormGroup,
  FormRadio,
  FormCheckbox,
  PasswordInput,
  faKey,
  Helmet,
  PageContainer,
  Card,
  CardBody,
} from "../../../traveltayo-ui-kit";
import { useAppData } from "../../../Context/AppContext";
import { FEATURES, PLATFORM, COLOR, APP_NAME, APP_LINK } from "../../../Hooks/utils/config";
import Container from "./Container";
import HCaptcha from "./HCaptcha";

const SignUp = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    username: "",
    password: "",
    email: "",
    firstname: "",
    lastname: "",
    mobilenumber: "",
    agent: "NO",
    terms: false,
  });

  const [usernameAvailability, setUsernameAvailability] = useState(false);

  const {
    formatUsername,
    validateEmail,
    validateMobileNumber,
    checkAvailability,
    signup,
    setAlertStatus,
  } = useAppData();

  useEffect(() => {
    if (validateEmail(form.email) && form.email) {
      checkAvailability({ username: form.username }, setUsernameAvailability);
    } else {
      setTimeout(() => {
        checkAvailability({ username: form.username }, setUsernameAvailability);
      }, 1000);
    }
  }, [form]);

  const [viewPass, setViewPass] = useState(false);

  useEffect(() => {
    setAlertStatus("");
  }, []);

  useEffect(() => {
    if (!FEATURES.SIGN_UP_PAGE) {
      window.location.replace("/");
    }
  }, []);

  const [token, setToken] = useState(null);

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Sign Up",
        url: APP_LINK + "/sign-up",
      }}
      noAds={true}
    >
      <Container>
        <CardBody>
          <h3 style={{ textAlign: "center" }}>
            {PLATFORM.LOGIN_PAGE.SIGN_UP_LABEL}
          </h3>
          <p
            style={{
              color: "#7D7D7D",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {PLATFORM.LOGIN_PAGE.SIGN_UP_SUBTEXT}
          </p>
          <Alert />
        </CardBody>
        <CardBody>
          <FormInput
            group={true}
            icon={faEnvelope}
            placeholder="email address"
            value={form.email}
            onChange={(e) =>
              setForm({
                ...form,
                email: e.target.value,
                username: formatUsername(e.target.value.split("@")[0]),
              })
            }
            hintText={
              usernameAvailability ? "This email address is already taken" : ""
            }
            hintColor="red"
            invalid={
              form.email
                ? !validateEmail(form.email) || usernameAvailability
                : false
            }
            // invalid={form.username ?  : false}
            valid={form.username ? !usernameAvailability : false}
            // valid={form.email ? !emailAvailability || validateEmail(form.email) : false}
          />

          <FormInput
            group={true}
            icon={faAt}
            placeholder="username"
            value={form.username}
            onChange={(e) =>
              setForm({ ...form, username: formatUsername(e.target.value) })
            }
            hintText="Autogenerated using email address"
            disabled
            // invalid={form.username ? usernameAvailability : false}
            // valid={form.username ? !usernameAvailability : false}
          />

          <FormInput
            group={true}
            icon={faUser}
            size="md"
            inputs={[
              {
                placeholder: "first name",
                className: "formgroup-left-input",
                value: form.firstname,
                onChange: (e) =>
                  setForm({ ...form, firstname: e.target.value }),
              },
              {
                placeholder: "last name",
                className: "formgroup-right-input",
                value: form.lastname,
                onChange: (e) => setForm({ ...form, lastname: e.target.value }),
              },
            ]}
          />

          <FormInput
            group={true}
            icon={faPhone}
            type="number"
            placeholder="mobile number"
            value={form.mobilenumber}
            onChange={(e) => setForm({ ...form, mobilenumber: e.target.value })}
            invalid={
              form.mobilenumber
                ? !validateMobileNumber(form.mobilenumber)
                : false
            }
          />

          <PasswordInput
            icon={faKey}
            viewPass={viewPass}
            setViewPass={setViewPass}
            value={form.password}
            onChange={(e) => setForm({ ...form, password: e.target.value })}
          />

          <FormGroup style={{ textAlign: "left", marginBottom: 20 }}>
            <p style={{ color: "#7D7D7D", margin: 0, fontSize: "11px" }}>
              Do you provide ticketing services, tour packages, event
              management, or any other similar services?
            </p>
            <FormRadio
              inline
              name="sport"
              checked={form.agent === "NO"}
              onChange={() =>
                setForm({
                  ...form,
                  agent: form.agent === "NO" ? "YES" : "NO",
                })
              }
            >
              No
            </FormRadio>
            <FormRadio
              inline
              name="sport"
              checked={form.agent === "YES"}
              onChange={() =>
                setForm({
                  ...form,
                  agent: form.agent === "YES" ? "NO" : "YES",
                })
              }
            >
              Yes
            </FormRadio>
          </FormGroup>

          <FormGroup style={{ textAlign: "left", marginBottom: 30 }}>
            <FormCheckbox
              style={{ display: "inline" }}
              checked={form.terms}
              onChange={() => setForm({ ...form, terms: !form.terms })}
            >
              I agree with {APP_NAME} Terms and Privacy Policy
            </FormCheckbox>
          </FormGroup>

          {form.username &&
          // data.username_avail &&
          form.password &&
          form.firstname &&
          form.lastname &&
          form.email &&
          // data.email_avail &&
          form.mobilenumber &&
          form.terms &&
          !usernameAvailability ? (
            <FormGroup>
              {token ? (
                <Button
                  backgroundColor={COLOR.primary}
                  style={{ width: "100%", fontSize: "15px" }}
                  onClick={() => signup(form, navigate)}
                >
                  Sign Up
                </Button>
              ) : (
                <HCaptcha setToken={setToken} />
              )}
            </FormGroup>
          ) : (
            ""
          )}

          <br />
          <FormGroup>
            <p style={{ color: "#7D7D7D", display: "inline" }}>
              Already have an account?{" "}
            </p>
            <Link
              to="/login"
              style={{
                textDecoration: "none",
                color: COLOR.primary,
                fontWeight: "400",
              }}
            >
              Login
            </Link>
          </FormGroup>
        </CardBody>
      </Container>
    </PageContainer>
  );
};

export default SignUp;
