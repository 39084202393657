import React from "react";
import dateFormat from "dateformat";
import {
  Button,
  faArrowRight,
  faCalendar,
  faLocationDot,
  Card,
  CardImg,
  CardBody,
  CardFooter,
  FontAwesomeIcon,
} from "../..";
import { useNavigate } from "@reach/router";
import { COLOR } from "../../../Hooks/utils/config";

const TravelPlanCard = (props) => {
  const navigate = useNavigate();
  return (
    <Card className="main-card">
      <CardImg top src={props.plan.cover} className="travel-plan-card-cover" />
      <CardBody className="travel-plan-card-body">
        <h4 className="travel-plan-title">{props.plan.name[0]}</h4>
        <p className="travel-plan-dates">
          <FontAwesomeIcon icon={faCalendar} />{" "}
          {dateFormat(new Date(props.plan.dates[0]), "mmm d")} -{" "}
          {dateFormat(
            new Date(props.plan.dates[props.plan.dates.length - 1]),
            "mmm d, yyyy"
          )}{" "}
        </p>
        <p className="travel-plan-location">
          <FontAwesomeIcon icon={faLocationDot} /> {props.plan.name[1]}{" "}
        </p>
      </CardBody>
      <CardFooter className="travel-plan-card-footer">
        <Button
          block
          backgroundColor={COLOR.primary}
          icon={faArrowRight}
          style={{
            borderRadius: "0rem 0rem 0.625rem 0.625rem",
          }}
          onClick={() =>
            navigate(
              `/${
                props.plan.event_type === "travel_plan" ? "plan" : "shared_plan"
              }/${props.plan.reference}`
            )
          }
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
};

export default TravelPlanCard;
