import React, { useState } from "react";
import dateFormat from "dateformat";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormInput,
  PageContainer,
  Button,
  Badge,
} from "../../../../../traveltayo-ui-kit";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { useBookingsData } from "../../../../../Context/BookingsContext";
import { APP_LINK, APP_NAME, PLATFORM } from "../../../../../Hooks/utils/config";
import HCaptcha from "../../../Accounts/HCaptcha";
import Container from "../../../Accounts/Container";

const ManageBooking = () => {
  const { getBookingStatus } = useBookingsData();
  const [bookingID, setBookingID] = useState("");

  const [result, setResult] = useState([]);

  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState(null);

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Manage Booking",
        url: APP_LINK + "/booking/manage",
      }}
      noAds={true}
    >
      <Container>
        <CardBody>
          <h4>Booking Status</h4>
        </CardBody>
        <CardBody>
          <FormInput
            group={true}
            icon={faHashtag}
            placeholder="booking id"
            value={bookingID}
            onChange={(e) => setBookingID(e.target.value)}
            inputBTN={
              <>
                {result.length !== 0 ? (
                  <Button
                    theme="secondary"
                    onClick={() => setBookingID("") || setResult([]) || setToken(null)}
                  >
                    Clear
                  </Button>
                ) : (
                  <Button
                    theme="secondary"
                    onClick={() =>
                      setLoading(true) ||
                      getBookingStatus(
                        { bookingID },
                        (e) => setResult(e) || setLoading(false)
                      )
                    }
                    disabled={bookingID === "" || loading || !token}
                  >
                    Check
                  </Button>
                )}
              </>
            }
          />
          {
            !token && <HCaptcha setToken={setToken} />
          }
          {loading && "Please wait while we check your booking status..."}
          {result.length !== 0 ? (
            <>
              {result.map((r) => (
                <>
                  {r.status === 404 ? (
                    "Booking Not Found. Please check the booking id."
                  ) : (
                    <>
                      <Badge
                        theme={
                          PLATFORM.BOOKING_STATUSES.find(
                            (s) => s.code === r.status
                          ).color
                        }
                        style={{ fontSize: "20px", margin: 10 }}
                      >
                        {PLATFORM.BOOKING_STATUSES.find(
                          (s) => s.code === r.status
                        ).label.toUpperCase()}
                      </Badge>
                      <p>Last update is on {dateFormat(r.date_modified)}</p>
                    </>
                  )}
                </>
              ))}
            </>
          ) : (
            <></>
          )}
        </CardBody>
      </Container>
    </PageContainer>
  );
};

export default ManageBooking;
