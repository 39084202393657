import React from "react";
import {
  Button,
  Col,
  FontAwesomeIcon,
  ListGroupItem,
  Row,
  faArrowRight,
  faEdit,
} from "../..";
import { COLOR } from "../../../Hooks/utils/config";
import { useNavigate } from "@reach/router";
import { Draggable } from "react-drag-and-drop";
import { useAppData } from "../../../Context/AppContext";

const AppListCard = ({ data, setEditApplication, setOpenAppForm }) => {
  const { user } = useAppData();
  const navigate = useNavigate();

  return (
    <ListGroupItem style={{ padding: "5px 10px" }}>
      <Draggable type="app" data={JSON.stringify(data)}>
        <Row style={{ padding: 0 }}>
          <Col md={2} xs={6} sm={6}>
            <img
              src={data.logo}
              style={{ maxHeight: "20px", width: "100%" }}
            />
          </Col>
          <Col md={6} xs={6} sm={6}>
            {data.description}
          </Col>

          <Col md={2} xs={6} sm={6}>
            {data.owner === user.username && (
              <Button
                theme="light"
                onClick={() => setOpenAppForm(true) || setEditApplication(data)}
                block
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
          </Col>

          <Col md={2} xs={6} sm={6}>
            <Button
              backgroundColor={COLOR.primary}
              onClick={() =>
                data.isQuotationMaker
                  ? navigate(`/apps/${data.id}`)
                  : window.open(data.link)
              }
              block
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Col>
        </Row>
      </Draggable>
    </ListGroupItem>
  );
};

export default AppListCard;
