import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { API } from "../../../Hooks/utils/config";
import {
  InputGroup,
  InputGroupAddon,
  FormInput,
  Button,
} from "shards-react";
import { useAppData } from "../../../Context/AppContext";

const FormUpload = (props) => {
  const { user, auth_login } = useAppData();

  const imageSizeLimit = 1000000;

  const [uploading, setUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageSize, setImageSize] = useState("");

  const onFileChange = (e) => {
    let files = e.target.files;
    props.setLoading(true);

    const controller = new AbortController();

    const options = {
      // other options here
      maxSizeMB: 0.1,
      signal: controller.signal,
      maxIteration: 5,
    };

    imageCompression(files[0], options)
      .then(function (compressedFile) {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(compressedFile);
        setImageSize(compressedFile.size);
        

        fileReader.onload = (event) => {
          setSelectedImage(event.target.result);
            // console.log(event.target.result);
          if (props.action) {
            props.action(event.target.result);
          }
        };
        props.setLoading(false);
      })
      .catch(function (error) {
        console.log(error.message); // output: I just want to stop
      });
  };

  const onSubmit = () => {
    setUploading(true);
    const formData = {
      image: selectedImage.slice(0, 14000),
      image1: selectedImage.slice(14000, 28000),
      image2: selectedImage.slice(28000, 42000),
      image3: selectedImage.slice(42000, selectedImage.length),
      username: user.username,
    };
    let endpoint = `${API.URL}/${props.fileUploadURI}`;
    if (auth_login) {
      axios.post(endpoint, formData, API.HEADERS).then((res) => {
        window.location.reload();
      });
    }
  };

  return (
    <>
      {props.label ? (
        <label
          htmlFor="image-file"
          style={{ marginBottom: "0.1rem", fontSize: "11px" }}
        >
          {props.label}
        </label>
      ) : (
        ""
      )}

      <InputGroup>
        <FormInput
          type="file"
          multiple
          accept={props.acceptFiles}
          className="form-control"
          style={{ padding: 5 }}
          onChange={(e) => onFileChange(e)}
          invalid={selectedImage ? imageSize > imageSizeLimit : false}
          disabled={uploading || props.loading}
        />
        {props.fileUploadURI ? (
          <InputGroupAddon type="append">
            <Button
              onClick={() => onSubmit()}
              theme="secondary"
              disabled={
                imageSize > imageSizeLimit || uploading || props.loading
              }
            >
              {""}
              {props.loading
                ? "Loading Image...."
                : uploading
                ? "Uploading Image...."
                : "Upload"}
            </Button>
          </InputGroupAddon>
        ) : (
          ""
        )}
      </InputGroup>
      {/* {
        selectedImage ?
        <span style={{ fontSize: "10px", color: "red" }}>
          File Size is {imageSize / 1000000}MB.
        </span> : ""
      } */}
    </>
  );
};

export default FormUpload;
