import React from "react";
import {
  FormCheckbox,
  FormGroup,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Collapse,
  FormInput,
  faMoneyBill,
} from "../../../../../../traveltayo-ui-kit";
import useCustomFunctions from "../../../../../../Hooks/api/useCustomFunctions";
import { useAppData } from "../../../../../../Context/AppContext";
const TourDetailsForm = ({ data }) => {
  const {
    quotation,
    setQuotation,
    quoteLocked,
    updatedRates,
    editQuotation,
  } = data;

  const { numberWithCommas } = useCustomFunctions();
  const { user } = useAppData();

  return (
    <>
      <FormGroup>
        {updatedRates.length !== 0 && (
          <FormCheckbox
            small
            toggle
            checked={quotation.bookingDetails.tourDetails.additionalTour}
            onChange={() =>
              quoteLocked
                ? ""
                : setQuotation((prev) => ({
                    ...prev,
                    bookingDetails: {
                      ...prev.bookingDetails,
                      tourDetails: {
                        ...prev.bookingDetails.tourDetails,
                        additionalTour: !prev.bookingDetails.tourDetails
                          .additionalTour,
                      },
                    },
                  }))
            }
            disabled={quoteLocked}
          >
            Additional Tours, Hotels, Activities
          </FormCheckbox>
        )}

        {quotation.bookingDetails.tourDetails.additionalTour &&
          updatedRates.length !== 0 && (
            <>
              {updatedRates.length !== 0 && (
                <>
                  <ListGroup>
                    <ListGroupItem>
                      <ListGroupItemHeading style={{ fontSize: 14 }}>
                        AVAILABLE ADD-ON:
                      </ListGroupItemHeading>
                    </ListGroupItem>
                    {updatedRates[0].ADDITIONAL_TOUR.map((tour) => (
                      <ListGroupItem>
                        <FormCheckbox
                          small
                          checked={quotation.bookingDetails.tourDetails.tours.find(
                            (t) => t.TOUR_NAME === tour.TOUR_NAME
                          )}
                          onChange={() =>
                            quoteLocked &&
                            quotation.bookingDetails.tourDetails.tours
                              .length === 0
                              ? ""
                              : setQuotation((prev) => ({
                                  ...prev,
                                  bookingDetails: {
                                    ...prev.bookingDetails,
                                    tourDetails: {
                                      ...prev.bookingDetails.tourDetails,
                                      tours: prev.bookingDetails.tourDetails.tours.find(
                                        (t) => t.TOUR_NAME === tour.TOUR_NAME
                                      )
                                        ? prev.bookingDetails.tourDetails.tours.filter(
                                            (t) =>
                                              t.TOUR_NAME !== tour.TOUR_NAME
                                          )
                                        : [
                                            ...prev.bookingDetails.tourDetails
                                              .tours,
                                            tour,
                                          ],
                                    },
                                  },
                                }))
                          }
                          style={{ margin: 0 }}
                          disabled={quoteLocked}
                        >
                          {tour.TOUR_NAME} - {tour.TYPE} | P{" "}
                          {numberWithCommas(tour.RATE)} per pax
                          <Collapse
                            open={quotation.bookingDetails.tourDetails.tours.find(
                              (t) => t.TOUR_NAME === tour.TOUR_NAME
                            )}
                          >
                            <p style={{ fontSize: 12, margin: 0 }}>
                              <strong>B2B RATE: </strong>P {tour.B2B_RATE}
                            </p>
                            <p style={{ fontSize: 12, margin: 0 }}>
                              <strong>DETAILS: </strong>
                              {tour.DETAILS}
                            </p>

                            <ul>
                              {tour.INCLUSIONS.map((inc) => (
                                <li>{inc}</li>
                              ))}
                            </ul>
                            {editQuotation.username === user.username && (
                              <FormInput
                                group={true}
                                icon={faMoneyBill}
                                label="CUSTOM AMOUNT PER PAX"
                                inputs={[
                                  {
                                    placeholder: "custom amount per pax",
                                    className: "formgroup-left-input form-control",
                                    value: quotation.bookingDetails.tourDetails.tours.find(
                                      (t) => t.ID === tour.ID
                                    )?.RATE,
                                    type: "number",
                                    onChange: (e) =>
                                      setQuotation((prev) => ({
                                        ...prev,
                                        bookingDetails: {
                                          ...prev.bookingDetails,
                                          tourDetails: {
                                            ...prev.bookingDetails.tourDetails,
                                            tours: prev.bookingDetails.tourDetails.tours.map(
                                              (edit) =>
                                                edit.ID === tour.ID
                                                  ? {
                                                      ...edit,
                                                      RATE: parseFloat(
                                                        e.target.value
                                                      ),
                                                    }
                                                  : edit
                                            ),
                                          },
                                        },
                                      })),
                                  },
                                  {
                                    placeholder: "number of pax",
                                    className: "formgroup-right-input form-control",
                                    value: quotation.bookingDetails.tourDetails.tours.find(
                                      (t) => t.ID === tour.ID
                                    )?.tourPax,
                                    type: "number",
                                    onChange: (e) =>
                                      setQuotation((prev) => ({
                                        ...prev,
                                        bookingDetails: {
                                          ...prev.bookingDetails,
                                          tourDetails: {
                                            ...prev.bookingDetails.tourDetails,
                                            tours: prev.bookingDetails.tourDetails.tours.map(
                                              (edit) =>
                                                edit.ID === tour.ID
                                                  ? {
                                                      ...edit,
                                                      tourPax: parseFloat(
                                                        e.target.value
                                                      ),
                                                    }
                                                  : edit
                                            ),
                                          },
                                        },
                                      })),
                                  },
                                ]}

                                // disabled={quoteLocked}
                              />
                            )}
                          </Collapse>
                        </FormCheckbox>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </>
              )}
            </>
          )}
      </FormGroup>
    </>
  );
};

export default TourDetailsForm;
