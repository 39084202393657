import React from "react";
import ProductProfile from "./ProductProfile";
import { PlanForm } from "../../../../../traveltayo-ui-kit";
import { PlanContext } from "../../../../../Context/PlanContext";
import { BookingsContext } from "../../../../../Context/BookingsContext";

const Index = ({ id, tag, merchant }) => {
  return (
    <BookingsContext>
      <PlanContext>
        {/* <PlanForm /> */}
        <ProductProfile id={id} tag={tag} merchant={merchant} />
      </PlanContext>
    </BookingsContext>
  );
};

export default Index;
