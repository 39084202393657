import React, { useEffect, useState } from "react";
import QuotationForm from "../../Components/TourQuotation/QuotationForm";
import Lists from "../../Components/TourQuotation/Lists";
import NewAppForm from "../../Components/NewAppForm";
import { useAppData } from "../../../../../Context/AppContext";
import { APP_NAME, COLOR, FEATURES } from "../../../../../Hooks/utils/config";
import {
  Button,
  Col,
  Row,
  FontAwesomeIcon,
  faArrowLeft,
  faEdit,
  faPlus,
  PageContainer,
  faUsers,
} from "../../../../../traveltayo-ui-kit";
import { useNavigate } from "@reach/router";
import { useApplicationData } from "../../../../../Context/ApplicationContext";
import Details from "./AgencyForm/Details";

const Quotations = ({ id, link }) => {
  const { user, getLoginSession } = useAppData();

  const {
    getApplicationByID,
    application,
    quotationsPerApp,
    getQuotationsPerApp,
  } = useApplicationData();

  useEffect(() => {
    getApplicationByID({ id }, () =>
      getQuotationsPerApp({ id, user, otp: getLoginSession[1] })
    );
  }, [user, getLoginSession]);

  const [openForm, setOpenForm] = useState(false);

  const navigate = useNavigate();

  const [openAppForm, setOpenAppForm] = useState(false);
  const [editApplication, setEditApplication] = useState([]);

  //agency details
  const [openAgencyDetailsForm, setOpenAgencyDetailsForm] = useState(false);

  console.log(application);

  return (
    <PageContainer
      helmet={{
        title: `${APP_NAME} | ${
          application.length !== 0 ? application.name : "App"
        }`,
      }}
      noAds={FEATURES.NO_ADS}
    >
      <NewAppForm
        openAppForm={openAppForm}
        setOpenAppForm={setOpenAppForm}
        editApplication={editApplication}
        setEditApplication={setEditApplication}
      />

      {openAgencyDetailsForm && (
        <Details
          data={{
            openAgencyDetailsForm,
            setOpenAgencyDetailsForm,
            application,
          }}
        />
      )}

      <Row style={{ padding: 0 }}>
        <Col md={12}>
          <Button
            onClick={() => navigate(-1)}
            theme="secondary"
            style={{ marginRight: 10 }}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </Button>

          {application.length !== 0 ? (
            <>
              {application.owner === user.username && (
                <Button
                  onClick={() =>
                    setOpenAppForm(true) || setEditApplication(application)
                  }
                  theme="secondary"
                  style={{ marginRight: 10 }}
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit Application
                </Button>
              )}

              <Button
                onClick={() => setOpenAgencyDetailsForm(true)}
                backgroundColor={COLOR.primary}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faEdit} /> Agency Details
              </Button>

              <Button
                onClick={() => setOpenForm(true)}
                backgroundColor={COLOR.primary}
                style={{ marginRight: 10 }}
              >
                <FontAwesomeIcon icon={faPlus} /> Create Quotation
              </Button>

              <Button
                onClick={() => navigate(link ? `/${link}/customers` : `/apps/${id}/customers`)}
                backgroundColor={COLOR.primary}
              >
                <FontAwesomeIcon icon={faUsers} /> Customers
              </Button>
            </>
          ) : "Loading ..."}

          <QuotationForm
            formData={{
              id,
              openForm,
              setOpenForm,
              editQuotation: [],
              link: link,
            }}
          />
        </Col>
        <Col md={12} style={{ paddingTop: 10 }}>
          <Lists
            data={{ application, id, quotations: quotationsPerApp, link: link }}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Quotations;
