import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  FontAwesomeIcon,
  FormCheckbox,
  FormGroup,
  FormInput,
  faCartShopping,
  faMap,
  faPercentage,
  faSearch,
  faTicket,
} from "../../../../../traveltayo-ui-kit";
import { useNavigate } from "@reach/router";
import { COLOR } from "../../../../../Hooks/utils/config";
import "./style.css";
import { useBookingsData } from "../../../../../Context/BookingsContext";
import useQueryURL from "../../../../../Hooks/api/useQueryURL";
import { faArrowLeft, faHome } from "@fortawesome/free-solid-svg-icons";
import { useAppData } from "../../../../../Context/AppContext";

const Widget = ({ data }) => {
  const navigate = useNavigate();
  const { auth_login, screenSizes, screenWidth } = useAppData();
  const { getQuery, changeParams } = useQueryURL();
  const { setProducts, getProducts, getProductsByTag } = useBookingsData();
  const {
    currentTab,
    loading,
    setLoading,
    offerFilter,
    setOfferFilter,
    search,
    setSearch,
  } = data;

  const { xSmall, small, medium, large } = screenSizes;

  const TABS = [
    {
      id: "destinations",
      label: "Destinations",
      icon: faMap,
    },
    {
      id: "offers",
      label: "Offers",
      icon: faCartShopping,
    },
    // {
    //   id: "travel-apps",
    //   label: "Travel Apps",
    //   icon: faTicket,
    // },
    // {
    //   id: "promo-codes",
    //   label: "Promo Codes",
    //   icon: faPercentage,
    // },
  ];

  const OFFERS_FILTERS = [
    "Land Arrangements",
    "Packages",
    "Hotels",
    "Tours",
    "Flights",
    "Activities",
    "Cars",
    "Buses",
    "Boats",
    "Ferries",
    "Trains",
    "Insurances",
    "Vans",
    "Attractions",
  ];
  const APPS_FILTERS = [
    "Hotels",
    "Flights",
    "Activities",
    "Cars",
    "Buses",
    "Boats",
    "Ferries",
    "Tours",
    "Trains",
    "Insurances",
    "Vans",
    "Attractions",
    "Travel Reviews",
    "Weather Updates",
  ];

  const query = getQuery("q");

  useEffect(() => {
    if (query) {
      setSearch(query);
      setLoading(true);
      getProducts(
        {
          search: query,
          tag: currentTab === "offers" ? 17 : 18,
        },
        (products) => setProducts(products) || setLoading(false)
      );
    }
  }, [query, currentTab]);

  return (
    <Card style={{marginBottom: 20}}>
      <CardBody style={{ padding: 0 }}>
        <ButtonToolbar>
          <ButtonGroup style={{ width: "100%" }} className="widget-menu">
            {/* {auth_login && !screenWidth && (
              <Button
                backgroundColor={COLOR.primary}
                theme="dark"
                className={`menu-tab`}
                onClick={() =>
                  setSearch("") || setOfferFilter([]) || navigate(`/`)
                }
                disabled={loading}
                style={{ display: "inline", fontSize: 15 }}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Back Home
              </Button>
            )} */}
            {TABS.map((tab) => (
              <Button
                backgroundColor={
                  currentTab.includes(tab.id) ? COLOR.secondary : COLOR.primary
                }
                theme="dark"
                className={`menu-tab`}
                onClick={() =>
                  setSearch("") ||
                  setOfferFilter([]) ||
                  navigate(`/explore/${tab.id}`)
                }
                disabled={loading}
              >
                <FontAwesomeIcon icon={tab.icon} />
                {tab.label}
              </Button>
            ))}
          </ButtonGroup>
        </ButtonToolbar>
      </CardBody>
      {["offers", "destinations", "travel-apps"].includes(currentTab) && (
        <CardBody style={{padding: 10}}>
          <FormInput
            group={true}
            icon={faSearch}
            placeholder={
              currentTab === "offers"
                ? "search for hotels, tour destinations, packages and more"
                : "search for destination reviews"
            }
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                if (["offers", "destinations"].includes(currentTab)) {
                  setLoading(true);
                  if (search !== "") {
                    getProducts(
                      {
                        search: search,
                        tag: currentTab === "offers" ? 17 : 18,
                      },
                      (products) =>
                        setProducts(products) ||
                        setLoading(false) ||
                        navigate(changeParams("q", search))
                    );
                  } else {
                    getProductsByTag(
                      {
                        tag: currentTab === "offers" ? 17 : 18,
                        page: 1,
                        per_page: 40,
                      },
                      (length) =>
                        setTimeout(() => {
                          setLoading(false);
                        }, 1000)
                    );
                  }
                } else {
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                }
              }
            }}
            inputBTN={
              <Button
                backgroundColor={COLOR.primary}
                style={{ margin: "0 auto", textAlign: "center" }}
                onClick={() => {
                  if (["offers", "destinations"].includes(currentTab)) {
                    setLoading(true);
                    if (search !== "") {
                      getProducts(
                        {
                          search: search,
                          tag: currentTab === "offers" ? 17 : 18,
                        },
                        (products) =>
                          setProducts(products) ||
                          setLoading(false) ||
                          navigate(changeParams("q", search))
                      );
                    } else {
                      getProductsByTag(
                        {
                          tag: currentTab === "offers" ? 17 : 18,
                          page: 1,
                          per_page: 40,
                        },
                        (length) =>
                          setTimeout(() => {
                            setLoading(false);
                          }, 1000)
                      );
                    }
                  } else {
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1000);
                  }
                }}
                disabled={loading}
              >
                <FontAwesomeIcon icon={faSearch}/>
              </Button>
            }
          />
          {currentTab === "offers" && (
            <>
              {OFFERS_FILTERS.map((FILTER) => (
                <FormCheckbox
                  inline={screenWidth}
                  checked={offerFilter.find((f) => f === FILTER)}
                  onChange={() =>
                    setOfferFilter(
                      offerFilter.find((f) => f === FILTER)
                        ? offerFilter.filter((f) => f !== FILTER)
                        : [...offerFilter, FILTER]
                    )
                  }
                >
                  {FILTER}
                </FormCheckbox>
              ))}
            </>
          )}

          {/* {currentTab === "travel-apps" && (
            <>
              {APPS_FILTERS.map((FILTER) => (
                <FormCheckbox
                  inline
                  checked={offerFilter.find((f) => f === FILTER)}
                  onChange={() =>
                    setOfferFilter(
                      offerFilter.find((f) => f === FILTER)
                        ? offerFilter.filter((f) => f !== FILTER)
                        : [...offerFilter, FILTER]
                    )
                  }
                >
                  {FILTER}
                </FormCheckbox>
              ))}
            </>
          )} */}
        </CardBody>
      )}
    </Card>
  );
};

export default Widget;
