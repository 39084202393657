import React, { createContext, useContext, useState } from "react";
import dateFormat from "dateformat";
import { useAppData } from "./AppContext";
import useDashboardAPI from "../Hooks/api/useDashboardAPI";
import { usePlanData } from "./PlanContext";
import { useBookingsData } from "./BookingsContext";
import { useApplicationData } from "./ApplicationContext";
import { FEATURES } from "../Hooks/utils/config";

const Context = createContext();

export function useDashboardData() {
  return useContext(Context);
}

export const DashboardContext = ({ children }) => {
  const { isOnline, holidays, festivals,getHolidays  } =
    useAppData();

    const { userBookings} = useBookingsData()
    const { quotations, getQuotations} = useApplicationData()

  const { plans } = usePlanData();

  const { allPlans, calendarEvents, incomingTravel, summaryPlans } =
    useDashboardAPI({
      plans,
      holidays,
      festivals,
      userBookings,
      quotations,
      getQuotations,
      getHolidays,
      isOnline,
    });

  const [eventFilter, setEventFilter] = useState([
    "reminder",
    FEATURES.BOOKING_SYSTEM ? "booking" : "",
    FEATURES.TRAVEL_PLAN_APP ? "travel_plan" : "",
    "quotation",
    "holiday"
  ].filter(filter => filter !== ""));
  const [calendarYear, setCalendarYear] = useState(
    dateFormat(new Date(), "yyyy")
  );
  const [viewEvent, setViewEvent] = useState(false);
  return (
    <Context.Provider
      value={{
        allPlans,
        calendarEvents,
        incomingTravel,
        summaryPlans,
        eventFilter,
        setEventFilter,
        calendarYear,
        setCalendarYear,
        viewEvent,
        setViewEvent,
      }}
    >
      {children}
    </Context.Provider>
  );
};
