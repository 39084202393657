import React from "react";
import {
  Col,
  Row,
  Button,
  FormInput,
  FormSelect,
  FontAwesomeIcon,
  faCalendar,
  faTrash,
  faUser,
} from "../../../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../../../Hooks/utils/config";

const PassengerForm = ({data}) => {
    const {quotation, setQuotation,quoteLocked} = data
  return (
    <>
      <h6>GUEST DETAILS </h6>
      <Row>
        {quotation.bookingDetails.passengers.length !== 0 && (
          <>
            {quotation.bookingDetails.passengers.map((passenger, index) => (
              <>
                <Col md={4} style={{ padding: 2 }}>
                  <FormInput
                    group={true}
                    icon={faUser}
                    label="Name"
                    value={passenger.name}
                    type="text"
                    placeholder="Name"
                    onChange={(e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        bookingDetails: {
                          ...prev.bookingDetails,
                          passengers: prev.bookingDetails.passengers.map(
                            (edit) =>
                              edit.id === passenger.id
                                ? {
                                    ...edit,
                                    name: e.target.value,
                                  }
                                : edit
                          ),
                        },
                      }))
                    }
                    disabled={quoteLocked}
                  />
                </Col>
                <Col md={2} style={{ padding: 2 }}>
                  <FormInput
                    group={false}
                    icon={faCalendar}
                    label="Age"
                    value={passenger.age}
                    type="number"
                    placeholder="Age"
                    onChange={(e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        bookingDetails: {
                          ...prev.bookingDetails,
                          passengers: prev.bookingDetails.passengers.map(
                            (edit) =>
                              edit.id === passenger.id
                                ? {
                                    ...edit,
                                    age: e.target.value,
                                  }
                                : edit
                          ),
                        },
                      }))
                    }
                    disabled={quoteLocked}
                  />
                </Col>
                <Col md={2} style={{ padding: 2 }}>
                  <FormSelect
                    group={true}
                    icon={""}
                    label="GENDER"
                    placeholder="choose"
                    value={passenger.gender}
                    onChange={(e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        bookingDetails: {
                          ...prev.bookingDetails,
                          passengers: prev.bookingDetails.passengers.map(
                            (edit) =>
                              edit.id === passenger.id
                                ? {
                                    ...edit,
                                    gender: e.target.value,
                                  }
                                : edit
                          ),
                        },
                      }))
                    }
                    disabled={quoteLocked}
                  >
                    <option value="">SELECT ONE</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </FormSelect>
                </Col>
                <Col md={4} style={{ padding: 2 }}>
                  <FormInput
                    group={true}
                    icon={""}
                    label="Nationality"
                    value={passenger.nationality}
                    type="text"
                    placeholder="Nationality"
                    onChange={(e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        bookingDetails: {
                          ...prev.bookingDetails,
                          passengers: prev.bookingDetails.passengers.map(
                            (edit) =>
                              edit.id === passenger.id
                                ? {
                                    ...edit,
                                    nationality: e.target.value,
                                  }
                                : edit
                          ),
                        },
                      }))
                    }
                    disabled={quoteLocked}
                    inputBTN={
                      <Button
                        backgroundColor={COLOR.danger}
                        onClick={() =>
                          setQuotation((prev) => ({
                            ...prev,
                            bookingDetails: {
                              ...prev.bookingDetails,
                              passengers: prev.bookingDetails.passengers.filter(
                                (pass) => pass.id !== passenger.id
                              ),
                            },
                          }))
                        }
                        disabled={index === 0 || quoteLocked}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    }
                  />
                </Col>
              </>
            ))}
          </>
        )}

        <Col md={12} style={{ padding: 2 }}>
          <Button
            theme="light"
            onClick={() => {
              setQuotation((prev) => ({
                ...prev,
                bookingDetails: {
                  ...prev.bookingDetails,
                  passengers: [
                    ...prev.bookingDetails.passengers,
                    {
                      id: (Math.random() + 1).toString(36).substring(7),
                      name: "",
                      age: "",
                      gender: "",
                      nationality: "",
                    },
                  ],
                },
              }));
            }}
            disabled={
              quotation.details.pax ===
                quotation.bookingDetails.passengers.length || quoteLocked
            }
          >
            Add Passsenger
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PassengerForm;
