import React from "react";
import { Badge, Button, Card, CardBody } from "../..";
import { COLOR } from "../../../Hooks/utils/config";
import { useNavigate } from "@reach/router";
import { Draggable } from "react-drag-and-drop";

const AppCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Draggable type="app" data={JSON.stringify(data)} style={{height: "100%"}}>
      <Card className="main-card"style={{height: "100%"}} >
        <CardBody
          style={{
            // height: "60px",
            padding: 10,
            textAlign: "center",
          }}
        >
          <img
            src={data.logo}
            style={{ maxWidth: "100%", margin: "0 auto", height: "25px" }}
          />
        </CardBody>
        <CardBody style={{ padding: 2, textAlign: "center", height: "fit-content" }}>
          <label style={{ margin: "0 auto", fontSize: "12px" }}>
            {data.offers.length !== 0 ? (
              <>
                {data.offers.map((offer) => (
                  <Badge theme="info" style={{marginRight: 3, marginBottom: 3}}>{offer}</Badge>
                ))}
              </>
            ) : (
              <>
                {data.description.slice(0, 25)}{" "}
                {data.description.length > 25 && "..."}
              </>
            )}
          </label>
        </CardBody>
        <CardBody style={{ padding: 0 }}>
          <Button
            backgroundColor={COLOR.primary}
            onClick={() =>
              data.isQuotationMaker
                ? navigate(`/apps/${data.id}`)
                : window.open(data.link)
            }
            style={{ borderRadius: "0 0 .35rem .35rem", height: "100%" }}
            block
          >
            Visit
          </Button>
        </CardBody>
      </Card>
    </Draggable>
  );
};

export default AppCard;
