import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import FormInput from "./FormInput";

const FormUploadFile = (props) => {
  const { setLoading, uploadError, setUploadError } = props;
  const [imageSize, setImageSize] = useState("");
  const [fileTypeError, setFileTypeError] = useState(false);

  const sizeLimit = 2000000;

  const onChange = (e) => {
    props.setLoading(true);
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    setUploadError(false);
    setFileTypeError(false);
    if (["image/jpeg", "image/jpg", "image/png"].includes(files[0].type)) {
      const controller = new AbortController();

      const options = {
        // other options here
        maxSizeMB: 0.01,
        signal: controller.signal,
        maxIteration: 20,
      };

      imageCompression(files[0], options)
        .then(function (compressedFile) {
          let fileReader = new FileReader();
          fileReader.readAsDataURL(compressedFile);
          setImageSize(compressedFile.size);

          fileReader.onload = (event) => {
            if (props.action) {
              props.action(event.target.result);
            }
          };
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error.message); // output: I just want to stop
        });
    } else {
      if (files[0].size > sizeLimit) {
        setUploadError(true);

        setImageSize(files[0].size);
      } else if (["application/pdf"].includes(files[0].type)) {
        setFileTypeError(true);
      } else {
        fileReader.onload = (event) => {
          if (props.action) {
            props.action(event.target.result);
          }
          props.setLoading(false);
          setImageSize(files[0].size);
        };
      }
    }
  };

  return (
    <>
      <FormInput
        group={true}
        icon={""}
        label={props.label ? props.label : ""}
        type="file"
        multiple
        className="form-control"
        style={{ padding: 5 }}
        onChange={(e) => onChange(e)}
        // disabled={uploading || props.loading}
        accept="image/*"
      />
      {uploadError ? (
        <label style={{ fontSize: "10px", color: "red" }}>
          File is to Large ({imageSize / 1000000}MB). Limit is{" "}
          {sizeLimit / 1000000}MB only. Please compress your file or upload a
          screet shot or image format of the file.
        </label>
      ) : (
        ""
      )}
      {fileTypeError ? (
        <label style={{ fontSize: "10px", color: "red" }}>
          Images(JPG, JPEG, PNG)/ Screen shots are the only file type acceptable
        </label>
      ) : (
        ""
      )}
    </>
  );
};

export default FormUploadFile;
