import React, { useState } from "react";

import {
  Card as MyCard,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  FontAwesomeIcon,
  faArrowRight,
  faLocationDot,
} from "../..";
import { useNavigate } from "@reach/router";
import { COLOR } from "../../../Hooks/utils/config";

const DestinationCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <>
      <MyCard className="main-card" style={{ marginBottom: 20 }}>
        <CardImg
          top
          src={data.images[0].src}
          className="travel-plan-card-cover"
        />
        <CardBody className="travel-plan-card-body" style={{ height: "90px" }}>
          <p
            className="travel-plan-title"
            style={{
              fontSize: ".70rem",
              marginBottom: 0,
              fontWeight: "bold",
              color: COLOR.primary,
            }}
          >
            <FontAwesomeIcon icon={faLocationDot} /> {data.name}
          </p>

          <div
            className="review-content"
            style={{ fontSize: "0.65rem", margin: 0 }}
            dangerouslySetInnerHTML={{
              __html:
                data.short_description
                  .replace("<p>", "")
                  .replace("</p>", "")
                  .slice(0, 70) + "... Read More",
            }}
          />
        </CardBody>
        <CardFooter className="travel-plan-card-footer">
          <Button
            block
            backgroundColor={COLOR.primary}
            icon={faArrowRight}
            style={{
              borderRadius: "0rem 0rem 0.625rem 0.625rem",
            }}
            onClick={() =>
              data.external_url !== ""
                ? window.open(data.external_url)
                : navigate(`/explore/destination/${data.slug}`)
            }
          >
            View Details
          </Button>
        </CardFooter>
      </MyCard>
    </>
  );
};

export default DestinationCard;
