import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FEATURES } from "../utils/config";

function useDashboardAPI(settings) {
  const {
    plans,
    holidays,
    getHolidays,
    userBookings,
    quotations,
    getQuotations,
  } = settings;

  const YEAR = dateFormat(new Date(), "yyyy");

  const [allPlans, setAllPlan] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [incomingTravel, setIncomingTravel] = useState([]);
  const [summaryPlans, setSummaryPlan] = useState([]);

  useEffect(() => {
    if (plans.length !== 0) {
      setAllPlan(
        plans.sort(
          (a, b) => new Date(a.travel_date[0]) - new Date(b.travel_date[0])
        )
      );
    }
  }, [plans]);

  useEffect(() => {
    if (allPlans.length !== 0) {
      setCalendarEvents(
        allPlans
          .map((plan) => ({
            ...plan,
            completedChecklist: (
              (plan.checklists.filter((check) => check.status === "Done")
                .length /
                plan.checklists.length) *
              100
            ).toFixed(2),
          }))
          .map((plan) => ({
            ...plan,
            id: plan.id,
            title: (
              <>
                {plan.type === "reminder" ? (
                  <>
                    <FontAwesomeIcon icon={faBell} />{" "}
                  </>
                ) : (
                  ""
                )}
                {plan.checklists.length !== 0
                  ? `${plan.name[0]} - ${plan.completedChecklist}% completed checklist`
                  : plan.name[0]}
              </>
            ),
            start: new Date(
              `${dateFormat(
                plan.travel_date[0],
                "paddedShortDate"
              )} ${"12:00 AM"}`
            ),
            end: new Date(plan.travel_date[1]),
            type: plan.name[2],
          }))
      );
    }
  }, [plans, allPlans]);

  useEffect(() => {
    setCalendarEvents(
      calendarEvents
        .filter((event) => !["festival", "holiday"].includes(event.event_type))
        .concat(
          holidays.map((holiday) => ({
            ...holiday,
            status:
              new Date(holiday.start) < new Date() &&
              dateFormat(new Date(), "paddedShortDate") !==
                dateFormat(new Date(holiday.start), "paddedShortDate")
                ? "Done"
                : "Incomming",
          }))
        )
    );
  }, [holidays]);

  useEffect(() => {
    setCalendarEvents(
      calendarEvents.concat(
        FEATURES.BOOKING_SYSTEM
          ? userBookings
              .map((booking) => ({
                ...booking,
                title: booking.details.product.name,
                name: booking.details.product.name,
                start: new Date(
                  booking.details.product.meta_data.find(
                    (m) => m.key === "Departure Date"
                  ).value
                ),
                end: new Date(
                  booking.details.product.meta_data.find(
                    (m) => m.key === "Departure Date"
                  ).value
                ),
                trueEnd: new Date(
                  booking.details.product.meta_data.find(
                    (m) => m.key === "Departure Date"
                  ).value
                ),
                event_type: "booking",
              }))
              .map((booking) => ({
                ...booking,
                status:
                  booking.start < new Date() &&
                  dateFormat(new Date(), "paddedShortDate") !==
                    dateFormat(booking.start, "paddedShortDate")
                    ? "Done"
                    : "Incomming",
              }))
          : []
      )
    );
  }, [userBookings]);

  useEffect(() => {
    getQuotations();
  }, [plans]);

  useEffect(() => {
    // console.log(quotations);
    setCalendarEvents(
      calendarEvents
        .filter((event) => !["quotation"].includes(event.event_type))
        .concat(
          quotations.length === 0
            ? []
            : quotations
                .map((quotation) => ({
                  ...quotation.details,
                  id: quotation.id,
                  app_id: quotation.app_id,
                  link: quotation.link,
                  approval_request: quotation.approval_request,
                  username: quotation.username
                }))
                .map((quotation) => ({
                  ...quotation,
                  title: `${
                    quotation.approvalDetails?.isApproved
                      ? "APPROVED - "
                      : quotation.approval_request === 1
                      ? "REQUESTED - "
                      : ""
                  }Quote for ${quotation.name}`,
                  name: `${
                    quotation.approvalDetails?.isApproved
                      ? "APPROVED - "
                      : quotation.approval_request === 1
                      ? "REQUESTED - "
                      : ""
                  }Quote for ${quotation.name}`,
                  start: new Date(quotation.startDate),
                  end: new Date(quotation.endDate),
                  trueEnd: new Date(quotation.endDate),
                  event_type: "quotation",
                }))
        )
    );
  }, [quotations]);

  useEffect(() => {
    if (allPlans.length !== 0) {
      setIncomingTravel(
        allPlans.length !== 0
          ? allPlans
              .filter(
                (p) =>
                  p.travel_date[1] &&
                  dateFormat(p.travel_date[1], "yyyy") === YEAR
              )
              .filter(
                (p) =>
                  new Date(dateFormat(p.travel_date[1], "paddedShortDate")) >=
                  new Date()
              )
          : []
      );
    }

    setSummaryPlan({
      earned_points:
        allPlans.reduce((a, b) => a + parseInt(b.travel_points), 0) || 0,
      plans_count: allPlans.filter((p) => p.type === "plan").length || 0,
      sharedPlans_count:
        allPlans.filter((p) => p.type === "shared_plan").length || 0,
    });
  }, [allPlans]);

  return { allPlans, calendarEvents, incomingTravel, summaryPlans };
}

export default useDashboardAPI;
