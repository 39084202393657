import React from "react";
import { Card, Col, Row } from "../../../traveltayo-ui-kit";
import { useAppData } from "../../../Context/AppContext";

const Container = ({ children }) => {
    const { screenWidth} = useAppData()
  return (
    <Card
      className="main-card"
      style={{
        textAlign: "center",
        width: screenWidth ? "90%" : "30%",
        margin: "0 auto",
        marginTop: "5%",
      }}
    >
      <Row>
        <Col md={12}>{children}</Col>
        {/* <Col md={5}></Col> */}
      </Row>
    </Card>
  );
};

export default Container;
