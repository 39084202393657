import React, { Suspense, useEffect, useState } from "react";
import Empty from "../../../../Basic/Empty";
import {
  Loading,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  FormInput,
  faSearch,
  Pagination,
  FormCheckbox,
  Dropdown,
  DropdownToggle,
  faList,
  DropdownMenu,
  DropdownItem,
  FontAwesomeIcon,
  Badge,
  faClose,
} from "../../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../../Context/AppContext";
import { useNavigate } from "@reach/router";
import { useApplicationData } from "../../../../../Context/ApplicationContext";
import ListItem from "./ListItem";
import { COLOR } from "../../../../../Hooks/utils/config";

const Lists = ({ data }) => {
  const { id, application, link } = data;
  const navigate = useNavigate();
  // const { diffDays } = useCustomFunctions();
  const { user, getLoginSession } = useAppData();
  const { quotationsPerApp, getQuotationsPerApp } = useApplicationData();
  const [loading, setLoading] = useState(false);
  const [prevQuoatations, setPrevQuotations] = useState([]);
  const [agentsQuotations, setAgentsQuotations] = useState([]);

  useEffect(() => {
    getQuotationsPerApp({ id, user, otp: getLoginSession[1] });
  }, [user]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setPrevQuotations(
      quotationsPerApp.filter((quote) => quote.username === user.username)
    );
    setAgentsQuotations(
      quotationsPerApp.filter(
        (quote) =>
          quote.username !== user.username && quote.approval_request !== 0
      )
    );
  }, [quotationsPerApp, id]);

  useEffect(() => {
    if (application.length !== 0 && !application.isQuotationMaker) {
      navigate(-1);
    }
  }, [application]);

  //Owner
  const [search, setSearch] = useState("");
  const [openToggle, setOpenToggle] = useState(false);
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  //Agent
  const [searchAgent, setSearchAgent] = useState("");
  const [openToggleAgent, setOpenToggleAgent] = useState(false);
  const [filtersAgent, setFiltersAgent] = useState([]);
  const [currentPageAgent, setCurrentPageAgent] = useState(0);
  const itemsPerPageAgent = 5;

  // console.log(application);

  const FILTERS = [
    { type: "status", value: "REQUESTED" },
    { type: "status", value: "APPROVED" },
    { type: "notif", value: "QUOTATION" },
    { type: "notif", value: "PAYMENT" },
    { type: "notif", value: "DISCOUNT" },
    { type: "notif", value: "CONFIRMED" },
  ];

  const [quotations, setQuotations] = useState([]);
  const [agencyQuotations, setAgencyQuotations] = useState([]);

  useEffect(() => {
    setQuotations(
      prevQuoatations
        .sort((a, b) => {
          let c = new Date(a.details.startDate);
          let d = new Date(b.details.startDate);

          return c - d;
        })
        .filter((quo) =>
          search !== ""
            ? quo.reference.toLowerCase().includes(search.toLowerCase()) ||
              quo.details.name.toLowerCase().includes(search.toLowerCase())
            : quo
        )
        .filter((quo) =>
          filters.length !== 0
            ? filters.map((f) => f.value).includes(quo.notifStatus) ||
              filters.map((f) => f.value).includes(quo.status)
            : quo
        )
        .map((quo) => ({
          ...quo,
          link: link,
          app_id: id,
        }))
    );
  }, [prevQuoatations, search, filters]);

  useEffect(() => {
    setAgencyQuotations(
      agentsQuotations
        .sort((a, b) => {
          let c = new Date(a.details.startDate);
          let d = new Date(b.details.startDate);

          return c - d;
        })
        .filter((quo) =>
          searchAgent !== ""
            ? quo.reference.toLowerCase().includes(searchAgent.toLowerCase()) ||
              quo.details.name.toLowerCase().includes(searchAgent.toLowerCase())
            : quo
        )
        .filter((quo) =>
          filtersAgent.length !== 0
            ? filtersAgent.map((f) => f.value).includes(quo.notifStatus) ||
              filtersAgent.map((f) => f.value).includes(quo.status)
            : quo
        )
    );
  }, [agentsQuotations, searchAgent, filtersAgent]);

  return (
    <Suspense fallback={<Loading />}>
      <ListGroup style={{ marginBottom: 20 }}>
        {prevQuoatations.length !== 0 ? (
          <>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Row>
                  <Col md={5}>My Quotations</Col>
                  <Col md={5}>
                    <FormInput
                      group={true}
                      icon={faSearch}
                      label=""
                      value={search}
                      onChange={(e) =>
                        setSearch(e.target.value) || setCurrentPage(0)
                      }
                      size="sm"
                      placeholder="search for refrence #"
                    />
                  </Col>
                  <Col md={2}>
                    <Dropdown
                      open={openToggle}
                      toggle={() => setOpenToggle(!openToggle)}
                    >
                      <DropdownToggle
                        theme="dark"
                        style={{
                          backgroundColor: COLOR.primary,
                          border: `1px solid ${COLOR.primary}`,
                        }}
                        size="sm"
                        block
                      >
                        <FontAwesomeIcon icon={faList} /> Filter
                      </DropdownToggle>
                      <DropdownMenu>
                        {FILTERS.filter(
                          (f) =>
                            !filters.map((fil) => fil.value).includes(f.value)
                        ).map((filter) => (
                          <DropdownItem
                            onClick={() =>
                              setFilters((prev) => [...prev, filter])
                            }
                          >
                            {filter.value} ({filter.type})
                          </DropdownItem>
                        ))}
                        {FILTERS.filter(
                          (f) =>
                            !filters.map((fil) => fil.value).includes(f.value)
                        ).length === 0 && (
                          <DropdownItem>SELECTED ALL</DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col md={12}>
                    {filters.map((f) => (
                      <Badge
                        theme={f.type === "status" ? "success" : "info"}
                        style={{ fontSize: 10, marginRight: 2 }}
                        onClick={() =>
                          setFilters((prev) =>
                            prev.filter((filter) => filter.value !== f.value)
                          )
                        }
                      >
                        {f.value} <FontAwesomeIcon icon={faClose} />
                      </Badge>
                    ))}
                  </Col>
                </Row>
              </ListGroupItemHeading>
            </ListGroupItem>
            <>
              {loading ? (
                <ListGroupItem>Loading ...</ListGroupItem>
              ) : (
                <>
                  <ListGroupItem>
                    <Row style={{ fontWeight: 600 }}>
                      <Col md={2} xs={3} sm={3}>
                        Reference
                      </Col>
                      <Col md={3} xs={3} sm={3}>
                        Reserver Name
                      </Col>
                      <Col md={3} xs={3} sm={3}>
                        Destination
                      </Col>
                      <Col md={2} xs={2} sm={2}>
                        Travel Date
                      </Col>
                      <Col md={2} xs={2} sm={2}>
                        Actions
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <Pagination
                    list={quotations}
                    itemCount={quotations.length}
                    // actions={get}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    // setProduct={setProduct}
                    Card={ListItem}
                    itemsPerPage={itemsPerPage}
                    cardType="list2"
                  />
                </>
              )}
            </>
          </>
        ) : (
          <ListGroupItem>
            <Empty />
          </ListGroupItem>
        )}
      </ListGroup>

      {application.owner === user.username && (
        <ListGroup>
          {agentsQuotations.length !== 0 ? (
            <>
              <ListGroupItem>
                <ListGroupItemHeading>
                  <Row>
                    <Col md={5}>Agent's Quotations</Col>
                    <Col md={5}>
                      <FormInput
                        group={true}
                        icon={faSearch}
                        label=""
                        value={searchAgent}
                        onChange={(e) =>
                          setSearchAgent(e.target.value) ||
                          setCurrentPageAgent(0)
                        }
                        size="sm"
                        placeholder="search for refrence #"
                      />
                    </Col>
                    <Col md={2}>
                      <Dropdown
                        open={openToggleAgent}
                        toggle={() => setOpenToggleAgent(!openToggleAgent)}
                      >
                        <DropdownToggle
                          theme="dark"
                          style={{
                            backgroundColor: COLOR.primary,
                            border: `1px solid ${COLOR.primary}`,
                          }}
                          size="sm"
                          block
                        >
                          <FontAwesomeIcon icon={faList} /> Filter
                        </DropdownToggle>
                        <DropdownMenu>
                          {FILTERS.filter(
                            (f) =>
                              !filtersAgent.map((fil) => fil.value).includes(f.value)
                          ).map((filter) => (
                            <DropdownItem
                              onClick={() =>
                                setFiltersAgent((prev) => [...prev, filter])
                              }
                            >
                              {filter.value} ({filter.type})
                            </DropdownItem>
                          ))}
                          {FILTERS.filter(
                            (f) =>
                              !filtersAgent.map((fil) => fil.value).includes(f.value)
                          ).length === 0 && (
                            <DropdownItem>SELECTED ALL</DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col md={12}>
                      {filtersAgent.map((f) => (
                        <Badge
                          theme={f.type === "status" ? "success" : "info"}
                          style={{ fontSize: 10, marginRight: 2 }}
                          onClick={() =>
                            setFiltersAgent((prev) =>
                              prev.filter((filter) => filter.value !== f.value)
                            )
                          }
                        >
                          {f.value} <FontAwesomeIcon icon={faClose} />
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                </ListGroupItemHeading>
              </ListGroupItem>
              <>
                {loading ? (
                  <ListGroupItem>Loading ...</ListGroupItem>
                ) : (
                  <>
                    <ListGroupItem>
                      <Row style={{ fontWeight: 600 }}>
                        <Col md={2} xs={3} sm={3}>
                          Reference
                        </Col>
                        <Col md={3} xs={3} sm={3}>
                          Reserver Name
                        </Col>
                        <Col md={3} xs={3} sm={3}>
                          Destination
                        </Col>
                        <Col md={2} xs={2} sm={2}>
                          Travel Date
                        </Col>
                        <Col md={2} xs={2} sm={2}>
                          Actions
                        </Col>
                      </Row>
                    </ListGroupItem>
                    <Pagination
                      list={agencyQuotations}
                      itemCount={agencyQuotations.length}
                      // actions={get}
                      currentPage={currentPageAgent}
                      setCurrentPage={setCurrentPageAgent}
                      // setProduct={setProduct}
                      Card={ListItem}
                      itemsPerPage={itemsPerPage}
                      cardType="list2"
                    />
                  </>
                )}
              </>
            </>
          ) : (
            ""
          )}
        </ListGroup>
      )}
    </Suspense>
  );
};

export default Lists;
