import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "shards-react";
import { Button } from "..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR } from "../../Hooks/utils/config";
import { useAppData } from "../../Context/AppContext";

function ModalForm({ children, ...props }) {
  const { isDeleting, setIsDeleting } = useAppData();

  return (
    <>
      <Modal open={props.open} toggle={props.toggle} {...props}>
        {props.header ? (
          <ModalHeader style={{ padding: "10px 20px" }}>
            {props.header}
          </ModalHeader>
        ) : (
          ""
        )}
        <ModalBody
          style={{
            padding: props.bodyPadding || "10px 20px",
            overflow: "auto",
            maxHeight: "70vh",
            ...props.bodyStyles,
          }}
        >
          {children}
        </ModalBody>

        {props.withFooter || true ? (
          <ModalFooter style={{ padding: "5px 15px", display: "block" }}>
            {props.deleteBTNProps.display && !isDeleting ? (
              <Button
                theme="danger"
                onClick={() => setIsDeleting(true)}
                className="modal-delete-btn"
                disabled={props.loading}
              >
                {props.deleteBTNProps.icon ? (
                  <FontAwesomeIcon icon={props.deleteBTNProps.icon} />
                ) : (
                  ""
                )}{" "}
                {props.deleteBTNProps.label || "Delete"}{" "}
              </Button>
            ) : (
              ""
            )}

            {isDeleting ? (
              <>
                <label>Do you really want to delete this item?</label>
                <Button
                  {...props.deleteBTNProps}
                  theme={props.deleteBTNProps.theme || "light"}
                  size={props.deleteBTNProps.size || "sm"}
                  disabled={props.loading}
                >
                  {props.loading ? "Loading..." : "Yes"}
                </Button>{" "}
                <Button
                  theme="light"
                  onClick={() => setIsDeleting(false)}
                  disabled={props.loading}
                >
                  No
                </Button>{" "}
              </>
            ) : (
              <>
                {props.approvedBTNProps.display ? (
                  <Button
                    {...props.approvedBTNProps}
                    size={props.approvedBTNProps.size || "sm"}
                    backgroundColor={
                      props.approvedBTNProps.backgroundColor || COLOR.primary
                    }
                    disabled={props.loading}
                  >
                    {props.loading ? (
                      props.loadLabel ? (
                        props.loadLabel
                      ) : (
                        "Loading..."
                      )
                    ) : (
                      <>
                        {props.approvedBTNProps.icon &&
                        props.approvedBTNProps.iconPosition === "left" ? (
                          <>
                            <FontAwesomeIcon
                              icon={props.approvedBTNProps.icon}
                            />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {props.approvedBTNProps.label || "Save"}{" "}
                        {props.approvedBTNProps.icon &&
                        props.approvedBTNProps.iconPosition === "right" ? (
                          <FontAwesomeIcon icon={props.approvedBTNProps.icon} />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Button>
                ) : (
                  ""
                )}

                {props.footerComponents}

                {props.cancelBTNProps.display ? (
                  <Button
                    {...props.cancelBTNProps}
                    theme={props.cancelBTNProps.theme || "light"}
                    size={props.cancelBTNProps.size || "sm"}
                    disabled={props.loading}
                  >
                    {props.cancelBTNProps.label || "Cancel"}{" "}
                    {props.cancelBTNProps.icon ? (
                      <FontAwesomeIcon icon={props.cancelBTNProps.icon} />
                    ) : (
                      ""
                    )}
                  </Button>
                ) : (
                  ""
                )}
              </>
            )}
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}

export default ModalForm;
