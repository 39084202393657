import React from "react";
import useCustomFunctions from "../../../Hooks/api/useCustomFunctions";
import { COLOR } from "../../../Hooks/utils/config";
import {
  Card as MyCard,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  FontAwesomeIcon,
  faArrowRight,
  faClock,
  faLocationDot,
  faUsers,
  faCalendar,
} from "../..";
import { useNavigate } from "@reach/router";
import dateFormat from "dateformat";

const ExploreCard = ({ data, setProduct }) => {
  const { numberWithCommas } = useCustomFunctions();

  const navigate = useNavigate();

  return (
    <>
      <a
        href={
          data.external_url !== ""
            ? data.external_url
            : `/explore/offers/${data.slug}`
        }
        target={data.external_url !== "" ? "_blank" : ""}
        style={{
          textDecoration: "none",
        }}
      >
        <MyCard
          className="main-card card-effect"
          style={{ marginBottom: 20, height: "100%" }}
        >
          <CardImg
            top
            src={data.images[0].src}
            className="travel-plan-card-cover"
          />
          <CardBody
            className="travel-plan-card-body"
            // style={{ height: "100%" }}
          >
            <p
              className="travel-plan-title"
              style={{
                fontSize: ".75rem",
                marginBottom: 0,
                fontWeight: "bold",
                color: COLOR.primary,
              }}
            >
              {data.name}
            </p>

            {data.sale_price && (
              <p style={{ margin: 0, fontSize: ".80rem", color: "#454545" }}>
                <strike>PHP {numberWithCommas(data.regular_price)}</strike>
              </p>
            )}

            {data.manage_stock && (
              <p
                style={{
                  margin: 0,
                  fontSize: ".80rem",
                  color: "#454545",
                  fontWeight: "bold",
                }}
              >
                <FontAwesomeIcon icon={faUsers} />{" "}
                {data.stock_quantity === 0 ? (
                  "FULL BOOKED"
                ) : (
                  <>{data.stock_quantity} Slots Only</>
                )}
              </p>
            )}

            <p
              style={{
                margin: 0,
                fontSize: ".80rem",
                color: "#454545",
                fontWeight: "bold",
              }}
            >
              <FontAwesomeIcon icon={faCalendar} />{" "}
              {data.attributes.find((attr) => attr.name === "travel_dates")
                ? dateFormat(
                    data.attributes.find((attr) => attr.name === "travel_dates")
                      .options[0],
                    "ddd - mmmm dd, yyyy"
                  )
                : "Open Date Events"}
            </p>

            <p
              style={{
                marginBottom: 5,
                fontSize: "1rem",
                fontWeight: "500",
                color: "#454545",
              }}
            >
              <span style={{ color: COLOR.primary }}>
                PHP {numberWithCommas(data.price)}
              </span>
              <span style={{ fontSize: ".6rem" }}>/per person</span>
            </p>
          </CardBody>
          {/* <CardFooter className="travel-plan-card-footer">
            <Button
              block
              backgroundColor={COLOR.primary}
              icon={faArrowRight}
              style={{
                borderRadius: "0rem 0rem 0.625rem 0.625rem",
              }}
              onClick={() =>
                data.external_url !== ""
                  ? window.open(data.external_url)
                  : navigate(`/explore/offers/${data.slug}`)
              }
            >
              {data.external_url !== "" ? data.button_text : "View Details"}
            </Button>
          </CardFooter> */}
        </MyCard>
      </a>
    </>
  );
};

export default ExploreCard;
