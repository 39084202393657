import React, { useEffect, useState } from "react";
import {
  FormInput,
  ModalForm,
  faFolderPlus,
  faSave,
  faTrash,
} from "../../../../traveltayo-ui-kit";
import { useApplicationData } from "../../../../Context/ApplicationContext";

const FolderForm = ({ openFolderForm, setOpenFolderForm, editFolder }) => {
  const {
    getApplications,
    addNewFolder,
    getFolders,
    updateFolder,
    deleteFolder,
  } = useApplicationData();

  const [loading, setLoading] = useState(false);

  const [folderForm, setFolderForm] = useState({
    name: "",
  });

  useEffect(() => {
    if (editFolder.length !== 0) {
      setFolderForm(editFolder);
    }
  }, [editFolder]);

  return (
    <>
      <ModalForm
        open={openFolderForm}
        toggle={() => {}}
        header=""
        bodyPadding="10px 20px"
        loading={loading}
        approvedBTNProps={{
          // block: "block",
          display: folderForm.name !== "",
          size: "sm",
          iconPosition: "left",
          icon: faSave,
          label: folderForm.id ? "Update" : "Save",
          onClick: () =>
            setLoading(true) || folderForm.id
              ? updateFolder(folderForm, () =>
                  getFolders(
                    () =>
                      setOpenFolderForm(false) ||
                      setLoading(false) ||
                      setFolderForm({
                        name: "",
                      })
                  )
                )
              : addNewFolder(folderForm, () =>
                  getApplications(() =>
                    getFolders(
                      () =>
                        setOpenFolderForm(false) ||
                        setLoading(false) ||
                        setFolderForm({
                          name: "",
                        })
                    )
                  )
                ),
        }}
        cancelBTNProps={{
          // block: "block",
          display: true,
          size: "sm",
          onClick: () =>
            setOpenFolderForm(false) ||
            setFolderForm({
              name: "",
            }),
        }}
        deleteBTNProps={{
          display: folderForm.id,
          icon: faTrash,
          theme: "danger",
          onClick: () =>
            setLoading(true) ||
            deleteFolder(folderForm, () => window.location.reload()),
        }}
      >
        {loading ? (
          "Please wait while we save your changes.."
        ) : (
          <FormInput
            group={true}
            icon={faFolderPlus}
            label="FOLDER NAME"
            value={folderForm.name}
            onChange={(e) =>
              setFolderForm({ ...folderForm, name: e.target.value })
            }
            placeholder="enter folder name"
          />
        )}
      </ModalForm>
    </>
  );
};

export default FolderForm;
