import React, { useEffect, useState } from "react";
import {
  Col,
  FontAwesomeIcon,
  ListGroup,
  ListGroupItem,
  Loading,
  faAngleLeft,
  faAngleRight,
} from "../..";
import ReactPaginate from "react-paginate";

const Pagination = ({ children, ...props }) => {
  const {
    list,
    Card,
    setProduct,
    colCount,
    cardType,
    setOpenAppForm,
    setEditApplication,
    itemCount,
    actions,
    currentPage,
    setCurrentPage,
  } = props;
  const [loading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage || 10);
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = list.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(
    parseInt(itemCount || list.length) / itemsPerPage
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % parseInt(itemCount || list.length);
    setItemOffset(newOffset);
    let page = parseInt(event.selected) + 1;
    if (setCurrentPage) {
      setCurrentPage(event.selected);
    }
    if (actions) {
      actions(page);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  // console.log(currentItems);

  return (
    <>
      {loading ? (
        <Col md={12} style={{backgroundColor: "#fff"}}>
          <Loading static={true} />
        </Col>
      ) : (
        <>
          {parseInt(itemCount || list.length) > itemsPerPage &&
            ["card", "list"].includes(cardType) && (
              <Col md={12} className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={itemsPerPage}
                  pageCount={pageCount}
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  renderOnZeroPageCount={null}
                  pageClassName="btn"
                  pageLinkClassName="btn-sm"
                  previousClassName="btn"
                  previousLinkClassName="btn-sm"
                  nextClassName="btn"
                  nextLinkClassName="btn-sm"
                  activeClassName="active-page"
                  forcePage={currentPage}
                />
              </Col>
            )}

          {cardType && cardType === "list2" && (
            <>
              {currentItems.map((list) => (
                <>
                  {children}
                  <Card
                    data={list}
                    setEditApplication={setEditApplication}
                    setOpenAppForm={setOpenAppForm}
                  />
                </>
              ))}
              <ListGroupItem>
                <div className="pagination">
                  <ReactPaginate
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={itemsPerPage}
                    pageCount={pageCount}
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    renderOnZeroPageCount={null}
                    pageClassName="btn"
                    pageLinkClassName="btn-sm"
                    previousClassName="btn"
                    previousLinkClassName="btn-sm"
                    nextClassName="btn"
                    nextLinkClassName="btn-sm"
                    activeClassName="active-page"
                    forcePage={currentPage}
                  />
                </div>
              </ListGroupItem>
            </>
          )}

          {cardType && cardType === "list" && (
            <ListGroup style={{ marginTop: 10, marginBottom: 20 }}>
              {currentItems.map((list) => (
                <>
                  {children}
                  <Card
                    data={list}
                    setEditApplication={setEditApplication}
                    setOpenAppForm={setOpenAppForm}
                  />
                </>
              ))}
            </ListGroup>
          )}

          {cardType && cardType === "card" && (
            <>
              {currentItems.map((product) => (
                <Col
                  md={colCount ? colCount : 3}
                  sm={6}
                  xs={6}
                  style={{
                    padding: 10,
                  }}
                >
                  {children}
                  <Card
                    data={product}
                    setProduct={setProduct ? setProduct : () => ""}
                  />
                </Col>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Pagination;
