import React from "react";
import { useAppData } from "../../../../Context/AppContext";

const CoverImage = ({ children, ...props }) => {
  const { screenWidth } = useAppData();
  return (
    <div
      className="plan-cover-image"
      style={{
        background: `url(${props.src})`,
        borderRadius: screenWidth ? 0 : "0.625rem 0.625rem 0 0",
        ...props.style,
      }}
    >
      <div
        className="plan-cover-image-overlay"
        style={{ borderRadius: screenWidth ? 0 : "0.625rem 0.625rem 0 0" }}
      >
        {children}
      </div>
    </div>
  );
};

export default CoverImage;
