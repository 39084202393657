import React, { useEffect, useState, useRef } from "react";
import useQueryURL from "../../../Hooks/api/useQueryURL";
import { Link } from "@reach/router";
import {
  Button,
  Alert,
  faAt,
  faLock,
  faKey,
  Col,
  Row,
  FormInput,
  FormGroup,
  FormCheckbox,
  PasswordInput,
  Helmet,
  PageContainer,
  Card,
  CardBody,
} from "../../../traveltayo-ui-kit";
import { useAppData } from "../../../Context/AppContext";
import {
  APP_LINK,
  APP_NAME,
  COLOR,
  FEATURES,
  PLATFORM,
} from "../../../Hooks/utils/config";
import Container from "./Container";
import HCaptcha from "./HCaptcha";

const Login = () => {
  const {
    user,
    login,
    loginByAccessKey,
    valitateToken,
    formatUsername,
  } = useAppData();
  const { getQuery } = useQueryURL();

  const [viewPass, setViewPass] = useState(false);

  const redirect = getQuery("redirect");
  const [useLoginKey, setUseLoginKey] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
    accessKey: "",
  });

  useEffect(() => {
    if (user.length !== 0) {
      window.location.replace("/");
    }
  }, [user]);

  const [token, setToken] = useState(null);

  return (
    <PageContainer
      helmet={{
        title: `${APP_NAME} | Login`,
        url: `${APP_LINK}/login`,
      }}
      noAds={true}
    >
      <Container>
        <CardBody>
          <h3>{PLATFORM.LOGIN_PAGE.LOGIN_LABEL}</h3>
          <p style={{ color: "#7D7D7D", fontSize: "12px" }}>
            {PLATFORM.LOGIN_PAGE.LOGIN_SUBTEXT}
          </p>
        </CardBody>
        <CardBody>
          <Alert />

          {FEATURES.ALLOW_ACCESS_KEY && (
            <FormGroup style={{ textAlign: "left" }}>
              <FormCheckbox
                toggle
                small
                checked={useLoginKey}
                onChange={() => setUseLoginKey(!useLoginKey)}
              >
                Login using Access Key
              </FormCheckbox>
            </FormGroup>
          )}

          {useLoginKey ? (
            <FormInput
              group={true}
              icon={faKey}
              label={""}
              placeholder="access key"
              type="password"
              value={form.accessKey}
              onChange={(e) => setForm({ ...form, accessKey: e.target.value })}
              valid={
                form.accessKey ? valitateToken(form.accessKey) : form.accessKey
              }
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  loginByAccessKey(form, redirect);
                }
              }}
            />
          ) : (
            <>
              <FormInput
                group={true}
                icon={faAt}
                label={""}
                placeholder="username"
                value={form.username}
                onChange={(e) =>
                  setForm({
                    ...form,
                    username: formatUsername(e.target.value),
                  })
                }
              />
              <PasswordInput
                icon={faLock}
                viewPass={viewPass}
                setViewPass={setViewPass}
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    login(form, redirect);
                  }
                }}
              />
            </>
          )}

          <FormGroup style={{ textAlign: "left", marginBottom: 30 }}>
            <Row>
              <Col md={6} style={{ padding: 0, margin: 0 }}>
                <FormCheckbox style={{ display: "inline" }}>
                  Remember Me
                </FormCheckbox>
              </Col>
              <Col md={6} style={{ padding: 0, margin: 0 }}>
                <Link
                  to="/forgot-password"
                  style={{
                    textDecoration: "none",
                    color: COLOR.primary,
                    fontWeight: "400",
                    float: "right",
                  }}
                >
                  Forgot Password
                </Link>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <HCaptcha setToken={setToken} />
            <Button
              backgroundColor={COLOR.primary}
              style={{ width: "100%", fontSize: "15px" }}
              onClick={() =>
                useLoginKey
                  ? loginByAccessKey(form, redirect)
                  : login(form, redirect)
              }
              disabled={
                useLoginKey ? !valitateToken(form.accessKey) : false || !token
              }
            >
              Login
            </Button>
          </FormGroup>

          <br />
          {FEATURES.SIGN_UP_PAGE && (
            <FormGroup>
              <p style={{ color: "#7D7D7D", display: "inline" }}>
                Don’t have an account yet?{" "}
              </p>
              <Link
                to="/sign-up"
                style={{
                  textDecoration: "none",
                  color: COLOR.primary,
                  fontWeight: "400",
                }}
              >
                Sign Up
              </Link>
            </FormGroup>
          )}
        </CardBody>
      </Container>
    </PageContainer>
  );
};

export default Login;
