import React from "react";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { useAppData } from "../../../../../Context/AppContext";
import Customers from "./Customers";

const Index = ({ id }) => {
  const { user } = useAppData();
  return (
    <ApplicationContext>
      <Customers id={id || user.POTB} link={id ? "" : "potb-quote-maker"} />
    </ApplicationContext>
  );
};

export default Index;
