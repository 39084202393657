import React from "react";
import {
  Alert,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Button,
  FormInput,
  FormSelect,
  FontAwesomeIcon,
  faBed,
  faClose,
  faHotel,
  faLocationDot,
  faUser,
  faUsers,
} from "../../../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../../../Hooks/utils/config";


const TravelPackageForm = ({ data }) => {
  const {
    quotation,
    setQuotation,
    quoteLocked,
    availableRooms,
    travelPackages,
    totalRoomPax,
    remainingPax,
    rates,
    destinations
  } = data;
  return (
    <>
      <FormSelect
        group={true}
        icon={faLocationDot}
        label="DESTINATION"
        value={quotation.details.destination}
        onChange={(e) =>
          setQuotation((prev) => ({
            ...prev,
            details: {
              ...prev.details,
              destination: e.target.value,
            },
            bookingDetails: {
              ...prev.bookingDetails,
              hotel: "",
              room: [],
              tourDetails: {
                ...prev.bookingDetails.tourDetails,
                tours: [],
              },
            },
          }))
        }
        disabled={quoteLocked}
      >
        <option value="">Select One</option>
        {destinations.map((destination) => (
          <option value={destination}>{destination}</option>
        ))}
      </FormSelect>

      <FormSelect
        group={true}
        icon={faHotel}
        label="TRAVEL PACKAGE"
        value={quotation.bookingDetails.hotel}
        onChange={(e) =>
          setQuotation((prev) => ({
            ...prev,
            bookingDetails: {
              ...prev.bookingDetails,
              hotel: e.target.value,
              tourDetails: {
                ...prev.bookingDetails.tourDetails,
                tours: [],
              },
            },
          }))
        }
        disabled={quoteLocked}
      >
        <option value="">Select One</option>
        {travelPackages.map((hotel) => (
          <option value={hotel}>{hotel}</option>
        ))}
      </FormSelect>

      <FormGroup>
        <label
          style={{
            display: "block",
            marginBottom: "0.1rem",
            fontSize: "11px",
          }}
        >
          SELECTED ROOMS
        </label>
        {quotation.bookingDetails.room.length !== 0 ? (
          <>
            {quotation.bookingDetails.room.map((r) => (
              <Button
                onClick={() =>
                  setQuotation((prev) => ({
                    ...prev,
                    bookingDetails: {
                      ...prev.bookingDetails,
                      room: prev.bookingDetails.room.filter(
                        (ro) => ro.id !== r.id
                      ),
                    },
                  }))
                }
                style={{ marginRight: 10, marginBottom: 10 }}
                backgroundColor={COLOR.primary}
                disabled={quoteLocked}
              >
                <FontAwesomeIcon icon={r.roomPax > 1 ? faUsers : faUser} />{" "}
                {r.roomPax} pax - {r.ROOM} <FontAwesomeIcon icon={faClose} />
              </Button>
            ))}
          </>
        ) : (
          "Please select room type"
        )}
      </FormGroup>

      {availableRooms.length !== 0 && totalRoomPax < quotation.details.pax ? (
        <>
          {remainingPax > 0 && (
            <Alert theme="danger">
              You have {remainingPax} remaining pax without a room.
            </Alert>
          )}{" "}
          <ListGroup style={{ marginBottom: 10 }}>
            <ListGroupItem>
              <FormGroup>
                <FormSelect
                  group={true}
                  icon={faBed}
                  label="Room"
                  value={quotation.bookingDetails.selectedRoom}
                  onChange={(e) =>
                    setQuotation((prev) => ({
                      ...prev,
                      bookingDetails: {
                        ...prev.bookingDetails,
                        selectedRoom: e.target.value,
                        minPax:
                          e.target.value === ""
                            ? 0
                            : parseInt(
                                rates.find(
                                  (rate) =>
                                    rate.DESTINATION ===
                                      prev.details.destination &&
                                    rate.value ===
                                      prev.bookingDetails.hotel &&
                                    rate.ROOM === e.target.value
                                ).PAX
                              ),
                      },
                    }))
                  }
                  disabled={quoteLocked}
                >
                  <option value="">Select One</option>
                  {availableRooms
                    .filter((rate) => remainingPax >= parseInt(rate.PAX))
                    .map((hotel) => (
                      <option value={hotel.ROOM}>
                        {hotel.ROOM} - minimum of {hotel.PAX} pax{" "}
                      </option>
                    ))}
                </FormSelect>
                <FormInput
                  group={true}
                  icon={faUsers}
                  label="No. of pax assigned to the room"
                  value={quotation.bookingDetails.roomPax}
                  type="number"
                  onChange={(e) =>
                    setQuotation((prev) => ({
                      ...prev,
                      bookingDetails: {
                        ...prev.bookingDetails,
                        roomPax:
                          prev.bookingDetails.minPax < remainingPax
                            ? prev.bookingDetails.minPax
                            : e.target.value > remainingPax
                            ? remainingPax
                            : e.target.value,
                      },
                    }))
                  }
                  disabled={quoteLocked}
                />
                {quotation.bookingDetails.selectedRoom !== "" &&
                quotation.bookingDetails.roomPax !== 0 ? (
                  <Button
                    onClick={() =>
                      setQuotation((prev) => ({
                        ...prev,
                        bookingDetails: {
                          ...prev.bookingDetails,
                          room: [
                            ...prev.bookingDetails.room,
                            {
                              id: (Math.random() + 1).toString(36).substring(7),
                              roomPax: prev.bookingDetails.roomPax,
                              ROOM: prev.bookingDetails.selectedRoom,
                            },
                          ],
                          selectedRoom: "",
                          roomPax: 0,
                        },
                      }))
                    }
                    backgroundColor={COLOR.primary}
                    disabled={quoteLocked}
                  >
                    ADD New Room
                  </Button>
                ) : (
                  ""
                )}
              </FormGroup>
            </ListGroupItem>
          </ListGroup>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default TravelPackageForm;
