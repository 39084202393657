import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  FontAwesomeIcon,
  Row,
  faPlaneDeparture,
} from "../../../../../../traveltayo-ui-kit";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";

const FlightTicket = ({ quotation }) => {
  console.log(quotation);
  const AIRLINES = [
    { name: "Cebu Pacific", logo: "/airlines/cebpac.png", color: "#F7D81D" },
    { name: "Philippine Airline", logo: "", color: "" },
    { name: "AirAsia", logo: "/airlines/airasia.png", color: "" },
    { name: "Sunlight Air", logo: "", color: "" },
    { name: "Royal Air", logo: "", color: "" },
  ];

  const IATA = [
    {
      code: "CEB",
      name: "CEBU",
      airport: "Mactan Cebu International Airport",
      terminal: "Terminal 1",
    },
    {
      code: "MNL",
      name: "MANILA",
      airport: "Ninoy Aquino International Airport",
      terminal: "Terminal 1",
    },
  ];

  const [depAirline, setDepAirline] = useState({});
  const [retAirline, setRetAirline] = useState({});

  useEffect(() => {
    setDepAirline(
      AIRLINES.find(
        (airline) =>
          airline.name ===
          quotation.details.flightDetails.departureSelectedAirline
      )
    );

    setRetAirline(
      AIRLINES.find(
        (airline) =>
          airline.name ===
          (quotation.details.flightDetails.sameAirline
            ? quotation.details.flightDetails.departureSelectedAirline
            : quotation.details.flightDetails.returnSelectedAirline)
      )
    );
  }, [quotation]);

  return (
    <>
      {quotation.details.flightDetails.departureSelectedAirline !== "" &&
        depAirline &&
        retAirline &&
        quotation.details.flightDetails.flightFareIncluded && (
          <Card className="main-card" style={{ marginTop: 20 }}>
            <CardBody
              style={{
                padding: "10px 30px 10px 30px",
                backgroundColor: AIRLINES.find(
                  (airline) =>
                    airline.name ===
                    quotation.details.flightDetails.departureSelectedAirline
                )?.color,
                borderRadius: "0.65rem 0.65rem 0rem 0rem",
              }}
            >
              <Row>
                <Col md={3}>
                  <img
                    src={
                      AIRLINES.find(
                        (airline) =>
                          airline.name ===
                          quotation.details.flightDetails
                            .departureSelectedAirline
                      )?.logo
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col
                  md={9}
                  style={{
                    textAlign: "right",
                    color: "black",
                    margin: "auto",
                    fontSize: 25,
                    fontWeight: "bold",
                  }}
                >
                  Travel Itinerary
                </Col>
              </Row>
            </CardBody>
            <CardBody>
              <Row>
                <Col
                  md={4}
                  style={{
                    padding: "10px 30px 10px 30px",
                    fontWeight: "bold",
                    backgroundColor: AIRLINES.find(
                      (airline) =>
                        airline.name ===
                        quotation.details.flightDetails.departureSelectedAirline
                    )?.color,
                    borderRadius: "0.65rem 0.65rem 0rem 0rem",
                    border: "1px solid #E5E5E5",
                  }}
                >
                  Booking Details
                </Col>
                <Col md={2}></Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
              </Row>
              <Row style={{ border: "1px solid #E5E5E5" }}>
                {/* content */}
                <Col md={3} style={{ padding: 10, margin: "auto" }}>
                  Status:
                  <p style={{ fontWeight: "bold", margin: 0 }}>Confirmed</p>
                </Col>
                <Col md={3} style={{ margin: "auto" }}>
                  Booking Date:
                  <p style={{ fontWeight: "bold", margin: 0 }}>
                    Sat. Oct. 6, 2023
                  </p>
                </Col>
                <Col md={3} style={{ margin: "auto" }}>
                  Booking Reference:
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 23,
                      margin: 0,
                    }}
                  >
                    {quotation.details.flightDetails.departureBookingPNR}
                  </p>
                </Col>
                {/* <Col md={3}></Col> */}
              </Row>
            </CardBody>
            <CardBody>
              <Row>
                <Col
                  md={4}
                  style={{
                    padding: "10px 30px 10px 30px",
                    fontWeight: "bold",
                    backgroundColor: AIRLINES.find(
                      (airline) =>
                        airline.name ===
                        quotation.details.flightDetails.departureSelectedAirline
                    )?.color,
                    borderRadius: "0.65rem 0.65rem 0rem 0rem",
                    border: "1px solid #E5E5E5",
                  }}
                >
                  Flight Booking Details
                </Col>
                <Col md={2}></Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
              </Row>
              <Row
                style={{
                  border: "1px solid #E5E5E5",
                  fontWeight: 500,
                  backgroundColor: "#E5E5E5",
                  textAlign: "left",
                  padding: 10,
                }}
              >
                {/* content */}
                <Col md={3} style={{}}>
                  Flight No./ Airline
                </Col>
                <Col md={4} style={{}}>
                  Departure
                </Col>
                <Col md={1}></Col>
                <Col md={4} style={{}}>
                  Arrival
                </Col>
                {/* <Col md={3}></Col> */}
              </Row>
              <Row
                style={{
                  border: "1px solid #E5E5E5",
                  textAlign: "left",
                  padding: 10,
                }}
              >
                {/* content */}
                <Col md={3}>
                  {depAirline.name}
                  <p style={{ fontWeight: "bold", margin: 0, fontSize: 21 }}>
                    <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                    {quotation.details.flightDetails.departureCODE}
                  </p>
                </Col>
                <Col md={4} style={{ margin: "auto", margin: 0 }}>
                  {
                    IATA.find(
                      (aita) =>
                        aita.code ===
                        quotation.details.flightDetails.departureTerminal
                          .replace(/\s+/g, "")
                          .split("-")[0]
                    ).name
                  }{" "}
                  |{" "}
                  {
                    IATA.find(
                      (aita) =>
                        aita.code ===
                        quotation.details.flightDetails.departureTerminal
                          .replace(/\s+/g, "")
                          .split("-")[0]
                    ).code
                  }
                  <p style={{ margin: 0 }}>
                    {
                      IATA.find(
                        (aita) =>
                          aita.code ===
                          quotation.details.flightDetails.departureTerminal
                            .replace(/\s+/g, "")
                            .split("-")[0]
                      ).airport
                    }
                  </p>
                  <p style={{ margin: 0, fontWeight: 600 }}>
                    {dateFormat(
                      quotation.details.startDate,
                      "ddd. dd mmm. yyyy"
                    )}{" "}
                    {quotation.details.flightDetails.departureETD}H
                  </p>
                </Col>
                <Col md={1} style={{ margin: "auto", textAlign: "center" }}>
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </Col>
                <Col md={4} style={{ margin: 0 }}>
                  {
                    IATA.find(
                      (aita) =>
                        aita.code ===
                        quotation.details.flightDetails.departureTerminal
                          .replace(/\s+/g, "")
                          .split("-")[1]
                    ).name
                  }{" "}
                  |{" "}
                  {
                    IATA.find(
                      (aita) =>
                        aita.code ===
                        quotation.details.flightDetails.departureTerminal
                          .replace(/\s+/g, "")
                          .split("-")[1]
                    ).code
                  }
                  <p style={{ margin: 0 }}>
                    {
                      IATA.find(
                        (aita) =>
                          aita.code ===
                          quotation.details.flightDetails.departureTerminal
                            .replace(/\s+/g, "")
                            .split("-")[1]
                      ).airport
                    }
                  </p>
                  <p style={{ margin: 0, fontWeight: 600 }}>
                    {dateFormat(
                      quotation.details.startDate,
                      "ddd. dd mmm. yyyy"
                    )}{" "}
                    {quotation.details.flightDetails.departureETA}H
                  </p>
                </Col>
                {/* <Col md={3}></Col> */}
              </Row>
              {quotation.details.flightDetails.sameAirline && (
                <Row
                  style={{
                    border: "1px solid #E5E5E5",
                    textAlign: "left",
                    padding: 10,
                  }}
                >
                  {/* content */}
                  <Col md={3}>
                    {retAirline.name}
                    <p style={{ fontWeight: "bold", margin: 0, fontSize: 21 }}>
                      <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                      {quotation.details.flightDetails.returnCODE}
                    </p>
                  </Col>
                  <Col md={4} style={{ margin: "auto", margin: 0 }}>
                    {
                      IATA.find(
                        (aita) =>
                          aita.code ===
                          quotation.details.flightDetails.returnTerminal
                            .replace(/\s+/g, "")
                            .split("-")[0]
                      ).name
                    }{" "}
                    |{" "}
                    {
                      IATA.find(
                        (aita) =>
                          aita.code ===
                          quotation.details.flightDetails.returnTerminal
                            .replace(/\s+/g, "")
                            .split("-")[0]
                      ).code
                    }
                    <p style={{ margin: 0 }}>
                      {
                        IATA.find(
                          (aita) =>
                            aita.code ===
                            quotation.details.flightDetails.returnTerminal
                              .replace(/\s+/g, "")
                              .split("-")[0]
                        ).airport
                      }
                    </p>
                    <p style={{ margin: 0, fontWeight: 600 }}>
                      {dateFormat(
                        quotation.details.endDate,
                        "ddd. dd mmm. yyyy"
                      )}{" "}
                      {quotation.details.flightDetails.returnETD}H
                    </p>
                  </Col>
                  <Col md={1} style={{ margin: "auto", textAlign: "center" }}>
                    <FontAwesomeIcon icon={faArrowCircleRight} />
                  </Col>
                  <Col md={4} style={{ margin: 0 }}>
                    {
                      IATA.find(
                        (aita) =>
                          aita.code ===
                          quotation.details.flightDetails.returnTerminal
                            .replace(/\s+/g, "")
                            .split("-")[1]
                      ).name
                    }{" "}
                    |{" "}
                    {
                      IATA.find(
                        (aita) =>
                          aita.code ===
                          quotation.details.flightDetails.returnTerminal
                            .replace(/\s+/g, "")
                            .split("-")[1]
                      ).code
                    }
                    <p style={{ margin: 0 }}>
                      {
                        IATA.find(
                          (aita) =>
                            aita.code ===
                            quotation.details.flightDetails.returnTerminal
                              .replace(/\s+/g, "")
                              .split("-")[1]
                        ).airport
                      }
                    </p>
                    <p style={{ margin: 0, fontWeight: 600 }}>
                      {dateFormat(
                        quotation.details.endDate,
                        "ddd. dd mmm. yyyy"
                      )}{" "}
                      {quotation.details.flightDetails.returnETA}H
                    </p>
                  </Col>
                  {/* <Col md={3}></Col> */}
                </Row>
              )}
            </CardBody>

            {/* retrun flight different airlines */}

            {!quotation.details.flightDetails.sameAirline &&
              quotation.details.flightDetails.returnSelectedAirline !== "" && (
                <>
                  <CardBody>
                    <hr style={{ border: "2px dashed #E5E5E5" }} />
                  </CardBody>
                  <CardBody
                    style={{
                      padding: "10px 30px 10px 30px",
                      backgroundColor: retAirline?.color,
                      borderRadius: "0.65rem 0.65rem 0rem 0rem",
                    }}
                  >
                    <Row>
                      <Col md={3}>
                        <img src={retAirline?.logo} style={{ width: "100%" }} />
                      </Col>
                      <Col
                        md={9}
                        style={{
                          textAlign: "right",
                          color: "black",
                          margin: "auto",
                          fontSize: 25,
                          fontWeight: "bold",
                        }}
                      >
                        Travel Itinerary
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <Col
                        md={4}
                        style={{
                          padding: "10px 30px 10px 30px",
                          fontWeight: "bold",
                          backgroundColor: retAirline?.color,
                          borderRadius: "0.65rem 0.65rem 0rem 0rem",
                          border: "1px solid #E5E5E5",
                        }}
                      >
                        Booking Details
                      </Col>
                      <Col md={2}></Col>
                      <Col md={3}></Col>
                      <Col md={3}></Col>
                    </Row>
                    <Row style={{ border: "1px solid #E5E5E5" }}>
                      {/* content */}
                      <Col md={3} style={{ padding: 10, margin: "auto" }}>
                        Status:
                        <p style={{ fontWeight: "bold", margin: 0 }}>
                          Confirmed
                        </p>
                      </Col>
                      <Col md={3} style={{ margin: "auto" }}>
                        Booking Date:
                        <p style={{ fontWeight: "bold", margin: 0 }}>
                          Sat. Oct. 6, 2023
                        </p>
                      </Col>
                      <Col md={3} style={{ margin: "auto" }}>
                        Booking Reference:
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 23,
                            margin: 0,
                          }}
                        >
                          PYNRS
                        </p>
                      </Col>
                      {/* <Col md={3}></Col> */}
                    </Row>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <Col
                        md={4}
                        style={{
                          padding: "10px 30px 10px 30px",
                          fontWeight: "bold",
                          backgroundColor: retAirline?.color,
                          borderRadius: "0.65rem 0.65rem 0rem 0rem",
                          border: "1px solid #E5E5E5",
                        }}
                      >
                        Flight Booking Details
                      </Col>
                      <Col md={2}></Col>
                      <Col md={3}></Col>
                      <Col md={3}></Col>
                    </Row>
                    <Row
                      style={{
                        border: "1px solid #E5E5E5",
                        fontWeight: 500,
                        backgroundColor: "#E5E5E5",
                        textAlign: "left",
                        padding: 10,
                      }}
                    >
                      {/* content */}
                      <Col md={3} style={{}}>
                        Flight No./ Airline
                      </Col>
                      <Col md={4} style={{}}>
                        Departure
                      </Col>
                      <Col md={1}></Col>
                      <Col md={4} style={{}}>
                        Arrival
                      </Col>
                      {/* <Col md={3}></Col> */}
                    </Row>
                    <Row
                      style={{
                        border: "1px solid #E5E5E5",
                        textAlign: "left",
                        padding: 10,
                      }}
                    >
                      {/* content */}
                      <Col md={3}>
                        {retAirline.name}
                        <p
                          style={{
                            fontWeight: "bold",
                            margin: 0,
                            fontSize: 21,
                          }}
                        >
                          <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                          {quotation.details.flightDetails.returnCODE}
                        </p>
                      </Col>
                      <Col md={4} style={{ margin: "auto", margin: 0 }}>
                        {
                          IATA.find(
                            (aita) =>
                              aita.code ===
                              quotation.details.flightDetails.returnTerminal
                                .replace(/\s+/g, "")
                                .split("-")[0]
                          ).name
                        }{" "}
                        |{" "}
                        {
                          IATA.find(
                            (aita) =>
                              aita.code ===
                              quotation.details.flightDetails.returnTerminal
                                .replace(/\s+/g, "")
                                .split("-")[0]
                          ).code
                        }
                        <p style={{ margin: 0 }}>
                          {
                            IATA.find(
                              (aita) =>
                                aita.code ===
                                quotation.details.flightDetails.returnTerminal
                                  .replace(/\s+/g, "")
                                  .split("-")[0]
                            ).airport
                          }
                        </p>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          {dateFormat(
                            quotation.details.endDate,
                            "ddd. dd mmm. yyyy"
                          )}{" "}
                          {quotation.details.flightDetails.returnETD}H
                        </p>
                      </Col>
                      <Col
                        md={1}
                        style={{ margin: "auto", textAlign: "center" }}
                      >
                        <FontAwesomeIcon icon={faArrowCircleRight} />
                      </Col>
                      <Col md={4} style={{ margin: 0 }}>
                        {
                          IATA.find(
                            (aita) =>
                              aita.code ===
                              quotation.details.flightDetails.returnTerminal
                                .replace(/\s+/g, "")
                                .split("-")[1]
                          ).name
                        }{" "}
                        |{" "}
                        {
                          IATA.find(
                            (aita) =>
                              aita.code ===
                              quotation.details.flightDetails.returnTerminal
                                .replace(/\s+/g, "")
                                .split("-")[1]
                          ).code
                        }
                        <p style={{ margin: 0 }}>
                          {
                            IATA.find(
                              (aita) =>
                                aita.code ===
                                quotation.details.flightDetails.returnTerminal
                                  .replace(/\s+/g, "")
                                  .split("-")[1]
                            ).airport
                          }
                        </p>
                        <p style={{ margin: 0, fontWeight: 600 }}>
                          {dateFormat(
                            quotation.details.endDate,
                            "ddd. dd mmm. yyyy"
                          )}{" "}
                          {quotation.details.flightDetails.returnETA}H
                        </p>
                      </Col>
                      {/* <Col md={3}></Col> */}
                    </Row>
                  </CardBody>
                </>
              )}
          </Card>
        )}
    </>
  );
};

export default FlightTicket;
