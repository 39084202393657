import React, { useEffect, useState } from "react";
import FolderForm from "./Components/FolderForm";
import NewAppFrom from "./Components/NewAppForm";
import { useAppData } from "../../../Context/AppContext";
import {
  Loading,
  Button,
  FontAwesomeIcon,
  faFolderPlus,
  faList,
  Col,
  Row,
  PageContainer,
} from "../../../traveltayo-ui-kit";
import { useApplicationData } from "../../../Context/ApplicationContext";
import Folders from "./Components/Folders";
import AllApps from "./Components/AllApps";
import { APP_LINK, APP_NAME, FEATURES } from "../../../Hooks/utils/config";

const Index = () => {
  const { user } = useAppData();
  const { getApplications, getFolders } = useApplicationData();

  const [openFolderForm, setOpenFolderForm] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [editFolder, setEditFolder] = useState([]);
  const [openAppForm, setOpenAppForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editApplication, setEditApplication] = useState([]);

  useEffect(() => {
    if (user.length !== 0) {
      getApplications(() => getFolders(() => setLoading(false)));
    }
  }, [user]);

  const initLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (user.length !== 0 && user.appMaker === 0) {
      window.location.replace("/");
    }
  }, [user]);

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Apps",
        url: APP_LINK,
      }}
      noAds={FEATURES.NO_ADS}
    >
      <FolderForm
        openFolderForm={openFolderForm}
        setOpenFolderForm={setOpenFolderForm}
        editFolder={editFolder}
      />

      {user.appMaker === 1 && (
        <NewAppFrom
          openAppForm={openAppForm}
          setOpenAppForm={setOpenAppForm}
          editApplication={editApplication}
          setEditApplication={setEditApplication}
        />
      )}
      <Row style={{ padding: 0 }}>
        {loading ? (
          <Col md={12}>
            <Loading />
          </Col>
        ) : (
          <>
            <Col md={12} style={{ marginBottom: 10 }}>
              <Row>
                <Col md={4} style={{ paddingLeft: 0 }}>
                  {FEATURES.ALLOW_FOLDERED_APP && (
                    <>
                      <Button
                        theme="light"
                        onClick={() => setOpenFolderForm(true)}
                        style={{ marginRight: 10 }}
                        backgroundColor="#fff"
                      >
                        <FontAwesomeIcon icon={faFolderPlus} /> New Folder
                      </Button>{" "}
                    </>
                  )}
                  {user.appMaker === 1 && (
                    <Button
                      theme="light"
                      onClick={() => setOpenAppForm(true)}
                      backgroundColor="#fff"
                    >
                      <FontAwesomeIcon icon={faList} /> New App
                    </Button>
                  )}
                </Col>
                <Col md={6}></Col>
                <Col md={2}></Col>
              </Row>
            </Col>
            {FEATURES.ALLOW_FOLDERED_APP && (
              <Col md={12} style={{ marginTop: 20 }}>
                <Folders
                  data={{
                    selectedFolder,
                    setSelectedFolder,
                    setEditFolder,
                    setOpenFolderForm,
                    setLoading,
                    initLoading,
                  }}
                />
                <hr />
              </Col>
            )}

            <AllApps data={{ setEditApplication, setOpenAppForm }} />
          </>
        )}
      </Row>
    </PageContainer>
  );
};

export default Index;
