import { useAppData } from "../../../Context/AppContext";

function useEditPlan(editPlan, updateChecklist, updateItinerary) {
  const { aiGenerateItinerary, updateBudget } = useAppData();

  /// CHECKLIST utils
  const insertNewChecklist = (form, checklist, actions) => {
    let checking = Array.isArray(form);
    let newList = checking ? [...checklist, ...form] : [...checklist, form];
    editPlan.checklists = newList;
    updateChecklist(editPlan, () => actions());
  };

  const updateChecklistValues = (form, checklist, actions) => {
    editPlan.checklists = checklist.map((edit) =>
      edit.id === form.id ? { ...form, last_change: new Date() } : edit
    );
    updateChecklist(editPlan, actions);
  };

  const updateChecklistStatus = (id, checklist, actions) => {
    editPlan.checklists = checklist.map((edit) =>
      edit.id === id
        ? {
            ...edit,
            status: edit.status === "Not Done" ? "Done" : "Not Done",
            last_change: new Date(),
          }
        : edit
    );
    updateChecklist(editPlan, actions);
  };

  const removeChecklistItem = (id, checklist, actions) => {
    editPlan.checklists = checklist.filter((checklist) => checklist.id !== id);
    updateChecklist(editPlan, () => actions());
  };

  ///ITINERARY utils

  const insertNewItinerary = (form, itineraries, actions) => {
    editPlan.itinerary = [...itineraries, form];
    updateItinerary(editPlan, () => actions());
  };

  const updateItineraryValues = (form, itineraries, actions) => {
    editPlan.itinerary = itineraries.map((edit) =>
      edit.id === form.id ? { ...form, last_change: new Date() } : edit
    );
    updateItinerary(editPlan, () => actions());
  };

  const regenerateItinerary = (preferences, actions) => {
    aiGenerateItinerary(
      {
        days: editPlan.days,
        destination: editPlan.name[1],
        preferences: preferences,
        budget: editPlan.budget,
      },
      (e) => {
        // console.log(e);
        editPlan.itinerary = e.itinerary;
        updateItinerary(editPlan, () =>
          updateBudget(
            { ...editPlan, id: editPlan.reference, budget: e.budget },
            actions
          )
        );
      }
    );
  };

  const removeItineraryItem = (id, itineraries, actions) => {
    editPlan.itinerary = itineraries.filter((itinerary) => itinerary.id !== id);
    updateItinerary(editPlan, () => actions());
  };

  return {
    /*CHECKLIST*/
    insertNewChecklist,
    updateChecklistValues,
    updateChecklistStatus,
    removeChecklistItem,
    /*ITINERARIES*/
    regenerateItinerary,
    insertNewItinerary,
    updateItineraryValues,
    removeItineraryItem,
  };
}

export default useEditPlan;
