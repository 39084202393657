import React, { createContext, useContext } from "react";
import { useAppData } from "./AppContext";
import useApplications from "../Hooks/api/useApplications";
import useAgency from "../Hooks/api/useAgency";

const Context = createContext();

export function useApplicationData() {
  return useContext(Context);
}

export const ApplicationContext = ({ children }) => {
  const { user, getLoginSession } = useAppData();

  const {
    addQuotation,
    quotations,
    getQuotations,
    updateQuotation,
    deleteQuotation,
    quotationsPerApp,
    getQuotationsPerApp,
    quotation,
    getQuotationsPerID,
    rates,
    getRates,
  } = useAgency({ user, getLoginSession });

  const {
    applications,
    getApplications,
    application,
    getApplicationByID,
    addNewFolder,
    folders,
    getFolders,
    folderizeApp,
    removeFolderizedApp,
    addNewApp,
    updateFolder,
    updateAppByID,
    deleteFolder,
    deleteAppByID,
    allAccessApps,
    getAllAccessApps,
    updateAgencyDetailsByID
  } = useApplications({ user, getLoginSession });

  return (
    <Context.Provider
      value={{
        addQuotation,
        quotations,
        getQuotations,
        updateQuotation,
        deleteQuotation,
        quotationsPerApp,
        getQuotationsPerApp,
        quotation,
        getQuotationsPerID,
        rates,
        getRates,
        //apps
        allAccessApps,
        getAllAccessApps,
        applications,
        getApplications,
        application,
        getApplicationByID,
        addNewFolder,
        folders,
        getFolders,
        folderizeApp,
        removeFolderizedApp,
        addNewApp,
        updateFolder,
        updateAppByID,
        deleteFolder,
        deleteAppByID,
        updateAgencyDetailsByID
      }}
    >
      {children}
    </Context.Provider>
  );
};
