import { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../utils/config";
import dateFormat from "dateformat";

function useHolidays(settings) {
  const { getLoginSession, user, auth_login, isOnline } = settings;
  const { HEADERS } = API;
  // const { getLoginSession, user } = useUsersAPI();
  const [holidays, setHolidays] = useState([]);
  const [festivals, setFestivals] = useState([]);
  const [upcommingHolidays, setUpCommingHolidays] = useState([]);

  function getHolidays(data, actions = ()=> '') {
    if (!isOnline) {
      setHolidays(JSON.parse(window.localStorage.getItem("holidays")));
    } else if (auth_login) {
      // console.log(data);
      axios
        .post(
          `${API.NEW_URL}/events/get_calendarEvents`,
          {
            month: data.month,
            year: data.year,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          if (response.data && response.data.length !== 0) {
            let data = response.data;

            setHolidays(
              data.map((d) => ({
                ...d,
                start: dateFormat(d.start),
                end: dateFormat(d.end),
                event_type: d.category,
                title: `${d.name}`,
              }))
            );
          }
          actions()
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getFestivals() {
    if (!isOnline) {
      setHolidays(JSON.parse(window.localStorage.getItem("festivals")));
      console.log("festivals offline");
    } else if (auth_login) {
      axios
        .post(
          `${API.URL}/api/map/get_festivals.php`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          if (response.data && response.data.length !== 0) {
            let data = response.data.data;

            setFestivals(
              data.map((d) => ({
                ...d,
                start: dateFormat(d.start),
                end: dateFormat(d.start),
                trueEnd: dateFormat(d.end),
                event_type: "festival",
                title: `${d.name}`,
              }))
            );
          }
        })
        .catch(function (error) {
          // handle error
          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  useEffect(() => {
    getHolidays();
    // getFestivals();
  }, []);

  useEffect(() => {
    if (holidays.length !== 0) {
      setUpCommingHolidays(
        holidays.filter((h) => new Date(h.start) > new Date())
      );
    }
  }, [holidays]);

  useEffect(() => {
    if (isOnline) {
      if (
        !window.localStorage.getItem("festivals") ||
        JSON.parse(window.localStorage.getItem("festivals")).length === 0
      ) {
        window.localStorage.setItem("festivals", JSON.stringify(festivals));
      }

      if (
        !window.localStorage.getItem("holidays") ||
        JSON.parse(window.localStorage.getItem("holidays")).length === 0
      ) {
        window.localStorage.setItem("holidays", JSON.stringify(holidays));
      }
    }
  }, [holidays, festivals]);

  return { holidays, festivals, upcommingHolidays, getHolidays, getFestivals };
}

export default useHolidays;
