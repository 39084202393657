import React from "react";
import useCustomFunctions from "../../../../../Hooks/api/useCustomFunctions";
import Empty from "../../../../Basic/Empty";
import dateFormat from "dateformat";
import { Col, Row } from "../../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../../Hooks/utils/config";
import { useAppData } from "../../../../../Context/AppContext";
import useQuotationApp from "../../../../../Hooks/utils/QuotationApp/useQuotationApp";

const ComputationView = ({ data }) => {
  const {
    application,
    editQuotation,
    updatedRates,
    details,
    bookingDetails,
    computedView,
    customerView,
  } = data;
  const { numberWithCommas } = useCustomFunctions();

  const { user } = useAppData();

  const { generateInclusionList } = useQuotationApp();

  // console.log(application);

  return (
    <div>
      {details.destination !== "" &&
      details.hotel !== "" &&
      bookingDetails.room.length !== 0 &&
      updatedRates.length !== 0 &&
      computedView &&
      computedView.length !== 0 ? (
        <div
        // style={{
        //   background: `url(${
        //     computedView.paymentDetails.isPaid &&
        //     computedView.paymentDetails.amountPaid !== 0
        //       ? PAID_STAMP
        //       : ""
        //   })`,
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        // }}
        >
          <Row
            style={{
              fontSize: "12px",
            }}
          >
            <>
              {bookingDetails.hotel !== "AirFare Only " && (
                <>
                  <Col md={3} xs={3} sm={3} style={{ fontWeight: 600 }}>
                    Travel Package
                  </Col>
                  <Col md={9} xs={9} sm={9} style={{ textAlign: "right" }}>
                    {bookingDetails.hotel}
                  </Col>

                  <Col md={5} xs={5} sm={5} style={{ fontWeight: 600 }}>
                    Rooms
                  </Col>
                  <Col md={7} xs={7} sm={7} style={{ textAlign: "right" }}>
                    {bookingDetails.room.length} -{" "}
                    {bookingDetails.room
                      .map((r) => `${r.ROOM} for ${r.roomPax}`)
                      .join(", ")}
                  </Col>

                  <Col md={5} xs={5} sm={5} style={{ fontWeight: 600 }}>
                    No. of Days
                  </Col>
                  <Col md={7} xs={7} sm={7} style={{ textAlign: "right" }}>
                    {bookingDetails.days}
                  </Col>
                  <Col md={5} xs={5} sm={5} style={{ fontWeight: 600 }}>
                    No. of Nights
                  </Col>
                  <Col md={7} xs={7} sm={7} style={{ textAlign: "right" }}>
                    {bookingDetails.night}
                  </Col>
                </>
              )}

              {customerView && (
                <>
                  <Col md={5} xs={5} sm={5} style={{ fontWeight: 600 }}>
                    Departure Details:
                  </Col>
                  <Col md={7} xs={7} sm={7} style={{ textAlign: "right" }}>
                    {details.flightDetails.flightFareIncluded
                      ? `${details.flightDetails.departureTerminal} on `
                      : ""}
                    {dateFormat(details.startDate, "fullDate")}
                  </Col>
                  <Col md={5} xs={5} sm={5} style={{ fontWeight: 600 }}>
                    Return Details:
                  </Col>
                  <Col md={7} xs={7} sm={7} style={{ textAlign: "right" }}>
                    {details.flightDetails.flightFareIncluded
                      ? `${details.flightDetails.returnTerminal} on `
                      : ""}
                    {dateFormat(details.endDate, "fullDate")}
                  </Col>
                </>
              )}
            </>

            {!customerView && (
              <>
                <Col md={12}>
                  <hr />
                </Col>
                {bookingDetails.hotel !== "AirFare Only " && (
                  <>
                    <Col
                      md={12}
                      style={{
                        fontWeight: 600,
                        marginBottom: 5,
                        fontSize: "13px",
                      }}
                    >
                      ROOM RATES COMPUTATION
                    </Col>
                    {updatedRates.map((r) => (
                      <>
                        <Col md={9} xs={9} sm={9} style={{ fontWeight: 600 }}>
                          Rates for {r.ROOM} (min. {r.PAX} pax)
                        </Col>
                        <Col
                          md={3}
                          xs={3}
                          sm={3}
                          style={{ textAlign: "right" }}
                        >
                          {numberWithCommas(r.RATE)}
                        </Col>

                        {parseFloat(r.roomPax) < parseFloat(r.PAX) ? (
                          <>
                            <Col md={9} xs={9} sm={9}>
                              Minimum Guest Rate
                            </Col>
                            <Col
                              md={3}
                              xs={3}
                              sm={3}
                              style={{ textAlign: "right" }}
                            >
                              {r.PAX}
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col md={9} xs={9} sm={9}>
                              No. of Assigned Guests
                            </Col>
                            <Col
                              md={3}
                              xs={3}
                              sm={3}
                              style={{ textAlign: "right" }}
                            >
                              {r.roomPax}
                            </Col>
                          </>
                        )}

                        <Col md={9} xs={9} sm={9}>
                          Sub-Total
                        </Col>
                        <Col
                          md={3}
                          xs={3}
                          sm={3}
                          style={{ marginBottom: 10, textAlign: "right" }}
                        >
                          {numberWithCommas(r.totalRate)}
                        </Col>
                      </>
                    ))}
                  </>
                )}

                {details.flightDetails.flightFareIncluded &&
                  editQuotation.username === user.username && (
                    <>
                      <Col
                        md={12}
                        style={{
                          fontWeight: 600,
                          marginBottom: 5,
                          fontSize: "13px",
                        }}
                      >
                        AIRFARE DETAILS
                      </Col>
                      <Col md={3} xs={3} sm={3}>
                        Departure:{" "}
                      </Col>
                      <Col md={6} xs={6} sm={6}>
                        {details.flightDetails.departureTerminal}
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        sm={3}
                        style={{ marginBottom: 10, textAlign: "right" }}
                      >
                        {numberWithCommas(details.flightDetails.departureFare)}
                      </Col>
                      <Col md={3} xs={3} sm={3}>
                        Return:{" "}
                      </Col>
                      <Col md={6} xs={6} sm={6}>
                        {details.flightDetails.returnTerminal}
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        sm={3}
                        style={{ marginBottom: 10, textAlign: "right" }}
                      >
                        {numberWithCommas(details.flightDetails.returnFare)}
                      </Col>
                    </>
                  )}

                {computedView.subTotalRates !== 0 && (
                  <>
                    <Col md={12}>
                      <hr />
                    </Col>
                    <Col
                      md={9}
                      xs={9}
                      sm={9}
                      style={{ fontWeight: 600, fontSize: "15px" }}
                    >
                      Sub-Total Room Rates
                    </Col>
                    <Col
                      md={3}
                      xs={3}
                      sm={3}
                      style={{
                        marginBottom: 10,
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      {numberWithCommas(computedView.subTotalRates)}
                    </Col>
                  </>
                )}

                {computedView.extraNightRates !== 0 && (
                  <>
                    <Col
                      md={9}
                      xs={9}
                      sm={9}
                      style={{ fontWeight: 600, fontSize: "15px" }}
                    >
                      Sub-Total Extra Night
                    </Col>
                    <Col
                      md={3}
                      xs={3}
                      sm={3}
                      style={{
                        marginBottom: 10,
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      {numberWithCommas(computedView.extraNightRates)}
                    </Col>
                  </>
                )}

                {details.flightDetails.flightFareIncluded &&
                  editQuotation.username === user.username && (
                    <>
                      <Col
                        md={9}
                        xs={9}
                        sm={9}
                        style={{ fontWeight: 600, fontSize: "15px" }}
                      >
                        Sub-Total Airfare
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        sm={3}
                        style={{
                          marginBottom: 10,
                          textAlign: "right",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        {numberWithCommas(computedView.subTotalAirfare)}
                      </Col>
                    </>
                  )}

                {bookingDetails.tourDetails.additionalTour &&
                  computedView.additionalTourRate !== 0 && (
                    <>
                      <Col
                        md={9}
                        xs={9}
                        sm={9}
                        style={{ fontWeight: 600, fontSize: "15px" }}
                      >
                        Add-on Tour
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        sm={3}
                        style={{
                          marginBottom: 10,
                          textAlign: "right",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        {numberWithCommas(
                          editQuotation.username === user.username
                            ? computedView.additionalTourRate
                            : computedView.masterAgentTotalAddOnTour
                        )}
                      </Col>
                    </>
                  )}

                {computedView.serviceFee !== 0 &&
                  editQuotation.username === user.username && (
                    <>
                      <Col
                        md={9}
                        xs={9}
                        sm={9}
                        style={{ fontWeight: 600, fontSize: "15px" }}
                      >
                        Add. Service Fee
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        sm={3}
                        style={{
                          marginBottom: 10,
                          textAlign: "right",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        {numberWithCommas(computedView.serviceFee)}
                      </Col>
                    </>
                  )}

                {computedView.discount !== 0 &&
                  editQuotation.username === user.username && (
                    <>
                      <Col
                        md={9}
                        xs={9}
                        sm={9}
                        style={{ fontWeight: 600, fontSize: "15px" }}
                      >
                        Discount: LESS
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        sm={3}
                        style={{
                          marginBottom: 10,
                          textAlign: "right",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        ({numberWithCommas(computedView.discount)})
                      </Col>
                    </>
                  )}

                {computedView.discount !== 0 &&
                  editQuotation.username !== user.username && (
                    <>
                      <Col
                        md={9}
                        xs={9}
                        sm={9}
                        style={{ fontWeight: 600, fontSize: "15px" }}
                      >
                        Commission: LESS
                      </Col>
                      <Col
                        md={3}
                        xs={3}
                        sm={3}
                        style={{
                          marginBottom: 10,
                          textAlign: "right",
                          fontWeight: 600,
                          fontSize: "15px",
                        }}
                      >
                        ({numberWithCommas(computedView.totalCommission)})
                      </Col>
                    </>
                  )}
              </>
            )}

            {editQuotation.username === user.username && (
              <>
                <Col md={12}>
                  <hr />
                  {!customerView && <h6>FOR CUSTOMER</h6>}
                </Col>

                <Col
                  md={9}
                  xs={9}
                  sm={9}
                  style={{ fontWeight: 600, fontSize: "15px" }}
                >
                  Total Package
                </Col>
                <Col
                  md={3}
                  xs={3}
                  sm={3}
                  style={{
                    textAlign: "right",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {numberWithCommas(computedView.totalPackage)}
                </Col>

                <Col
                  md={9}
                  xs={9}
                  sm={9}
                  style={{ fontWeight: 600, fontSize: "15px" }}
                >
                  Rate per Pax
                </Col>
                <Col
                  md={3}
                  xs={3}
                  sm={3}
                  style={{
                    marginBottom: 10,
                    textAlign: "right",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {numberWithCommas(computedView.perPaxRate)}
                </Col>
              </>
            )}

            {editQuotation.username !== user.username && customerView && (
              <>
                <Col md={12}>
                  <hr />
                  {!customerView && <h6>FOR MASTER AGENT</h6>}
                </Col>
                <Col
                  md={9}
                  xs={9}
                  sm={9}
                  style={{ fontWeight: 600, fontSize: "15px" }}
                >
                  Total Package
                </Col>
                <Col
                  md={3}
                  xs={3}
                  sm={3}
                  style={{
                    textAlign: "right",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {numberWithCommas(computedView.masterAgentTotalPackage)}
                </Col>

                <Col
                  md={9}
                  xs={9}
                  sm={9}
                  style={{ fontWeight: 600, fontSize: "15px" }}
                >
                  Rate per Pax
                </Col>
                <Col
                  md={3}
                  xs={3}
                  sm={3}
                  style={{
                    marginBottom: 10,
                    textAlign: "right",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {numberWithCommas(computedView.masterAgentPerPaxRate)}
                </Col>
              </>
            )}

            {!customerView && (
              <>
                <Col md={12}>
                  <hr />
                  {!customerView && <h6>FOR MASTER AGENT</h6>}
                </Col>
                <Col
                  md={9}
                  xs={9}
                  sm={9}
                  style={{ fontWeight: 600, fontSize: "15px" }}
                >
                  Total Package
                </Col>
                <Col
                  md={3}
                  xs={3}
                  sm={3}
                  style={{
                    textAlign: "right",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {numberWithCommas(computedView.masterAgentTotalPackage)}
                </Col>

                <Col
                  md={9}
                  xs={9}
                  sm={9}
                  style={{ fontWeight: 600, fontSize: "15px" }}
                >
                  Rate per Pax
                </Col>
                <Col
                  md={3}
                  xs={3}
                  sm={3}
                  style={{
                    marginBottom: 10,
                    textAlign: "right",
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {numberWithCommas(computedView.masterAgentPerPaxRate)}
                </Col>
              </>
            )}

            {editQuotation.username === user.username && (
              <>
                {!customerView && (
                  <>
                    <Col md={12}>
                      <hr />
                    </Col>
                    <Col
                      md={9}
                      xs={9}
                      sm={9}
                      style={{ fontWeight: 600, fontSize: "15px" }}
                    >
                      Total Commission
                    </Col>
                    <Col
                      md={3}
                      xs={3}
                      sm={3}
                      style={{
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      {numberWithCommas(computedView.totalCommission)}
                    </Col>
                  </>
                )}

                {bookingDetails.paymentDetails.isPaid && (
                  <>
                    <Col md={12}>
                      <hr />
                    </Col>

                    <Col
                      md={9}
                      xs={9}
                      sm={9}
                      style={{ fontWeight: 600, fontSize: "15px" }}
                    >
                      Amount Paid
                    </Col>
                    <Col
                      md={3}
                      xs={3}
                      sm={3}
                      style={{
                        marginBottom: 10,
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      {numberWithCommas(
                        bookingDetails.paymentDetails.amountPaid
                      )}
                    </Col>
                    <Col
                      md={6}
                      xs={6}
                      sm={6}
                      style={{ fontWeight: 600, fontSize: "15px" }}
                    >
                      {computedView.isFullyPaid ? "Status" : "Balance Left"}
                    </Col>
                    <Col
                      md={6}
                      xs={6}
                      sm={6}
                      style={{
                        marginBottom: 10,
                        textAlign: "right",
                        fontWeight: 600,
                        fontSize: "15px",
                        color: computedView.isFullyPaid
                          ? "inherit"
                          : COLOR.danger,
                      }}
                    >
                      {computedView.isFullyPaid
                        ? "CONFIRMED"
                        : numberWithCommas(computedView.balanceLeft)}
                    </Col>
                    <Col
                      md={4}
                      xs={4}
                      sm={4}
                      style={{ fontWeight: 600, fontSize: "15px" }}
                    >
                      Payment Ref.
                    </Col>
                    <Col
                      md={8}
                      xs={8}
                      sm={8}
                      style={{
                        textAlign: "right",
                        fontSize: "15px",
                        fontWeight: 600,
                      }}
                    >
                      {bookingDetails.paymentDetails.paymentReference} via{" "}
                      {bookingDetails.paymentDetails.paymentMethod}
                      {bookingDetails.paymentDetails.notes && (
                        <p
                          style={{
                            margin: 0,
                            textAlign: "right",
                            fontSize: "15px",
                            fontWeight: "normal",
                          }}
                        >
                          {bookingDetails.paymentDetails.notes}
                        </p>
                      )}
                    </Col>
                  </>
                )}
              </>
            )}

            <Col md={12}>
              <hr />
            </Col>

            {bookingDetails.tourDetails.additionalTour &&
              bookingDetails.tourDetails.tours.length !== 0 && (
                <>
                  <Col
                    md={12}
                    style={{
                      fontWeight: 600,
                      marginBottom: 5,
                      fontSize: "13px",
                    }}
                  >
                    TOUR DETAILS:
                  </Col>
                  <Col md={12} style={{ marginBottom: 5, fontSize: "13px" }}>
                    <ul>
                      {/* <li>{computedView.tourRate} each</li> */}
                      {bookingDetails.tourDetails.tours.map((tour) => (
                        <li style={{ listStyleType: "none" }}>
                          <strong>{tour.TOUR_NAME}: </strong>
                          {tour.DETAILS}
                          {tour?.INCLUSIONS && (
                            <ul>
                              {tour?.INCLUSIONS.map((inc) => (
                                <li>{inc}</li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </>
              )}

            <Col md={12}  style={{ padding: 0 }}>
              <Row>
                <Col md={6} sm={12} style={{ padding: 0 }}>
                  <Col
                    md={12}
                    style={{
                      fontWeight: 600,
                      marginBottom: 5,
                      fontSize: "13px",
                    }}
                  >
                    INCLUSIONS:
                  </Col>
                  <Col md={12} style={{ marginBottom: 5, fontSize: "13px" }}>
                    <ul>
                      {generateInclusionList(updatedRates, { bookingDetails })
                        .split("\n")
                        .map((inclusion) => (
                          <li style={{ listStyleType: "none" }}>{inclusion}</li>
                        ))}
                    </ul>
                  </Col>
                </Col>
                <Col md={6} style={{ padding: 0 }}>
                  <Col
                    md={12}
                    style={{
                      fontWeight: 600,
                      marginBottom: 5,
                      fontSize: "13px",
                    }}
                  >
                    ITINERARIES:
                  </Col>
                  <Col md={12} style={{ marginBottom: 5, fontSize: "13px" }}>
                    <ul>
                      {updatedRates[0].ITINERARY.map((itinerary, index) => (
                        <li style={{ listStyleType: "none" }}>
                          <strong>DAY {index + 1}:</strong> {itinerary}
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>

          {bookingDetails.paymentDetails.isPaid && (
            <>
              <hr />
              <Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Col md={12} style={{ padding: 2 }}>
                  <h6>Guests Details</h6>
                </Col>
                {bookingDetails.passengers.map((passenger) => (
                  <>
                    <Col
                      md={4}
                      style={{ padding: 2, fontSize: "13px", fontWeight: 600 }}
                    >
                      {passenger.name}
                    </Col>
                    <Col md={2} style={{ padding: 2, fontSize: "13px" }}>
                      {passenger.age}
                    </Col>
                    <Col md={3} style={{ padding: 2, fontSize: "13px" }}>
                      {passenger.gender}
                    </Col>
                    <Col md={3} style={{ padding: 2, fontSize: "13px" }}>
                      {passenger.nationality}
                    </Col>
                  </>
                ))}
              </Row>
            </>
          )}
          <hr />
        </div>
      ) : (
        <Empty>
          Please Complete the Quotation Form to generate computation
        </Empty>
      )}
    </div>
  );
};

export default ComputationView;
