import { useState } from "react";
import axios from "axios";
import config, { PLATFORM } from "../utils/config";
import useCustomFunctions from "./useCustomFunctions";

function useTravelBookings(settings) {
  const { auth_login, isOnline, user, getLoginSession } = settings;
  const { encryptMessage, decryptMessage } = useCustomFunctions();
  const { API } = config;
  const { HEADERS } = API;

  const [bookings, setBookings] = useState([]);
  const [userBookings, setUserBookings] = useState([]);

  const [searchResults, setSearchResults] = useState([]);
  const [bookingsPerPlan, setUserBookingsPerPlan] = useState([]);
  const [uploadedbookingsPerPlan, setUploadedBookingsPerPlan] = useState([]);

  const [products, setProducts] = useState([]);
  const [rtProducts, setRTProducts] = useState([]);

  function searchProducts(data) {
    // console.log();
    if (data.length !== 0) {
      axios
        .post(
          `${API.URL}/api/bookings/search_products.php`,
          {
            category: data.category || "",
            destination: data.destination
              .toLowerCase()
              .replace(/[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, " "),
            origin: data.origin
              .toLowerCase()
              .replace(/[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, " "),
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          if (response.data.totalrecords !== 0) {
            let data = response.data.data.map((d) => ({
              ...d,
              cover: `data:image/jpeg;base64,${d.cover}`,
              description: d.description ? d.description.split("|") : [],
              cancellation_policy: d.cancellation_policy
                ? d.cancellation_policy.split("|")
                : [],
              luggage_policy: d.luggage_policy
                ? d.luggage_policy.split("|")
                : [],
              itinerary: d.itineraries ? JSON.parse(d.itineraries) : [],
            }));

            setSearchResults(data);
          } else {
            setSearchResults([]);
          }
        })
        .catch(function (error) {
          // handle error
          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getProductsByTag(data, actions) {
    axios
      .post(
        `${API.NEW_URL}/woocommerce/get_products_by_tag`,
        {
          merchantID: 1,
          tag: data.tag,
          search: data.search,
          page: data.page || 1,
          per_page: data.per_page,
        },
        API.HEADERS
      )
      .then((response) => {
        if (response.length !== 0) {
          setProducts(
            response.data.data.map((d) => ({
              ...d,
              merchant: { id: 1 },
            }))
          );
          actions(response.data.length);
        } else {
          actions();
        }
      })
      .catch(function (error) {
        // handle error
        // window.location.reload();
        actions();
        console.log("error");
      })
      .then(function () {
        // always executed
      });
  }

  function getProducts(data, actions) {
    axios
      .post(
        `${API.NEW_URL}/woocommerce/get_products_by_tag`,
        {
          merchantID: 1,
          tag: data.tag || 18,
          search: data.search,
        },
        API.HEADERS
      )
      .then(function (response) {
        // handle success
        // console.log(response);
        if (response.length !== 0) {
          actions(response.data);
        } else {
          actions([]);
        }
      })
      .catch(function (error) {
        // handle error

        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function getProduct(data, actions) {
    if (Number.isInteger(parseInt(data.id))) {
    } else {
      axios
        .post(
          `${API.NEW_URL}/woocommerce/get_product_by_slug`,
          {
            id: data.id,
            merchantID: 1,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          actions(response);
        })
        .catch(function (error) {
          // handle error

          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getProductVariations(data, actions) {
    // console.log(data);
    axios
      .post(
        `${API.NEW_URL}/woocommerce/get_product_variations`,
        {
          id: data.id,
          merchantID: 1,
        },
        HEADERS
      )
      .then(function (response) {
        // handle success
        // console.log(response);
        actions(response);
      })
      .catch(function (error) {
        // handle error

        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function getProductReviews(data, actions) {
    axios
      .post(
        `${API.NEW_URL}/woocommerce/get_product_reviews`,
        {
          id: data.id,
          merchantID: 1,
        },
        HEADERS
      )
      .then(function (response) {
        // handle success
        // console.log(response);
        actions(response);
      })
      .catch(function (error) {
        // handle error

        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function addReviews(data, actions) {
    // console.log(data);
    axios
      .post(
        `${API.NEW_URL}/woocommerce/add_new_review`,
        {
          data: data,
        },
        HEADERS
      )
      .then(function (response) {
        actions(response);
      })
      .catch(function (error) {
        // handle error

        console.log("error");
      })
      .then(function () {
        // always executed
      });
  }

  function addNewOrder(data, merchant, actions) {
    axios
      .post(
        `${API.NEW_URL}/woocommerce/add_new_order`,
        {
          data: data,
          merchantID: merchant.id,
        },
        HEADERS
      )
      .then(function (response) {
        // handle success
        actions(response);
      })
      .catch(function (error) {
        // handle error

        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function addNewBookings(data, actions) {
    axios
      .post(
        `${API.NEW_URL}/bookings/insert_booking`,
        {
          username: data.billing.first_name,
          reference: data.id,
          order_number: data.number,
          plan_reference: data.plan_reference || "",
          reserver: encryptMessage(JSON.stringify(data.billing)),
          details: encryptMessage(
            JSON.stringify({
              merchant: data.merchant,
              product: { ...data.line_items[0] },
              startDate: data.shipping.address_1,
              endDate: data.shipping.address_1,
              roundTrip: data.city === "Round Trip",
            })
          ),
          passengers: encryptMessage(data.passengers),
          source: data.merchant.id,
        },
        HEADERS
      )
      .then(function (response) {
        // handle success
        actions(response);
      })
      .catch(function (error) {
        // handle error

        console.log("error");
      })
      .then(function () {
        // always executed
      });
  }

  function uploadBookings(data, actions) {
    if (auth_login) {
      let d_type = data.ticket.split(";")[0];
      let d_ticket = encryptMessage(data.ticket);
      let length = d_ticket.length;
      let sliceLength = Math.floor(length / 5);

      axios
        .post(
          `${API.NEW_URL}/bookings/insert_uploaded_booking`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            reference: data.reference,
            plan_reference: data.plan_reference,
            data_type: d_type,
            ticket1: d_ticket.slice(0, sliceLength),
            ticket2: d_ticket.slice(sliceLength, sliceLength * 2),
            ticket3: d_ticket.slice(sliceLength * 2, sliceLength * 3),
            ticket4: d_ticket.slice(sliceLength * 3, sliceLength * 4),
            ticket5: d_ticket.slice(sliceLength * 4),
            payment_details: encryptMessage(
              JSON.stringify({
                isPaid: data.isPaid,
                amountPaid: data.amountPaid,
                paidBy: data.paidBy,
              })
            ),
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          actions();
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getAllBookingsAdmin() {
    if (auth_login && user) {
      axios
        .post(
          `${API.URL}/api/bookings/get_all_bookings_admin.php`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          if (parseInt(response.data.totalrecords) === 0) {
            setBookings([]);
          } else {
            setBookings(
              response.data.data.map((b) => ({
                ...b,
                payment_details: b.payment_status
                  ? JSON.parse(b.payment_status)
                  : [],
                reserver: JSON.parse(decryptMessage(b.reserver)),
                passengers: JSON.parse(decryptMessage(b.passengers)),
                details: JSON.parse(decryptMessage(b.details)),
                ticket: "",
              }))
            );
          }
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getUserBookingsPerPlan(id, actions = () => "") {
    if (!isOnline && window.localStorage.getItem(`bookings-${id}`)) {
      setUserBookingsPerPlan(
        JSON.parse(
          decryptMessage(window.localStorage.getItem(`bookings-${id}`))
        )
      );
    } else if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}/bookings/get_user_bookings_per_plan`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            reference: id,
          },
          HEADERS
        )
        .then(function (response) {
          if (response.data.length === 0) {
            setUserBookingsPerPlan([]);
          } else {
            setUserBookingsPerPlan(
              response.data
                .map((b) => ({
                  ...b,
                  id1: b.id,
                  id: `${b.plan_reference}${b.id}`,
                  reserver: JSON.parse(decryptMessage(b.reserver)),
                  passengers: JSON.parse(decryptMessage(b.passengers)),
                  details: JSON.parse(decryptMessage(b.details)),
                  type: "my_booking",
                }))
                .map((b) => ({
                  ...b,
                  ...b.payment_details,
                }))
            );

            window.localStorage.setItem(
              `bookings-${id}`,
              encryptMessage(JSON.stringify(bookingsPerPlan))
            );
            actions();
          }
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getOrderData(bookings, actions) {
    let ids = bookings.map((booking) => ({
      path: `/orders/${booking.reference}`,
      merchant: booking.details.merchant,
    }));
  }

  function getAllBookingsUser(actions = () => "") {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/bookings/get_all_user_bookings`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
          },
          HEADERS
        )
        .then(function (response) {
          if (response.data.length === 0) {
            setUserBookings([]);
          } else {
            setUserBookings(
              response.data.map((b) => ({
                ...b,
                reserver: JSON.parse(decryptMessage(b.reserver)),
                passengers: JSON.parse(decryptMessage(b.passengers)),
                details: JSON.parse(decryptMessage(b.details)),
                reviews: b.reviews ? JSON.parse(decryptMessage(b.reviews)) : "",
                data_type: "application/pdf",
                type: "my_booking",
              }))
            );
          }
          actions();
        })
        .catch(function (error) {
          // handle error
          // window.location.reload();
          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getTicketByID(id, actions) {
    if (auth_login && user && id) {
      axios
        .post(
          `${API.URL}/api/bookings/get_ticket_by_booking_id.php`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: id,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          // console.log(response);
          if (response.data.totalrecords === 0) {
            actions([]);
          } else {
            actions(
              response.data.data.map((b) => ({
                ...b,
                type: "my_booking",
                ticket: `data:application/pdf;base64,${b.ticket}`,
              }))[0]
            );
          }
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getUserUploadedBookingsPerPlan(id, actions = () => "") {
    if (!isOnline && window.localStorage.getItem(`uploaded_bookings-${id}`)) {
      setUploadedBookingsPerPlan(
        JSON.parse(
          decryptMessage(window.localStorage.getItem(`uploaded_bookings-${id}`))
        )
      );
    } else if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}/bookings/get_user_uploaded_bookings_per_plan`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            plan_reference: id,
          },
          HEADERS
        )
        .then(function (response) {
          // console.log(response);
          // handle success
          if (response.data.length === 0) {
            setUploadedBookingsPerPlan([]);
          } else {
            setUploadedBookingsPerPlan(
              response.data
                .map((b) => ({
                  id1: b.id,
                  id: `${b.plan_reference}${b.id}`,
                  plan_reference: b.plan_reference,
                  reference: b.reference,
                  username: b.username,
                  timestamp: b.timestamp,
                  data_type: b.data_type.replace("data:", ""),
                  ticket: decryptMessage(
                    `${b.ticket1}${b.ticket2}${b.ticket3}${b.ticket4}${b.ticket5}`
                  ),
                  type: "uploaded_booking",
                  payment_details: b.payment_details
                    ? JSON.parse(decryptMessage(b.payment_details))
                    : [],
                }))
                .map((b) => ({
                  ...b,
                  ...b.payment_details,
                }))
            );

            // window.localStorage.setItem(
            //   `uploaded_bookings-${id}`,
            //   encryptMessage(JSON.stringify(uploadedbookingsPerPlan))
            // );
            actions();
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function removeUploadedBookings(data, actions) {
    if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}/bookings/delete_uploaded_booking_by_id`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: data.ticket_id,
            reference: data.reference,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          actions();
          // console.log(response);
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function updateBookingPlanReference(form, actions) {
    if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}/bookings/update_booking_plan_reference`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: form.id,
            plan_reference: form.plan_reference,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          actions();
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getBookingStatus(data, actions) {
    if (data) {
      axios
        .post(
          `${API.NEW_URL}/woocommerce/get_booking_status`,
          {
            bookingID: data.bookingID,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          // console.log(response);
          if (response.data.length === 0) {
            actions([{ status: 404 }]);
          } else {
            actions(response.data);
          }
        })
        .catch(function (error) {
          // handle error

          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  return {
    getProductVariations,
    getBookingStatus,
    searchProducts,
    searchResults,
    addNewBookings,
    bookingsPerPlan,
    getUserBookingsPerPlan,
    uploadedbookingsPerPlan,
    getUserUploadedBookingsPerPlan,
    uploadBookings,
    getAllBookingsAdmin,
    bookings,
    getAllBookingsUser,
    userBookings,
    removeUploadedBookings,
    getTicketByID,
    products,
    rtProducts,
    getProducts,
    addNewOrder,
    getOrderData,
    addReviews,
    getProductReviews,
    getProduct,
    updateBookingPlanReference,
    setProducts,
    getProductsByTag,
  };
}

export default useTravelBookings;
