import React, { useState, useEffect } from "react";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import {
  FormUpload,
  FormInput,
  ModalForm,
  faReceipt,
  faUpload,
  faPesoSign,
  Alert,
  FormCheckbox,
  FontAwesomeIcon,
} from "../../..";
import { usePlanData } from "../../../../Context/PlanContext";
import { useBookingsData } from "../../../../Context/BookingsContext";

const UploadBookingForm = (props) => {
  const { uploadBookings } = useBookingsData();
  const { plan, getPlan, totalExpense } = usePlanData();
  const [uploadError, setUploadError] = useState(false);

  const { numberWithCommas } = useCustomFunctions();
  const [budgetLeft, setBudgetLeft] = useState(0);

  useEffect(() => {
    setBudgetLeft(plan.budget - totalExpense);
  }, [plan, totalExpense]);

  return (
    <ModalForm
      open={props.isUploading}
      toggle={() => ""}
      bodyPadding="10px 20px"
      loading={props.loading}
      approvedBTNProps={{
        display:
          props.uploadBookingForm.reference &&
          !uploadError &&
          props.uploadBookingForm.ticket &&
          props.uploadBookingForm.amountPaid <= budgetLeft ||
          !props.uploadBookingForm.isPaid,
        size: "sm",
        iconPosition: "left",
        icon: faUpload,
        label: "Upload",
        onClick: () =>
          props.setLoading(true) ||
          uploadBookings(props.uploadBookingForm, () => props.doneUpload()),
      }}
      cancelBTNProps={{
        display: true,
        size: "sm",
        onClick: () => props.setIsUploading(!props.isUploading),
      }}
      deleteBTNProps={{
        display: false,
      }}
    >
      <FormInput
        group={true}
        icon={faReceipt}
        placeholder="enter the booking reference"
        value={props.uploadBookingForm.reference}
        label="BOOKING REFRENCE/ TICKET ID/ FILE PURPOSE"
        size={"md"}
        onChange={(e) =>
          props.setUploadBookingForm({
            ...props.uploadBookingForm,
            reference: e.target.value,
          })
        }
        disabled={props.page}
      />
      <FormUpload
        label="UPLOAD FILE HERE - Images(JPG, JPEG, PNG)/ Screen shots Only"
        action={(e) =>
          props.setUploadBookingForm({
            ...props.uploadBookingForm,
            ticket: e,
          })
        }
        loading={props.loading}
        setLoading={props.setLoading}
        acceptFiles={"image/"}
        uploadError={uploadError}
        setUploadError={setUploadError}
      />

      <FormCheckbox
        toggle
        small
        checked={props.uploadBookingForm.isPaid}
        onChange={(e) =>
          props.setUploadBookingForm({
            ...props.uploadBookingForm,
            isPaid: !props.uploadBookingForm.isPaid,
          })
        }
      >
        is this included in the budget?
      </FormCheckbox>

      <FormInput
        type="number"
        label="AMOUNT PAID"
        placeholder="enter the amount paid"
        value={props.uploadBookingForm.amountPaid}
        hintText={
          props.uploadBookingForm.amountPaid > budgetLeft
            ? "The amount you have input is higher than the budget left."
            : ""
        }
        hintColor="red"
        onChange={(e) =>
          props.setUploadBookingForm({
            ...props.uploadBookingForm,
            amountPaid: e.target.value === "" ? 0 : e.target.value,
          })
        }
      />

      {props.uploadBookingForm.isPaid && (
        <Alert theme="info">
          Budget Left: <FontAwesomeIcon icon={faPesoSign} />{" "}
          {numberWithCommas(budgetLeft.toFixed(2))}
        </Alert>
      )}
    </ModalForm>
  );
};

export default UploadBookingForm;
