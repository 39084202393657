import React from "react";
import { PageContainer } from "../../../traveltayo-ui-kit";
import Empty from "../../Basic/Empty";
import { FEATURES } from "../../../Hooks/utils/config";

const NotFound404 = () => {
  return (
    <PageContainer noAds={FEATURES.NO_ADS}>
      <Empty>Sorry, this link has been expired.</Empty>
    </PageContainer>
  );
};

export default NotFound404;
