import React from "react";
import {
  Button,
  FontAwesomeIcon,
  FormInput,
  faCalendar,
  faUser,
} from "../../../../../../traveltayo-ui-kit";
import {
  faPhone,
  faUserEdit,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import useCustomFunctions from "../../../../../../Hooks/api/useCustomFunctions";
import { COLOR } from "../../../../../../Hooks/utils/config";
import { useApplicationData } from "../../../../../../Context/ApplicationContext";
import { useAppData } from "../../../../../../Context/AppContext";

const BasicDetails = ({ data }) => {
  const {
    quotation,
    setQuotation,
    quoteLocked,
    openCustomerForm,
    setOpenCustomerForm,
    setEditCustomerData,
    editQuotation,
  } = data;
  const { diffDays } = useCustomFunctions();

  const { application } = useApplicationData();
  const { user } = useAppData();

  return (
    <>
      <div className="dropdown show" style={{ width: "100%" }}>
        <FormInput
          group={true}
          icon={faUser}
          label="RESERVER NAME"
          value={quotation.details.name}
          placeholder="reserver name"
          onChange={(e) =>
            setQuotation((prev) => ({
              ...prev,
              details: {
                ...prev.details,
                name: e.target.value,
                customer_details: {},
              },
            }))
          }
          disabled={
            quoteLocked ||
            (editQuotation.length !== 0 &&
              editQuotation.username !== user.username)
          }
          inputBTN={
            <>
              {quotation.details.customer_details &&
              !quotation.details.customer_details.name ? (
                <Button
                  onClick={() => setOpenCustomerForm(true)}
                  backgroundColor={COLOR.primary}
                  disabled={
                    quoteLocked ||
                    (editQuotation.length !== 0 &&
                      editQuotation.username !== user.username)
                  }
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    setOpenCustomerForm(true) ||
                    setEditCustomerData(application.customers.find(customer => customer.id === quotation.details.customer_details.id) )
                  }
                  backgroundColor={COLOR.primary}
                  disabled={
                    quoteLocked ||
                    (editQuotation.length !== 0 &&
                      editQuotation.username !== user.username)
                  }
                >
                  <FontAwesomeIcon icon={faUserEdit} />
                </Button>
              )}
            </>
          }
        />
        {quotation.details.customer_details &&
          !quotation.details.customer_details.name &&
          quotation.details.name !== "" &&
          application.customers.length !== 0 && (
            <div
              className="dropdown-menu show"
              aria-hidden="false"
              tabIndex="-1"
              role="menu"
              style={{ width: "100%" }}
            >
              {application.customers.filter((customer) =>
                customer.name
                  .toLowerCase()
                  .includes(quotation.details.name.toLowerCase())
              ).length !== 0 ? (
                application.customers
                  .filter((customer) =>
                    customer.name
                      .toLowerCase()
                      .includes(quotation.details.name.toLowerCase())
                  )
                  .map((customer) => (
                    <div
                      type="button"
                      tabindex="0"
                      className="dropdown-item"
                      style={{ padding: 10 }}
                      onClick={() => {
                        setQuotation((prev) => ({
                          ...prev,
                          details: {
                            ...prev.details,
                            name: customer.name,
                            customer_details: customer,
                          },
                        }));
                      }}
                    >
                      {customer.name}
                    </div>
                  ))
              ) : (
                <div
                  type="button"
                  tabindex="0"
                  className="dropdown-item"
                  style={{ padding: 10 }}
                >
                  NO RESULTS - Please add he customer data first
                </div>
              )}
            </div>
          )}
      </div>

      <FormInput
        group={true}
        icon={faUsers}
        label="NUMBER OF GUEST"
        value={quotation.details.pax}
        placeholder="number of guest"
        type="number"
        onChange={(e) =>
          setQuotation((prev) => ({
            ...prev,
            details: {
              ...prev.details,
              pax: e.target.value,
            },
          }))
        }
        disabled={
          quoteLocked ||
          (editQuotation.length !== 0 &&
            editQuotation.username !== user.username)
        }
      />

      <FormInput
        group={true}
        icon={faCalendar}
        label="TRAVEL DATE"
        inputs={[
          {
            placeholderText: "departure date",
            className: "formgroup-left-input form-control",
            type: "date",
            selected: new Date(quotation.details.startDate),
            onChange: (e) =>
              setQuotation((prev) => ({
                ...prev,
                details: {
                  ...prev.details,
                  startDate: e,
                  endDate: e,
                  days: diffDays(new Date(e), new Date(e)),
                  night: diffDays(new Date(e), new Date(e)) - 1,
                },
              })),
            minDate: new Date(),
            disabled:
              quoteLocked ||
              (editQuotation.length !== 0 &&
                editQuotation.username !== user.username),
          },
          {
            placeholderText: "return date",
            className: "formgroup-right-date-input  form-control",
            type: "date",
            selected: new Date(quotation.details.endDate),
            onChange: (e) =>
              setQuotation((prev) => ({
                ...prev,
                details: {
                  ...prev.details,
                  endDate: e,
                },
                bookingDetails: {
                  ...prev.bookingDetails,
                  days: diffDays(new Date(prev.details.startDate), new Date(e)),
                  night:
                    diffDays(new Date(prev.details.startDate), new Date(e)) - 1,
                },
              })),
            minDate: new Date(quotation.details.startDate),
            disabled:
              quoteLocked ||
              (editQuotation.length !== 0 &&
                editQuotation.username !== user.username),
          },
        ]}
      />
    </>
  );
};

export default BasicDetails;
