import React from "react";
import {
  Button,
  FontAwesomeIcon,
  faFolderOpen,
} from "../../../../traveltayo-ui-kit";
import { Droppable } from "react-drag-and-drop";
import { COLOR } from "../../../../Hooks/utils/config";
import { useApplicationData } from "../../../../Context/ApplicationContext";
import FolderContainer from "./FolderContainer";

const Folders = ({ data }) => {
  const {
    selectedFolder,
    setSelectedFolder,
    setEditFolder,
    setOpenFolderForm,
    setLoading,
    initLoading,
  } = data;

  const { folders, getApplications, folderizeApp } = useApplicationData();
  return (
    <>
      {folders.length !== 0 && (
        <>
          <h5 style={{ marginBottom: 20 }}>My Folders</h5>

          {folders.map((folder) => (
            <Droppable
              types={["app"]} // <= allowed drop types
              onDrop={(d) =>
                setSelectedFolder(JSON.parse(d.app).folder_id) ||
                JSON.parse(d.app).folder_id === folder.id
                  ? ""
                  : folderizeApp(
                      {
                        folder_id: folder.id,
                        app_id: JSON.parse(d.app).id,
                      },
                      () => initLoading() || getApplications(() => "")
                    )
              }
              style={{ display: "inline-block" }}
            >
              <Button
                onClick={() =>
                  setSelectedFolder(
                    selectedFolder !== "" && selectedFolder === folder.id
                      ? ""
                      : folder.id
                  )
                }
                style={{ marginRight: 10 }}
                size="md"
                backgroundColor={
                  selectedFolder === folder.id ? COLOR.primary : COLOR.secondary
                }
              >
                <FontAwesomeIcon icon={faFolderOpen} /> {folder.name}
              </Button>
            </Droppable>
          ))}
        </>
      )}

      {selectedFolder && (
        <FolderContainer
          data={{
            selectedFolder,
            setEditFolder,
            setOpenFolderForm,
            setLoading,
            initLoading,
          }}
        />
      )}
    </>
  );
};

export default Folders;
