import { useState, useEffect } from "react";
import dateFormat from "dateformat";
import axios from "axios";
import config, { FEATURES } from "../utils/config";
import useCustomFunctions from "./useCustomFunctions";

function useTravelPlansAPI(settings) {
  const { getLoginSession, user, auth_login, isOnline } = settings;
  const { API, PLATFORM } = config;
  const { HEADERS } = API;
  const { generateDates, diffDays, encryptMessage, decryptMessage } =
    useCustomFunctions();

  const [allPlans, setAllPlans] = useState([]);

  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState([]);
  const [buddies, setBuddies] = useState([]);

  function sendInvites(data, id, editBuddies, setEditBuddies) {
    if (auth_login) {
      axios
        .post(
          `${API.URL}/api/send_invites.php`,
          {
            email: user.email,
            otp: getLoginSession[1],
            // username: user.username,
            sender: data.sender,
            username: data.username,
            plan_name: data.plan_name,
            id: data.id,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          // window.location.reload();
          if (response.data.status === "success") {
            setEditBuddies(
              editBuddies.map((edit) =>
                edit.id == id
                  ? {
                      ...edit,
                      invite: true,
                      error: false,
                    }
                  : edit
              )
            );
          } else {
            setEditBuddies(
              editBuddies.map((edit) =>
                edit.id == id
                  ? {
                      ...edit,
                      error: true,
                    }
                  : edit
              )
            );
          }
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function planAppraisal(data) {
    return JSON.stringify({
      destination: data.destination ? 10 : 0,
      description: data.description ? 10 : 0,
      name: data.name ? 5 : 0,
      budget: data.budget && data.budget !== 0 ? 10 : 0,
      checklists: data.checklists && data.checklists.length !== 0 ? 15 : 0,
      itinerary: data.itinerary && data.itinerary.length !== 0 ? 20 : 0,
      cover: data.cover ? 5 : 0,
    });
  }

  function formatChecklist(data) {
    // console.log(data);
    return data.map((l, index) => ({
      id: index,
      name: l.checklist,
      category: l.category,
      owner: "All",
      status: "Not Done",
      username: user.username,
      description: "",
      last_edit: user.username,
      isPaid: false,
      amountPaid: 0,
      paidBy: "",
    }));
  }

  function aiGenerateItinerary(data, actions) {
    if (auth_login) {
      axios
        .post(`${API.NEW_URL}/aiGenerator/generate`, {
          email: user.email,
          otp: getLoginSession[1],
          username: user.username,
          days: data.days,
          destination: data.destination,
          preferences: data.preferences,
          budget: data.budget !== 0 ? data.budget : null,
        })
        .then(function (response) {
          // console.log(response);

          let chatGPT = response.data.choices
            .map((i) => ({ reply: i.message.content }))
            .map((i) => ({
              reply: JSON.parse(i.reply.replace(/\n/g, "")),
            }))[0].reply;

          let iti = chatGPT.itinerary.map((i, index) => ({
            id: index,
            day: i.day,
            plans: [],
            description: i.description,
            title: i.title,
            start:
              index !== 0 && i.day === chatGPT.itinerary[index - 1].day
                ? new Date(
                    `${dateFormat(new Date(), "ddd mmm dd yyyy")} ${
                      chatGPT.itinerary[index - 1].time
                    }`
                  )
                : new Date(
                    `${dateFormat(new Date(), "ddd mmm dd yyyy")} ${i.time}`
                  ),
            end: new Date(
              `${dateFormat(new Date(), "ddd mmm dd yyyy")} ${i.time}`
            ),
            category: "route",
            location: "",
            interval: 30,
            last_edit: "boone",
            isPaid: false,
            isIncBudget: i.budget,
            amountPaid: i.budget,
            paidBy: "",
          }));

          let checklist = formatChecklist(chatGPT.checklist);

          let budget =
            iti.reduce((a, b) => a + parseFloat(b.amountPaid), 0) +
            iti.reduce((a, b) => a + parseFloat(b.amountPaid), 0) * 0.3;

          // console.log(budget);

          actions({
            itinerary: iti,
            checklist: checklist,
            budget: data.budget !== 0 ? data.budget : budget,
          });
        })
        .catch(function (error) {
          // handle error
          actions({
            itinerary: [],
            checklist: [],
            budget: data.budget !== 0 ? data.budget : 0,
          });
          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getPlan(id, actions = () => "") {
    if (auth_login && id) {
      axios
        .post(
          `${API.NEW_URL}/plans/get_plan_by_id`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: id.replace(/\s+/g, "_").replace(/[\W]/g, ""),
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          // console.log(response);
          if (response.data.length == 0) {
            setPlan([]);
          } else {
            // console.log(reStructure(response.data.data));
            setPlan(reStructure(response.data)[0]);
          }
          actions();
        })
        .catch(function (error) {
          // handle error
          setPlan({ status: "error" });
          actions();
          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function addTravelPlan(data, actions) {
    // console.log(data);
    if (auth_login) {
      let reference = (Math.random() + 1).toString(36).substring(7);

      if (
        data.destination !== "" &&
        data.type !== "reminder" &&
        diffDays(data.start, data.end) <= FEATURES.AI_GEN_LIMIT_DAYS &&
        user.ai_tool === 1 &&
        data.generateAI
      ) {
        aiGenerateItinerary(
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            days: diffDays(data.start, data.end),
            destination: data.destination.replace(/[^a-zA-Z0-9]/g, " "),
            preferences: data.preferences.join(","),
            budget: data.budget,
          },
          (e) => {
            // console.log(e);
            axios
              .post(
                `${API.NEW_URL}/plans/insert_plan`,
                {
                  email: user.email,
                  otp: getLoginSession[1],
                  username: user.username,
                  budget: e.budget,
                  reference: reference,
                  //Travel Plan Data
                  name: encryptMessage(
                    `${data.name}|${data.destination}|${data.type}`
                  ),
                  description: encryptMessage(
                    `${data.description}#preferences#${
                      data.preferences ? data.preferences.join(",") : ""
                    }`
                  ),
                  travel_date: encryptMessage(`${data.start}|${data.end}`),
                  days: diffDays(data.start, data.end),
                  checklists:
                    e.checklist.lenth !== 0
                      ? encryptMessage(JSON.stringify(e.checklist))
                      : null,
                  geoData: encryptMessage(JSON.stringify(data.geoData || [])),
                  itineraries:
                    e.itinerary.length !== 0
                      ? encryptMessage(JSON.stringify(e.itinerary))
                      : null,
                  buddies: encryptMessage(
                    JSON.stringify([
                      {
                        id: (Math.random() + 1).toString(36).substring(7),
                        name: user.full_name,
                        username: getLoginSession[2],
                        role: "Organizer/ Coordinator",
                        share: 0,
                        status: "No Payment",
                        amountPaid: 0,
                        invite: false,
                        payments: [],
                        user_role: "owner",
                        edit_access: {
                          checklist: true,
                          itinerary: true,
                          expenses: true,
                          buddies: true,
                          details: true,
                        },
                      },
                    ])
                  ),
                  settings: encryptMessage(
                    JSON.stringify({
                      public: false,
                      passwordProtected: false,
                      password: "",
                    })
                  ),
                  appraisal: planAppraisal(data),
                },
                HEADERS
              )
              .then(function (response) {
                actions();

                setTimeout(() => {
                  // handle success
                  if (data.type === "plan") {
                    window.location.replace(`/plan/${reference}`);
                  }

                  if (data.event_type === "shared_travel_plan") {
                  } else {
                    getPlan(data.reference);
                    getPlans();
                  }
                }, 2000);

                // window.location.replace("/me/travelPlanner?tab=1");
                // console.log(response);
                // getPlans();
              })
              .catch(function (error) {
                // handle error

                console.log("error");
              })
              .then(function () {
                // always executed
              });
          }
        );
      } else {
        axios
          .post(
            `${API.NEW_URL}/plans/insert_plan`,
            {
              email: user.email,
              otp: getLoginSession[1],
              username: user.username,
              budget: 0,
              reference: reference,
              //Travel Plan Data
              name: encryptMessage(
                `${data.name}|${data.destination}|${data.type}`
              ),
              description: encryptMessage(
                `${data.description}#preferences#${
                  data.preferences ? data.preferences.join(",") : ""
                }`
              ),
              travel_date: encryptMessage(`${data.start}|${data.end}`),
              days: diffDays(data.start, data.end),
              checklists: encryptMessage(JSON.stringify([])),
              geoData: encryptMessage(JSON.stringify(data.geoData || [])),
              itineraries: encryptMessage(JSON.stringify([])),
              buddies: encryptMessage(
                JSON.stringify([
                  {
                    id: (Math.random() + 1).toString(36).substring(7),
                    name: user.full_name,
                    username: getLoginSession[2],
                    role: "Organizer/ Coordinator",
                    share: 0,
                    status: "No Payment",
                    amountPaid: 0,
                    invite: false,
                    payments: [],
                    user_role: "owner",
                    edit_access: {
                      checklist: true,
                      itinerary: true,
                      expenses: true,
                      buddies: true,
                      details: true,
                    },
                  },
                ])
              ),
              settings: encryptMessage(
                JSON.stringify({
                  public: false,
                  passwordProtected: false,
                  password: "",
                })
              ),
              appraisal: planAppraisal(data),
              month: dateFormat(data.start, "mmmm"),
              year: dateFormat(data.start, "yyyy"),
            },
            HEADERS
          )
          .then(function (response) {
            // handle success

            // handle success
            if (data.type === "plan") {
              window.location.replace(`/plan/${reference}`);
              actions();
            } else {
              getPlans(() => actions());
            }

            // window.location.replace("/me/travelPlanner?tab=1");
            // console.log(response);
            // getPlans();
          })
          .catch(function (error) {
            // handle error

            console.log("error");
          })
          .then(function () {
            // always executed
          });
      }
    }
  }

  function removePlan(id, actions) {
    if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}/plans/delete_plan`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: id,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          actions();
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function reStructure(data) {
    let list = data
      .map((d) => ({
        ...d,
        name: decryptMessage(d.name),
        description: decryptMessage(d.description).split("#preferences#"),
        travel_date: decryptMessage(d.travel_date),
        checklists:
          d.checklists !== null
            ? decryptMessage(d.checklists)
            : JSON.stringify([]),
        geoData: decryptMessage(d.geoData),
        buddies: decryptMessage(d.buddies),
        settings: decryptMessage(d.settings),
        itinerary:
          d.itinerary !== null
            ? decryptMessage(d.itinerary)
            : JSON.stringify([]),
        expenses:
          d.expenses !== null ? decryptMessage(d.expenses) : JSON.stringify([]),
      }))
      .map((d) => ({
        ...d,
        description: d.description[0],
        preferences: d.description[1] ? d.description[1].split(",") : "",
      }))
      .map((d) => ({
        ...d,
        access: d.access ? d.access : "edit",
        name: d.name.split("|"),
        travel_date: d.travel_date.split("|"),
        itinerary: d.itinerary !== null ? JSON.parse(d.itinerary) : [],
        expenses: d.expenses !== null ? JSON.parse(d.expenses) : [],
        buddies: d.buddies !== null ? JSON.parse(d.buddies) : [],
        checklists: d.checklists !== null ? JSON.parse(d.checklists) : [],
        geoData: d.geoData !== null ? JSON.parse(d.geoData) : [],
        month: dateFormat(new Date(d.travel_date.split("|")[0]), "mmmm"),
        daysLeft: Math.floor(
          (new Date(d.travel_date.split("|")[0]).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        ),

        settings: d.settings !== null ? JSON.parse(d.settings) : [],
      }))
      // .map((d) => ({
      //   ...d,
      //   cover: `${d.cover ? d.cover : ""}${d.cover1 ? d.cover1 : ""}${
      //     d.cover2 ? d.cover2 : ""
      //   }${d.cover3 ? d.cover3 : ""}`,
      // }))
      .map((d) => ({
        ...d,
        cover: d.cover || "/images/plan_image.jpeg",
        itinerary: d.itinerary
          .sort((a, b) => {
            return parseInt(a.day) - parseInt(b.day);
          })
          .map((itin) => ({
            ...itin,
            amountPaid: itin.amountPaid ? itin.amountPaid : 0,
          })),
      }))
      .map((d) => ({
        ...d,
        type: d.name[2],
        buddies: d.buddies.map((b) => ({
          ...b,
          edit_access: b.edit_access
            ? b.edit_access
            : {
                checklist: true,
                itinerary: true,
                expenses: true,
                buddies: true,
                details: true,
              },
        })),
      }))
      .map((d) => ({
        ...d,
        dates: generateDates(
          new Date(d.travel_date[0]),
          new Date(d.travel_date[1])
        ),
        expenses: d.expenses.map((e) => ({
          ...e,
          paid: e.paid ? e.paid : false,
          due_date: e.due_date ? new Date(e.due_date) : new Date(),
        })),
      }))
      .map((d) => ({
        ...d,
        total_paid_expenses: d.expenses
          .filter((expense) => expense.paid)
          .reduce((a, b) => a + parseFloat(b.totalAmountPaid), 0),
        total_unpaid_expenses: d.expenses
          .filter((expense) => !expense.paid)
          .reduce((a, b) => a + parseFloat(b.totalAmountPaid), 0),
      }))
      .map((d) => ({
        ...d,
        total_expenses: d.total_paid_expenses + d.total_unpaid_expenses,
      }))
      .map((d) => ({
        ...d,
        budget_used_percentage: (d.total_expenses / parseFloat(d.budget)) * 100,
      }))
      .map((d) => ({
        ...d,
        plan_status:
          new Date(d.travel_date[1]) < new Date() ? "Completed" : "Upcoming",
        settings: {
          ...d.settings,
          password: d.settings.password ? window.atob(d.settings.password) : "",
        },
        event_type: "travel_plan",
        // getLoginSession[2] === d.username
        //   ? "travel_plan"
        //   : "shared_travel_plan",
      }))
      .map((d) => ({
        ...d,
        plan_status: d.dates.find(
          (date) =>
            dateFormat(new Date(date), "paddedShortDate") ===
            dateFormat(new Date(), "paddedShortDate")
        )
          ? "Today"
          : d.plan_status,
        paid_total_expenses: d.expenses
          .filter((expense) => expense.paid)
          .map((expense) => parseFloat(expense.totalAmountPaid))
          .reduce((a, b) => a + b, 0),
        unpaid_total_expenses: d.expenses
          .filter((expense) => !expense.paid)
          .map((expense) => parseFloat(expense.totalAmountPaid))
          .reduce((a, b) => a + b, 0),
        shares: d.buddies
          .map((buddy) => parseFloat(buddy.share))
          .reduce((a, b) => a + b, 0),
      }))
      .map((d) => ({
        ...d,
        budget_left: parseFloat(d.budget) - d.paid_total_expenses,
        travel_points:
          d.event_type === "travel_plan"
            ? PLATFORM.TRAVEL_POINTS.TRAVEL_POINTS_PLANS * parseFloat(d.days)
            : d.event_type === "shared_travel_plan"
            ? PLATFORM.TRAVEL_POINTS.TRAVEL_POINTS_SHARED_PLANS *
              parseFloat(d.days)
            : 0,
      }));

    return list;
  }

  function reStructure1(data) {
    let list = data
      .map((d) => ({
        ...d,
        name: decryptMessage(d.name),
        travel_date: decryptMessage(d.travel_date),
        description: decryptMessage(d.description).split("#preferences#"),
        checklists:
          d.checklists !== null
            ? decryptMessage(d.checklists)
            : JSON.stringify([]),
      }))
      .map((d) => ({
        ...d,
        description: d.description[0],
        preferences: d.description[1] ? d.description[1].split(",") : "",
        access: d.access ? d.access : "edit",
        name: d.name.split("|"),
        travel_date: d.travel_date.split("|"),
        checklists: JSON.parse(d.checklists),
      }))
      .map((d) => ({
        ...d,
        type: d.name[2],
        dates: generateDates(
          new Date(d.travel_date[0]),
          new Date(d.travel_date[1])
        ),
        plan_status:
          new Date(d.travel_date[1]) < new Date() ? "Completed" : "Upcoming",
        event_type: "travel_plan",
        cover: d.cover || "/images/plan_image.jpeg",
      }));

    return list;
  }

  function getPlans(actions = () => "") {
    if (!isOnline) {
      setPlans(
        JSON.parse(decryptMessage(window.localStorage.getItem("allPlans")))
      );
    } else if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}/plans/get_user_plans`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          if (response.data.length == 0) {
            setPlans([]);
          } else {
            setPlans(reStructure1(response.data));
          }
          actions();
        })
        .catch(function (error) {
          // handle error

          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function getBuddiesPerPlan(data, actions = () => "") {
    if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}/plans/get_buddies_per_plan`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            reference: data.reference,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          if (response.data.length == 0) {
            setBuddies([]);
          } else {
            setBuddies(response.data);
          }
          actions();
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  //Edit functions components

  function updateReminderToPlan(data, action) {
    if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}${"/plans/update_reminder_to_plan.php"}`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: data.id,
            ...data,
            name: encryptMessage(`${data.name[0]}|${data.name[1]}|plan`),
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          action();

          if (data.event_type === "shared_travel_plan") {
          } else {
            getPlan(data.reference);
            getPlans();
          }

          // console.log(response);
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function updateDetails(data, action) {
    // console.log(data);
    function res() {
      getPlan(data.reference, () => getPlans() || action());
    }

    if (!isOnline) {
      window.localStorage.setItem(
        data.reference,
        encryptMessage(
          JSON.stringify({
            ...data,
            description: encryptMessage(
              `${data.description}#preferences#${
                data.preferences ? data.preferences.join(",") : ""
              }`
            ),
            name: [data.name, data.destination, data.type],
            travel_date: [data.start, data.end],
            days: diffDays(new Date(data.start), new Date(data.end)),
            daysLeft: Math.floor(
              (new Date(data.start).getTime() - new Date().getTime()) /
                (1000 * 60 * 60 * 24)
            ),
            dates: generateDates(new Date(data.start), new Date(data.end)),
          })
        )
      );
      window.localStorage.setItem(`${data.reference}-sync`, "false");
      setPlan(
        JSON.parse(decryptMessage(window.localStorage.getItem(data.reference)))
      );
      res();
    } else if (auth_login) {
      let length = data.cover.length;
      let sliceLength = Math.floor(length / 5);

      axios
        .post(
          `${API.NEW_URL}${"/plans/update_details"}`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: data.reference,
            // ...data,
            name: encryptMessage(
              `${data.name}|${data.destination}|${data.type}`
            ),
            description: encryptMessage(
              `${data.description}#preferences#${
                data.preferences ? data.preferences.join(",") : ""
              }`
            ),
            travel_date: encryptMessage(`${data.start}|${data.end}`),
            days: diffDays(new Date(data.start), new Date(data.end)),
            geoData: encryptMessage(JSON.stringify(data.geoData || [])),

            cover: data.cover.slice(0, sliceLength),
            cover1: data.cover.slice(sliceLength, sliceLength * 2),
            cover2: data.cover.slice(sliceLength * 2, sliceLength * 3),
            cover3: data.cover.slice(sliceLength * 3),

            appraisal: planAppraisal(data),
            month: dateFormat(data.start, "mmmm"),
            year: dateFormat(data.start, "yyyy"),
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          res();
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function updateBudget(data, action) {
    // console.log(data);
    function res() {
      getPlan(data.id, () => action());
    }

    if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}${"/plans/update_budget"}`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: data.id,
            budget: data.budget,
            split_type: data.split_type,
            appraisal: planAppraisal(data),
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          res();
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  //Checklist
  function updateChecklist(data, action) {
    function res() {
      getPlan(data.reference, () => action());
    }

    if (!isOnline) {
      window.localStorage.setItem(
        data.reference,
        encryptMessage(JSON.stringify(data))
      );
      window.localStorage.setItem(`${data.reference}-sync`, "false");
      setPlan(
        JSON.parse(decryptMessage(window.localStorage.getItem(data.reference)))
      );
      res();
    } else if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}/plans/update_checklist`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: data.id,
            owner: data.username,
            event_type: data.event_type,
            checklists: encryptMessage(JSON.stringify(data.checklists)),
            appraisal: planAppraisal(data),
            shared_plan: data.shared_plan,
            access: data.access,
            reference: data.reference,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          res();
          // console.log(response);
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  //Itinerary

  function updateItinerary(data, action) {
    function res() {
      getPlan(data.reference, () => getPlans() || action());
    }

    if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}${"/plans/update_itinerary"}`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: data.id,
            // ...data,
            itineraries: encryptMessage(JSON.stringify(data.itinerary)),
            appraisal: planAppraisal(data),
            reference: data.reference,
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          res();

          // console.log(response);
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function modifyBuddyList(data, actions = () => "") {
    if (auth_login) {
      axios
        .post(
          `${API.NEW_URL}${"/plans/modify_plan_buddy"}`,
          {
            email: user.email,
            plain_email: decryptMessage(data.email),
            otp: getLoginSession[1],
            username: user.username,
            buddy: data.username,
            credential: data.credential,
            reference: data.reference,
            for_update: data.for_update,
            id: data.id,
          },
          HEADERS
        )
        .then(function (response) {
          actions();
          // console.log(response);
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function duplicatePlan(data, action) {
    // console.log(data);
    if (auth_login) {
      let ref = (Math.random() + 1).toString(36).substring(7);

      function res() {
        getPlan(ref, () =>
          getPlans(() => {
            action();
            if (data.type !== "reminder") {
              window.location.replace(`/plan/${ref}`);
            }
          })
        );
      }

      axios
        .post(
          `${API.NEW_URL}${"/plans/duplicate_plan"}`,
          {
            email: user.email,
            plain_email: decryptMessage(data.email),
            otp: getLoginSession[1],
            username: user.username,
            reference: data.reference,
            name: encryptMessage(
              `${data.name[0]} (Copy)|${data.name[1]}|${data.name[2]}`
            ),
            new_reference: ref,
            buddies: encryptMessage(
              JSON.stringify([
                {
                  id: (Math.random() + 1).toString(36).substring(7),
                  name: user.full_name,
                  username: getLoginSession[2],
                  role: "Organizer/ Coordinator",
                  share: 0,
                  status: "No Payment",
                  amountPaid: 0,
                  invite: false,
                  payments: [],
                  user_role: "owner",
                  edit_access: {
                    checklist: true,
                    itinerary: true,
                    expenses: true,
                    buddies: true,
                    details: true,
                  },
                },
              ])
            ),
          },
          HEADERS
        )
        .then(function (response) {
          // handle success
          // console.log(response);
          res();

          // console.log(response);
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  useEffect(() => {
    setAllPlans(plans);
  }, [plans, auth_login]);

  useEffect(() => {
    window.localStorage.setItem(
      "allPlans",
      encryptMessage(JSON.stringify(allPlans))
    );
  }, [allPlans, isOnline]);

  useEffect(() => {
    if (isOnline && plan.length !== 0) {
      window.localStorage.setItem(
        plan.reference,
        encryptMessage(JSON.stringify(plan))
      );
    }
  }, [plan, isOnline]);

  return {
    duplicatePlan,
    addTravelPlan,
    removePlan,
    plans,
    plan,
    setPlan,
    getPlan,
    sendInvites,
    allPlans,
    getPlans,
    //small edits
    aiGenerateItinerary,
    updateDetails,
    updateReminderToPlan,
    updateChecklist,
    updateItinerary,
    updateBudget,
    buddies,
    getBuddiesPerPlan,
    modifyBuddyList,
  };
}

export default useTravelPlansAPI;
