import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import {
  Col,
  Row,
  ListGroupItem,
  FontAwesomeIcon,
  Badge,
  faArrowRight,
} from "../../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../../Hooks/utils/config";
import { Link } from "@reach/router";
import useCustomFunctions from "../../../../../Hooks/api/useCustomFunctions";

const ListItem = ({ data }) => {
  const quo = data;

  const { numberWithCommas } = useCustomFunctions();

  return (
    <ListGroupItem>
      <Row>
        <Col md={2} xs={3} sm={3}>
          {quo.computedView.amountPaid === 0 ? (
            <>
              <Badge theme="warning" style={{ fontSize: 10, marginRight: 2 }}>
                {quo.reference}
              </Badge>
            </>
          ) : quo.computedView.amountPaid !== 0 &&
            quo.computedView.isFullyPaid ? (
            <>
              <Badge theme="success" style={{ fontSize: 10, marginRight: 2 }}>
                {quo.reference}
              </Badge>
            </>
          ) : (
            <>
              <Badge theme="success" style={{ fontSize: 10, marginRight: 2 }}>
                {quo.reference}
              </Badge>
            </>
          )}
          {quo.details?.approvalDetails?.isApproved ? (
            <Badge theme="success" style={{ fontSize: 10 }}>
              APPROVED
            </Badge>
          ) : quo.approval_request === 1 ? (
            <Badge theme="info" style={{ fontSize: 10 }}>
              REQUESTED
            </Badge>
          ) : (
            ""
          )}
        </Col>
        <Col md={3} xs={3} sm={3}>
          {quo.details.name}
        </Col>
        <Col md={3} xs={3} sm={3}>
          {quo.details.flightDetails.flightFareIncluded
            ? quo.details.flightDetails.departureTerminal
            : quo.details.destination}
        </Col>
        <Col md={2} xs={2} sm={2}>
          {dateFormat(quo.details.startDate, "mediumDate")}
        </Col>
        <Col md={2} xs={2} sm={2}>
          <Link
            to={
              quo.link !== ""
                ? `/potb-quote-maker/${quo.id}`
                : `/apps/${quo.app_id}/${quo.id}`
            }
            style={{ textDecoration: "none" }}
          >
            View <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </Col>

        {/* new line data */}

        <Col md={2} xs={3} sm={3}>
          <span style={{ fontSize: 12 }}>
            Total: ₱ {numberWithCommas(quo.computedView.totalPackage)}
          </span>
        </Col>
        <Col md={3} xs={3} sm={3}>
          {quo.details.contactDetails}
        </Col>
        <Col md={3} xs={3} sm={3}>
          {quo.details.flightDetails.flightFareIncluded
            ? quo.details.flightDetails.returnTerminal
            : ""}
        </Col>
        <Col md={2} xs={2} sm={2}>
          <span style={{ color: COLOR.danger, fontWeight: 500 }}>
            {quo.daysLeft < 0 ? "EXPIRED" : `${quo.daysLeft} day/s left`}
          </span>
        </Col>
        <Col md={2} xs={2} sm={2}>
          {quo.notifStatus}
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default ListItem;
