import CryptoJS from "crypto-js";
import { useCookies } from "react-cookie";

function useCustomFunctions() {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function diffDays(d1, d2) {
    var ndays;
    var tv1 = d1.getTime(); // msec since 1970
    var tv2 = d2.getTime();

    ndays = (tv2 - tv1) / 1000 / 86400;
    ndays = Math.round(ndays - 0.5);
    return ndays + 1;
  }

  function insertFreeTime(arr, extraDays) {
    let free = [];

    for (let index = 0; index < extraDays; index++) {
      free.push("FREE TIME - gala galore, explore more of the islands or you can join optional tour ");
    }

    let list = [
      ...arr.slice(0, arr.length - 1),
      ...free,
      ...arr.slice(arr.length - 1),
    ];

    return list;
  }

  function generateDates(start, end) {
    const date = new Date(start.getTime());

    const dates = [];

    while (date <= end) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  const secretKey = process.env.REACT_APP_SECRET_KEY_CRYPTO;

  const encryptMessage = (value) => {
    let cipherText = CryptoJS.AES.encrypt(value, secretKey);

    return window.btoa(cipherText.toString());
  };

  const decryptMessage = (value) => {
    let bytes = CryptoJS.AES.decrypt(window.atob(value), secretKey);
    let decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return decrypted;
  };

  const [cookies, setCookies, removeCookie] = useCookies();

  const createCookies = (key, value, path = "/") => {
    setCookies(key, value, {
      path: path,
      maxAge: 2630000,
      secure: true,
      // httpOnly: true,
      sameSite: true,
    });
  };

  function ValidURL(str) {
    var regex =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  return {
    ValidURL,
    encryptMessage,
    decryptMessage,
    numberWithCommas,
    diffDays,
    generateDates,
    cookies,
    createCookies,
    removeCookie,
    insertFreeTime
  };
}
export default useCustomFunctions;
