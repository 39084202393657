import React from "react";
import dateFormat from "dateformat";
import { Button, FontAwesomeIcon, faCalendarPlus } from "../..";
import { atcb_action } from "add-to-calendar-button";

const AddToCalendarButton = ({ event }) => {
  const isLongWeekend = event.event_type === "long-weekend";
  const isHoliday = event.event_type === "holiday";
  const isFestivals = event.event_type === "festival";
  const isReminder = event.type === "reminder" && event.length !== 0;
  const isTravelPlan = event.type === "plan" && event.length !== 0;
  const isBooking = event.type === "my_booking";
  const isQuotation = event.event_type === "quotation";

  return (
    <Button
      theme="light"
      onClick={() =>
        atcb_action({
          name:
            isReminder || isTravelPlan
              ? `${event.name[2].toUpperCase()}: ${event.name[0]}`
              : event.name,
          startDate: dateFormat(event.start, "yyyy-mm-dd"),
          endDate: dateFormat(event.trueEnd || event.end, "yyyy-mm-dd"),
          location:
            isTravelPlan || isReminder
              ? `${event.name[1]}`
              : isQuotation
              ? event.destination
              : "",
          options: ["Google", "iCal"],
          buttonStyle: "3d",
          listStyle: "dropdown-static",
          hideTextLabelList: false,
          lightMode: "bodyScheme",
          inline: true,
          description: isTravelPlan
            ? `Please see the full details here: [url]https://app.traveltayo.com/plan/${event.reference}|${event.name[0]}[/url]`
            : isQuotation
            ? `Please see the full details here: [url]https://app.traveltayo.com/apps/${event.app_id}/${event.id}[/url]`
            : isFestivals
            ? event.description
            : "",
          pastDateHandling: "disable",
        })
      }
    >
      <FontAwesomeIcon icon={faCalendarPlus} /> Add to Calendar
    </Button>
  );
};

export default AddToCalendarButton;
