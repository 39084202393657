import React from "react";
import { useAppData } from "../../../Context/AppContext";
import { Col, Row } from "shards-react";

const Ads = () => {
  const { screenWidth } = useAppData();

  const ADS = [
    {
      brand: "Agoda",
      link: "https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1908345",
      square: "https://book.traveltayo.com/wp-content/uploads/2023/09/agoda.png",
      long: "https://book.traveltayo.com/wp-content/uploads/2023/09/long-agoda.png"
    },
    {
      brand: "Klook",
      link: "https://affiliate.klook.com/redirect?aid=35799&aff_adid=738673&k_site=https%3A%2F%2Fwww.klook.com%2F",
      square: "https://book.traveltayo.com/wp-content/uploads/2023/09/klook.png",
      long: "https://book.traveltayo.com/wp-content/uploads/2023/09/long-klook.png"
    },
    {
      brand: "12Go Asia",
      link: "https://12go.asia/en?z=4954977",
      square: "https://book.traveltayo.com/wp-content/uploads/2023/09/12Go.png",
      long: "https://book.traveltayo.com/wp-content/uploads/2023/09/long-12go.png"
    },
  ];
  return (
    <>
      <Row style={{ padding: 0, paddingTop: screenWidth ? 0 : 0 }}>
        {ADS.map((ad) => (
          <Col md={12} xs={12} sm={12} style={{padding: 0, paddingBottom: screenWidth? 5 : 0}}>
            <div
              className="main-card card"
              style={{ marginBottom: 10, borderRadius: "0.625rem" }}
            >
              {/* {data.agoda} */}
              <a href={ad.link} target="_blank">
                <img
                  src={screenWidth ? ad.long: ad.square}
                  width="100%"
                  height="auto"
                  alt={ad.brand}
                  style={{ borderRadius: "0.625rem" }}
                />
              </a>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Ads;
