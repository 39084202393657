import React, { useEffect } from "react";
import {
  Fade,
  Row,
  Col,
  FormGroup,
  FormCheckbox,
  FormInput,
  FormSelect,
  Button,
  FontAwesomeIcon,
  faUser,
  faAt,
  faPhone,
  faClose,
  faPlus,
  faUsers,
} from "../../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../../Context/AppContext";

const ReserverDetails = (props) => {
  const { validateEmail } = useAppData();
  const {
    product,
    reserver,
    setReserver,
    passengers,
    setPassenger,
    form,
    travelDetails,
    guests,
    setGuests,
  } = props.data;

  const generateGuest = (qty) => {
    let list = [];
    for (let index = 0; index < qty; index++) {
      // console.log(qty);
      list.push({
        id: (Math.random() + 2).toString(36).substring(7),
        username: "",
        full_name: `Guest ${index + 1}`,
        gender: "Male",
        age: 18,
        type: "regular",
        email: "",
        phone: "",
        passenger: true,
      });
    }

    return list;
  };

  useEffect(() => {
    if (guests !== 0 && guests !== "") {
      setPassenger((prev) => guests !== prev.length ? generateGuest(guests) : prev);
    }
  }, [guests]);

  useEffect(() => {
    if (travelDetails.departureSchedule !== "" && passengers.length !== 0) {
      setGuests(travelDetails.variant.download_limit);
    }
  }, [travelDetails, passengers]);

  return (
    <Fade>
      <h6>RESERVER DETAILS</h6>
      <Row>
        <Col md={3}>
          <FormGroup>
            <FormInput
              group={true}
              icon={faUser}
              placeholder="enter your full name"
              value={reserver.full_name}
              label="FULL NAME"
              size={"md"}
              onChange={(e) =>
                setReserver({
                  ...reserver,
                  full_name: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <FormInput
              group={true}
              icon={faAt}
              placeholder="enter your email"
              value={reserver.email}
              label="EMAIL ADDRESS"
              size={"md"}
              onChange={(e) =>
                setReserver({
                  ...reserver,
                  email: e.target.value,
                })
              }
              invalid={reserver.email !== "" && !validateEmail(reserver.email)}
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <FormInput
              group={true}
              icon={faPhone}
              placeholder="enter your mobile number"
              value={reserver.phone}
              label="MOBILE NUMBER"
              size={"md"}
              onChange={(e) =>
                setReserver({
                  ...reserver,
                  phone: e.target.value,
                })
              }
            />
          </FormGroup>
        </Col>
        {reserver.full_name !== "" &&
          reserver.email !== "" &&
          reserver.phone !== "" &&
          validateEmail(reserver.email) && (
            <Col md={3}>
              <label style={{ marginBottom: "0.1rem", fontSize: "11px" }}>
                ARE YOU A TRAVELLER ?
              </label>
              <FormCheckbox
                toggle
                small
                checked={reserver.passenger}
                onChange={() =>
                  setReserver({
                    ...reserver,
                    passenger: reserver.passenger ? false : true,
                  }) || reserver.passenger
                    ? setPassenger(
                        passengers.filter(
                          (passenger) => passenger.id !== reserver.id
                        )
                      ) || passengers.length <= 1
                      ? setPassenger([form])
                      : generateGuest(passengers.length)
                    : setPassenger(
                        passengers.length === 1
                          ? [reserver]
                          : [
                              reserver,
                              ...passengers.slice(0, passengers.length - 1),
                            ]
                      )
                }
              ></FormCheckbox>
            </Col>
          )}
      </Row>

      {reserver.full_name !== "" &&
        reserver.email !== "" &&
        reserver.phone !== "" &&
        validateEmail(reserver.email) && (
          <>
            <h6>PASSENGERS DETAILS</h6>
            <p style={{ margin: 0, fontSize: 12 }}>
              (Optional) Please change the passenger details so we can create
              correct quotations or discount
            </p>
            <hr />

            {passengers.length !== 0 ? (
              <>
                {passengers.map((passenger, index) => (
                  <Row>
                    <Col md={12}>Traveller #{index + 1}</Col>
                    <Col md={3}>
                      <FormGroup>
                        <FormInput
                          group={true}
                          icon={faUser}
                          placeholder="enter your full name"
                          value={passenger.full_name}
                          label="FULL NAME"
                          size={"md"}
                          onChange={(e) =>
                            setPassenger(
                              passengers.map((edit) =>
                                edit.id === passenger.id
                                  ? { ...edit, full_name: e.target.value }
                                  : edit
                              )
                            )
                          }
                          disabled={
                            reserver.full_name === "" &&
                            reserver.email === "" &&
                            reserver.phone === ""
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3} sm={6} xs={6}>
                      <FormGroup>
                        <FormInput
                          group={true}
                          // icon={faCalendar}
                          placeholder="enter your age"
                          value={passenger.age}
                          label="AGE"
                          size={"md"}
                          type="number"
                          onChange={(e) =>
                            setPassenger(
                              passengers.map((edit) =>
                                edit.id === passenger.id
                                  ? { ...edit, age: e.target.value }
                                  : edit
                              )
                            )
                          }
                          disabled={
                            reserver.full_name === "" &&
                            reserver.email === "" &&
                            reserver.phone === ""
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3} sm={6} xs={6}>
                      <FormGroup>
                        <FormSelect
                          label="GENDER"
                          group={true}
                          // icon={faTransgender}
                          value={passenger.gender}
                          onChange={(e) =>
                            setPassenger(
                              passengers.map((edit) =>
                                edit.id === passenger.id
                                  ? { ...edit, gender: e.target.value }
                                  : edit
                              )
                            )
                          }
                          disabled={
                            reserver.full_name === "" &&
                            reserver.email === "" &&
                            reserver.phone === ""
                          }
                        >
                          <option value="">Select One</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </FormSelect>
                      </FormGroup>
                    </Col>

                    <Col
                      md={2}
                      sm={index === 0 ? 12 : 9}
                      xs={index === 0 ? 12 : 9}
                    >
                      <FormGroup>
                        <FormSelect
                          label="DISCOUNT TYPE"
                          group={true}
                          // icon={faPercent}
                          value={passenger.type}
                          onChange={(e) =>
                            setPassenger(
                              passengers.map((edit) =>
                                edit.id === passenger.id
                                  ? { ...edit, type: e.target.value }
                                  : edit
                              )
                            )
                          }
                          disabled={
                            reserver.full_name === "" &&
                            reserver.email === "" &&
                            reserver.phone === ""
                          }
                        >
                          <option value="">Select One</option>
                          <option value="regular">Regular</option>
                          <option value="child">Child</option>
                          <option value="infant">Infant</option>
                          <option value="student">Student</option>
                          <option value="senior">Senior</option>
                        </FormSelect>
                      </FormGroup>
                    </Col>
                    <Col md={1} sm={3} xs={3}>
                      {passenger.id === reserver.id ||
                      passengers.length <= 1 ? (
                        ""
                      ) : (
                        <>
                          <label
                            style={{
                              marginBottom: "0.1rem",
                              fontSize: "11px",
                            }}
                          >
                            REMOVE
                          </label>
                          <Button
                            theme="light"
                            onClick={() =>
                              setPassenger(
                                passengers.filter((p) => p.id !== passenger.id)
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faClose} />
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                ))}
              </>
            ) : (
              ""
            )}

            <Row>
              <Col md={12}>
                <Button
                  theme="light"
                  onClick={() => setPassenger([...passengers, form])}
                  disabled={
                    (passengers.length !== 0 &&
                      passengers[passengers.length - 1]?.full_name === "") ||
                    passengers[passengers.length - 1]?.age === "" ||
                    passengers[passengers.length - 1]?.gender === "" ||
                    passengers[passengers.length - 1]?.type === "" ||
                    (reserver.full_name === "" &&
                      reserver.email === "" &&
                      reserver.phone === "") ||
                    passengers.length === travelDetails.variant.download_expiry
                  }
                >
                  <FontAwesomeIcon icon={faPlus} /> ADD TRAVELLER{" "}
                  {product.manage_stock && (
                    <>
                      <span>
                        ({product.stock_quantity - passengers.length} left)
                      </span>
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </>
        )}
    </Fade>
  );
};

export default ReserverDetails;
