import React from "react";
import {
  Button,
  FormSelect,
  Col,
  Row,
  FontAwesomeIcon,
  faList,
  faPlus,
} from "../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../Context/AppContext";
import { COLOR } from "../../../../Hooks/utils/config";
import { usePlanData } from "../../../../Context/PlanContext";

const HeaderFilter = () => {
  const { plansFilter, setPlansFilter, screenWidth, isOnline } = useAppData();

  const { setIsAddingNewPlan } = usePlanData();

  return (
    <Row style={{ padding: "0", marginBottom: 10 }}>
      {/* <Col
        md={3}
        style={{
          padding: "0 10px",
          maxWidth: screenWidth ? "60%" : "",
        }}
      >
        <FormSelect
          group={true}
          icon={faList}
          value={plansFilter}
          onChange={(e) => setPlansFilter(e.target.value)}
        >
          <option value="">All Plans</option>
          <option value="travel_plan">My Plans</option>
          <option value="shared_travel_plan">Shared Plans</option>
        </FormSelect>
      </Col> */}
      {isOnline ? (
        <Col
          md={3}
          style={{
            padding: "0 10px",
            maxWidth: screenWidth ? "40%" : "",
          }}
        >
          <Button
            onClick={() => setIsAddingNewPlan(true) || console.log("test 1")}
            size="md"
            theme="dark"
            backgroundColor={COLOR.primary}
            block
          >
            <FontAwesomeIcon icon={faPlus} /> New Plan
          </Button>
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
};

export default HeaderFilter;
