import React, { useEffect, useState } from "react";
import {
  Col,
  FormCheckbox,
  FormInput as ShardInput,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import dateFormat from "dateformat";
import useCustomFunctions from "../../../../../Hooks/api/useCustomFunctions";
import { Button } from "../../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../../Context/AppContext";
import { COLOR } from "../../../../../Hooks/utils/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { useBookingsData } from "../../../../../Context/BookingsContext";

const ReviewDetails = ({ form }) => {
  const { user, walletTransactions } =
    useAppData();

    const { addNewOrder, addNewBookings, } = useBookingsData()

  const { travelDetails, passengers, reserver, setSuccessDetails, setLoading } =
    form;

  const { numberWithCommas } = useCustomFunctions();

  const [bookingLoading, setBookingLoading] = useState(false);

  const computeFare = () => {
    if (travelDetails.roundTrip) {
      return numberWithCommas(
        (travelDetails.schedules.departure.adultFare +
          travelDetails.schedules.return.adultFare) *
          passengers.length
      );
    } else {
      return numberWithCommas(
        parseFloat(travelDetails.schedules.departure.adultFare) *
          passengers.length
      );
    }
  };

  const [travelPoints, setTravelPoints] = useState(0);

  const [points, setPoints] = useState({
    amount: 0,
    allow: false,
  });

  useEffect(() => {
    setTravelPoints(walletTransactions.reduce((a, b) => a + b.amount, 0));
  }, [walletTransactions]);

  const travelDetailSummary = () => {
    if (travelDetails.roundTrip) {
      return [
        {
          product_id: travelDetails.schedules.departure.id,
          meta_data: [
            {
              key: "Round Trip",
              value: travelDetails.roundTrip ? "Yes" : "No",
              display_key: "round-trip",
              display_value: travelDetails.roundTrip ? "Yes" : "No",
            },
            {
              key: "Departure Schedule",
              value: `${travelDetails.schedules.departure.origin} to ${travelDetails.schedules.departure.destination} - ${travelDetails.schedules.departure.departureTime} - ${travelDetails.schedules.departure.tripCode}`,
              display_key: "departure-schedule",
              display_value: `${travelDetails.schedules.departure.origin} to ${travelDetails.schedules.departure.destination} - ${travelDetails.schedules.departure.departureTime} - ${travelDetails.schedules.departure.tripCode}`,
            },
            {
              key: "Departure Date",
              value: dateFormat(travelDetails.departure_date, "fullDate"),
              display_key: "departure-date",
              display_value: dateFormat(
                travelDetails.departure_date,
                "fullDate"
              ),
            },
            ...passengers.map((passenger, index) => ({
              key: `passenger #${index + 1}`,
              value: `Name: ${passenger.full_name}, Age: ${passenger.age}, Gender: ${passenger.gender}, Type: ${passenger.type}`,
              display_key: `passenger-${index + 1}`,
              display_value: `Name: ${passenger.full_name}, Age: ${passenger.age}, Gender: ${passenger.gender}, Type: ${passenger.type}`,
            })),
          ],
          quantity: passengers.length,
        },
        {
          product_id: travelDetails.schedules.return.id,
          quantity: passengers.length,
          meta_data: [
            {
              key: "Return Schedule",
              value: `${travelDetails.schedules.return.origin} to ${travelDetails.schedules.return.destination} - ${travelDetails.schedules.return.departureTime} - ${travelDetails.schedules.return.tripCode}`,
              display_key: "return-schedule",
              display_value: `${travelDetails.schedules.return.origin} to ${travelDetails.schedules.return.destination} - ${travelDetails.schedules.return.departureTime} - ${travelDetails.schedules.return.tripCode}`,
            },
            {
              key: "Return Date",
              value: dateFormat(travelDetails.return_date, "fullDate"),
              display_key: "return-date",
              display_value: dateFormat(travelDetails.return_date, "fullDate"),
            },
            ...passengers.map((passenger, index) => ({
              key: `passenger #${index + 1}`,
              value: `Name: ${passenger.full_name}, Age: ${passenger.age}, Gender: ${passenger.gender}, Type: ${passenger.type}`,
              display_key: `passenger-${index + 1}`,
              display_value: `Name: ${passenger.full_name}, Age: ${passenger.age}, Gender: ${passenger.gender}, Type: ${passenger.type}`,
            })),
            {
              key: "Travel Points",
              value: points.amount,
              display_key: "travel-points",
              display_value: `"${points.amount}"`,
            },
          ],
        },
      ];
    } else {
      return [
        {
          product_id: travelDetails.schedules.departure.id,
          meta_data: [
            {
              key: "Round Trip",
              value: travelDetails.roundTrip ? "Yes" : "No",
              display_key: "round-trip",
              display_value: travelDetails.roundTrip ? "Yes" : "No",
            },
            {
              key: "Departure Schedule",
              value: `${travelDetails.schedules.departure.origin} to ${travelDetails.schedules.departure.destination} - ${travelDetails.schedules.departure.departureTime} - ${travelDetails.schedules.departure.tripCode}`,
              display_key: "departure-schedule",
              display_value: `${travelDetails.schedules.departure.origin} to ${travelDetails.schedules.departure.destination} - ${travelDetails.schedules.departure.departureTime} - ${travelDetails.schedules.departure.tripCode}`,
            },
            {
              key: "Departure Date",
              value: dateFormat(travelDetails.departure_date, "fullDate"),
              display_key: "departure-date",
              display_value: dateFormat(
                travelDetails.departure_date,
                "fullDate"
              ),
            },
            ...passengers.map((passenger, index) => ({
              key: `passenger #${index + 1}`,
              value: `Name: ${passenger.full_name}, Age: ${passenger.age}, Gender: ${passenger.gender}, Type: ${passenger.type}`,
              display_key: `passenger-${index + 1}`,
              display_value: `Name: ${passenger.full_name}, Age: ${passenger.age}, Gender: ${passenger.gender}, Type: ${passenger.type}`,
            })),
            {
              key: "Travel Points",
              value: points.amount,
              display_key: "travel-points",
              display_value: `"${points.amount}"`,
            },
          ],
          quantity: passengers.length,
        },
      ];
    }
  };

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col md={12}>
          <h6>Reserver Details</h6>
        </Col>
        <Col md={3}>{reserver.full_name}</Col>
        <Col md={4}>{reserver.email}</Col>
        <Col md={3}>{reserver.phone}</Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col md={12}>
          <h6>Passenger Details</h6>
        </Col>
        {passengers.map((passenger, index) => (
          <>
            <Col md={3}>Passenger #{index + 1}</Col>
            <Col md={4}>{passenger.full_name}</Col>
            <Col md={1}>{passenger.age}</Col>
            <Col md={1}>
              {passenger.gender ? passenger.gender[0].toUpperCase() : ""}
            </Col>
            <Col md={2}>{passenger.type}</Col>
          </>
        ))}
      </Row>

      <Row style={{ marginBottom: 20 }}>
        <Col md={12}>
          <h6>Travel Details</h6>
        </Col>
        <Col md={12}>
          <Row>
            <Col md={6} style={{ fontWeight: "500" }}>
              Departure Schedule:
            </Col>
            <Col md={6}>
              {`${travelDetails.schedules.departure.origin} to ${travelDetails.schedules.departure.destination} - ${travelDetails.schedules.departure.departureTime}`}
            </Col>
            <Col md={6} style={{ fontWeight: "500" }}>
              Departure Date:
            </Col>
            <Col md={6}>
              {dateFormat(travelDetails.departure_date, "fullDate")}
            </Col>
            <Col md={6} style={{ fontWeight: "500" }}>
              Departure fare per seat:
            </Col>
            <Col md={6}>
              &#8369;{" "}
              {numberWithCommas(travelDetails.schedules.departure.adultFare)}
            </Col>

            {travelDetails.roundTrip && (
              <>
                <Col md={6} style={{ fontWeight: "500" }}>
                  Round Trip?:
                </Col>
                <Col md={6}>{travelDetails.roundTrip ? "YES" : "NO"}</Col>
                <Col md={6} style={{ fontWeight: "500" }}>
                  Return Schedule:
                </Col>
                <Col md={6}>
                  {`${travelDetails.schedules.return.origin} to ${travelDetails.schedules.return.destination} - ${travelDetails.schedules.return.departureTime}`}
                </Col>
                <Col md={6} style={{ fontWeight: "500" }}>
                  Return Date:
                </Col>
                <Col md={6}>
                  {dateFormat(travelDetails.return_date, "fullDate")}
                </Col>
                <Col md={6} style={{ fontWeight: "500" }}>
                  Return fare per seat:
                </Col>
                <Col md={6}>
                  &#8369;{" "}
                  {numberWithCommas(travelDetails.schedules.return.adultFare)}
                </Col>
              </>
            )}

            <Col md={6} style={{ fontWeight: "500" }}>
              Passenger Count:
            </Col>
            <Col md={6}>{passengers.length}</Col>

            <Col md={6} style={{ fontWeight: "500" }}>
              Total Fare:
            </Col>
            <Col md={6}>&#8369; {computeFare()}</Col>
          </Row>
        </Col>

        <Col md={12}>
          <h6>Terms and Conditions</h6>
          <ul>
            <li>No cancellation for confirmed bookings</li>
            <li>
              Once the booking is confirmed, the ticket will be subject to the
              providers terms and conditions.
            </li>
            <li>
              2 FREE SMS travel reminders is included in this booking. One SMS 2
              days before your travel and another one on the day of your travel.
            </li>
          </ul>
        </Col>
        {user.length !== 0 && travelPoints !== 0 && (
          <Col md={12}>
            <h6>Travel Points</h6>
            <FormCheckbox
              checked={points.allow}
              onChange={() => setPoints({ ...points, allow: !points.allow })}
              toggle
              small
            >
              Use my travel points for this booking
            </FormCheckbox>
            {points.allow && (
              <InputGroup>
                <InputGroupAddon type="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faWallet} /> You only have{" "}
                    {travelPoints} travel points
                  </InputGroupText>
                </InputGroupAddon>
                <ShardInput
                  type="number"
                  value={points.amount}
                  onChange={(e) =>
                    e.target.value > travelPoints
                      ? ""
                      : setPoints({
                          ...points,
                          amount: e.target.value === "" ? 0 : e.target.value,
                        })
                  }
                />
              </InputGroup>
            )}
          </Col>
        )}
      </Row>

      <Row style={{ marginBottom: 20, textAlign: "center" }}>
        <Col md={12}>
          All discounts will be apply once we approve your booking and once you
          provided all the needed documents. By proceeding this booking, you
          aggree with our terms and conditions.
        </Col>
        <Col md={12}>
          <Button
            style={{ margin: 20 }}
            backgroundColor={COLOR.primary}
            size="md"
            onClick={() =>
              setBookingLoading(true) ||
              addNewOrder(
                {
                  payment_method: "bux_gateway",
                  payment_method_title: "Bux",
                  set_paid: false,
                  status: "on-hold",
                  customer_id: user.wallet_id !== "" ? user.wallet_id : 0,
                  billing: {
                    first_name: reserver.username || reserver.email,
                    last_name: reserver.full_name,
                    address_1: "",
                    address_2: "",
                    city: "",
                    state: "",
                    postcode: "",
                    country: "PH",
                    email: reserver.email,
                    phone: reserver.phone,
                  },
                  shipping: {
                    first_name: "",
                    last_name: "",
                    company: "",
                    address_1: "",
                    address_2: "",
                    city: "",
                    state: "",
                    postcode: "",
                    country: "PH",
                  },
                  line_items: travelDetailSummary(),
                  customer_note: "",
                },
                { id: 1 },
                (res) =>
                  addNewBookings(
                    {
                      ...res.data,
                      plan_reference: "",
                      merchant: { id: 1 },
                      passengers: JSON.stringify(passengers),
                    },
                    (e) =>
                      setSuccessDetails(res.data) ||
                      setLoading(true) ||
                      setTimeout(() => {
                        setLoading(false) || setBookingLoading(false);
                      }, 1500)
                  )
              )
            }
            disabled={bookingLoading}
          >
            {bookingLoading ? "Please wait ...." : "BOOK NOW!"}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ReviewDetails;
