import React, { useState, useEffect, Suspense } from "react";
import UploadBookingForm from "../../../../traveltayo-ui-kit/templates/Forms/Bookings/UploadBookingForm";
import Empty from "../../../Basic/Empty";
import TicketCard from "../Components/TicketCard";
import FileView from "../Components/FileView";
import { useAppData } from "../../../../Context/AppContext";
import { FEATURES } from "../../../../Hooks/utils/config";
import {
  Button,
  Fade,
  Row,
  Col,
  FontAwesomeIcon,
  faUpload,
  Loading,
  ListGroup,
} from "../../../../traveltayo-ui-kit";
import { useBookingsData } from "../../../../Context/BookingsContext";
import { usePlanData } from "../../../../Context/PlanContext";
import BookingList from "../Components/BookingList";

const PlanBookings = () => {
  const { isOnline } = useAppData();

  const { plan } = usePlanData();

  const {
    bookingsPerPlan,
    uploadedbookingsPerPlan,
    getUserBookingsPerPlan,
    getUserUploadedBookingsPerPlan,
  } = useBookingsData();

  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(true);

  const UPLOAD_FORM = {
    reference: "",
    plan_reference: "",
    ticket: "",
    isPaid: false,
    amountPaid: 0,
    paidBy: "",
  };

  const [uploadBookingForm, setUploadBookingForm] = useState(UPLOAD_FORM);

  const [ticket, setTicket] = useState("");
  const [ticketID, setTicketID] = useState("");

  useEffect(() => {
    if (plan.length !== 0) {
      if (FEATURES.BOOKING_SYSTEM) {
        getUserBookingsPerPlan(
          plan.reference,
          () =>
            setLoading(true) ||
            setTimeout(() => {
              setLoading(false);
            }, 1500)
        );
      }
      setUploadBookingForm({
        ...uploadBookingForm,
        plan_reference: plan.reference,
      });
      getUserUploadedBookingsPerPlan(
        plan.reference,
        () =>
          setLoading(true) ||
          setTimeout(() => {
            setLoading(false);
          }, 1500)
      );
    }
  }, [plan]);

  return (
    <Suspense fallback={<Loading  static={true}/>}>
      <Fade>
        <UploadBookingForm
          doneUpload={() =>
            getUserUploadedBookingsPerPlan(
              plan.reference,
              () =>
                setUploadBookingForm({
                  ...UPLOAD_FORM,
                  plan_reference: plan.reference,
                }) ||
                getUserBookingsPerPlan(plan.reference) ||
                setLoading(false) ||
                setIsUploading(false)
            )
          }
          ticket={ticket}
          selectedID={ticketID}
          setTicketID={setTicketID}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          loading={loading}
          setLoading={setLoading}
          uploadBookingForm={uploadBookingForm}
          setUploadBookingForm={setUploadBookingForm}
        />

        {ticket.length !== 0 && (
          <FileView
            data={{
              ticket: ticket,
              setTicket: setTicket,
              setLoading: setLoading,
              loading: loading,
              doneDeleting: () =>
                getUserUploadedBookingsPerPlan(
                  plan.reference,
                  () => getUserBookingsPerPlan(plan.reference)
                ),
            }}
          />
        )}

        {loading ? (
          <Loading  static={true}/>
        ) : (
          <>
            {(plan && bookingsPerPlan.length !== 0) ||
            uploadedbookingsPerPlan.length !== 0 ? (
              <>
                {isOnline ? (
                  <Row>
                    <Col style={{ padding: 10 }}>
                      <Button
                        theme="light"
                        style={{ marginBottom: 5, marginRight: 5 }}
                        onClick={() => setIsUploading(true)}
                        disabled={plan.access !== "edit"}
                      >
                        <FontAwesomeIcon icon={faUpload} /> Upload Booking/
                        Files
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row>
                  <Col md={12} style={{ padding: 0 }}>
                    <ListGroup>
                      <BookingList
                        label="Uploaded Files"
                        list={uploadedbookingsPerPlan}
                        type="file"
                        setTicket={setTicket}
                      />
                      {FEATURES.BOOKING_SYSTEM &&
                        bookingsPerPlan.length !== 0 && (
                          <BookingList
                            label="Bookings"
                            list={bookingsPerPlan}
                            type="booking"
                            setTicket={setTicket}
                          />
                        )}
                    </ListGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <Empty>
                {isOnline ? (
                  <>
                    <Button
                      theme="light"
                      style={{ marginBottom: 5, marginRight: 5 }}
                      onClick={() => setIsUploading(true)}
                      disabled={plan.access !== "edit"}
                    >
                      <FontAwesomeIcon icon={faUpload} /> Upload Booking/ Files
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </Empty>
            )}
          </>
        )}
      </Fade>
    </Suspense>
  );
};

export default PlanBookings;
