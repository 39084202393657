import React, { Suspense, useEffect, useState } from "react";
import Empty from "../../Basic/Empty";
import HeaderFilter from "./Components/HeaderFilter";
import {
  Button,
  Col,
  Fade,
  Row,
  FontAwesomeIcon,
  faPlus,
  Loading,
  Helmet,
  TravelPlanCard,
  PlanForm,
  PageContainer,
} from "../../../traveltayo-ui-kit";
import { useAppData } from "../../../Context/AppContext";
import { usePlanData } from "../../../Context/PlanContext";
import { APP_LINK, APP_NAME, FEATURES } from "../../../Hooks/utils/config";

const Index = () => {
  const { auth_login, screenWidth } = useAppData();

  const { setIsAddingNewPlan, getPlans, allPlans, plansFilter } = usePlanData();

  useEffect(() => {
    getPlans(() =>
      setTimeout(() => {
        setLoading(false);
      }, 1500)
    );
    // getSharedPlans();
  }, [auth_login]);

  const [loading, setLoading] = useState(true);

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Plans",
        url: APP_LINK + "/plans",
      }}
      noAds={FEATURES.NO_ADS}
    >
      {loading ? (
        <Row style={{ padding: 0 }}>
          <Col md={12}>
            <Loading />
          </Col>
        </Row>
      ) : (
        <div style={{ padding: 0 }}>
          {allPlans.length !== 0 &&
          allPlans.filter((plan) => plan.type !== "reminder").length === 0 ? (
            <Empty>
              <Button
                theme="light"
                onClick={() => setIsAddingNewPlan(true)}
                size="lg"
              >
                <FontAwesomeIcon icon={faPlus} /> First Plan
              </Button>
            </Empty>
          ) : (
            <>
            {screenWidth ? "" : <HeaderFilter />}
            </>
          )}
          {allPlans.length !== 0 && (
            <Row style={{ padding: "0" }}>
              {allPlans
                .filter((plan) => plan.type !== "reminder")
                .filter((plan) =>
                  plansFilter ? plan.event_type === plansFilter : plan
                )
                .map((plan) => (
                  <Col md={3} xs={6} sm={6} style={{ padding: 10, paddingTop: screenWidth? 10: 0 }}>
                    <TravelPlanCard plan={plan} />
                  </Col>
                ))}
            </Row>
          )}
        </div>
      )}
    </PageContainer>
  );
};

export default Index;
