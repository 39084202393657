import { useState } from "react";
import axios from "axios";
import { API } from "../utils/config";
import useCustomFunctions from "./useCustomFunctions";

function useApplications(settings) {
  const { user, getLoginSession } = settings;
  const { encryptMessage, decryptMessage } = useCustomFunctions();

  const { HEADERS } = API;

  const [applications, setApplications] = useState([]);
  const [application, setApplication] = useState([]);
  const [folders, setFolders] = useState([]);
  const [allAccessApps, setAllAccessApps] = useState([]);

  function addNewFolder(data, actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/add_new_folder`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            name: encryptMessage(data.name),
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function updateFolder(data, actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/update_folder`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            name: encryptMessage(data.name),
            id: data.id,
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function deleteFolder(data, actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/delete_folder`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            name: data.name,
            id: data.id,
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function getFolders(actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/get_folders`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          if (response.data.length !== 0) {
            setFolders(
              response.data.map((d) => ({ ...d, name: decryptMessage(d.name) }))
            );
          }
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function folderizeApp(data, actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/folderized_the_app`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            folder_id: data.folder_id,
            app_id: data.app_id,
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function removeFolderizedApp(data, actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/removed_folderized_app`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            folder_id: data.folder_id,
            app_id: data.app_id,
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function addNewApp(data, actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/add_new_app`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            form: {
              ...data,
              name: encryptMessage(data.name),
              description: encryptMessage(data.description),
              link: encryptMessage(data.link),
              logo: encryptMessage(data.logo),
              brand: encryptMessage(data.brand),
              rate_link: encryptMessage(data.rate_link),
              payment_options: encryptMessage(data.payment_options),
              contact_details: encryptMessage(data.contact_details),
              terms: encryptMessage(data.terms),
            },
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function getApplications(actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/get_applications`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          if (response.data.length !== 0) {
            setApplications(
              response.data
                .map((app) => ({
                  ...app,
                  name: decryptMessage(app.name),
                  description: decryptMessage(app.description),
                  link: decryptMessage(app.link),
                  logo: decryptMessage(app.logo),
                  brand: decryptMessage(app.brand),
                  rate_link: decryptMessage(app.rate_link),
                  payment_options: decryptMessage(app.payment_options),
                  contact_details: decryptMessage(app.contact_details),
                  terms: decryptMessage(app.terms),
                  isQuotationMaker: parseInt(app.isQuotationMaker) === 1,
                }))
                .map((app) => ({ ...app, offers: app.description.split(",") }))
            );
          }
          actions();
        })
        .catch(function(error) {
          // console.log("error");
          actions();
        })
        .then(function() {});
    }
  }

  function getApplicationByID(data, actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/get_application_by_id`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            id: data.id,
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          if (response.data.length !== 0) {
            setApplication(
              response.data
                .map((app) => ({
                  ...app,
                  name: decryptMessage(app.name),
                  description: decryptMessage(app.description),
                  link: decryptMessage(app.link),
                  logo: decryptMessage(app.logo),
                  brand: decryptMessage(app.brand),
                  rate_link: decryptMessage(app.rate_link),
                  payment_options: decryptMessage(app.payment_options),
                  contact_details: decryptMessage(app.contact_details),
                  terms: decryptMessage(app.terms),
                  isQuotationMaker: parseInt(app.isQuotationMaker) === 1,
                  agency_data: JSON.parse(decryptMessage(app.agency_data)),
                  // products: JSON.parse(decryptMessage(app.products)),
                  customers: JSON.parse(decryptMessage(app.customers)),
                }))
                .map((app) => ({
                  ...app,
                  offers: app.description.split(","),
                }))[0]
            );
          }
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function updateAppByID(data, actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/update_app_by_id`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            form: {
              ...data,
              name: encryptMessage(data.name),
              description: encryptMessage(data.description),
              link: encryptMessage(data.link),
              logo: encryptMessage(data.logo),
              brand: encryptMessage(data.brand),
              rate_link: encryptMessage(data.rate_link),
              payment_options: encryptMessage(data.payment_options),
              contact_details: encryptMessage(data.contact_details),
              terms: encryptMessage(data.terms),
            },
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function updateAgencyDetailsByID(data, actions) {
    // console.log(data);
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/update_agency_details_by_id`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            form: {
              app_id: data.app_id,
              agency_id: data.agency_id,
              customers: data.customers
                ? encryptMessage(JSON.stringify(data.customers))
                : "",
              products: data.products
                ? encryptMessage(JSON.stringify(data.products))
                : "",
              agency_data: data.agency_data
                ? encryptMessage(JSON.stringify(data.agency_data))
                : "",
            },
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function deleteAppByID(data, actions) {
    console.log(data);
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/apps/delete_app_by_id`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
            form: data,
          },
          HEADERS
        )
        .then(function(response) {
          // console.log(response);
          actions();
        })
        .catch(function(error) {
          // console.log("error");
        })
        .then(function() {});
    }
  }

  function getAllAccessApps(actions) {
    axios
      .post(`${API.NEW_URL}/apps/get_all_access_apps`, HEADERS)
      .then(function(response) {
        // console.log(response);
        if (response.data.length !== 0) {
          setAllAccessApps(
            response.data
              .map((app) => ({
                ...app,
                name: decryptMessage(app.name),
                description: decryptMessage(app.description),
                link: decryptMessage(app.link),
                logo: decryptMessage(app.logo),
                brand: decryptMessage(app.brand),
                rate_link: decryptMessage(app.rate_link),
                payment_options: decryptMessage(app.payment_options),
                contact_details: decryptMessage(app.contact_details),
                terms: decryptMessage(app.terms),
                isQuotationMaker: parseInt(app.isQuotationMaker) === 1,
              }))
              .map((app) => ({ ...app, offers: app.description.split(",") }))
          );
          actions();
        }
      })
      .catch(function(error) {
        // console.log("error");
      })
      .then(function() {});
  }

  return {
    allAccessApps,
    getAllAccessApps,
    applications,
    getApplications,
    application,
    getApplicationByID,
    addNewFolder,
    folders,
    getFolders,
    folderizeApp,
    removeFolderizedApp,
    addNewApp,
    updateFolder,
    updateAppByID,
    deleteFolder,
    deleteAppByID,
    //agency
    updateAgencyDetailsByID,
  };
}

export default useApplications;
