import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import ReserverDetails from "./ReserverDetails";
import BookingDetails from "./BookingDetails";
import ReviewDetails from "./ReviewDetails";
import SuccessDetails from "./SuccessDetails";
import { useBookingsData } from "../../../../../Context/BookingsContext";
import {
  Button,
  Col,
  FormGroup,
  FormInput,
  FormSelect,
  Loading,
  ModalForm,
  Row,
  faCalendar,
  faVanShuttle,
} from "../../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../../Context/AppContext";
import { useNavigate } from "@reach/router";
import { COLOR } from "../../../../../Hooks/utils/config";

const Index = (props) => {
  const navigate = useNavigate();
  const { product, setProduct } = props;
  const { user, decryptMessage, sceenWidth } = useAppData();

  const {
    addNewOrder,
    addNewBookings,
    newBookingForm,
    getProductVariations,
  } = useBookingsData();

  const form = {
    id: (Math.random() + 2).toString(36).substring(7),
    username: "",
    full_name: "",
    gender: "Male",
    age: 18,
    type: "regular",
    email: "",
    phone: "",
    passenger: true,
  };

  const detailForm = {
    departureTerminal: "",
    departureSchedule: "",
    returnTerminal: "",
    returnSchedule: "",
    departure: newBookingForm.startDate,
    return: newBookingForm.endDate,
    roundTrip:
      product.length !== 0 &&
      ["bus-tickets"].includes(product.categories[0].slug)
        ? newBookingForm.roundTrip
        : false,
    variant: [],
  };
  const [step, setStep] = useState("");
  const [reserver, setReserver] = useState({ ...form, passenger: false });
  const [passengers, setPassenger] = useState([form]);
  const [travelDetails, setTravelDetails] = useState(detailForm);
  const [loading, setLoading] = useState(false);
  const [sucessDetails, setSuccessDetails] = useState([]);
  const [copied, setCopied] = useState(false);
  const [variants, setVariants] = useState([]);
  const [guests, setGuests] = useState(10);
  const [includedDates, setIncludedDates] = useState([]);

  const reset = () => {
    setProduct([]);
    setReserver(
      user.length !== 0
        ? {
            id: (Math.random() + 2).toString(36).substring(7),
            username: user.username,
            full_name: decryptMessage(user.full_name),
            gender: "",
            age: "",
            type: "regular",
            email: decryptMessage(user.email),
            phone: decryptMessage(user.mobile_number),
            passenger: false,
          }
        : { ...form, passenger: false }
    );
    setPassenger([form]);
    setTravelDetails({ ...detailForm, departure: new Date(includedDates[0]) });
  };

  const travelDetailSummary = () => {
    if (["tours", "packages"].includes(product.categories[0].slug)) {
      return [
        {
          key: "Round Trip",
          value: "Yes",
          display_key: "round-trip",
          display_value: "Yes",
        },
        {
          key: "Tour Type",
          value: travelDetails.departureSchedule,
          display_key: "tour-type",
          display_value: travelDetails.departureSchedule,
        },
        {
          key: "Departure Date",
          value: dateFormat(travelDetails.departure, "paddedShortDate"),
          display_key: "departure-date",
          display_value: dateFormat(travelDetails.departure, "paddedShortDate"),
        },
        {
          key: "Return Date",
          value: "",
          display_key: "return-date",
          display_value: "",
        },
        ...passengers.map((passenger, index) => ({
          key: `passenger #${index + 1}`,
          value: `Name: ${passenger.full_name}, Age: ${passenger.age}, Gender: ${passenger.gender}, Type: ${passenger.type}`,
          display_key: `passenger-${index + 1}`,
          display_value: `Name: ${passenger.full_name}, Age: ${passenger.age}, Gender: ${passenger.gender}, Type: ${passenger.type}`,
        })),
      ];
    }
  };

  useEffect(() => {
    if (product.length !== 0) {
      getProductVariations(product, (res) => setVariants(res.data));
    }

    if (
      product.length !== 0 &&
      product.attributes.find((attr) => attr.name === "travel_dates")
    ) {
      setIncludedDates(
        product.attributes.find((attr) => attr.name === "travel_dates").options
      );
    }
  }, [product]);

  useEffect(() => {
    if (includedDates.length !== 0) {
      setTravelDetails({
        ...travelDetails,
        departure: new Date(includedDates[0]),
      });

      console.log(includedDates);
    }
  }, [includedDates]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [step]);

  return (
    <>
      <ModalForm
        open={product.length !== 0}
        toggle={() => {}}
        bodyPadding="10px 20px"
        loading={loading}
        size="lg"
        className="booking-form-modal"
        style={{ width: sceenWidth ? "100%" : "90%" }}
        header={
          step === "details"
            ? "Reserver & Passenger Details"
            : step === "review"
            ? "Review Booking Details"
            : product.name
        }
        approvedBTNProps={{
          // block: "block",
          display:
            step === ""
              ? travelDetails.departureSchedule !== ""
              : step === "details"
              ? passengers.length !== 0 &&
                passengers[passengers.length - 1]?.full_name !== "" &&
                passengers[passengers.length - 1]?.age !== "" &&
                passengers[passengers.length - 1]?.gender !== "" &&
                passengers[passengers.length - 1]?.type !== "" &&
                passengers.length >=
                  parseInt(travelDetails.variant.download_limit)
              : product.id && step !== "success",

          size: "sm",
          iconPosition: "left",
          label:
            step === "details"
              ? "Review"
              : step === "review"
              ? loading
                ? "Processing ...."
                : "Proceed Booking"
              : "Book Now!",
          onClick: () =>
            step === "details"
              ? setStep("review")
              : step === "review"
              ? setLoading(true) ||
                addNewOrder(
                  {
                    payment_method: "bux_gateway",
                    payment_method_title: "Bux",
                    set_paid: false,
                    status: "on-hold",
                    billing: {
                      first_name: reserver.username || reserver.email,
                      last_name: reserver.full_name,
                      address_1: "",
                      address_2: "",
                      city: "",
                      state: "",
                      postcode: "",
                      country: "PH",
                      email: reserver.email,
                      phone: reserver.phone,
                    },
                    shipping: {
                      first_name: "",
                      last_name: "",
                      company: "",
                      address_1: "",
                      address_2: "",
                      city: "",
                      state: "",
                      postcode: "",
                      country: "PH",
                    },
                    line_items: [
                      {
                        product_id: product.id,
                        variation_id: travelDetails.variant.id,
                        meta_data: travelDetailSummary(),
                        quantity: guests,
                      },
                    ],
                    customer_note: "",
                  },
                  product.merchant,
                  (res) =>
                    addNewBookings(
                      {
                        ...res.data,
                        plan_reference: newBookingForm.plan_reference,
                        merchant: product.merchant,
                        passengers: JSON.stringify(passengers),
                      },
                      (e) =>
                        setSuccessDetails(res.data) ||
                        setStep("success") ||
                        setLoading(false)
                    )
                )
              : step === "success"
              ? setCopied(true)
              : setStep("details"),
        }}
        cancelBTNProps={{
          // block: "block",
          display: true,
          label:
            step === "details"
              ? "Back"
              : step === "review"
              ? "Back"
              : step === "success"
              ? "Close"
              : "Cancel",
          size: "sm",
          onClick: () =>
            step === "details"
              ? setStep("")
              : step === "review"
              ? setStep("details")
              : step === "success"
              ? setStep("") || reset()
              : reset(),
        }}
        deleteBTNProps={{
          display: false,
          theme: "danger",
          onClick: () => "",
        }}
        footerComponents={
          <>
            {props.viewProfileBTN && (
              <Button
                theme="dark"
                onClick={() => navigate(`/explore/offers/${product.slug}`)}
                backgroundColor={COLOR.primary}
              >
                View Product Page
              </Button>
            )}
          </>
        }
      >
        {product.length !== 0 && (
          <>
            {loading ? (
              <Loading static={true} />
            ) : (
              <>
                {["", "details"].includes(step) && (
                  <Row>
                    <Col md={6} style={{ paddingLeft: 0 }}>
                      {includedDates.length !== 0 ? (
                        <FormInput
                          group={true}
                          icon={faCalendar}
                          label="TRAVEL DATE"
                          size={"md"}
                          placeholderText="departure date"
                          formClassName="formgroup-departure"
                          className="formgroup-departure-input form-control"
                          type="date"
                          dateFormat="MMMM dd, yyyy"
                          selected={travelDetails.departure}
                          onChange={(e) => {
                            setTravelDetails({
                              ...travelDetails,
                              departure: e,
                              return:
                                e > travelDetails.return
                                  ? e
                                  : travelDetails.return,
                            });
                          }}
                          minDate={new Date()}
                          includeDates={includedDates.map(
                            (date) => new Date(date)
                          )}
                        />
                      ) : (
                        <FormInput
                          group={true}
                          icon={faCalendar}
                          label="TRAVEL DATE"
                          size={"md"}
                          placeholderText="departure date"
                          formClassName="formgroup-departure"
                          className="formgroup-departure-input form-control"
                          type="date"
                          dateFormat="MMMM dd, yyyy"
                          selected={travelDetails.departure}
                          onChange={(e) => {
                            setTravelDetails({
                              ...travelDetails,
                              departure: e,
                              return:
                                e > travelDetails.return
                                  ? e
                                  : travelDetails.return,
                            });
                          }}
                          minDate={new Date()}
                        />
                      )}
                    </Col>
                    {[
                      "bus-tickets",
                      "tours",
                      "hotels",
                      "tickets",
                      "activities",
                      "packages",
                      "land-arrangements",
                    ].includes(product.categories[0].slug) &&
                    variants.length !== 0 ? (
                      <Col md={6} style={{ paddingRight: 0 }}>
                        <FormGroup>
                          <FormSelect
                            label={"GROUP OR JOINER"}
                            group={true}
                            icon={faVanShuttle}
                            value={travelDetails.departureSchedule}
                            onChange={(e) =>
                              setTravelDetails({
                                ...travelDetails,
                                departureSchedule: e.target.value,
                                variant: e.target.value
                                  ? variants.find(
                                      (variant) =>
                                        variant.attributes[0].option ===
                                        e.target.value
                                    )
                                  : [],
                              }) ||
                              setReserver({ ...reserver, passenger: false })
                            }
                          >
                            <option value="">Select One</option>

                            {product.attributes[0].options.map((option) => (
                              <option value={option}>{option}</option>
                            ))}
                          </FormSelect>
                        </FormGroup>
                      </Col>
                    ) : (
                      "Loading..."
                    )}
                  </Row>
                )}
                {step === "" && (
                  <BookingDetails
                    data={{
                      product: { ...product, variants },
                      setTravelDetails,
                      travelDetails,
                    }}
                  />
                )}
                {step === "details" && (
                  <ReserverDetails
                    data={{
                      product: { ...product, variants },
                      reserver,
                      setReserver,
                      passengers,
                      setPassenger,
                      form,
                      travelDetails,
                      guests,
                      setGuests,
                    }}
                  />
                )}
                {step === "review" && (
                  <ReviewDetails
                    data={{
                      product: { ...product, variants },
                      passengers,
                      reserver,
                      travelDetails,
                    }}
                  />
                )}
                {step === "success" && <SuccessDetails data={sucessDetails} />}
                {step === "review" && (
                  <div>
                    <label>
                      By clicking "Proceed Booking", you aggree to the terms and
                      conditions of the provider.
                    </label>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </ModalForm>
    </>
  );
};

export default Index;
