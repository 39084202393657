import { useState, useEffect } from "react";
import axios from "axios";
import config, { APP_LINK } from "../utils/config";
import useCustomFunctions from "./useCustomFunctions";

function useUsersAPI(setAlertStatus, isOnline) {
  const {
    encryptMessage,
    decryptMessage,
    cookies,
    createCookies,
    removeCookie,
  } = useCustomFunctions();
  const { API } = config;
  const { HEADERS } = API;

  function valitateToken(token) {
    if (
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
        token
      )
    ) {
      return true;
    }
    return false;
  }

  const [getLoginSession, setLoginSession] = useState([]);

  // let getLoginSession =
  //   valitateToken(window.atob(window.localStorage.getItem("session"))) &&
  //   window.localStorage.getItem("session")
  //     ? decryptMessage(window.localStorage.getItem("session")).split("/")
  //     : [];

  useEffect(() => {
    if (cookies.session && valitateToken(window.atob(cookies.session))) {
      setLoginSession(decryptMessage(cookies.session).split("/"));
    }
  }, [cookies]);

  // console.log(getLoginSession);

  const [user, setUser] = useState([]);

  let auth_login = user && getLoginSession.length != 0 && user.length != 0;

  const [checks, setChecks] = useState({
    email_status: "email_available",
    username_status: "username_available",
  });

  function formatUsername(username) {
    return username.toLowerCase().replace(/\s+/g, "_").replace(/[\W]/g, "");
  }
  function formatNames(name) {
    return name.replace(/[\W]/g, "");
  }

  function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  function validateMobileNumber(mail) {
    if (/^\d{11}$/.test(mail)) {
      return true;
    }
    return false;
  }

  function loginNotif(data) {
    axios
      .post(
        `${API.NEW_URL}/users/send_login_notif`,
        {
          username: data.username,
          email: data.email,
          plain_email: data.plain_email,
        },
        HEADERS
      )
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.log("error");
      })
      .then(function () {});
  }

  function login(data, redirect) {
    axios
      .post(
        `${API.NEW_URL}/users/login`,
        {
          username: data.username,
          password: window.btoa(data.password),
          website: APP_LINK
        },
        HEADERS
      )
      .then(function (response) {
        if (response.data.length !== 0) {
          loginNotif({
            plain_email: decryptMessage(response.data[0].email),
            email: response.data[0].email,
            username: response.data[0].username,
          });
        }
        createCookies("session", encryptMessage(response.data[0].newSession));
        window.location.replace(redirect ? redirect : "/");
      })
      .catch(function (error) {
        console.log("error");
        setAlertStatus(1);
      })
      .then(function () {});
  }

  function loginByAccessKey(data, redirect) {
    if (data.accessKey) {
      let key = decryptMessage(data.accessKey);
      if (key.split("/").length !== 0) {
        loginNotif({
          plain_email: decryptMessage(key.split("/")[0]),
          email: key.split("/")[0],
          username: key.split("/")[2],
        });

        createCookies("session", data.accessKey);
        window.location.replace(redirect ? redirect : "/");
      } else {
        setAlertStatus(2);
      }
    }
  }

  function logout() {
    removeCookie("session");
    removeCookie("user");
    window.location.replace("/");
    window.localStorage.clear();
  }

  function signup(data, navigate) {
    // console.log(data);
    if (
      data.username &&
      // data.username_avail &&
      data.password &&
      data.firstname &&
      data.lastname &&
      data.email &&
      // data.email_avail &&
      data.mobilenumber
    ) {
      axios
        .post(
          `${API.NEW_URL}/users/insert_user`,
          {
            username: data.username,
            password: window.btoa(data.password),
            full_name: encryptMessage(`${data.firstname} ${data.lastname}`),
            plain_email: data.email,
            email: encryptMessage(data.email),
            mobile_number: encryptMessage(data.mobilenumber),
            access_levels: encryptMessage(
              JSON.stringify([
                {
                  type: "user",
                  level: 0,
                },
              ])
            ),
            store_app_link: "traveltayo",
            agent: data.agent,
            website: data.website,
          },
          HEADERS
        )
        .then(function (response) {
          if (response.data.serverStatus === 2) {
            navigate("/login");
            setAlertStatus(7);
          } else {
            setAlertStatus(8);
          }
        })
        .catch(function (error) {
          console.log("error");
        })
        .then(function () {});
    }
  }

  function getUser(actions) {
    if (!isOnline && cookies.user) {
      setUser(JSON.parse(decryptMessage(cookies.user)));
    } else if (getLoginSession.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/users/get_user`,
          {
            email: getLoginSession[0],
            otp: getLoginSession[1],
          },
          HEADERS
        )
        .then(function (response) {
          let user = response.data.map((user) => ({
            ...user,
            accessKey: cookies.session,
            // profile_pic: `${user.profile_pic}${user.profile_pic1}${user.profile_pic2}${user.profile_pic3}`,
            profile_pic: "",
            ai_tool: parseInt(user.ai_tool),
          }));
          setUser(user[0]);
          if (user.length !== 0) {
            actions();
          } else if (user.length === 0) {
            setAlertStatus(3);
            logout();
          }
        })
        .catch(function (error) {
          // if (!isOnline) {
          //   window.location.replace("/");
          // } else {
          //   window.localStorage.removeItem("session");
          // }
          // window.localStorage.removeItem("session");

          setAlertStatus(3);
          console.log(error);
        })
        .then(function () {});
    }
  }

  function checkAvailability(data, actions) {
    if (data.email) {
      axios
        .post(
          `${API.NEW_URL}/users/verify_email`,
          { email: data.email },
          HEADERS
        )
        .then(function (response) {
          console.log(response);
          actions(response.data.status === "email_not_available");
        })
        .catch(function (error) {
          console.log("error");
        })
        .then(function () {});
    }

    if (data.username) {
      axios
        .post(
          `${API.NEW_URL}/users/verify_username`,
          { username: data.username },
          HEADERS
        )
        .then(function (response) {
          console.log(response);
          // handle success
          actions(response.data.length !== 0);
        })
        .catch(function (error) {
          // handle error

          console.log("error");
        })
        .then(function () {
          // always executed
        });
    }
  }

  function resetPassword(data, navigate) {
    if (data && data.email !== "") {
      axios
        .post(
          `${API.NEW_URL}/users/request_reset_password`,
          {
            username: data.username,
          },
          HEADERS
        )
        .then(function (response) {
          if (response.data.length !== 0 && response.data[0].email) {
            axios
              .post(
                `${API.NEW_URL}/users/send_reset`,
                {
                  email: response.data[0].email,
                  plain_email: decryptMessage(response.data[0].email),
                },
                HEADERS
              )
              .then(function (response) {
                response.data.length !== 0
                  ? setAlertStatus(4) || navigate("/login")
                  : setAlertStatus(5);
              })
              .catch(function (error) {
                setAlertStatus(5);
                console.log(error);
              })
              .then(function () {});
          } else {
            setAlertStatus(5);
          }
        })
        .catch(function (error) {
          setAlertStatus(5);
          console.log("error");
        })
        .then(function () {});
    }
  }

  function changePassword(data, navigate) {
    axios
      .post(
        `${API.NEW_URL}/users/reset_password`,
        {
          email: data.email,
          plain_email: decryptMessage(data.email),
          otp: data.otp,
          password: window.btoa(data.newPass),
        },
        HEADERS
      )
      .then(function (response) {
        if (response.data.status === "success") {
          setAlertStatus(6);
          navigate("/login");
        } else {
          window.location.replace("/login/?login_error=1");
        }
      })
      .catch(function (error) {
        console.log("error");
      })
      .then(function () {});
  }

  const [walletTransactions, setWalletTransactions] = useState([]);

  function getWalletTransactions() {
    axios
      .post(
        `${API.URL}/api/users/get_user_wallet.php`,
        {
          email: user.email,
          otp: getLoginSession[1],
          username: user.username,
        },
        HEADERS
      )
      .then(function (response) {
        if (response.data.totalrecords !== 0) {
          setWalletTransactions(
            response.data.data.map((transaction) => ({
              ...transaction,
              amount:
                transaction.type === "debit"
                  ? -Math.abs(parseFloat(transaction.amount))
                  : parseFloat(transaction.amount),
            }))
          );
        }
      })
      .catch(function (error) {
        // console.log("error");
      })
      .then(function () {});
  }

  

  const [announcements, setAnnouncements] = useState([]);

  function getAnnouncements() {
    axios
      .get(`${API.NEW_URL}/events/announcements`, HEADERS)
      .then(function (response) {
        setAnnouncements(response.data);
      })
      .catch(function (error) {
        console.log("error");
      })
      .then(function () {});
  }

  useEffect(() => {
    if (isOnline) {
      createCookies("user", encryptMessage(JSON.stringify(user)));
    }
  }, [user, isOnline]);

  return {
    announcements,
    getAnnouncements,
    getLoginSession,
    user,
    resetPassword,
    changePassword,
    login,
    loginByAccessKey,
    logout,
    signup,
    checkAvailability,
    checks,
    auth_login,
    valitateToken,
    formatUsername,
    formatNames,
    validateEmail,
    validateMobileNumber,
    walletTransactions,
    getUser,
  };
}

export default useUsersAPI;
