import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import Empty from "../../../Basic/Empty";
import StarRatings from "react-star-ratings";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import { useAppData } from "../../../../Context/AppContext";
import {
  Button,
  Badge,
  Col,
  Collapse,
  FormSelect,
  FormTextarea,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
  FontAwesomeIcon,
  faArrowRight,
} from "../../../../traveltayo-ui-kit";
import { COLOR, PLATFORM } from "../../../../Hooks/utils/config";
import { useNavigate } from "@reach/router";
import { usePlanData } from "../../../../Context/PlanContext";
import { useBookingsData } from "../../../../Context/BookingsContext";

const BookingCard = ({
  bookings,
  setSelectedID,
  selectedID,
  setTicket,
  label,
  ticketID,
  addReviews,
  getAllBookingsUser,
}) => {
  const { user, decryptMessage } = useAppData();

  const { updateBookingPlanReference } = useBookingsData();

  const { plans, getPlans } = usePlanData();

  const { numberWithCommas } = useCustomFunctions();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [review, setReview] = useState({
    content: "",
    rating: 5,
  });

  const [planReference, setPlanReference] = useState("");
  const [bookingID, setBookingID] = useState("");

  useEffect(() => {
    setLoading(true);
    getPlans(() =>
      setTimeout(() => {
        setLoading(false);
      }, 1500)
    );

    // getPlans();
  }, [bookings]);

  return (
    <ListGroup style={{ marginBottom: 5 }} key={0}>
      <ListGroupItem style={{padding: "5px 10px"}}>
        <ListGroupItemHeading>{label}</ListGroupItemHeading>
      </ListGroupItem>

      {bookings.length !== 0 ? (
        <>
          {bookings.map((booking) => (
            <>
              {booking.source2 === "traveltayo" ? (
                <ListGroupItem style={{padding: "5px 10px"}}>
                  <Row>
                    <Col md={3}>
                      <p className="event-title-label">BOOKING #</p>
                      <h6>{booking.order_number}</h6>
                      {loading ? (
                        "Loading ..."
                      ) : (
                        <>
                          {booking.length !== 0 ? (
                            <Badge
                              theme={
                                PLATFORM.BOOKING_STATUSES.find(
                                  (s) => s.code === booking.status
                                ).color
                              }
                              style={{ fontSize: "10px" }}
                            >
                              {PLATFORM.BOOKING_STATUSES.find(
                                (s) => s.code === booking.status
                              ).label.toUpperCase()}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </Col>
                    <Col md={4}>
                      <p className="event-title-label">RESERVER DETAILS</p>
                      <h6 style={{ fontSize: "12px" }}>
                        {booking.reserver.last_name}{" "}
                        {booking.reserver.first_name &&
                          `(${booking.reserver.first_name})`}
                      </h6>
                      <h6 style={{ fontSize: "12px" }}>
                        {booking.reserver.email}
                      </h6>
                      <h6 style={{ fontSize: "12px" }}>
                        {booking.reserver.phone}
                      </h6>
                    </Col>
                    <Col md={4}>
                      <Button
                        theme="light"
                        size="sm"
                        style={{ marginRight: 10 }}
                        onClick={() =>
                          setSelectedID(
                            booking.reference === selectedID
                              ? ""
                              : booking.reference
                          )
                        }
                      >
                        {booking.reference === selectedID
                          ? "Hide Details"
                          : "View Details"}
                      </Button>
                      {booking.status === "pending" && booking.payment_link && (
                        <a href={booking.payment_link} target="_blank">
                          <Button theme="light" size="sm">
                            Payment Link
                          </Button>
                        </a>
                      )}

                      {booking.status === "completed" ? (
                        <Button
                          theme="light"
                          size="sm"
                          onClick={() =>
                            setTicket(booking)
                          }
                          disabled={ticketID === booking.reference}
                        >
                          {ticketID === booking.reference
                            ? "Loading ..."
                            : "View Ticket"}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Collapse open={booking.reference === selectedID}>
                    <hr />
                    <Row>
                      <Col md={6} style={{ paddingLeft: 0 }}>
                        <ListGroup style={{ marginBottom: 10 }}>
                          <ListGroupItem style={{padding: "5px 10px"}}>
                            <ListGroupItemHeading>
                              <p className="event-title-label">PASSENGERS</p>
                            </ListGroupItemHeading>
                          </ListGroupItem>
                          <ListGroupItem style={{ fontSize: "12px", padding: "5px 10px" }}>
                            <Row>
                              <Col md={1}>
                                <b>#</b>
                              </Col>
                              <Col md={4}>
                                <b>FULL NAME</b>
                              </Col>
                              <Col md={2}>
                                <b>AGE</b>
                              </Col>
                              <Col md={2}>
                                <b>GENDER</b>
                              </Col>
                              <Col md={3}>
                                <b>TYPE</b>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          {booking.passengers.map((passenger, count) => (
                            <ListGroupItem style={{ fontSize: "12px",padding: "5px 10px" }}>
                              <Row>
                                <Col md={1}>{count + 1}</Col>
                                <Col md={4}>{passenger.full_name}</Col>
                                <Col md={2}>{passenger.age}</Col>
                                <Col md={2}>{passenger.gender[0]}</Col>
                                <Col md={2}>{passenger.type.toUpperCase()}</Col>
                              </Row>
                            </ListGroupItem>
                          ))}
                        </ListGroup>

                        <ListGroup style={{ marginBottom: 10 }}>
                          <ListGroupItem style={{padding: "5px 10px"}}>
                            <ListGroupItemHeading>
                              <p className="event-title-label">
                                {booking.details.product.name}
                              </p>
                            </ListGroupItemHeading>
                          </ListGroupItem>
                          {booking.details.product.meta_data
                            .filter(
                              (m) =>
                                !m.key.includes("passenger") && m.value !== ""
                            )
                            .map((m) => (
                              <ListGroupItem style={{ fontSize: "12px", padding: "5px 10px" }}>
                                <Row>
                                  <Col md={5}>
                                    <b>{m.key}</b>
                                  </Col>
                                  <Col md={7}>{m.value}</Col>
                                </Row>
                              </ListGroupItem>
                            ))}
                        </ListGroup>

                        <ListGroup style={{ marginBottom: 10 }}>
                          <ListGroupItem style={{padding: "5px 10px"}}>
                            <ListGroupItemHeading>
                              <Row>
                                <Col md={5}>
                                  <b>TOTAL</b>
                                </Col>
                                <Col md={7}>
                                  {numberWithCommas(
                                    parseFloat(booking.details.product.total)
                                  )}
                                </Col>
                              </Row>
                            </ListGroupItemHeading>
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                      <Col>
                        <ListGroup style={{ marginBottom: 10 }}>
                          <ListGroupItem style={{padding: "5px 10px"}}>
                            <ListGroupItemHeading>
                              <p className="event-title-label">
                                PAYENT DETAILS
                              </p>
                            </ListGroupItemHeading>
                          </ListGroupItem>
                          <ListGroupItem style={{ fontSize: "12px",padding: "5px 10px" }}>
                            <Row>
                              <Col md={3}>
                                <b>DATE PAID</b>
                              </Col>
                              <Col md={9}>
                                {booking.date_paid
                                  ? dateFormat(booking.date_paid, "fullDate")
                                  : "Waiting for Payment"}
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>

                        <ListGroup style={{ marginBottom: 10 }}>
                          <ListGroupItem style={{padding: "5px 10px"}}>
                            <ListGroupItemHeading>
                              <p className="event-title-label">PLAN DETAILS</p>
                            </ListGroupItemHeading>
                          </ListGroupItem>
                          <ListGroupItem style={{ fontSize: "12px",padding: "5px 10px" }}>
                            <Row>
                              <Col md={12} style={{ padding: 0 }}>
                                <FormSelect
                                  value={
                                    bookingID === booking.id
                                      ? planReference || booking.plan_reference
                                      : booking.plan_reference
                                  }
                                  onChange={(e) =>
                                    setPlanReference(e.target.value) ||
                                    setBookingID(booking.id)
                                  }
                                >
                                  <option value="">SELECT ONE</option>
                                  {plans
                                    .filter((plan) => plan.type !== "reminder")
                                    .map((plan) => (
                                      <option value={plan.reference}>
                                        {plan.name[0]} |{" "}
                                        {dateFormat(
                                          plan.travel_date[0],
                                          "fullDate"
                                        )}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md={6} style={{ padding: 0 }}>
                                <Button
                                  backgroundColor={COLOR.primary}
                                  onClick={() =>
                                    updateBookingPlanReference(
                                      {
                                        id: booking.id,
                                        plan_reference: planReference,
                                      },
                                      () => getAllBookingsUser()
                                    )
                                  }
                                  disabled={bookingID === ""}
                                  style={{ marginRight: 10 }}
                                >
                                  Update
                                </Button>
                                {booking.plan_reference && (
                                  <Button
                                    backgroundColor={COLOR.primary}
                                    onClick={() =>
                                      navigate(
                                        `/plan/${booking.plan_reference}`
                                      )
                                    }
                                  >
                                    View Details
                                    <FontAwesomeIcon icon={faArrowRight} />
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>

                        {booking.status === "wc-completed" && (
                          <ListGroup>
                            <ListGroupItem>
                              {booking.reviews ? (
                                <>
                                  <p className="event-title-label">MY REVIEW</p>
                                  <StarRatings
                                    rating={booking.reviews.rating}
                                    starRatedColor="#ffb400"
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="20px"
                                    starSpacing="5px"
                                  />
                                  <p>{booking.reviews.review}</p>
                                </>
                              ) : (
                                <>
                                  <label
                                    style={{ padding: 0, display: "block" }}
                                  >
                                    Please give us feedback
                                  </label>
                                  <StarRatings
                                    rating={review.rating}
                                    starRatedColor="#ffb400"
                                    changeRating={(e) =>
                                      setReview({ ...review, rating: e })
                                    }
                                    numberOfStars={5}
                                    name="rating"
                                    starDimension="20px"
                                    starSpacing="5px"
                                  />
                                  <FormTextarea
                                    style={{
                                      marginTop: 10,
                                      marginBottom: 10,
                                    }}
                                    value={review.content}
                                    onChange={(e) =>
                                      setReview({
                                        ...review,
                                        content: e.target.value,
                                      })
                                    }
                                    placeholder="tell us something about your experience"
                                  />

                                  <Button
                                    backgroundColor={COLOR.primary}
                                    onClick={() =>
                                      addReviews(
                                        {
                                          product_id:
                                            booking.details.product.product_id,
                                          review: review.content,
                                          reviewer: user.username,
                                          reviewer_email: decryptMessage(
                                            user.email
                                          ),
                                          rating: review.rating,
                                          verified: true,
                                        },
                                        booking.reference,
                                        booking.details.merchant,
                                        (res) =>
                                          getAllBookingsUser() ||
                                          setReview({
                                            content: "",
                                            rating: 5,
                                          })
                                      )
                                    }
                                  >
                                    Add Review
                                  </Button>
                                </>
                              )}
                            </ListGroupItem>
                          </ListGroup>
                        )}
                      </Col>
                    </Row>
                  </Collapse>
                </ListGroupItem>
              ) : (
                <ListGroupItem  style={{padding: "5px 10px"}}>
                  <Row>
                    <Col md={2}>
                      <p className="event-title-label">BOOKING #</p>
                      <h6>{booking.order_number}</h6>

                      {loading ? (
                        "Loading ..."
                      ) : (
                        <>
                          {booking.status ? (
                            <Badge
                              theme={
                                PLATFORM.BOOKING_STATUSES.find(
                                  (s) => s.code === booking.status
                                ).color
                              }
                              style={{ fontSize: "10px" }}
                            >
                              {PLATFORM.BOOKING_STATUSES.find(
                                (s) => s.code === booking.status
                              ).label.toUpperCase()}
                            </Badge>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </Col>
                    <Col md={4}>
                      <p className="event-title-label">RESERVER DETAILS</p>
                      <h6 style={{ fontSize: "12px" }}>
                        {booking.reserver.last_name}{" "}
                        {booking.reserver.first_name &&
                          `(${booking.reserver.first_name})`}
                      </h6>
                      <h6 style={{ fontSize: "12px" }}>
                        {booking.reserver.email}
                      </h6>
                      <h6 style={{ fontSize: "12px" }}>
                        {booking.reserver.phone}
                      </h6>
                    </Col>
                    <Col md={4}>
                      <Button
                        theme="light"
                        size="sm"
                        style={{ marginRight: 10 }}
                        onClick={() =>
                          setSelectedID(
                            booking.reference === selectedID
                              ? ""
                              : booking.reference
                          )
                        }
                      >
                        {booking.reference === selectedID
                          ? "Hide Details"
                          : "View Details"}
                      </Button>
                    </Col>
                  </Row>
                  <Collapse open={booking.reference === selectedID}>
                    <hr />
                    <Row>
                      <Col md={6} style={{ paddingLeft: 0 }}>
                        <ListGroup style={{ marginBottom: 10 }}>
                          <ListGroupItem>
                            <ListGroupItemHeading>
                              <p className="event-title-label">PASSENGERS</p>
                            </ListGroupItemHeading>
                          </ListGroupItem>
                          <ListGroupItem style={{ fontSize: "12px" }}>
                            <Row>
                              <Col md={1}>
                                <b>No.</b>
                              </Col>
                              <Col md={4}>
                                <b>FULL NAME</b>
                              </Col>
                              <Col md={2}>
                                <b>AGE</b>
                              </Col>
                              <Col md={2}>
                                <b>GENDER</b>
                              </Col>
                              <Col md={3}>
                                <b>TYPE</b>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          {booking.passengers.map((passenger, count) => (
                            <ListGroupItem style={{ fontSize: "12px" }}>
                              <Row>
                                <Col md={1}>{count + 1}</Col>
                                <Col md={4}>{passenger.full_name}</Col>
                                <Col md={2}>{passenger.age}</Col>
                                <Col md={2}>{passenger.gender[0]}</Col>
                                <Col md={2}>{passenger.type.toUpperCase()}</Col>
                              </Row>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </Col>
                      <Col md={6} style={{ paddingLeft: 0 }}>
                        <ListGroup style={{ marginBottom: 10 }}>
                          <ListGroupItem>
                            <ListGroupItemHeading>
                              <p className="event-title-label">PLAN DETAILS</p>
                            </ListGroupItemHeading>
                          </ListGroupItem>
                          <ListGroupItem style={{ fontSize: "12px" }}>
                            <Row>
                              <Col md={12} style={{ padding: 0 }}>
                                <FormSelect
                                  value={
                                    bookingID === booking.id
                                      ? planReference || booking.plan_reference
                                      : booking.plan_reference
                                  }
                                  onChange={(e) =>
                                    setPlanReference(e.target.value) ||
                                    setBookingID(booking.id)
                                  }
                                >
                                  <option value="">SELECT ONE</option>
                                  {plans
                                    .filter((plan) => plan.type !== "reminder")
                                    .map((plan) => (
                                      <option value={plan.reference}>
                                        {plan.name[0]} |{" "}
                                        {dateFormat(
                                          plan.travel_date[0],
                                          "fullDate"
                                        )}
                                      </option>
                                    ))}
                                </FormSelect>
                              </Col>
                              <Col md={12}>
                                <Button
                                  backgroundColor={COLOR.primary}
                                  onClick={() =>
                                    updateBookingPlanReference(
                                      {
                                        id: booking.id,
                                        plan_reference: planReference,
                                      },
                                      () => getAllBookingsUser()
                                    )
                                  }
                                  disabled={bookingID === ""}
                                  style={{ marginRight: 10 }}
                                >
                                  Update
                                </Button>
                                {booking.plan_reference && (
                                  <Button
                                    backgroundColor={COLOR.primary}
                                    onClick={() =>
                                      navigate(
                                        `/plan/${booking.plan_reference}`
                                      )
                                    }
                                  >
                                    View Details
                                    <FontAwesomeIcon icon={faArrowRight} />
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                    </Row>
                  </Collapse>
                </ListGroupItem>
              )}
            </>
          ))}
        </>
      ) : (
        <ListGroupItem>
          <Empty />
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

export default BookingCard;
