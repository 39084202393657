import { useState } from "react";
import axios from "axios";
import config from "../utils/config";
import useCustomFunctions from "./useCustomFunctions";
import useQuotationApp from "../utils/QuotationApp/useQuotationApp";

function useAgency(settings) {
  const { user, getLoginSession } = settings;
  const { encryptMessage, decryptMessage, diffDays } = useCustomFunctions();
  const { notifStatus } = useQuotationApp();
  const { API } = config;
  const { HEADERS } = API;

  const [quotations, setQuotations] = useState([]);
  const [quotation, setQuotation] = useState([]);
  const [rates, setRates] = useState([]);

  function addQuotation(data, action) {
    let reference = (Math.random() + 1).toString(36).substring(7);
    axios
      .post(
        `${API.NEW_URL}/agency/insert_quotation`,
        {
          email: user.email,
          otp: getLoginSession[1],
          username: user.username,
          details: encryptMessage(JSON.stringify(data.details)),
          bookingDetails: encryptMessage(JSON.stringify(data.bookingDetails)),
          computedView: encryptMessage(JSON.stringify(data.computedView)),
          app_id: data.application.id,
          reference: reference,
          approval_request: data.approval_request,
        },
        HEADERS
      )
      .then(function (response) {
        // console.log(response);
        window.location.replace(
          data.link !== ""
            ? `/${data.link}/${response.data.insertId}`
            : `/apps/${data.application.id}/${response.data.insertId}`
        );
      })
      .catch(function (error) {
        // console.log("error");
      })
      .then(function () {});
  }

  function updateQuotation(data, action) {
    axios
      .post(
        `${API.NEW_URL}/agency/update_quotation`,
        {
          email: user.email,
          otp: getLoginSession[1],
          username: user.username,
          details: encryptMessage(JSON.stringify(data.details)),
          bookingDetails: encryptMessage(JSON.stringify(data.bookingDetails)),
          computedView: encryptMessage(JSON.stringify(data.computedView)),
          id: data.id,
          approval_request: data.approval_request,
        },
        HEADERS
      )
      .then(function (response) {
        // console.log(response);
        action();
      })
      .catch(function (error) {
        // console.log("error");
      })
      .then(function () {});
  }

  function deleteQuotation(data, action) {
    axios
      .post(
        `${API.NEW_URL}/agency/delete_quotation`,
        {
          email: user.email,
          otp: getLoginSession[1],
          username: user.username,
          id: data.id,
        },
        HEADERS
      )
      .then(function (response) {
        // console.log(response);
        action();
      })
      .catch(function (error) {
        // console.log("error");
      })
      .then(function () {});
  }

  function getQuotations(actions) {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/agency/get_quotations`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
          },
          HEADERS
        )
        .then(function (response) {
          // console.log(response);
          if (response.data.length !== 0) {
            setQuotations(
              response.data.map((d) => ({
                ...d,
                details: JSON.parse(decryptMessage(d.details)),
              }))
            );
          } else {
            setQuotations([]);
          }
          actions();
        })
        .catch(function (error) {
          // console.log("error");
        })
        .then(function () {});
    }
  }

  const [quotationsPerApp, setQuotationsPerApp] = useState([]);

  function getQuotationsPerApp(data) {
    if (data.user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/agency/get_quotations_per_app`,
          {
            email: data.user.email,
            otp: data.otp,
            username: data.user.username,
            app_id: data.id,
          },
          HEADERS
        )
        .then(function (response) {
          // console.log(response);
          if (response.data.length !== 0) {
            setQuotationsPerApp(
              response.data
                .map((d) => ({
                  ...d,
                  details: JSON.parse(decryptMessage(d.details)),
                  computedView: JSON.parse(decryptMessage(d.computedView)),
                }))
                .map((quote) => ({
                  ...quote,
                  daysLeft:
                    diffDays(new Date(), new Date(quote.details.startDate)) - 1,
                  notifStatus: notifStatus(quote.details),
                  status: quote.details?.approvalDetails?.isApproved
                    ? "APPROVED"
                    : "REQUESTED",
                }))
            );
          } else {
            setQuotationsPerApp([]);
          }
          // actions()
        })
        .catch(function (error) {
          // console.log("error");
        })
        .then(function () {});
    }
  }

  function getQuotationsPerID(data) {
    if (data.user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/agency/get_quotations_per_id`,
          {
            email: data.user.email,
            otp: data.otp,
            username: data.user.username,
            id: data.id,
          },
          HEADERS
        )
        .then(function (response) {
          // console.log(response);
          if (response.data.length !== 0) {
            setQuotation(
              response.data
                .map((d) => ({
                  ...d,
                  details: JSON.parse(decryptMessage(d.details)),
                  computedView: JSON.parse(decryptMessage(d.computedView)),
                  bookingDetails: JSON.parse(decryptMessage(d.bookingDetails)),
                }))
                .map((quote) => ({
                  ...quote,
                  daysLeft:
                    diffDays(new Date(), new Date(quote.details.startDate)) - 1,
                  notifStatus: notifStatus(quote.details),
                  status: quote.details?.approvalDetails?.isApproved
                    ? "APPROVED"
                    : "REQUESTED",
                }))[0]
            );
          } else {
            setQuotation([]);
          }
          // actions()
        })
        .catch(function (error) {
          // console.log("error");
        })
        .then(function () {});
    }
  }

  function getRates() {
    axios
      .post(
        `${API.NEW_URL}/bookings/get_rates`,
        {
          ...user,
          otp: getLoginSession[1],
        },
        HEADERS
      )
      .then(function (response) {
        // handle success
        // setRates(response);
        // console.log(response);
        setRates(response.data);
      })
      .catch(function (error) {
        // handle error

        console.log("error");
      })
      .then(function () {
        // always executed
      });
  }

  return {
    addQuotation,
    quotations,
    getQuotations,
    updateQuotation,
    deleteQuotation,
    quotationsPerApp,
    getQuotationsPerApp,
    quotation,
    getQuotationsPerID,
    rates,
    getRates,
  };
}

export default useAgency;
