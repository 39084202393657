import React from "react";
import ReactDOM from "react-dom/client";
import ReactPwa from "react-pwa-app";
import App from "./App";
import CookieConsent from "react-cookie-consent";
import { Loading } from "./traveltayo-ui-kit";
import { AppContext } from "./Context/AppContext";
import { CookiesProvider } from "react-cookie";
import { COLOR } from "./Hooks/utils/config";

import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Components/Styles/index.css";
import "photoswipe/dist/photoswipe.css";
import "react-image-gallery/styles/css/image-gallery.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const MainApp = () => {
  return (
    <ReactPwa
      suspense={
        <>
          <Loading />
        </>
      }
      config={{
        swUrl: "/service-worker.js",
        onUpdate: (reg) => {
          // console.log(reg);
        },
        onSuccess: (reg) => {
          // console.log(reg);
        },
        onError: (reg) => {
          // console.log(reg);
        },
        onPrompt: (e) => {
          if (e.outcome === "accepted") {
            console.log("user click on install and accept");
          }
          if (e.outcome === "dismissed") {
            console.log("user click on install and refuse");
          }
        },
      }}
    >
      <CookiesProvider>
        <AppContext>
          <App />
          <CookieConsent
            location="bottom"
            buttonText="I Agree"
            cookieName="TravelTayo Cookie"
            style={{ backgroundColor: COLOR.primary }}
            buttonStyle={{ backgroundColor: COLOR.secondary, fontSize: "13px" }}
            expires={150}
          >
            We use cookies to ensure you get the best experience on our website.
            By continuing, you are agreeing to our use of cookies.
          </CookieConsent>
        </AppContext>
      </CookiesProvider>
    </ReactPwa>
  );
};

// if (root.hasChildNodes()) {
//   ReactDOM.hydrate(, root);
// } else {
//   ReactDOM.render(<MainApp />, root);
// }

root.render(<MainApp />);

// serviceWorker.register();
