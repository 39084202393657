import React, { useEffect, useState } from "react";
import {
  Calendar,
  Fade,
  Loading,
  Helmet,
  PageContainer,
} from "../../../traveltayo-ui-kit";
import { useAppData } from "../../../Context/AppContext";
import { usePlanData } from "../../../Context/PlanContext";
import { useBookingsData } from "../../../Context/BookingsContext";
import { useApplicationData } from "../../../Context/ApplicationContext";
import { APP_LINK, APP_NAME, FEATURES } from "../../../Hooks/utils/config";

const TravelCalendar = () => {
  const { isOnline, user, auth_login, getHolidays } = useAppData();
  const { getQuotations } = useApplicationData();
  const { getAllBookingsUser } = useBookingsData();

  const { getPlans } = usePlanData();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPlans(() =>
      getQuotations(() => getAllBookingsUser(() => setLoading(false)))
    );
    if (user.length === 0 && !auth_login) {
      window.location.replace("/");
    }
  }, [auth_login, user, isOnline]);

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Calendar",
        url: APP_LINK,
      }}
      noAds={FEATURES.NO_ADS}
    >
      {loading ? (
        <div
          style={{
            height: "100vh",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            // padding: 20,
            // backgroundColor: "#fff",
          }}
        >
          <Calendar />
        </div>
      )}
    </PageContainer>
  );
};

export default TravelCalendar;
