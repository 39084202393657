import React from "react";
import {
  FormCheckbox,
  FormInput,
  faMoneyBill,
} from "../../../../../../traveltayo-ui-kit";
import {
  faCreditCard,
  faHashtag,
  faLink,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
const PaymentDetailsForm = ({ data }) => {
  const { quotation, setQuotation } = data;
  return (
    <>
      <h6>PAYMENT DETAILS</h6>
      <FormCheckbox
        small
        toggle
        checked={quotation.bookingDetails.paymentDetails.isPaid}
        onChange={() =>
          setQuotation((prev) => ({
            ...prev,
            bookingDetails: {
              ...prev.bookingDetails,
              paymentDetails: {
                ...prev.bookingDetails.paymentDetails,
                isPaid: !prev.bookingDetails.paymentDetails.isPaid,
              },
            },
          }))
        }
      >
        Is Paid?
      </FormCheckbox>
      {quotation.bookingDetails.paymentDetails.isPaid && (
        <>
          <FormInput
            group={true}
            icon={faMoneyBill}
            label="Amount Paid"
            value={quotation.bookingDetails.paymentDetails.amountPaid}
            type="number"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                bookingDetails: {
                  ...prev.bookingDetails,
                  paymentDetails: {
                    ...prev.bookingDetails.paymentDetails,
                    amountPaid: e.target.value === "" ? 0 : e.target.value,
                  },
                },
              }))
            }
            // disabled={quoteLocked}
          />
          <FormInput
            group={true}
            icon={faCreditCard}
            label="Payment Method"
            value={quotation.bookingDetails.paymentDetails.paymentMethod}
            type="text"
            placeholder="payment method use"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                bookingDetails: {
                  ...prev.bookingDetails,
                  paymentDetails: {
                    ...prev.bookingDetails.paymentDetails,
                    paymentMethod: e.target.value,
                  },
                },
              }))
            }
            // disabled={quoteLocked}
          />
          <FormInput
            group={true}
            icon={faHashtag}
            label="Payment Reference"
            value={quotation.bookingDetails.paymentDetails.paymentReference}
            type="text"
            placeholder="payment reference"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                bookingDetails: {
                  ...prev.bookingDetails,
                  paymentDetails: {
                    ...prev.bookingDetails.paymentDetails,
                    paymentReference: e.target.value,
                  },
                },
              }))
            }
            // disabled={quoteLocked}
          />
          <FormInput
            group={true}
            icon={faNoteSticky}
            label="Payment Notes"
            value={quotation.bookingDetails.paymentDetails.notes}
            type="text"
            placeholder="payment notes"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                bookingDetails: {
                  ...prev.bookingDetails,
                  paymentDetails: {
                    ...prev.bookingDetails.paymentDetails,
                    notes: e.target.value,
                  },
                },
              }))
            }
            // disabled={quoteLocked}
          />
          <FormInput
            group={true}
            icon={faLink}
            label="Payment Link"
            value={quotation.bookingDetails.paymentDetails.link}
            type="text"
            placeholder="payment link"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                bookingDetails: {
                  ...prev.bookingDetails,
                  paymentDetails: {
                    ...prev.bookingDetails.paymentDetails,
                    link: e.target.value,
                  },
                },
              }))
            }
            // disabled={quoteLocked}
          />
        </>
      )}
    </>
  );
};

export default PaymentDetailsForm;
