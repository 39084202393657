import React, {
  createContext,
  useContext,
  useState,
  useId,
  useEffect,
} from "react";
import { useAppData } from "./AppContext";
import useTravelBookings from "../Hooks/api/useTravelBookings";

const Context = createContext();

export function useBookingsData() {
  return useContext(Context);
}

export const BookingsContext = ({ children }) => {
  const { auth_login, isOnline, user, getLoginSession, decryptMessage } =
    useAppData();

  const {
    getBookingStatus,
    searchProducts,
    searchResults,
    addNewBookings,
    bookingsPerPlan,
    getUserBookingsPerPlan,
    uploadedbookingsPerPlan,
    getUserUploadedBookingsPerPlan,
    uploadBookings,
    removeUploadedBookings,
    getTicketByID,
    products,
    rtProducts,
    getProducts,
    addNewOrder,
    getOrderData,
    getProductReviews,
    getProduct,
    addReviews,
    updateBookingPlanReference,
    getAllBookingsUser,
    userBookings,
    getProductsByTag,
    setProducts,
    getProductVariations
  } = useTravelBookings({ auth_login, isOnline, user, getLoginSession });

  const bookingForm = {
    step: 1,
    reference: useId(),
    destination: "",
    origin: "",
    plan_reference: "",
    roundTrip: false,
    startDate: new Date(),
    endDate: new Date(),
    searchingFor: [],
    reserver: {
      id: auth_login && user ? user.username : "",
      full_name: auth_login && user ? decryptMessage(user.full_name) : "",
      email: auth_login && user ? decryptMessage(user.email) : "",
      mobile_number:
        auth_login && user ? decryptMessage(user.mobile_number) : "",
    },
    passengers: [
      {
        id: useId(),
        full_name: "",
        age: 0,
        gender: "",
      },
    ],
    tag: 17,
  };
  const [newBookingForm, setNewBookingForm] = useState(bookingForm);

  useEffect(() => {
    setNewBookingForm(bookingForm);
  }, [auth_login]);

  return (
    <Context.Provider
      value={{
        getProductVariations,
        getBookingStatus,
        newBookingForm,
        setNewBookingForm,
        searchProducts,
        searchResults,
        addNewBookings,
        bookingsPerPlan,
        getUserBookingsPerPlan,
        uploadedbookingsPerPlan,
        getUserUploadedBookingsPerPlan,
        uploadBookings,
        removeUploadedBookings,
        getTicketByID,
        products,
        rtProducts,
        getProducts,
        addNewOrder,
        getOrderData,
        getProductReviews,
        getProduct,
        addReviews,
        updateBookingPlanReference,
        getAllBookingsUser,
        userBookings,
        setProducts,
        getProductsByTag
      }}
    >
      {children}
    </Context.Provider>
  );
};
