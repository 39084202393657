import React, { useState } from "react";
import dateFormat from "dateformat";
import CoverImage from "../Components/CoverImage";
import { useAppData } from "../../../../Context/AppContext";
import {
  Button,
  Card,
  CardBody,
  Col as MyCol,
  Row,
  FontAwesomeIcon,
  faCalendar,
  faCopy,
  faDownload,
  faEdit,
  faLocationDot,
  DuplicateForm,
} from "../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../Hooks/utils/config";
import { usePlanData } from "../../../../Context/PlanContext";
import { useBookingsData } from "../../../../Context/BookingsContext";

const PlanCoverHeader = () => {
  const { isOnline, screenWidth } = useAppData();

  const { getUserUploadedBookingsPerPlan, getUserBookingsPerPlan } =
    useBookingsData();
  const { plan, setIsEditingPlan, setNewPlan } = usePlanData();

  const [openDuplicateForm, setOpenDuplicateForm] = useState(false);

  const Col = ({ children, ...props }) => {
    return (
      <MyCol
        {...props}
        style={{ margin: 0, marginBottom: 2, padding: 0, ...props.style }}
      >
        {children}
      </MyCol>
    );
  };

  return (
    <div>
      <DuplicateForm data={{ openDuplicateForm, setOpenDuplicateForm, plan }} />

      <Card
        style={{ marginBottom: screenWidth ? 10 : 20 }}
        className="main-card"
      >
        <CoverImage src={plan.cover}>
          <Button
            theme="light"
            onClick={() =>
              setIsEditingPlan(true) ||
              setNewPlan({
                ...plan,
                start: plan.travel_date[0],
                end: plan.travel_date[1],
                name: plan.name[0],
                destination: plan.name[1],
                description: plan.description,
                type: plan.name[2],
                geoData: plan.geoData,
              })
            }
            style={{
              marginRight: 5,
            }}
            disabled={plan.access !== "edit"}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit Details
          </Button>

          <Button
            theme="light"
            onClick={() => setOpenDuplicateForm(true)}
            style={{
              marginRight: 5,
            }}
          >
            <FontAwesomeIcon icon={faCopy} /> Duplicate
          </Button>

          {/* {isOnline ? (
            <Button
              theme="light"
              onClick={() =>
                getUserUploadedBookingsPerPlan(plan.reference) ||
                getUserBookingsPerPlan(plan.reference) ||
                alert(
                  "Successfully Saved! Your plan details are saved on this device for offline use!"
                )
              }
              s
            >
              <FontAwesomeIcon icon={faDownload} /> Save Offline
            </Button>
          ) : (
            ""
          )} */}
        </CoverImage>
        <CardBody style={{ padding: "10px 20px" }}>
          <Row>
            <Col md={6}>
              <p className="event-title-label">TRAVEL PLAN NAME</p>
              <h6>{plan.name[0]}</h6>
            </Col>

            {plan.description === "" ||
            plan.description.split("\n").length > 1 ? (
              ""
            ) : (
              <Col md={6}>
                <p className="event-title-label">DESCRIPTION</p>
                <h6>{plan.description}</h6>
              </Col>
            )}

            <Col md={6}>
              <p className="event-title-label">TRAVEL DATE</p>
              <h6>
                <FontAwesomeIcon icon={faCalendar} />{" "}
                {plan.dates[0] === plan.dates[plan.dates.length - 1] ? (
                  <>{dateFormat(plan.dates[0], "mmm dd, yyyy")}</>
                ) : (
                  <>
                    {dateFormat(plan.dates[0], "mmm dd")} -{" "}
                    {dateFormat(
                      plan.dates[plan.dates.length - 1],
                      "mmm dd, yyyy"
                    )}
                  </>
                )}
              </h6>
            </Col>

            {plan.name[1] && (
              <Col md={6}>
                <p className="event-title-label">DESTINATION</p>
                <h6>
                  <FontAwesomeIcon icon={faLocationDot} /> {plan.name[1]}
                </h6>
              </Col>
            )}
          </Row>
        </CardBody>
        {plan.description.split("\n").length > 1 ? (
          <CardBody style={{ padding: "10px 20px" }}>
            <p className="event-title-label">DESCRIPTION/ NOTES</p>
            <ul style={{ fontSize: "12px", color: COLOR.primary }}>
              {/* <FontAwesomeIcon icon={faNoteSticky} />{" "} */}
              {plan.description.split("\n").map((d) => (
                <li>{d}</li>
              ))}
            </ul>
          </CardBody>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

export default PlanCoverHeader;
