import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import { useAppData } from "./Context/AppContext";
import { Fade, Loading, PlanForm } from "./traveltayo-ui-kit";
import Login from "./Components/Pages/Accounts/Login";
import LoginQR from "./Components/Pages/Accounts/LoginQR";
import SignUp from "./Components/Pages/Accounts/SignUp";
import TravelCalendar from "./Components/Pages/TravelCalendar";
import Explore from "./Components/Pages/Explore";
import SelectedExplore from "./Components/Pages/Explore";
import ProductProfile from "./Components/Pages/Explore/Pages/ProductProfile";
import OldProductProfile from "./Components/Pages/Explore/Pages/ProductProfile";

//Profile
import Settings from "./Components/Pages/Settings";
import Notification from "./Components/Pages/Settings/Notifications";

//Plans
import Plans from "./Components/Pages/Plans";
import Plan from "./Components/Pages/Plans/Plan";
import Bookings from "./Components/Pages/Bookings";
import ManageBooking from "./Components/Pages/Bookings/Pages/BookingManager";

//Print View
import ForgotPassword from "./Components/Pages/Accounts/ForgotPassword";
import ResetPassword from "./Components/Pages/Accounts/ResetPassword";
import Blogs from "./Components/Pages/Other/Blogs";
import Blog from "./Components/Pages/Other/Blogs/Blog";

import Map from "./Components/Pages/Map";

import TicketBooking from "./Components/Pages/Explore/Pages/TicketBooking";
import Applications from "./Components/Pages/Apps";
import QuotationMaker from "./Components/Pages/Apps/QuotationMaker/App";
import Quotation from "./Components/Pages/Apps/QuotationMaker/Quotation";
import POTB from "./Components/Pages/Apps/POTB/App"
import POTBQuote from "./Components/Pages/Apps/POTB/Quotation"
import Customers from "./Components/Pages/Apps/Components/Customers";

import VerfiyAccount from "./Components/Pages/Accounts/VerfiyAccount";
import NotFound404 from "./Components/Pages/Other/NotFound404";
import { FEATURES } from "./Hooks/utils/config";


import AirlineBooking from "./Components/Pages/AirlineBooking"

const App = () => {
  const { auth_login, user, isOnline } = useAppData();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [auth_login, user]);

  return (
    <>
      
      <Fade>
        {isOnline ? (
          ""
        ) : (
          <p style={{ textAlign: "center", margin: 10 }}>
            You are using the offline version of this application. No internet!
            Some of edit and modification features are disabled.
          </p>
        )}

        {loading ? (
          <Loading />
        ) : (
          <Router>
            {user.length !== 0 ? (
              <TravelCalendar path="/" />
            ) : (
              <>
                {!FEATURES.EXPLORE_PAGE ? (
                  <Login path="/" />
                ) : (
                  <Explore path="/" />
                )}
              </>
            )}

            <VerfiyAccount path="/verify/:email/:reference" />
            <Login path="/login" />
            <LoginQR path="/login/qr/:data" />
            <SignUp path="/sign-up" />
            <ForgotPassword path="/forgot-password" />
            <ResetPassword path="/reset-password/:token1" />
            <TravelCalendar path="/calendar" />

            <Bookings path="/bookings" />
            <ManageBooking path="/booking/manage" />

            <Explore path="/explore" />
            <SelectedExplore path="/explore/:tag" />
            <ProductProfile path="/explore/:tag/:id" />
            <OldProductProfile path="/explore/page/:merchant/:id" />

            <Plans path="/plans" />
            <Settings path="/settings" />
            <Notification path="/notifications" />
            <Applications path="/apps" />
            <QuotationMaker path="/apps/:id" />
            <Quotation path="/apps/:app_id/:id" />

            {/* POTB APP */}
            <POTB path="/potb-quote-maker"/>
            <POTBQuote path="/potb-quote-maker/:id"/>

            <Customers path="/potb-quote-maker/customers" />
            <Customers path="/apps/:id/customers" />
            

            <Plan path="/plan/:id" />
            {/* <Plan path="/shared_plan/:id" event_type="shared_travel_plan" /> */}

            {/* other page */}
            <Blogs path="/blogs" />
            <Blog path="/blog/:slug" />

            {/* ticket bookings */}
            <TicketBooking path="/booking/ticket" />

            <Map path="/map" />

            {/* <AirlineBooking path="airline-booking"/> */}
            <NotFound404 path="*"/>
          </Router>
        )}
      </Fade>
    </>
  );
};

export default App;
