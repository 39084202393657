import {
  faEnvelope,
  faPhone,
  faSave,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import {
  Col,
  FormInput,
  FormUpload,
  ModalForm,
  Row,
} from "../../../../../../traveltayo-ui-kit";
import { useApplicationData } from "../../../../../../Context/ApplicationContext";

const Details = ({ data }) => {
  const { openAgencyDetailsForm, setOpenAgencyDetailsForm } = data;

  const {
    application,
    updateAgencyDetailsByID,
    getApplicationByID,
  } = useApplicationData();

  const [loading, setLoading] = useState(false);

  const [agencyDetails, setAgencyDetails] = useState({
    name: "",
    logo: "",
    address: "",
    contactDetails: "",
    termsAndConditions: "",
    emailAddress: "",
    paymentOption: "",
  });

  useEffect(() => {
    if (application.length !== 0) {
      setAgencyDetails(application.agency_data);
    }
  }, [application]);

  return (
    <ModalForm
      open={openAgencyDetailsForm}
      toggle={() => {}}
      header={`Edit Agency Details`}
      className="quoration-form"
      bodyPadding="10px"
      loading={loading}
      size="sm"
      approvedBTNProps={{
        // block: "block",
        display: true,
        size: "sm",
        iconPosition: "left",
        icon: faSave,
        label: "Save",
        onClick: () =>
          updateAgencyDetailsByID(
            {
              app_id: application.id,
              agency_id: application.agency_id,
              agency_data: agencyDetails,
            },
            () =>
              getApplicationByID(
                { id: application.id },
                () =>
                  setLoading(true) ||
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000)
              )
          ),
      }}
      cancelBTNProps={{
        // block: "block",
        display: true,
        size: "sm",
        onClick: () => setOpenAgencyDetailsForm(false),
        label: "Close"
      }}
      deleteBTNProps={{
        display: false,
      }}
    >
      <Row>
        <Col md={6}>
          <FormInput
            group={true}
            icon={faUser}
            label="AGENCY NAME"
            value={agencyDetails.name}
            placeholder="agency name"
            onChange={(e) =>
              setAgencyDetails((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />

          <FormInput
            group={true}
            icon={faEnvelope}
            label="EMAIL ADDRESS"
            value={agencyDetails.emailAddress}
            placeholder="email Address"
            onChange={(e) =>
              setAgencyDetails((prev) => ({
                ...prev,
                emailAddress: e.target.value,
              }))
            }
          />

          <FormInput
            group={true}
            icon={faPhone}
            label="CONTACT DETAILS"
            value={agencyDetails.contactDetails}
            placeholder="contact details"
            onChange={(e) =>
              setAgencyDetails((prev) => ({
                ...prev,
                contactDetails: e.target.value,
              }))
            }
          />

          <FormInput
            group={false}
            icon={faPhone}
            inputType="textarea"
            label="OFFICE ADDRESS"
            value={agencyDetails.address}
            placeholder="office details"
            onChange={(e) =>
              setAgencyDetails((prev) => ({
                ...prev,
                address: e.target.value,
              }))
            }
          />
          <FormUpload
            label="AGENCY LOGO"
            value={agencyDetails.logo}
            placeholder=""
            action={(e) =>
              setAgencyDetails((prev) => ({
                ...prev,
                logo: e,
              }))
            }
            loading={loading}
            setLoading={setLoading}
            acceptFiles={"image/*"}
          />

          <FormUpload
            label="PAYMENT OPTION IMAGE"
            value={agencyDetails.paymentOption}
            placeholder=""
            action={(e) =>
              setAgencyDetails((prev) => ({
                ...prev,
                paymentOption: e,
              }))
            }
            loading={loading}
            setLoading={setLoading}
            acceptFiles={"image/*"}
          />
        </Col>
        <Col md={6}>
          <FormInput
            group={false}
            icon={faPhone}
            inputType="textarea"
            label="AGENCY TERMS & CONDITIONS"
            value={agencyDetails.termsAndConditions}
            placeholder="terms and condition seperated by line"
            rows={6}
            onChange={(e) =>
              setAgencyDetails((prev) => ({
                ...prev,
                termsAndConditions: e.target.value,
              }))
            }
          />
          {agencyDetails.logo !== "" && (
            <img src={agencyDetails.logo} style={{ width: "200px", marginBottom: 20 }} />
          )}

          {agencyDetails.paymentOption !== "" && (
            <img src={agencyDetails.paymentOption} style={{ width: "100%" }} />
          )}
        </Col>
      </Row>
    </ModalForm>
  );
};

export default Details;
