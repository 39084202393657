import React, { useState, useEffect, Suspense } from "react";
import dateFormat from "dateformat";
import Empty from "../../../Basic/Empty";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import {
  Button,
  ModalForm,
  Fade,
  ListGroup,
  ListGroupItemHeading,
  ListGroupItem,
  Badge,
  Row,
  Col,
  FormCheckbox,
  FontAwesomeIcon,
  faPlus,
  faEdit,
  faLocationDot,
  faPesoSign,
  Loading,
  ItineraryForm,
} from "../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../Context/AppContext";
import { usePlanData } from "../../../../Context/PlanContext";
import { DEFAULT_VALUES } from "../../../../Hooks/utils/config";

const PlanItinerary = () => {
  const { isOnline, user, screenWidth } = useAppData();
  const {
    plan,
    getPlan,
    updateDetails,
    regenerateItinerary,
    setIsAddingNewItineraries,
    setIsEditingItineraries,
    setItinerariesNewForm,
  } = usePlanData();

  const { numberWithCommas, ValidURL } = useCustomFunctions();

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    setPreferences(plan.preferences);
  }, [plan]);

  const ListItem = ({ itin }) => {
    return (
      <ListGroupItem
        style={{ display: "flex", flexDirection: "row", padding: "5px 10px" }}
      >
        <div style={{ width: "85%", display: "inline" }}>
          <Badge theme="success">
            {dateFormat(itin.start, "h:MM TT")} -{" "}
            {dateFormat(itin.end, "h:MM TT")}
          </Badge>{" "}
          {itin.location ? (
            <Badge theme="info">
              <FontAwesomeIcon icon={faLocationDot} /> {itin.location}
            </Badge>
          ) : (
            ""
          )}{" "}
          <Badge theme="warning">
            {parseFloat(itin.amountPaid) !== 0 ? (
              <>
                {/* <FontAwesomeIcon icon={faPesoSign} />{" "} */}
                &#8369; {numberWithCommas(itin.amountPaid)}
              </>
            ) : (
              ""
            )}
          </Badge>{" "}
          <Badge theme="light">{itin.title}</Badge>{" "}
          {itin.description ? (
            <p
              style={{
                margin: 0,
                padding: "10px 0px",
                paddingBottom: "0px",
                fontSize: "12px",
              }}
            >
              {/* <FontAwesomeIcon icon={faNoteSticky} />{" "} */}
              {/* {itin.description} */}

              <ul
                style={{
                  listStyleType: "square",
                  paddingLeft: "20px",
                }}
              >
                {itin.description
                  .replace(/-/g, "")
                  .split("\n")
                  .filter((list) => list)
                  .map((list) => (
                    <li>
                      {ValidURL(list) ? (
                        <a
                          href={list}
                          target="_blank"
                          style={{ color: "inherit" }}
                        >
                          {list}
                        </a>
                      ) : (
                        list
                      )}
                    </li>
                  ))}
              </ul>
            </p>
          ) : (
            ""
          )}
        </div>
        <div style={{ width: "15%", display: "inline" }}>
          {isOnline && plan.credential !== "viewer" ? (
            <Button
              theme="light"
              onClick={() =>
                setItinerariesNewForm(itin) || setIsEditingItineraries(true)
              }
              style={{ width: "100%" }}
            >
              <FontAwesomeIcon icon={faEdit} /> {screenWidth ? "" : "Edit"}
            </Button>
          ) : (
            ""
          )}
        </div>
      </ListGroupItem>
    );
  };

  return (
    <Suspense fallback={<Loading />}>
      <Fade>
        <ModalForm
          open={openModal}
          toggle={() => {}}
          header="Travel Preferences"
          bodyPadding="10px 20px"
          loading={loading}
          approvedBTNProps={{
            // block: "block",
            display: true,
            size: "sm",
            iconPosition: "left",
            icon: faEdit,
            label: "Generate Itinerary",
            onClick: () =>
              setLoading(true) ||
              regenerateItinerary(preferences, () =>
                updateDetails(
                  {
                    ...plan,
                    preferences: preferences,
                    start: plan.travel_date[0],
                    end: plan.travel_date[1],
                    name: plan.name[0],
                    destination: plan.name[1],
                    type: plan.name[2],
                  },
                  () =>
                    setOpenModal(false) ||
                    setLoading(false) ||
                    getPlan(plan.reference)
                )
              ),
          }}
          cancelBTNProps={{
            // block: "block",
            display: true,
            size: "sm",
            onClick: () => setOpenModal(false),
          }}
          deleteBTNProps={{
            display: false,
          }}
        >
          {loading ? (
            <Loading>
              Please wait while AI generate your new itineraries and budget
            </Loading>
          ) : (
            ""
          )}
          {!loading && (
            <Row style={{ padding: 0, fontSize: "13px" }}>
              {DEFAULT_VALUES.TRAVEL_PREFERENCES.map((preference) => (
                <Col
                  md={4}
                  xs={6}
                  sm={6}
                  style={{ padding: 0, paddingRight: 5 }}
                >
                  <FormCheckbox
                    checked={preferences.includes(preference)}
                    onChange={() =>
                      setPreferences(
                        preferences.includes(preference)
                          ? preferences.filter((p) => p !== preference)
                          : [...preferences, preference]
                      )
                    }
                    inline
                  >
                    {preference}
                  </FormCheckbox>
                </Col>
              ))}
            </Row>
          )}
        </ModalForm>

        {plan.length !== 0 && <ItineraryForm />}

        {isOnline ? (
          <>
            {plan.itinerary.length === 0 ? (
              <Empty>
                <Button
                  theme="light"
                  onClick={() => setIsAddingNewItineraries(true)}
                  disabled={plan.access !== "edit"}
                >
                  <FontAwesomeIcon icon={faPlus} /> First Itinerary
                </Button>
                {user.ai_tool === 1 && (
                  <Button
                    theme="light"
                    onClick={() => setOpenModal(true)}
                    style={{ margin: 5 }}
                    disabled={plan.access !== "edit"}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Generate Itinerary
                  </Button>
                )}
              </Empty>
            ) : (
              <div style={{ marginBottom: 15 }}>
                <Button
                  theme="light"
                  onClick={() => setIsAddingNewItineraries(true)}
                  disabled={plan.access !== "edit"}
                >
                  <FontAwesomeIcon icon={faPlus} /> New Itinerary
                </Button>
                {user.ai_tool === 1 && (
                  <Button
                    theme="light"
                    onClick={() => setOpenModal(true)}
                    style={{ margin: 5 }}
                  >
                    <FontAwesomeIcon icon={faEdit} /> Regenerate New Itinerary
                  </Button>
                )}
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {plan.itinerary.length !== 0 ? (
          <>
            <>
              {plan.itinerary.filter((itin) => itin.day == 0).length !== 0 ? (
                <ListGroup style={{ marginBottom: 5 }} key={0}>
                  <ListGroupItem style={{ padding: "5px 10px" }}>
                    <ListGroupItemHeading>Day Zero</ListGroupItemHeading>
                  </ListGroupItem>
                  {plan.itinerary
                    .filter((itin) => itin.day == 0)
                    .sort(function (a, b) {
                      var c = new Date(a.start);
                      var d = new Date(b.start);
                      return c - d;
                    })
                    .map((itin, index) => (
                      <ListItem itin={itin} />
                    ))}
                </ListGroup>
              ) : (
                ""
              )}
            </>

            {plan.dates.map((date, index) => (
              <>
                {plan.itinerary.filter((itin) => itin.day == index + 1)
                  .length !== 0 ? (
                  <ListGroup style={{ marginBottom: 10 }} key={index}>
                    <ListGroupItem style={{ padding: "5px 10px" }}>
                      <ListGroupItemHeading>
                        {dateFormat(date, "fullDate")}
                      </ListGroupItemHeading>
                    </ListGroupItem>
                    {plan.itinerary
                      .filter((itin) => itin.day == index + 1)
                      .sort(function (a, b) {
                        var c = new Date(a.start);
                        var d = new Date(b.start);
                        return c - d;
                      })
                      .map((itin, index) => (
                        <>
                          <ListItem itin={itin} />
                        </>
                      ))}
                  </ListGroup>
                ) : (
                  ""
                )}
              </>
            ))}
          </>
        ) : (
          <>{isOnline ? "" : <Empty />}</>
        )}
      </Fade>
    </Suspense>
  );
};

export default PlanItinerary;
