import React, { useState } from "react";
import {
  NavbarBrand,
  Button,
  FontAwesomeIcon,
  faList,
  faBell,
  faCalendar,
  ButtonToolbar,
  ButtonGroup,
  faPaperPlane,
  faPlaneArrival,
  faCompass,
  faGear,
  faSignOut,
  Row,
  Col,
} from "../../../traveltayo-ui-kit";
import { COLOR, FEATURES, PLATFORM } from "../../../Hooks/utils/config";
import { Link } from "@reach/router";
import { useAppData } from "../../../Context/AppContext";
import { useNavigate } from "@reach/router";
import {
  faBook,
  faCheck,
  faHome,
  faNewspaper,
  faPlaneDeparture,
  faPlus,
  faQuestionCircle,
  faSign,
} from "@fortawesome/free-solid-svg-icons";
import { usePlanData } from "../../../Context/PlanContext";

const HeaderMenu = (props) => {
  const navigate = useNavigate();
  const {
    auth_login,
    logout,
    screenSizes,
    setIsAddingNewPlan,
    screenWidth,
    user,
  } = useAppData();

  const { xSmall, small, medium, large } = screenSizes;

  const MENUS = [
    {
      label: "Notif",
      icon: faBell,
      link: "/notifications",
      action: () => "",
      show: auth_login,
      position: "top",
    },
    {
      label: "FAQs",
      icon: faQuestionCircle,
      link: "https://gladexb2b.gurucan.com/app/courses",
      target: "new_tab",
      action: () => "",
      show: auth_login && user.POTB !== 0,
      position: "top",
    },
    {
      label: "Calendar",
      icon: faCalendar,
      link: "/",
      action: () => "",
      show: auth_login,
      position: "bottom",
    },
    {
      label: "Plans",
      icon: faPaperPlane,
      link: "/plans",
      action: () => "",
      show: auth_login,
      position: "bottom",
    },
    {
      label: "New Plan",
      icon: faPlus,
      link: "#",
      action: () => setIsAddingNewPlan(true),
      show: auth_login,
      position: "bottom",
    },
    {
      label: "Bookings",
      icon: faPlaneArrival,
      link: "/bookings",
      action: () => "",
      show: auth_login && FEATURES.BOOKING_SYSTEM,
      position: "bottom",
    },
    {
      label: "Explore",
      icon: faCompass,
      link: "/explore",
      action: () => "",
      show: auth_login && FEATURES.EXPLORE_PAGE,
      position: "top",
    },

    {
      label: "Apps",
      icon: faList,
      link: "/apps",
      action: () => "",
      show: auth_login && user.role === "admin",
      position: "top",
    },

    {
      label: "Blogs",
      icon: faBook,
      link: "/blogs",
      action: () => "",
      show: auth_login && FEATURES.BLOG_PAGE,
      position: "top",
    },
    {
      label: "Quote",
      icon: faPlaneArrival,
      link: "/potb-quote-maker",
      action: () => "",
      show: auth_login && user.POTB !== 0,
      position: "bottom",
    },
    {
      label: "Portal",
      icon: faPlaneDeparture,
      link: user.airBooking,
      target: "new_tab",
      action: () => "",
      show: auth_login && user.POTB !== 0,
      position: "bottom",
    },
    {
      label: "Settings",
      icon: faGear,
      link: "/settings",
      action: () => "",
      show: auth_login,
      position: "top",
    },
    {
      label: "Logout",
      icon: faSignOut,
      link: "#",
      action: () => logout(),
      show: auth_login,
      position: "top",
    },
    {
      label: "Home",
      icon: faHome,
      link: "/",
      action: () => "",
      show: !auth_login,
      position: "top",
    },
    {
      label: "About Us",
      icon: faNewspaper,
      link: "/blog/about-us",
      action: () => "",
      show: !auth_login && FEATURES.ABOUT_US_PAGE,
      position: "top",
    },
    {
      label: "Blogs",
      icon: faBook,
      link: "/blogs",
      action: () => "",
      show: !auth_login && FEATURES.BLOG_PAGE,
      position: xSmall ? "bottom" : "top",
    },
    {
      label: "Manage Booking",
      icon: faCheck,
      link: "/booking/manage",
      action: () => "",
      show: !auth_login && FEATURES.MANAGE_BOOKING_PAGE,
      position: xSmall ? "bottom" : "top",
    },
    {
      label: "Login",
      icon: faSign,
      link: "/login",
      action: () => "",
      show: !auth_login,
      position: xSmall ? "bottom" : "top",
    },
  ];

  return (
    <>
      <Row
        type="dark"
        expand="sm"
        className={`navbar`}
        style={{
          padding: 0,
          backgroundColor: COLOR.primary,
          width: "100%",
          // display: "flex",
        }}
      >
        {!screenWidth && (
          <Col md={3} lg={3}>
            {!PLATFORM.LOGO.PARTNER_LOGO && (
              <img src={PLATFORM.LOGO.WHITE} style={{ width: "100px" }} />
            )}
          </Col>
        )}
        <Col md={6} lg={6} xs={12} sm={12} style={{ padding: 0, margin: 0 }}>
          <ButtonToolbar style={{ display: "inline" }}>
            <ButtonGroup
              style={{
                backgroundColor: COLOR.primary,
                width: "100%",
              }}
            >
              {MENUS.map((MENU) => (
                <>
                  {MENU.show && MENU.position === "top" && (
                    <Button
                      size="lg"
                      onClick={() =>
                        MENU.action() || MENU.target === "new_tab"
                          ? window.open(MENU.link)
                          : navigate(MENU.link)
                      }
                      backgroundColor={
                        window.location.pathname === MENU.link
                          ? COLOR.secondary
                          : COLOR.primary
                      }
                      className={`menu-tab-header`}
                    >
                      <FontAwesomeIcon icon={MENU.icon} />
                      <span style={{ fontSize: 10 }}>{MENU.label}</span>
                    </Button>
                  )}
                </>
              ))}
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
        <Col md={3} lg={3} sm={12} xs={12}></Col>
      </Row>

      <Row
        type="dark"
        expand="sm"
        className="footer-navbar navbar2"
        style={{
          padding: 0,
          backgroundColor: COLOR.primary,
          width: "100%",
          display: "flex",
        }}
      >
        {!screenWidth && <Col md={3} lg={3} sm={12} xs={12}></Col>}

        <Col md={6} lg={6} sm={12} xs={12} style={{ padding: 0 }}>
          <ButtonToolbar style={{ display: "inline" }}>
            <ButtonGroup
              style={{
                backgroundColor: COLOR.primary,
                width: "100%",
              }}
            >
              {MENUS.map((MENU) => (
                <>
                  {MENU.show && MENU.position === "bottom" && (
                    <Button
                      size="lg"
                      onClick={() =>
                        MENU.action() || MENU.target === "new_tab"
                          ? window.open(MENU.link)
                          : navigate(MENU.link)
                      }
                      backgroundColor={
                        window.location.pathname === MENU.link
                          ? COLOR.secondary
                          : COLOR.primary
                      }
                      className={`menu-tab-footer`}
                    >
                      <FontAwesomeIcon icon={MENU.icon} />
                      <span style={{ fontSize: 10 }}>{MENU.label}</span>
                    </Button>
                  )}
                </>
              ))}
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
        <Col md={3} lg={3} sm={12} xs={12}></Col>
      </Row>
    </>
  );
};

export default HeaderMenu;
