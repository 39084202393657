import axios from "axios";
import useCustomFunctions from "../../api/useCustomFunctions";
import { useState } from "react";
import { APP_NAME } from "../config";

function useQuotationApp() {
  const { numberWithCommas, insertFreeTime } = useCustomFunctions();

  const getRates = (settings, setRates, setLoading) => {
    setLoading(true);
    axios.get(settings.rates).then((response) => {
      let data = response.data
        .split("\r\n")
        .slice(1, response.data.length)
        .map((d) => d.split("\t"))
        .map((d) => ({
          id: parseFloat(d[0]),
          DESTINATION: d[1],
          HOTEL_NAME: d[2],
          DAYS: parseFloat(d[3]),
          NIGHTS: parseFloat(d[4]),
          PAX: parseFloat(d[5]),
          TYPE: d[6],
          ROOM: d[7],
          RATE: parseFloat(d[8]),
          EXTRA: parseFloat(d[9]),
          INCLUSION: d[10],
          ACTIVE: d[11],
          INCLUSIONS: d[12],
          ITINERARY: d[13],
          DEPARTURE_TERMINAL: d[14],
          RETURN_TERMINAL: d[15],
          COMMISSION: parseFloat(d[17]),
          ADDITIONAL_TOUR:
            d[26] !== "-"
              ? JSON.parse(d[26].replace(/'/g, '"')).map((rate) => ({
                  ...rate,
                  PAX: parseInt(rate.PAX),
                  RATE: parseFloat(rate.RATE),
                  ID: parseInt(rate.ID),
                  B2B_RATE: parseFloat(rate.B2B_RATE),
                  INCLUSIONS: rate.INCLUSIONS ? rate.INCLUSIONS.split("|") : [],
                }))
              : [],
        }))
        .map((rate, index) => ({
          ...rate,
          id: index,
          value: `${rate.HOTEL_NAME} | ${rate.TYPE}`,
        }))
        .filter((rate) => rate.ACTIVE === "TRUE");

      // console.log(
      //   data
      //     .map((d) => d.DESTINATION)
      //     .filter((v, i) => data.map((d) => d.DESTINATION).indexOf(v) == i)
      //     .map((d, index) => ({
      //       id: index,
      //       destination: d,
      //       country: "PH",
      //       packages: data
      //         .filter((da) => da.DESTINATION === d)
      //         .map((da) => da.HOTEL_NAME)
      //         .filter(
      //           (v, i) =>
      //             data
      //               .filter((da) => da.DESTINATION === d)
      //               .map((da) => da.HOTEL_NAME)
      //               .indexOf(v) == i
      //         )
      //         .map((da) => ({
      //           provider: "Gladex",
      //           display_name: data.filter(
      //             (dat) => dat.DESTINATION === d && dat.HOTEL_NAME === da
      //           )[0].TYPE,
      //           hotel_name: data.filter(
      //             (dat) => dat.DESTINATION === d && dat.HOTEL_NAME === da
      //           )[0].HOTEL_NAME,
      //           description: "",
      //           no_days: data.filter(
      //             (dat) => dat.DESTINATION === d && dat.HOTEL_NAME === da
      //           )[0].DAYS,
      //           no_nights: data.filter(
      //             (dat) => dat.DESTINATION === d && dat.HOTEL_NAME === da
      //           )[0].NIGHTS,
      //           rates: data
      //             .filter(
      //               (dat) => dat.DESTINATION === d && dat.HOTEL_NAME === da
      //             )
      //             .map((dat) => ({
      //               count: dat.PAX,
      //               publish_rate: dat.RATE,
      //               b2b_rate: dat.RATE,
      //               extra_night_rate: dat.EXTRA,
      //               is_child: false,
      //               name: dat.ROOM,
      //               commission: dat.COMMISSION,
      //               additional_fee: 0,
      //               SKU: "",
      //             })),
      //           inclusions: data.filter(
      //             (dat) => dat.DESTINATION === d && dat.HOTEL_NAME === da
      //           )[0].INCLUSIONS.split(" \\n "),
      //           itineraries: data.filter(
      //             (dat) => dat.DESTINATION === d && dat.HOTEL_NAME === da
      //           )[0].ITINERARY.split(" \\n "),
      //           additional_tours: data.filter(
      //             (dat) => dat.DESTINATION === d && dat.HOTEL_NAME === da
      //           )[0].ADDITIONAL_TOUR,
      //         })),
      //     }))
      // );

      setRates(data);
      setLoading(false);
    });
  };

  const generateGuest = (qty) => {
    let list = [];
    for (let index = 0; index < qty; index++) {
      // console.log(qty);
      list.push({
        id: (Math.random() + 1).toString(36).substring(7),
        name: "",
        age: "",
        gender: "",
        nationality: "",
      });
    }

    return list;
  };

  const generateItineraryList = (rates) => {
    let list = [];

    for (let index = 0; index < rates[0].ITINERARY.length; index++) {
      list.push(`DAY ${index + 1}: ${rates[0].ITINERARY[index]}`);
    }

    return list.join("\n");
  };

  const generateInclusionList = (rates, quotation) => {
    let list = [];

    for (let index = 0; index < rates[0].INCLUSIONS.length; index++) {
      list.push(`☑️ ${rates[0].INCLUSIONS[index]}`);
    }

    if (quotation.bookingDetails.tourDetails.additionalTour) {
      for (
        let index = 0;
        index < quotation.bookingDetails.tourDetails.tours.length;
        index++
      ) {
        list.push(
          `☑️ ${quotation.bookingDetails.tourDetails.tours[index].TOUR_NAME}`
        );
      }
    }

    return list.join("\n");
  };

  const getRemainingPax = (quotation) => {
    return (
      quotation.details.pax -
      quotation.bookingDetails.room.reduce(
        (a, b) => parseInt(a) + parseInt(b.roomPax),
        0
      )
    );
  };

  const getTotalRoomPax = (quotation) => {
    return quotation.bookingDetails.room.reduce(
      (a, b) => parseInt(a) + parseInt(b.roomPax),
      0
    );
  };

  const checkExpiredQuotation = (rates, quotation) => {
    return rates.filter(
      (rate) =>
        rate.DESTINATION === quotation.details.destination &&
        rate.value === quotation.bookingDetails.hotel
    ).length !== 0
      ? false
      : true;
  };

  const generateUpdatedRates = (rates, quotation) => {
    let data = checkExpiredQuotation(rates, quotation)
      ? []
      : quotation.bookingDetails.room
          .map((r) => ({
            ...rates.find(
              (rate) =>
                rate.ROOM === r.ROOM &&
                rate.DESTINATION === quotation.details.destination &&
                rate.value === quotation.bookingDetails.hotel
            ),
            roomPax: r.roomPax,
          }))
          .map((r) => ({
            ...r,
            INCLUSIONS: r.INCLUSIONS.replace(
              "[nights]",
              `${quotation.bookingDetails.night} nights`
            ).split("\\n "),
            ITINERARY: r.ITINERARY.split("\\n "),
            totalRate:
              parseFloat(r.RATE) *
              (parseFloat(r.roomPax) < parseFloat(r.PAX)
                ? parseFloat(r.PAX)
                : parseFloat(r.roomPax)),
          }))
          .map((r) => ({
            ...r,
            ITINERARY:
              quotation.bookingDetails.days - r.DAYS > 0
                ? insertFreeTime(
                    r.ITINERARY,
                    quotation.bookingDetails.days - r.DAYS
                  )
                : r.ITINERARY,
          }))
          .map((r) => ({
            ...r,
            ITINERARY: quotation.bookingDetails.replaceItinerary
              ? quotation.bookingDetails.customItinerary.split("\n")
              : quotation.bookingDetails.customItinerary !== ""
              ? r.ITINERARY.concat(
                  quotation.bookingDetails.customItinerary.split("\n")
                )
              : r.ITINERARY,
            INCLUSIONS: quotation.bookingDetails.replaceInclusions
              ? quotation.bookingDetails.customInclusions.split("\n")
              : quotation.bookingDetails.customInclusions !== ""
              ? r.INCLUSIONS.concat(
                  quotation.bookingDetails.customInclusions.split("\n")
                )
              : r.INCLUSIONS,
          }));

    return data;
  };

  const generateComputedView = (rates, quotation) => {
    let filteredRates = rates.filter(
      (obj, index) => rates.findIndex((rate) => rate.id === obj.id) === index
    );
    //commissions
    let totalCommission = rates.reduce(
      (a, b) => a + b.COMMISSION * b.roomPax,
      0
    );

    //extra night
    let nightRates =
      filteredRates.reduce((a, b) => a + b.EXTRA, 0) *
      rates.reduce((a, b) => a + b.PAX, 0);

    let extraNightRates =
      nightRates *
      (parseFloat(rates[0].NIGHTS) -
        parseFloat(quotation.bookingDetails.night) >=
      0
        ? 0
        : parseFloat(quotation.bookingDetails.night) -
          parseFloat(rates[0].NIGHTS));

    let extraNights =
      parseFloat(rates[0].NIGHTS) -
        parseFloat(quotation.bookingDetails.night) ===
      0
        ? 0
        : parseFloat(quotation.bookingDetails.night) -
          parseFloat(rates[0].NIGHTS);

    //airfare
    let totalDepartureFare =
      quotation.details.flightDetails.departureFare *
      quotation.details.flightDetails.departureGuestCount;
    let totalReturnFare =
      quotation.details.flightDetails.returnFare *
      quotation.details.flightDetails.returnGuestCount;

    //tour
    let totalAddOnTour = quotation.bookingDetails.tourDetails.additionalTour
      ? quotation.bookingDetails.tourDetails.tours.reduce(
          (a, b) => a + b.RATE * b.tourPax | 0,
          0
        )
      : 0;

    //total package for master agent

    let masterAgentTotalAddOnTour =
      (quotation.bookingDetails.tourDetails.additionalTour
        ? quotation.bookingDetails.tourDetails.tours.reduce(
            (a, b) => a + b.B2B_RATE,
            0
          )
        : 0) * parseFloat(quotation.details.pax);

    let masterAgentTotalPackage =
      masterAgentTotalAddOnTour +
      rates.reduce((a, b) => a + b.totalRate, 0) +
      extraNightRates -
      totalCommission;

    let masterAgentPerPaxRate =
      masterAgentTotalPackage / parseFloat(quotation.details.pax);

    //total package for agent
    let totalPackage =
      totalAddOnTour +
      rates.reduce((a, b) => a + b.totalRate, 0) +
      (quotation.details.flightDetails.flightFareIncluded
        ? parseFloat(totalDepartureFare) + parseFloat(totalReturnFare)
        : 0) +
      parseFloat(quotation.bookingDetails.serviceFee) +
      extraNightRates -
      quotation.bookingDetails.discount;

    let perPaxRate = totalPackage / parseFloat(quotation.details.pax);

    let balanceLeft =
      totalPackage - quotation.bookingDetails.paymentDetails.amountPaid;
    let subTotalRates = rates.reduce((a, b) => a + b.totalRate, 0);

    let amountPaid = quotation.bookingDetails.paymentDetails.isPaid
      ? numberWithCommas(quotation.bookingDetails.paymentDetails.amountPaid)
      : 0;

    let computed_data = {
      // ...quotation,
      // passengers: passengers,
      masterAgentTotalAddOnTour,
      masterAgentTotalPackage: masterAgentTotalPackage,
      masterAgentPerPaxRate: masterAgentPerPaxRate,
      departureFare: totalDepartureFare,
      returnFare: totalReturnFare,
      subTotalRates: subTotalRates,
      subTotalAirfare: totalDepartureFare + totalReturnFare,
      additionalTourRate: totalAddOnTour, ///
      serviceFee: parseFloat(quotation.bookingDetails.serviceFee),
      discount: parseFloat(quotation.bookingDetails.discount),
      totalPackage: totalPackage,
      perPaxRate: perPaxRate,
      extraNightRates: extraNightRates,
      extraNights: extraNights,
      amountPaid: amountPaid,
      balanceLeft: balanceLeft,
      isFullyPaid:
        parseInt(quotation.bookingDetails.paymentDetails.amountPaid) ===
        parseInt(totalPackage),
      totalCommission: totalCommission,
    };

    return computed_data;
  };

  const notifMessage = (quotation, updatedRates, computedView, user) => {
    let quoteMessage =
      "Hi, [RESERVER_NAME]! This is [USER] from [APP_NAME]. \n\nHere's the initial quotation for the [PACKAGE_NAME] Package: \n\nTour Package Rate Per Person: P [RATE_PER_PAX] \nTotal Package Rate: P [TOTAL_PACKAGE] \n \nINCLUSIONS:\n[INCLUSIONS]\n\nITINERARIES:\n[ITINERARIES]\n\nPlease note that all rates are subject to change and availability. Kindly message us if you wish to proceed with booking this tour package.";

    let RESERVER_NAME = quotation.details.name;
    let PACKAGE_NAME = `${quotation.bookingDetails.days}D${
      quotation.bookingDetails.night
    }N ${quotation.details.destination} ${
      quotation.details.flightDetails.flightFareIncluded ? "ALL-IN " : ""
    }`;

    let RATE_PER_PAX = computedView.perPaxRate || 0;
    let TOTAL_PACKAGE = computedView.totalPackage || 0;

    //messages
    if (quotation.details.notifications.initQuote) {
      return quoteMessage
        .replace("[RESERVER_NAME]", RESERVER_NAME)
        .replace("[USER]", user.username)
        .replace("[APP_NAME]", APP_NAME)
        .replace("[PACKAGE_NAME]", PACKAGE_NAME)
        .replace("[RATE_PER_PAX]", numberWithCommas(RATE_PER_PAX))
        .replace("[TOTAL_PACKAGE]", numberWithCommas(TOTAL_PACKAGE))
        .replace("[INCLUSIONS]", generateInclusionList(updatedRates, quotation))
        .replace("[ITINERARIES]", generateItineraryList(updatedRates));
    } else if (quotation.details.notifications.discountReminder) {
      return `Hi, ${RESERVER_NAME}! Are you still interested to book po? 😊 \n\nIf you are working on a budget or you have any concerns regarding this booking, please let us know and we'll be glad to assist. ❤️ \n\n Thank you and have a nice day!`;
    } else if (quotation.details.notifications.paymentReminder) {
      return `Hi, ${RESERVER_NAME}! Just a quick reminder about your pending payment for your upcoming travel package.\n\nRates are subject to change, so it's a good idea to settle it soon to lock in the current price.\n\n Thank you and have a nice day!`;
    } else if (quotation.details.notifications.paymentSuccess) {
      return `Hi, ${RESERVER_NAME}! \n\nWe're excited to let you know that your payment has been successfully processed, and your booking is now confirmed.\n\nPlease check your email for the full booking details.\n\nIf you have any questions or need further assistance, feel free to reach out to us. We can't wait to help you plan an amazing trip!`;
    }
  };

  const notifStatus = (quotation) => {
    if (quotation.notifications ? quotation.notifications.initQuote : false) {
      return "QUOTATION";
    } else if (
      quotation.notifications ? quotation.notifications.discountReminder : false
    ) {
      return "DISCOUNT";
    } else if (
      quotation.notifications ? quotation.notifications.paymentReminder : false
    ) {
      return "PAYMENT";
    } else if (
      quotation.notifications ? quotation.notifications.paymentSuccess : false
    ) {
      return "CONFIRMED";
    }
  };

  return {
    generateGuest,
    generateItineraryList,
    generateInclusionList,
    getRates,
    // rateList,
    getRemainingPax,
    getTotalRoomPax,
    checkExpiredQuotation,
    generateUpdatedRates,
    generateComputedView,
    notifMessage,
    notifStatus,
  };
}

export default useQuotationApp;
