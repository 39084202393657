import React from "react";
import { Helmet as MyHelmet } from "react-helmet";
import { APP_LINK, APP_NAME } from "../../Hooks/utils/config";

const Helmet = ({ data }) => {
  const { title, description, url, image } = data;
  return (
    <MyHelmet>
      <title>{title || APP_NAME}</title>
      <meta
        name="description"
        content={
          description ||
          "Join us on an adventure of discovery and help put yourfavorite local tourist spot on the map, all while inspiring others to explore the local hidden gems of the Philippines! Let support Local Tourism! Tara, Travel Tayo!"
        }
      />
      <meta property="og:title" content={title || APP_NAME} />
      <meta property="og:url" content={url || APP_LINK} />
      <meta
        property="og:image"
        content={
          image ||
          "https://book.traveltayo.com/wp-content/uploads/2023/03/travel-tayo-deck.png"
        }
      />
    </MyHelmet>
  );
};

export default Helmet;
