import React, { useEffect } from "react";
import Quotations from "../../Components/TourQuotation/Quotations";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { useAppData } from "../../../../../Context/AppContext";

const Index = () => {

  const {user} = useAppData()

  useEffect(() => {
    if(user.length !== 0 && user.POTB === 0){
      window.location.replace("/")
    }
  }, [user])
  return (
    <ApplicationContext>
      <Quotations id={user.POTB} link="potb-quote-maker" />
    </ApplicationContext>
  );
};

export default Index;
