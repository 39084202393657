import React from "react";
import { COLOR, FEATURES } from "../../../../Hooks/utils/config";
import {
  Button,
  Card,
  CardBody,
  ButtonGroup,
  ButtonToolbar,
  FontAwesomeIcon,
} from "../../../../traveltayo-ui-kit";
import { usePlanData } from "../../../../Context/PlanContext";
import { useAppData } from "../../../../Context/AppContext";

const PlanMainBody = () => {
  const { MENU_TABS, currentTab } = usePlanData();
  const { screenWidth } = useAppData();

  console.log(FEATURES.TRAVEL_FILES_UPLOAD );

  return (
    <>
      <Card className="main-card">
        <CardBody
          style={{
            backgroundColor: COLOR.primary,
            borderRadius: "0.625rem 0.625rem 0 0",
            padding: 0,
          }}
        >
          <ButtonToolbar>
            <ButtonGroup size="sm" style={{ width: "100%" }}>
              {MENU_TABS.filter((menu) =>
                screenWidth ? menu : menu.index !== 3
              )
                .filter((menu) =>
                  FEATURES.TRAVEL_FILES_UPLOAD ? menu : menu.index !== 2 
                )
                .filter((menu) =>
                  FEATURES.TRAVEL_BUDGET_MONITORING ? menu : menu.index !== 3 
                )
                .map((menu) => (
                  <Button
                    backgroundColor={
                      currentTab === menu.index
                        ? COLOR.secondary
                        : COLOR.primary
                    }
                    onClick={menu.action}
                    className={`menu-tab ${
                      menu.className && !screenWidth ? menu.className : ""
                    }`}
                  >
                    <FontAwesomeIcon icon={menu.icon} /> {menu.label}
                  </Button>
                ))}
            </ButtonGroup>
          </ButtonToolbar>
        </CardBody>
        <CardBody
          style={{ padding: 2, backgroundColor: COLOR.secondary }}
        ></CardBody>
        <CardBody style={{ padding: 10 }}>
          {MENU_TABS.find((menu) => menu.index === currentTab).component}
        </CardBody>
      </Card>
    </>
  );
};

export default PlanMainBody;
