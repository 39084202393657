import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import {
  ModalForm,
  FormInput,
  FormSelect,
  Alert,
  FormCheckbox,
  FontAwesomeIcon,
  faSave,
  faPlus,
  faTrash,
  faPaperPlane,
  faCalendar,
  faClock,
  faLocationDot,
  faStopwatch,
  faPesoSign,
} from "../../..";
import { usePlanData } from "../../../../Context/PlanContext";
import { useAppData } from "../../../../Context/AppContext";
import { FEATURES } from "../../../../Hooks/utils/config";

const ItineraryForm = () => {
  const { setIsDeleting } = useAppData();
  const {
    plan,
    totalExpense,
    isAddingNewItineraries,
    setIsAddingNewItineraries,
    isEditingItineraries,
    setIsEditingItineraries,
    itinerariesNewForm,
    setItinerariesNewForm,
    resetItinerariesForm,
    insertNewItinerary,
    removeItineraryItem,
    updateItineraryValues,
  } = usePlanData();

  const [loading, setLoading] = useState(false);

  const { numberWithCommas } = useCustomFunctions();
  const [budgetLeft, setBudgetLeft] = useState(0);

  useEffect(() => {
    setBudgetLeft(plan.budget - totalExpense);
  }, [plan, totalExpense]);

  return (
    <ModalForm
      open={isAddingNewItineraries || isEditingItineraries}
      toggle={() => {}}
      bodyPadding="10px 20px"
      loading={loading}
      approvedBTNProps={{
        // block: "block",
        display:
          plan.access === "edit" &&
          itinerariesNewForm.title !== "" &&
          itinerariesNewForm.day !== "" &&
          itinerariesNewForm.start !== "" &&
          itinerariesNewForm.end !== "",
        size: "sm",
        iconPosition: "left",
        icon: isEditingItineraries ? faSave : faPlus,
        label: isEditingItineraries ? "SAVE" : "ADD",
        onClick: () =>
          setLoading(true) || isEditingItineraries
            ? updateItineraryValues(
                itinerariesNewForm,
                plan.itinerary,
                () =>
                  setIsEditingItineraries(false) ||
                  setLoading(false) ||
                  resetItinerariesForm()
              )
            : insertNewItinerary(
                itinerariesNewForm,
                plan.itinerary,
                () =>
                  setIsAddingNewItineraries(false) ||
                  setLoading(false) ||
                  resetItinerariesForm()
              ),
      }}
      cancelBTNProps={{
        // block: "block",
        display: true,
        size: "sm",
        onClick: () =>
          isEditingItineraries
            ? setIsEditingItineraries() || resetItinerariesForm()
            : setIsAddingNewItineraries(false) || resetItinerariesForm(),
      }}
      deleteBTNProps={{
        display: plan.access === "edit" && isEditingItineraries,
        icon: faTrash,
        theme: "danger",
        onClick: () =>
          setLoading(true) ||
          removeItineraryItem(
            itinerariesNewForm.id,
            plan.itinerary,
            () =>
              setIsEditingItineraries(false) ||
              setIsDeleting(false) ||
              setLoading(false) ||
              resetItinerariesForm()
          ),
      }}
    >
      <FormSelect
        group={true}
        icon={faCalendar}
        label="DAY ON YOUR ITINERARY"
        placeholder="enter your itinerary title"
        value={itinerariesNewForm.day}
        onChange={(e) =>
          setItinerariesNewForm({
            ...itinerariesNewForm,
            day: e.target.value,
          })
        }
        // valid
      >
        <option value={0}>Day 0 (Day Before the event)</option>

        {plan.dates.map((date, index) => (
          <option value={index + 1}>
            Day {index + 1} ({dateFormat(date, "mediumDate")})
          </option>
        ))}
      </FormSelect>

      <FormInput
        group={true}
        icon={faPaperPlane}
        label="SHORT TITLE FOR THIS ITINERARY"
        placeholder="enter your itinerary title"
        value={itinerariesNewForm.title}
        onChange={(e) =>
          setItinerariesNewForm({
            ...itinerariesNewForm,
            title: e.target.value,
          })
        }
        // valid
      />

      <FormSelect
        group={true}
        icon={faStopwatch}
        label="TIME INTERVAL (OPTIONAL)"
        placeholder="enter your itinerary title"
        value={itinerariesNewForm.interval}
        onChange={(e) =>
          setItinerariesNewForm({
            ...itinerariesNewForm,
            interval: e.target.value,
            end: itinerariesNewForm.start,
          })
        }
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
        <option value={25}>25</option>
        <option value={30}>30</option>
      </FormSelect>

      <FormInput
        group={true}
        icon={faClock}
        label="TRAVEL TIME"
        inputs={[
          {
            placeholderText: "departure date",
            className: "formgroup-left-input form-control",
            type: "date",
            selected: itinerariesNewForm.start,
            onChange: (e) =>
              setItinerariesNewForm({
                ...itinerariesNewForm,
                start: e,
                end: itinerariesNewForm.end < e ? e : itinerariesNewForm.end,
              }),
            minDate: new Date(),
            showTimeSelect: "showTimeSelect",
            showTimeSelectOnly: "showTimeSelectOnly",
            timeIntervals: itinerariesNewForm.interval,
            timeCaption: "Travel Start Time",
            dateFormat: "h:mm aa",
          },
          {
            placeholderText: "return date",
            className: "formgroup-right-input  form-control",
            type: "date",
            selected: itinerariesNewForm.end,
            onChange: (e) =>
              setItinerariesNewForm({ ...itinerariesNewForm, end: e }),
            minDate: new Date(itinerariesNewForm.start) || new Date(),
            showTimeSelect: "showTimeSelect",
            showTimeSelectOnly: "showTimeSelectOnly",
            timeIntervals: itinerariesNewForm.interval,
            timeCaption: "Travel End Time",
            dateFormat: "h:mm aa",
            filterTime: (time) => {
              const currentDate = new Date(itinerariesNewForm.start);
              const selectedDate = new Date(time);

              return currentDate.getTime() < selectedDate.getTime();
            },
          },
        ]}
      />

      <FormInput
        group={true}
        icon={faLocationDot}
        label="ADD YOUR LOCATION DESTINATION (OPTIONAL)"
        placeholder="enter your location destination "
        value={itinerariesNewForm.location}
        onChange={(e) =>
          setItinerariesNewForm({
            ...itinerariesNewForm,
            location: e.target.value,
          })
        }
      />

      <FormInput
        inputType="textarea"
        label="SHORT NOTES  (OPTIONAL)"
        placeholder="enter your short notes"
        value={itinerariesNewForm.description}
        onChange={(e) =>
          setItinerariesNewForm({
            ...itinerariesNewForm,
            description: e.target.value,
          })
        }
      />

      {FEATURES.TRAVEL_BUDGET_MONITORING && (
        <>
          <FormCheckbox
            toggle
            small
            checked={itinerariesNewForm.isIncBudget}
            onChange={(e) =>
              setItinerariesNewForm({
                ...itinerariesNewForm,
                isIncBudget: !itinerariesNewForm.isIncBudget,
              })
            }
          >
            is this included in the budget?
          </FormCheckbox>
          <FormCheckbox
            toggle
            small
            checked={itinerariesNewForm.isPaid}
            onChange={(e) =>
              setItinerariesNewForm({
                ...itinerariesNewForm,
                isPaid: !itinerariesNewForm.isPaid,
              })
            }
          >
            is this Paid already?
          </FormCheckbox>
        </>
      )}

      <FormInput
        type="number"
        label="AMOUNT PAID"
        placeholder="enter the amount paid"
        hintText={
          itinerariesNewForm.amountPaid > budgetLeft
            ? "The amount you have input is higher than the budget left."
            : ""
        }
        hintColor="red"
        value={itinerariesNewForm.amountPaid}
        onChange={(e) =>
          setItinerariesNewForm({
            ...itinerariesNewForm,
            amountPaid: e.target.value === "" ? 0 : e.target.value,
          })
        }
      />

      {itinerariesNewForm.isPaid && (
        <Alert theme="info">
          Budget Left: <FontAwesomeIcon icon={faPesoSign} />{" "}
          {numberWithCommas(budgetLeft.toFixed(2))}
        </Alert>
      )}
    </ModalForm>
  );
};

export default ItineraryForm;
