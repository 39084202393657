import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import {
  ModalForm,
  FormInput,
  FormSelect,
  Alert,
  FormCheckbox,
  faSave,
  faPlus,
  faList,
  faListCheck,
  faTrash,
  faPesoSign,
  FontAwesomeIcon,
} from "../../..";
import { usePlanData } from "../../../../Context/PlanContext";
import { DEFAULT_VALUES, FEATURES } from "../../../../Hooks/utils/config";
import { useAppData } from "../../../../Context/AppContext";

const CheckListForm = () => {
  const { setIsDeleting, user } = useAppData();
  const {
    plan,
    totalExpense,
    insertNewChecklist,
    updateChecklistValues,
    isAddingNewChecklist,
    setIsAddingNewChecklist,
    isEditingChecklist,
    setIsEditingChecklist,
    checkListNewForm,
    setCheckListNewForm,
    resetChecklistForm,
    removeChecklistItem,
  } = usePlanData();
  const { numberWithCommas } = useCustomFunctions();
  const [loading, setLoading] = useState(false);
  const [budgetLeft, setBudgetLeft] = useState(0);

  useEffect(() => {
    setBudgetLeft(plan.budget - totalExpense);
  }, [plan, totalExpense]);

  const [insertMultipleEntry, setInsertMultipleEntry] = useState(false);
  const [multipleEntry, setMultipleEntry] = useState("");

  return (
    <ModalForm
      open={isAddingNewChecklist || isEditingChecklist}
      toggle={() => {}}
      bodyPadding="10px 20px"
      loading={loading}
      approvedBTNProps={{
        // block: "block",
        display:
          (plan.access === "edit" && checkListNewForm.name !== "") ||
          (insertMultipleEntry && multipleEntry !== "" && plan.length !== 0),
        // &&checkListNewForm.amountPaid <= budgetLeft,
        size: "sm",
        iconPosition: "left",
        icon: isEditingChecklist ? faSave : faPlus,
        label: isEditingChecklist ? "SAVE" : "ADD",
        onClick: () =>
          setLoading(true) || isEditingChecklist
            ? updateChecklistValues(
                checkListNewForm,
                plan.checklists,
                () =>
                  setIsEditingChecklist(false) ||
                  setLoading(false) ||
                  resetChecklistForm()
              )
            : insertNewChecklist(
                insertMultipleEntry
                  ? multipleEntry.split("\n").map((list) => ({
                      id: (Math.random() + 1).toString(36).substring(7),
                      name: list,
                      category: "",
                      owner: "All",
                      status: "Not Done",
                      username: user.username,
                      description: "",
                      last_edit: user.username,
                      last_change: new Date(),
                      isPaid: false,
                      amountPaid: 0,
                      paidBy: "",
                    }))
                  : checkListNewForm,
                plan.checklists,
                () =>
                  setIsAddingNewChecklist(false) ||
                  setLoading(false) ||
                  resetChecklistForm() ||
                  setInsertMultipleEntry(false) ||
                  setMultipleEntry("")
              ),
      }}
      cancelBTNProps={{
        // block: "block",
        display: true,
        size: "sm",
        onClick: () =>
          isEditingChecklist
            ? setIsEditingChecklist(false) || resetChecklistForm()
            : setIsAddingNewChecklist(false) || resetChecklistForm(),
      }}
      deleteBTNProps={{
        display: plan.access === "edit" && isEditingChecklist,
        icon: faTrash,
        theme: "danger",
        onClick: () =>
          setLoading(true) ||
          removeChecklistItem(
            checkListNewForm.id,
            plan.checklists,
            () =>
              setIsEditingChecklist(false) ||
              setIsDeleting(false) ||
              setLoading(false) ||
              resetChecklistForm()
          ),
      }}
    >
      {!isEditingChecklist && (
        <FormCheckbox
          toggle
          small
          checked={insertMultipleEntry}
          onChange={(e) => setInsertMultipleEntry(!insertMultipleEntry)}
        >
          Insert Multiple Checklist
        </FormCheckbox>
      )}
      {insertMultipleEntry ? (
        <>
          <FormInput
            inputType="textarea"
            label="MULTIPLE CHECKLIST SEPARATED BY LINE"
            placeholder="enter your checklists per line"
            value={multipleEntry}
            onChange={(e) => setMultipleEntry(e.target.value)}
            rows={6}
          />
        </>
      ) : (
        <>
          {" "}
          <FormSelect
            group={true}
            icon={
              DEFAULT_VALUES.CHECKLIST_CATEGORIES.find(
                (category) => category.value === checkListNewForm.category
              )
                ? DEFAULT_VALUES.CHECKLIST_CATEGORIES.find(
                    (category) => category.value === checkListNewForm.category
                  ).icon
                : faList
            }
            label="CATEGORY"
            placeholder="choos"
            value={checkListNewForm.category}
            onChange={(e) =>
              setCheckListNewForm({
                ...checkListNewForm,
                category: e.target.value,
              })
            }
          >
            <option value="">SELECT ONE</option>
            {DEFAULT_VALUES.CHECKLIST_CATEGORIES.map((category, index) => (
              <option value={category.value} key={index}>
                {category.label}
              </option>
            ))}
          </FormSelect>
          <FormInput
            group={true}
            icon={faListCheck}
            label="SHORT NAME FOR A CHECKLIST"
            placeholder="enter your checklist name"
            value={checkListNewForm.name}
            onChange={(e) =>
              setCheckListNewForm({ ...checkListNewForm, name: e.target.value })
            }
          />
          <FormInput
            inputType="textarea"
            label="SHORT DESCRIPTION (OPTIONAL)"
            placeholder="enter your checklist name"
            value={checkListNewForm.description}
            onChange={(e) =>
              setCheckListNewForm({
                ...checkListNewForm,
                description: e.target.value,
              })
            }
          />
          {FEATURES.TRAVEL_BUDGET_MONITORING && (
            <FormCheckbox
              toggle
              small
              checked={checkListNewForm.isPaid}
              onChange={(e) =>
                setCheckListNewForm({
                  ...checkListNewForm,
                  isPaid: !checkListNewForm.isPaid,
                })
              }
            >
              is this included in the budget?
            </FormCheckbox>
          )}
          <FormInput
            type="number"
            label="AMOUNT PAID"
            placeholder="enter the amount paid"
            hintText={
              checkListNewForm.amountPaid > budgetLeft
                ? "The amount you have input is higher than the budget left."
                : ""
            }
            hintColor="red"
            value={checkListNewForm.amountPaid}
            min="0"
            onChange={(e) =>
              setCheckListNewForm({
                ...checkListNewForm,
                amountPaid: e.target.value === "" ? 0 : e.target.value,
              })
            }
          />
          {checkListNewForm.isPaid && (
            <Alert theme="info">
              Budget Left: <FontAwesomeIcon icon={faPesoSign} />{" "}
              {numberWithCommas(budgetLeft.toFixed(2))}
            </Alert>
          )}
          {checkListNewForm.last_change && (
            <p style={{ margin: 0, fontSize: 10 }}>
              Last Change:{" "}
              {dateFormat(checkListNewForm.last_change, "fullDate")}
            </p>
          )}
        </>
      )}
    </ModalForm>
  );
};

export default CheckListForm;
