import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect as Select,
  FormGroup,
} from "shards-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormSelect = ({ children, ...props }) => {
  return (
    <>
      {props.group ? (
        <FormGroup>
          {props.label && (
            <label
              htmlFor={
                props.label
                  ? `#${props.label.toLowerCase().replace(/[^A-Z0-9]/gi, "")}`
                  : ""
              }
              style={{ marginBottom: "0.1rem", fontSize: "11px" }}
            >
              {props.label}
            </label>
          )}
          <InputGroup className="mb-2" seamless>
            {props.icon ? (
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon={props.icon} />
                </InputGroupText>
              </InputGroupAddon>
            ) : (
              ""
            )}

            <Select {...props}>{children}</Select>
          </InputGroup>
        </FormGroup>
      ) : (
        <>
          {props.inline ? (
            <>
              <FormGroup style={{ display: "inline-block" }}>
                <label
                  htmlFor={
                    props.label
                      ? `#${props.label
                          .toLowerCase()
                          .replace(/[^A-Z0-9]/gi, "")}`
                      : ""
                  }
                  style={{ marginBottom: "0.1rem", fontSize: "11px" }}
                >
                  {props.label}
                </label>
                <Select {...props}>{children}</Select>
              </FormGroup>
            </>
          ) : (
            <FormGroup>
              <label
                htmlFor={
                  props.label
                    ? `#${props.label.toLowerCase().replace(/[^A-Z0-9]/gi, "")}`
                    : ""
                }
                style={{ marginBottom: "0.1rem", fontSize: "11px" }}
              >
                {props.label}
              </label>
              <Select {...props}>{children}</Select>
            </FormGroup>
          )}
        </>
      )}
    </>
  );
};

export default FormSelect;
