import React from "react";
import { FormCheckbox, FormInput } from "../../../../../../traveltayo-ui-kit";
import { faHashtag, faMoneyBill } from "@fortawesome/free-solid-svg-icons";

const AdminForm = ({ data }) => {
  const { quotation, editQuotation, setQuotation, user, application } = data;
  return (
    <>
      {editQuotation.approval_request === 1 && (
        <>
          <h6>FOR ADMIN USE ONLY</h6>
          <FormCheckbox
            checked={quotation.details.approvalDetails.isApproved}
            onChange={() =>
              application.owner !== user.username
                ? ""
                : setQuotation((prev) => ({
                    ...prev,
                    details: {
                      ...prev.details,
                      approvalDetails: {
                        ...prev.details.approvalDetails,
                        isApproved: !prev.details.approvalDetails.isApproved,
                      },
                    },
                  }))
            }
            disabled={application.owner !== user.username}
          >
            Is this booking approved?
          </FormCheckbox>
          <FormInput
            group={true}
            icon={faHashtag}
            label="SERVICE VOUCHER CODE"
            value={quotation.details.approvalDetails.serviceVoucherCode}
            placeholder="service voucher code"
            disabled={application.owner !== user.username}
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                details: {
                  ...prev.details,
                  approvalDetails: {
                    ...prev.details.approvalDetails,
                    serviceVoucherCode: e.target.value,
                  },
                },
              }))
            }
          />
          <FormInput
            group={true}
            icon={faMoneyBill}
            label="AMOUNT PAID"
            value={quotation.details.approvalDetails.amountPaid}
            placeholder="amount paid"
            type="number"
            disabled={application.owner !== user.username}
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                details: {
                  ...prev.details,
                  approvalDetails: {
                    ...prev.details.approvalDetails,
                    amountPaid: e.target.value,
                  },
                },
              }))
            }
          />
          <FormInput
            group={false}
            icon={faHashtag}
            label="TICKET DETAILS"
            value={quotation.details.approvalDetails.ticketDetails}
            placeholder="ticket details"
            disabled={application.owner !== user.username}
            inputType="textarea"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                details: {
                  ...prev.details,
                  approvalDetails: {
                    ...prev.details.approvalDetails,
                    ticketDetails: e.target.value,
                  },
                },
              }))
            }
          />

          <FormInput
            group={false}
            icon={faHashtag}
            label="BOOKING REMARKS"
            value={quotation.details.approvalDetails.bookingRemarks}
            disabled={application.owner !== user.username}
            placeholder="booking remarks"
            inputType="textarea"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                details: {
                  ...prev.details,
                  approvalDetails: {
                    ...prev.details.approvalDetails,
                    bookingRemarks: e.target.value,
                  },
                },
              }))
            }
          />
        </>
      )}
    </>
  );
};

export default AdminForm;
