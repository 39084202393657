import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
} from "shards-react";
import { COLOR } from "../../../../../Hooks/utils/config";
import { Button } from "../../../../../traveltayo-ui-kit";
import useCustomFunctions from "../../../../../Hooks/api/useCustomFunctions";
import { useAppData } from "../../../../../Context/AppContext";

const ScheduleList = ({data}) => {
  const { screenWidth } = useAppData();
  const { departureResults, returnResults, form, setForm, setStep } = data;

  const { numberWithCommas } = useCustomFunctions();
  return (
    <>
      <Card className="main-card" style={{ marginTop: 20 }}>
        <CardBody style={{ padding: 0 }}>
          <Row>
            <Col md={12} style={{ padding: 0 }}>
              <ListGroup>
                <ListGroupItem>
                  <ListGroupItemHeading>
                    <h5>DEPARTURE SCHEDULES</h5>
                  </ListGroupItemHeading>
                </ListGroupItem>

                {departureResults
                  .filter((departure) =>
                    form.schedules.departure.length !== 0
                      ? form.schedules.departure.id === departure.id
                      : departure
                  )
                  .map((departure) => (
                    <ListGroupItem
                      style={{
                        backgroundColor:
                          form.schedules.departure.id === departure.id
                            ? "#ededed"
                            : "#fff",
                      }}
                    >
                      <Row>
                        <Col
                          md={5}
                          style={{ marginBottom: screenWidth ? 10 : 0 }}
                        >
                          <p
                            style={{
                              margin: 0,
                              marginBottom: 2,
                              textTransform: "uppercase",
                              fontWeight: 500,
                              fontSize: "0.8rem",
                            }}
                          >
                            Bus Details
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontSize: "1.0rem",
                              fontWeight: 500,
                            }}
                          >
                            {departure.busName}
                          </p>
                          <span
                            style={{
                              margin: 0,
                              fontSize: ".80rem",
                            }}
                          >
                            {departure.busType}{" "}
                            {departure.tripCode
                              ? `( ${departure.tripCode} )`
                              : ""}
                          </span>
                        </Col>
                        <Col
                          md={3}
                          xs={6}
                          sm={6}
                          style={{ marginBottom: screenWidth ? 10 : 0 }}
                        >
                          <p
                            style={{
                              margin: 0,
                              marginBottom: 2,
                              textTransform: "uppercase",
                              fontWeight: 500,
                              fontSize: "0.8rem",
                            }}
                          >
                            Departure Time
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontSize: "1.0rem",
                            }}
                          >
                            {departure.departureTime}
                          </p>
                        </Col>
                        <Col md={2} xs={6} sm={6}>
                          <p
                            style={{
                              margin: 0,
                              marginBottom: 2,
                              textTransform: "uppercase",
                              fontWeight: 500,
                              fontSize: "0.8rem",
                            }}
                          >
                            Fare
                          </p>
                          &#8369; {numberWithCommas(departure.adultFare)}
                        </Col>
                        <Col md={2}>
                          <Button
                            backgroundColor={
                              form.schedules.departure.id === departure.id
                                ? COLOR.secondary
                                : COLOR.primary
                            }
                            block
                            onClick={() =>
                              setForm({
                                ...form,
                                schedules: {
                                  ...form.schedules,
                                  departure:
                                    form.schedules.departure.id === departure.id
                                      ? []
                                      : departure,
                                },
                              })
                            }
                          >
                            {form.schedules.departure.id === departure.id
                              ? "SELECTED"
                              : "SELECT"}
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {returnResults.length !== 0 && form.schedules.departure.length !== 0 && (
        <Card className="main-card" style={{ marginTop: 20 }}>
          <CardBody style={{ padding: 0 }}>
            <Row>
              <Col md={12} style={{ padding: 0 }}>
                <ListGroup>
                  <ListGroupItem>
                    <ListGroupItemHeading>
                      <h5>RETURN SCHEDULES</h5>
                    </ListGroupItemHeading>
                  </ListGroupItem>
                  {returnResults
                    .filter((return_sched) =>
                      form.schedules.return.length !== 0
                        ? form.schedules.return.id === return_sched.id
                        : return_sched
                    )
                    .map((return_sched) => (
                      <ListGroupItem
                        style={{
                          backgroundColor:
                            form.schedules.return.id === return_sched.id
                              ? "#ededed"
                              : "#fff",
                        }}
                      >
                        <Row>
                          <Col
                            md={5}
                            style={{ marginBottom: screenWidth ? 10 : 0 }}
                          >
                            <p
                              style={{
                                margin: 0,
                                marginBottom: 2,
                                textTransform: "uppercase",
                                fontWeight: 500,
                                fontSize: "0.8rem",
                              }}
                            >
                              Bus Details
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontSize: "1.0rem",
                                fontWeight: 500,
                              }}
                            >
                              {return_sched.busName}
                            </p>
                            <span
                              style={{
                                margin: 0,
                                fontSize: ".80rem",
                              }}
                            >
                              {return_sched.busType}{" "}
                              {return_sched.tripCode
                                ? `( ${return_sched.tripCode} )`
                                : ""}
                            </span>
                          </Col>
                          <Col
                            md={3}
                            xs={6}
                            sm={6}
                            style={{ marginBottom: screenWidth ? 10 : 0 }}
                          >
                            <p
                              style={{
                                margin: 0,
                                marginBottom: 2,
                                textTransform: "uppercase",
                                fontWeight: 500,
                                fontSize: "0.8rem",
                              }}
                            >
                              Departure time
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontSize: "1.0rem",
                              }}
                            >
                              {return_sched.departureTime}
                            </p>
                          </Col>
                          <Col md={2} xs={6} sm={6}>
                            <p
                              style={{
                                margin: 0,
                                marginBottom: 2,
                                textTransform: "uppercase",
                                fontWeight: 500,
                                fontSize: "0.8rem",
                              }}
                            >
                              Fare
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontSize: "1.0rem",
                              }}
                            >
                              &#8369; {numberWithCommas(return_sched.adultFare)}
                            </p>
                          </Col>
                          <Col md={2}>
                            <Button
                              backgroundColor={
                                form.schedules.return.id === return_sched.id
                                  ? COLOR.secondary
                                  : COLOR.primary
                              }
                              block
                              onClick={() =>
                                setForm({
                                  ...form,
                                  schedules: {
                                    ...form.schedules,
                                    return:
                                      form.schedules.return.id ===
                                      return_sched.id
                                        ? []
                                        : return_sched,
                                  },
                                })
                              }
                            >
                              {form.schedules.return.id === return_sched.id
                                ? "SELECTED"
                                : "SELECT"}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}

      {(
        form.roundTrip
          ? form.schedules.departure.length !== 0 &&
            form.schedules.return.length !== 0
          : form.schedules.departure.length !== 0
      ) ? (
        <Button
          backgroundColor={COLOR.primary}
          style={{ marginTop: 20 }}
          size="md"
          onClick={() => setStep("passenger-details")}
        >
          PROCEED <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      ) : (
        ""
      )}
    </>
  );
};

export default ScheduleList;
