import React from "react";
import {
  FormCheckbox,
  FormTextarea,
  FormGroup,
} from "../../../../../../traveltayo-ui-kit";

const CustomDetailsForm = ({data}) => {
    const {quotation, setQuotation, quoteLocked} = data
  return (
    <>
    <h6>CUSTOM DETAILS</h6>
      <FormGroup>
        <FormCheckbox
          small
          toggle
          checked={quotation.bookingDetails.replaceItinerary}
          onChange={() =>
            // quoteLocked
            //   ? ""
            //   : 
              setQuotation((prev) => ({
                  ...prev,
                  bookingDetails: {
                    ...prev.bookingDetails,
                    replaceItinerary: !prev.bookingDetails.replaceItinerary,
                  },
                }))
          }
          // disabled={quoteLocked}
        >
          Replace Current Itinerary
        </FormCheckbox>
        <FormTextarea
          value={quotation.bookingDetails.customItinerary}
          onChange={(e) =>
            setQuotation((prev) => ({
              ...prev,
              bookingDetails: {
                ...prev.bookingDetails,
                customItinerary: e.target.value,
              },
            }))
          }
          placeholder="insert custom itinerary separated per lines"
          // disabled={quoteLocked}
        />
      </FormGroup>
      <FormGroup>
        <FormCheckbox
          small
          toggle
          checked={quotation.bookingDetails.replaceInclusions}
          onChange={() =>
            // quoteLocked
            //   ? ""
            //   : 
              setQuotation((prev) => ({
                  ...prev,
                  bookingDetails: {
                    ...prev.bookingDetails,
                    replaceInclusions: !prev.bookingDetails.replaceInclusions,
                  },
                }))
          }
          // disabled={quoteLocked}
        >
          Replace Current Inclusions
        </FormCheckbox>
        <FormTextarea
          value={quotation.bookingDetails.customInclusions}
          onChange={(e) =>
            setQuotation((prev) => ({
              ...prev,
              bookingDetails: {
                ...prev.bookingDetails,
                customInclusions: e.target.value,
              },
            }))
          }
          placeholder="insert custom inclusions separated per lines"
          // disabled={quoteLocked}
        />
      </FormGroup>
    </>
  );
};

export default CustomDetailsForm;
