import React from "react";
import Plan from "./Plan";
import { PlanForm } from "../../../../traveltayo-ui-kit";
import { BookingsContext } from "../../../../Context/BookingsContext";
import { PlanContext } from "../../../../Context/PlanContext";
import "react-chat-elements/dist/main.css"

const Index = ({ id }) => {
  return (
    <BookingsContext>
      <PlanContext>
        {/* <PlanForm /> */}
        <Plan id={id} />
      </PlanContext>
    </BookingsContext>
  );
};

export default Index;
