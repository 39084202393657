import React, { createContext, useContext, useEffect, useState } from "react";
import useMediaQuery from "use-mediaquery";
import useUsersAPI from "../Hooks/api/useUsersAPI";
import useHolidays from "../Hooks/api/useHolidays";
import useCustomFunctions from "../Hooks/api/useCustomFunctions";
import useNotifications from "../Hooks/api/useNotifications";
// import socketIO from "socket.io-client"

// const socket = socketIO.connect(API.CHAT_SERVER)

const Context = createContext();

export function useAppData() {
  return useContext(Context);
}

export const AppContext = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const {
    encryptMessage,
    decryptMessage,
    cookies,
    createCookies,
    removeCookie,
  } = useCustomFunctions();

  //width
  const screenWidth = useMediaQuery("(max-width: 991px)");
  const xSmall = useMediaQuery("(max-width: 575px)");
  const small = useMediaQuery("(max-width: 767px)");
  const medium = useMediaQuery("(max-width: 991px)");
  const large = useMediaQuery("(max-width: 1199px)");

  const screenSizes = {
    xSmall,
    small,
    medium,
    large,
  };

  const [alertStatus, setAlertStatus] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  //Users
  const {
    getLoginSession,
    user,
    resetPassword,
    changePassword,
    login,
    loginByAccessKey,
    logout,
    signup,
    checkAvailability,
    checks,
    auth_login,
    valitateToken,
    formatUsername,
    formatNames,
    validateEmail,
    validateMobileNumber,
    walletTransactions,
    getUser,
    announcements,
    getAnnouncements,
  } = useUsersAPI(setAlertStatus, isOnline);

  const { notifications, getNotifications, markAsReadNotif } = useNotifications(
    {
      getLoginSession,
      user,
      auth_login,
      isOnline,
    }
  );

  const { holidays, upcommingHolidays, getHolidays } = useHolidays({
    getLoginSession,
    user,
    auth_login,
    isOnline,
  });

  const [isAddingNewPlan, setIsAddingNewPlan] = useState(false);
  let newPlanForm = {
    start: new Date(),
    end: new Date(),
    name: "",
    destination: "",
    description: "",
    type: "plan",
    geoData: [],
    budget: 0,
    generateAI: false,
    preferences: [],
  };
  const [newPlan, setNewPlan] = useState(newPlanForm);
  const [isEditingPlan, setIsEditingPlan] = useState(false);

  useEffect(() => {
    if (user.length === 0) {
      getUser(() => "");
    }
  }, [getLoginSession]);

  useEffect(() => {
    if (user.length !== 0) {
      getNotifications(() => "");
    }
  }, [user]);

  return (
    <Context.Provider
      value={{
        // socket,
        newPlanForm,
        newPlan,
        setNewPlan,
        isEditingPlan, setIsEditingPlan,
        isAddingNewPlan,
        setIsAddingNewPlan,
        screenSizes,
        screenWidth,
        announcements,
        getAnnouncements,
        isOnline,
        notifications,
        markAsReadNotif,
        getNotifications,
        //users API
        getLoginSession,
        user,
        resetPassword,
        changePassword,
        login,
        loginByAccessKey,
        logout,
        signup,
        checkAvailability,
        checks,
        auth_login,
        valitateToken,
        formatUsername,
        formatNames,
        validateEmail,
        validateMobileNumber,
        walletTransactions,
        //Holidays
        holidays,
        upcommingHolidays,
        getHolidays,
        alertStatus,
        setAlertStatus,
        //Encryption
        encryptMessage,
        decryptMessage,
        cookies,
        createCookies,
        removeCookie,
        //Modal Form
        isDeleting,
        setIsDeleting,
      }}
    >
      {children}
    </Context.Provider>
  );
};
