import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Button,
} from "shards-react";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PasswordInput = ({
  icon,
  value,
  onChange,
  onKeyPress,
  viewPass,
  setViewPass,
}) => {
  return (
    <InputGroup className="mb-2" seamless>
      {icon && (
        <InputGroupAddon type="prepend">
          <InputGroupText>
            <FontAwesomeIcon icon={icon} />
          </InputGroupText>
        </InputGroupAddon>
      )}

      <FormInput
        type={viewPass ? "text" : "password"}
        placeholder="password"
        value={value}
        onChange={(e) => onChange(e)}
        onKeyPress={(e) => onKeyPress(e)}
        style={{ borderRadius: "0.35rem" }}
      />
      <InputGroupAddon
        type="append"
        style={{ border: "1px solid rgba(0, 0, 0, 0)" }}
      >
        <Button
          theme="light"
          outline
          style={{ backgroundColor: "#fff" }}
          onClick={() => setViewPass(!viewPass)}
        >
          <FontAwesomeIcon icon={viewPass ? faEyeSlash : faEye} />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default PasswordInput;
