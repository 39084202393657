import React from "react";
import { Button as MyButton } from "shards-react";

const Button = ({ children, ...props }) => {
  return (
    <MyButton
      {...props}
      style={{
        ...props.style,
        backgroundColor: props.backgroundColor,
        borderColor: props.backgroundColor,
      }}
      size={props.size || "sm"}
    >
      {children}
    </MyButton>
  );
};

export default Button;
