import React, { useState } from "react";
import {
  Button,
  Alert,
  FormInput,
  FormGroup,
  faAt,
  PageContainer,
  CardBody,
} from "../../../traveltayo-ui-kit";
import { useAppData } from "../../../Context/AppContext";
import { APP_NAME, COLOR, FEATURES } from "../../../Hooks/utils/config";
import { useNavigate, Link } from "@reach/router";
import Container from "./Container";
import HCaptcha from "./HCaptcha";

const ForgotPassword = () => {
  const [form, setForm] = useState({
    username: "",
  });

  const navigate = useNavigate();

  const { formatUsername, resetPassword } = useAppData();

  const [token, setToken] = useState(null);
  return (
    <PageContainer
      helmet={{ title: `${APP_NAME} | Forgot Password` }}
      noAds={true}
    >
      <Container>
        <CardBody>
          <h3>Reset Password</h3>
          <p style={{ color: "#7D7D7D", fontSize: "12px" }}>
            It's normal to forgot something. We're here to help you reset
            everything.
          </p>

          <Alert />

          <FormInput
            group={true}
            icon={faAt}
            label={""}
            placeholder="enter your username"
            value={form.username}
            onChange={(e) =>
              setForm({ ...form, username: formatUsername(e.target.value) })
            }
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                resetPassword(form);
              }
            }}
          />

          {form.username !== "" && (
            <FormGroup>
              {token ? (
                <Button
                  backgroundColor={COLOR.primary}
                  style={{ width: "100%", fontSize: "15px" }}
                  onClick={() => resetPassword(form, navigate)}
                  disabled={form.username === ""}
                >
                  Send Reset Password
                </Button>
              ) : (
                <HCaptcha setToken={setToken} />
              )}
            </FormGroup>
          )}

          <br />
          {FEATURES.SIGN_UP_PAGE && (
            <>
              <FormGroup>
                <p style={{ color: "#7D7D7D", display: "inline" }}>
                  Don’t have an account yet?{" "}
                </p>
                <Link
                  to="/sign-up"
                  style={{
                    textDecoration: "none",
                    color: COLOR.primary,
                    fontWeight: "400",
                  }}
                >
                  Sign Up
                </Link>
              </FormGroup>
              <br />
            </>
          )}

          <FormGroup>
            <p style={{ color: "#7D7D7D", display: "inline" }}>
              Already have an account?{" "}
            </p>
            <Link
              to="/login"
              style={{
                textDecoration: "none",
                color: COLOR.primary,
                fontWeight: "400",
              }}
            >
              Login
            </Link>
          </FormGroup>
        </CardBody>
      </Container>
    </PageContainer>
  );
};

export default ForgotPassword;
