import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Loading,
  Card,
  CardBody,
  PageContainer,
} from "../../../../traveltayo-ui-kit";
import ShareButtons from "../../../Basic/ShareButtons";
import dateFormat from "dateformat";
import "../Styles/blog.css";
import { APP_NAME, FEATURES } from "../../../../Hooks/utils/config";

const Blog = ({ slug }) => {
  const [data, setData] = useState([]);
  const [featuredmedia, setFeatureMedia] = useState("");

  useEffect(() => {
    axios
      .get(`https://book.traveltayo.com/wp-json/wp/v2/posts?slug=${slug}`)
      .then(function (response) {
        // handle success
        console.log(response);
        setData(response.data[0]);

        axios
          .get(
            `https://book.traveltayo.com/wp-json/wp/v2/media/${response.data[0].featured_media}`
          )
          .then(function (response) {
            // handle success
            // console.log(response);
            setFeatureMedia(response.data.source_url);
            // setData(response.data[0]);
          })
          .catch(function (error) {
            // handle error
            console.log("error");
          })
          .then(function () {
            // always executed
          });
      })
      .catch(function (error) {
        // handle error
        console.log("error");
      })
      .then(function () {
        // always executed
      });
  }, []);

  // console.log(data);

  useEffect(() => {
    if (!FEATURES.ABOUT_US_PAGE) {
      window.location.replace("/");
    }
  }, []);

  return (
    <PageContainer
      helmel={{
        title: data.length !== 0 ? data.title.rendered : APP_NAME + " | Blog",
      }}
      noAds={FEATURES.NO_ADS}
    >
      {data.length !== 0 ? (
        <div>
          <Card className="main-card">
            {featuredmedia && (
              <CardBody style={{ padding: 0 }}>
                <div
                  style={{
                    width: "100%",
                    height: "350px",
                    backgroundImage: `url(${featuredmedia})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    borderRadius: "0.625rem 0.625rem 0 0",
                  }}
                />
              </CardBody>
            )}

            <CardBody style={{ paddingBottom: 0 }}>
              <ShareButtons
                link={`https://traveltayo.com/blog/${data.slug}.html`}
              />
              <h5>{data.title.rendered}</h5>
              <p style={{ marginBottom: 0 }}>
                {dateFormat(data.date, "fullDate")}
              </p>
            </CardBody>
            <CardBody style={{ paddingTop: 0 }}>
              <div
                dangerouslySetInnerHTML={{ __html: data.content.rendered }}
              />
            </CardBody>
          </Card>
        </div>
      ) : (
        <Loading />
      )}
    </PageContainer>
  );
};

export default Blog;
