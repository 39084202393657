import React, { Suspense } from "react";
import Quotation from "../../Components/TourQuotation/Quotation";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { Loading } from "../../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../../Context/AppContext";

const Index = ({ app_id, id }) => {
  const { user } = useAppData();
  return (
    <Suspense fallback={<Loading />}>
      <ApplicationContext>
        <Quotation app_id={user.POTB} id={id} link="potb-quote-maker" />
      </ApplicationContext>
    </Suspense>
  );
};

export default Index;
