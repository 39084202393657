import React from "react";
import Bookings from "./Bookings";
import { PlanContext } from "../../../Context/PlanContext";
import { BookingsContext } from "../../../Context/BookingsContext";

const Index = () => {
  return (
    <BookingsContext>
      <PlanContext>
        <Bookings />
      </PlanContext>
    </BookingsContext>
  );
};

export default Index;
