import React, { useEffect, useState } from "react";
import { useBookingsData } from "../../../../Context/BookingsContext";
import {
  Col,
  Loading,
  Pagination,
  ProductCard,
} from "../../../../traveltayo-ui-kit";
import BookingForm from "../Components/BookingForm";
import Empty from "../../../Basic/Empty";

const Offers = ({ data }) => {
  const { offerFilter, tag, loading, setLoading } = data;
  const { products, getProductsByTag } = useBookingsData();

  const [product, setProduct] = useState([]);
  const [itemCount, setItemCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 40;

  const get = (page) => {
    setLoading(true);
    getProductsByTag(
      { tag: 17, page: page || 1, per_page: itemsPerPage },
      (length) =>
        setTimeout(() => {
          setLoading(false);
          setItemCount(length);
        }, 1000)
    );
  };

  useEffect(() => {
    if (tag) {
      get();
    }
  }, [tag]);

  const [filteredList, setFilteredList] = useState(products);

  useEffect(() => {
    if (products.length !== 0) {
      setFilteredList(
        products.filter((product) =>
          offerFilter.length !== 0
            ? offerFilter.includes(product.categories[0].name)
            : product
        )
      );
    }
  }, [offerFilter, products]);

  return (
    <>
      {loading ? (
        <Col md={12}>
          <Loading />
        </Col>
      ) : (
        <>
          <BookingForm product={product} setProduct={setProduct} viewProfileBTN={true} />
          {filteredList.length !== 0 ? (
            <Pagination
              list={filteredList.filter((p) => p.reviews_allowed)}
              itemCount={itemCount}
              actions={get}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setProduct={setProduct}
              Card={ProductCard}
              itemsPerPage={itemsPerPage}
              cardType="card"
              colCount={3}
            />
          ) : (
            <Col>
              <Empty />
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default Offers;
