import React, { useEffect, useState } from "react";
import Empty from "../../../Basic/Empty";
import PlanCoverHeader from "./PlanCoverHeader";
import PlanBudget from "./PlanBudget";
import PlanMainBody from "./PlanMainBody";
import {
  Loading,
  Col,
  Fade,
  Row,
  PageContainer,
} from "../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../Context/AppContext";
import { usePlanData } from "../../../../Context/PlanContext";
import { APP_NAME, FEATURES } from "../../../../Hooks/utils/config";

const TravelPlan = ({ id }) => {
  const { isOnline, user, screenWidth, decryptMessage } = useAppData();

  const { plan, setPlan, getPlan } = usePlanData();

  const [loading, setLoading] = useState(true);
  const [offlineStats, setOfflineStats] = useState(true);

  useEffect(() => {
    if (!isOnline) {
      if (window.localStorage.getItem(id)) {
        setPlan(JSON.parse(decryptMessage(window.localStorage.getItem(id))));
      } else {
        setOfflineStats(false);
      }
    } else {
      getPlan(
        id,
        () =>
          setLoading(true) ||
          setTimeout(() => {
            if (id) {
              setLoading(false);
            }
          }, 1500)
      );
    }
  }, [id, user, isOnline]);

  return (
    <PageContainer
      rightSideBar={
        !screenWidth &&
        plan.length !== 0 &&
        FEATURES.TRAVEL_BUDGET_MONITORING && <PlanBudget />
      }
      helmet={{
        title: plan.length !== 0 ? `${APP_NAME} | ${plan.name[0]}` : APP_NAME,
      }}
      noAds={FEATURES.NO_ADS}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          {offlineStats ? (
            <>
              {plan.status === "error" || plan.length === 0 ? (
                <Empty>Sorry! But this Travel Plan is not available</Empty>
              ) : (
                <>
                  <Fade>
                    {plan.length !== 0 ? (
                      <div style={{ padding: screenWidth ? 0 : "0" }}>
                        <Row style={{ paddingTop: screenWidth ? 0 : 0 }}>
                          <Col md={12} style={{ margin: 0, padding: 0 }}>
                            <PlanCoverHeader />
                            <PlanMainBody />
                          </Col>
                          {/* <Col
                              md={4}
                              style={{
                                paddingRight: 0,
                                paddingLeft: screenWidth ? 0 : "15px",
                                marginTop: screenWidth ? 15 : 0,
                              }}
                            >
                              {!screenWidth && <PlanBudget />}
                            </Col> */}
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                  </Fade>
                </>
              )}
            </>
          ) : (
            <Empty>
              This Plan is not available for offline viewing.
              <br /> To view Plans offline, you have to save them / or view them
              once your are online.
            </Empty>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default TravelPlan;
