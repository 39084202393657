import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  faPlus,
  faAngleLeft,
  faAngleRight,
  faList,
  faClose,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FontAwesomeIcon,
} from "../..";
import { useAppData } from "../../../Context/AppContext";
import { COLOR, FEATURES } from "../../../Hooks/utils/config";
import { usePlanData } from "../../../Context/PlanContext";
import { useDashboardData } from "../../../Context/DashboardContext";
import dateFormat from "dateformat";

const CalendarToolBar = (toolbar) => {
  const { isOnline, screenWidth, getHolidays } = useAppData();

  const { setIsAddingNewPlan, setNewPlan, newPlan, plans } = usePlanData();

  const { eventFilter, setEventFilter, calendarEvents, setCalendarYear } =
    useDashboardData();

  const [openToggle, setOpenToggle] = useState("");

  const toolbarDate = toolbar.date.getMonth();
  const now = new Date();
  const date = moment(toolbar.date);

  useEffect(() => {
    let month = dateFormat(new Date(), "mmmm");
    let year = dateFormat(new Date(), "yyyy");
    if (
      eventFilter.find(
        (filter) => filter === "holiday" || filter === "festival"
      )
    ) {
      getHolidays({
        month: month,
        year: year,
      });
    }
  }, [eventFilter]);

  const [selectedMonth, setSelectedMonth] = useState("September");
  const [selectedYear, setSelectedYear] = useState(2023);

  const goToBack = () => {
    let month = dateFormat(toolbar.date.setMonth(toolbarDate - 1), "mmmm");
    let year = dateFormat(toolbar.date.setMonth(toolbarDate - 1), "yyyy");

    toolbar.date.setMonth(toolbarDate - 1);
    toolbar.onNavigate("prev");
    setCalendarYear(year);
    // getPlans();

    setSelectedMonth(month);
    setSelectedYear(year);

    if (
      eventFilter.find(
        (filter) => filter === "holiday" || filter === "festival"
      )
    ) {
      getHolidays({
        month: month,
        year: year,
      });
    }
  };

  const goToNext = () => {
    let month = dateFormat(toolbar.date.setMonth(toolbarDate + 1), "mmmm");
    let year = dateFormat(toolbar.date.setMonth(toolbarDate + 1), "yyyy");

    toolbar.date.setMonth(toolbarDate + 1);
    toolbar.onNavigate("next");
    setCalendarYear(year);

    setSelectedMonth(month);
    setSelectedYear(year);

    if (
      eventFilter.filter(
        (filter) => filter === "holiday" || filter === "festival"
      ).length !== 0
    ) {
      getHolidays({
        month: month,
        year: year,
      });
    }
  };

  const goToCurrent = () => {
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
    setCalendarYear(date.format("YYYY"));
    // getPlans();
    if (
      eventFilter.find(
        (filter) => filter === "holiday" || filter === "festival"
      )
    ) {
      getHolidays({
        month: date.format("MMMM"),
        year: date.format("YYYY"),
      });

      setSelectedMonth(date.format("MMMM"));
      setSelectedYear(date.format("YYYY"));
    }
  };

  useEffect(() => {
    getHolidays({
      month: selectedMonth,
      year: selectedYear,
    });
  }, [plans]);

  const label = () => {
    return (
      <>
        {date.format("MMMM")} {date.format("YYYY")}
      </>
    );
  };

  const FILTERS = [
    {
      label: "My Plans",
      value: "travel_plan",
      color: COLOR.primary,
      actions: () => "",
      show: FEATURES.TRAVEL_PLAN_APP,
    },
    {
      label: "My Reminders",
      value: "reminder",
      color: COLOR.reminder,
      actions: () => "",
      show: true,
    },
    // { label: "Shared Plans", value: "shared_travel_plan" },
    {
      label: "My Quotations",
      value: "quotation",
      color: COLOR.quotation,
      actions: () => "",
      show: true,
    },
    {
      label: "My Bookings",
      value: "booking",
      color: COLOR.booking,
      actions: () => "",
      show: FEATURES.BOOKING_SYSTEM,
    },
    {
      label: "Festivals",
      value: "festival",
      actions: () =>
        getHolidays({
          month: date.format("MMMM"),
          year: date.format("YYYY"),
        }),
      color: COLOR.festivals,
      show: true
    },
    {
      label: "Holidays",
      value: "holiday",
      actions: () =>
        getHolidays({
          month: date.format("MMMM"),
          year: date.format("YYYY"),
        }),
      color: COLOR.holidays,
      show: true
    },
    {
      label: "Long Weekends",
      value: "long-weekend",
      color: COLOR.long_weekend,
      actions: () => "",
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Col
          md={7}
          style={{ padding: 0, margin: 0, marginBottom: screenWidth ? 10 : 0 }}
        >
          <Button theme="light" size="md" onClick={() => goToCurrent()}>
            Today
          </Button>
          <Button
            pill
            outline
            theme="light"
            size="md"
            style={{ border: "2px solid black", margin: 5 }}
            onClick={() => goToBack()}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <Button
            pill
            outline
            theme="light"
            size="md"
            style={{ border: "2px solid black", margin: 5 }}
            onClick={() => goToNext()}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
          <h4
            style={{
              display: "inline",
              marginLeft: 20,
              verticalAlign: "middle",
              fontSize: screenWidth ? "16px" : "",
            }}
          >
            {label()}
          </h4>
        </Col>

        <Col
          md={2}
          style={{ maxWidth: screenWidth ? "40%" : "", paddingLeft: 0 }}
        >
          {isOnline ? (
            <Dropdown
              open={openToggle === "plan"}
              toggle={() => setOpenToggle(openToggle === "plan" ? "" : "plan")}
            >
              <DropdownToggle
                theme="dark"
                style={{
                  backgroundColor: COLOR.primary,
                  border: "0px solid #fff",
                  margin: 5,
                  width: "100%",
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> New
              </DropdownToggle>
              <DropdownMenu>
                {FEATURES.TRAVEL_PLAN_APP && (
                  <DropdownItem onClick={() => setIsAddingNewPlan(true)}>
                    New Plan
                  </DropdownItem>
                )}

                <DropdownItem
                  onClick={() =>
                    console.log("test") ||
                    setNewPlan({ ...newPlan, type: "reminder" }) ||
                    setIsAddingNewPlan(true)
                  }
                >
                  New Reminder
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            ""
          )}
        </Col>
        <Col
          md={3}
          style={{
            padding: 0,
            margin: 0,
            verticalAlign: "middle",
            maxWidth: screenWidth ? "60%" : "",
          }}
        >
          <Dropdown
            open={openToggle === "filter"}
            toggle={() =>
              setOpenToggle(openToggle === "filter" ? "" : "filter")
            }
          >
            <DropdownToggle
              theme="dark"
              style={{
                backgroundColor: COLOR.primary,
                border: "0px solid #fff",
                margin: 5,
                width: "100%",
              }}
            >
              <FontAwesomeIcon icon={faList} /> View Filter
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() =>
                  setEventFilter(FILTERS.map((f) => f.value)) ||
                  getHolidays({
                    month: date.format("MMMM"),
                    year: date.format("YYYY"),
                  })
                }
              >
                All Events
              </DropdownItem>
              {FILTERS.filter((filter) => filter.show)
                .filter((filter) => !eventFilter.includes(filter.value))
                .map((filter) => (
                  <DropdownItem
                    onClick={() =>
                      setEventFilter([...eventFilter, filter.value]) ||
                      filter.actions()
                    }
                  >
                    {filter.label}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
        {calendarEvents && eventFilter.length !== 0 && (
          <Col md={12} style={{ padding: 0, paddingTop: 10 }}>
            {eventFilter.map((filter) => (
              <Button
                style={{ margin: 2 }}
                // theme="light"
                backgroundColor={FILTERS.find((f) => f.value === filter).color}
                onClick={() =>
                  setEventFilter(eventFilter.filter((f) => f !== filter))
                }
              >
                {filter.replace(/_|-/g, " ").toUpperCase()}{" "}
                <FontAwesomeIcon icon={faClose} />
              </Button>
            ))}
          </Col>
        )}
      </Row>
    </>
  );
};

export default CalendarToolBar;
