import React, { useEffect, useState } from "react";
import { useAppData } from "../../../Context/AppContext";
import { APP_LINK, APP_NAME, FEATURES } from "../../../Hooks/utils/config";
import { Col, Row, PageContainer } from "../../../traveltayo-ui-kit";
import Destinations from "./Pages/Destinations";
import Offers from "./Pages/Offers";
import TravelApps from "./Pages/TravelApps";
import PromoCodes from "./Pages/PromoCodes";
import Widget from "./Components/SearchWidget/Widget";

const Index = ({ tag }) => {
  const { screenWidth, auth_login } = useAppData();
  const [currentTab, setCurrentTab] = useState("");

  useEffect(() => {
    if (!FEATURES.EXPLORE_PAGE) {
      window.location.replace("/");
    }
  }, []);

  useEffect(() => {
    if (tag) {
      setCurrentTab(tag);
    } else {
      setCurrentTab("offers");
    }
  }, [tag]);

  const [loading, setLoading] = useState(true);

  const [offerFilter, setOfferFilter] = useState([]);
  const [search, setSearch] = useState("");

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Explore",
        url: APP_LINK + "/explore",
        image: "/images/plan_image.jpeg",
      }}
      // noAds={}
      noAds={true}
      noSideBar={true}
      sideMenu={
        <Widget
          data={{
            currentTab,
            loading,
            setLoading,
            offerFilter,
            setOfferFilter,
            search,
            setSearch,
          }}
        />
      }
      headerComponents={
        <>
          {/* <Row
          style={{
            padding: screenWidth ? "5%" : "10%",
            backgroundImage: `url(https://book.traveltayo.com/wp-content/uploads/2023/10/website-cover.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no repeat",
          }}
        >
          <Col md={12}>
            <h2
              style={{
                textAlign: "center",
                margin: 20,
                color: "#ffff",
                textShadow: "2px 3px 10px rgba(0,0,0,0.93)",
              }}
            >
              LET'S EXPLORE THE PHILIPPINES
            </h2>
            <Widget
              data={{
                currentTab,
                loading,
                setLoading,
                offerFilter,
                setOfferFilter,
                search,
                setSearch,
              }}
            />
          </Col>
        </Row> */}
        </>
      }
    >
      <Row style={{ padding: 0 }}>
        {/* <Col md={12}></Col> */}
        {/* <Col md={12} style={{ padding: screenWidth ? 0 : 0 }}></Col> */}
        <Col md={12} style={{ padding: screenWidth ? 0 : 0 }}>
          <Row style={{ padding: 0 }}>
            <>
              {currentTab === "destinations" && (
                <Destinations
                  data={{
                    tag: "destinations",
                    loading,
                    setLoading,
                  }}
                />
              )}

              {currentTab === "offers" && (
                <Offers
                  data={{
                    offerFilter,
                    tag: "offers",
                    loading,
                    setLoading,
                  }}
                />
              )}

              {/* {currentTab === "travel-apps" && (
                <TravelApps
                  data={{
                    offerFilter,
                    search,
                    loading,
                    setLoading,
                  }}
                />
              )} */}

              {/* {currentTab === "promo-codes" && (
                <PromoCodes
                  tag={"promo-codes"}
                  loading={loading}
                  setLoading={setLoading}
                />
              )} */}
            </>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Index;
