const {
  faCompass,
  faShirt,
  faToiletPaper,
  faUtensils,
  faBusAlt,
  faFerry,
  faVanShuttle,
  faCarAlt,
  faHotel,
  faHiking,
  faMobilePhone,
  faList,
  faIdBadge,
  faCheck,
  faLocationDot,
  faUpload,
  faTicket,
} = require("@fortawesome/free-solid-svg-icons");

module.exports = {
  API: {
    MAIN_URI: process.env.REACT_APP_MAIN_URI,
    URL: process.env.REACT_APP_URL,
    NEW_URL: "https://booneraye-001-site1.ftempurl.com/new-server/api/v1",
    // CHAT_SERVER: "http://localhost:4000",
    // NEW_URL: "http://localhost:5500/new-server/api/v1",
    FILES: process.env.REACT_APP_FILES,
    KEY: process.env.REACT_APP_TRAVELTAYO_API_KEY,
    CORS: "",
    HEADERS: {
      headers: {
        "Content-Type": "application/json",
        "API-Key": process.env.REACT_APP_TRAVELTAYO_API_KEY,
        "Access-Control-Allow-Origin": "*",
      },
    },
  },
  APP_NAME: "Travel Tayo",
  APP_LINK: "https://app.traveltayo.com",
  HCAPTCHA: "33704661-2e3c-414d-b0c6-f086a5fc7151",
  FEATURES: {
    BOOKING_SYSTEM: false,
    AI_GENERATOR: false,
    AI_GEN_LIMIT_DAYS: 4,
    ANNOUNCEMENT_PANEL: false,
    SIGN_UP_PAGE: false,
    ALLOW_ACCESS_KEY: true,
    TRAVEL_POINTS: false,
    BUS_BOOKING: false,
    ALLOW_APPS: ["admin", "agent"],
    NO_ADS: false,
    EXPLORE_PAGE: false,
    ABOUT_US_PAGE: false,
    BLOG_PAGE: false,
    MANAGE_BOOKING_PAGE: false,
    CONTACT_DETAILS: true,
    FOOTER_DETAILS: true,
    TRAVEL_PLAN_APP: true,
    TRAVEL_FILES_UPLOAD: false,
    TRAVEL_BUDGET_MONITORING: false,
    ALL_APPS_VIEW: true,
    ALLOW_FOLDERED_APP: true
  },
  PLATFORM: {
    MERCHANTS: [
      {
        id: 1,
        name: "Travel Tayo",
        forApproval: true,
      },
    ],
    BRAND: "Travel Tayo",
    LABELS: {
      DASHBOARD: "Dashboard",
      PLANNER: "My Plans",
      PROFILE: "Profile",
      SETTINGS: "Account Settings",
      LOGOUT: "Log Out",
      SEARCH: "Search here...",
    },
    CREATE_PLAN_WIDGET: {
      PLAN: "New Adventure starts here...",
      REMINDER: "Don't Miss Anything",
    },
    BOOKING_WIDGET: {
      TITLE: "Booking Form",
      DESCRIPTION:
        "These are our booking partners, you'll be redirected to outside link for booking.",
      STATUS: process.env.REACT_APP_BOOKING_WIDGET === "true" ? true : false,
    },
    TRAVEL_POINTS: {
      CONTAINER_LABEL: "Travel Adeventure Points & Badges",
      TRAVEL_POINTS_PLANS: 10,
      TRAVEL_POINTS_SHARED_PLANS: 5,
    },
    LOGO: {
      LONG: "/images/svg-logo.svg",
      WHITE: "/images/svg-logo-white.svg",
      NORMAL: "/images/svg-logo.svg",
      LOADING_ICON: "/images/loading.gif",
      // PARTNER_LOGO: "https://gladextours.com/wp-content/uploads/2023/09/Untitled-design-75.png"
    },
    LOGIN_PAGE: {
      SIGN_UP_LABEL: "Adventure Starts Here!",
      SIGN_UP_SUBTEXT:
        "Create your planner account, to enjoy the hassle-free way of travelling.",
      LOGIN_LABEL: "Hello Again!",
      LOGIN_SUBTEXT:
        "Start planning your trips, Schedule your flights and Book your tickets in one place.",
    },
    CONTACT_DETAILS: {
      ADDRESS: "",
      PHONE: "09162577220",
      EMAIL: "support@traveltayo.com",
      SOCIALS: [
        {
          NAME: "facebook",
          ICON: "",
          LINK: "https://www.facebook.com/traveltayoAdventures",
          LABEL: "Facebook "
        },
        {
          NAME: "instagram",
          ICON: "",
          LINK: "https://www.instagram.com/traveltayoadventures/",
          LABEL: "Instagram"
        },
      ],
    },
    ABOUT_US_PAGE: {
      LINK: "",
      SHOW: false,
    },
    BLOG_PAGE: {
      LINK: "",
      SHOW: true,
    },
    AFFILLIATES: [
      {
        LOGO: "https://sherpa.agoda.com/Badge/GetBadge?badgetype=2&refkey=PIFikm9OzzlAYd0mXGkgAA%3D%3D",
        BRAND: "Agoda",
        LINK: "https://www.agoda.com/partners/partnersearch.aspx?cid=1908345&pcs=8",
      },
      {
        LOGO: "https://book.traveltayo.com/wp-content/uploads/2023/04/service-partner-brand-logo-12go.png",
        BRAND: "12Go Asia",
        LINK: "https://12go.asia/en?z=4954977",
      },
      {
        LOGO: "https://cdn.klook.com/s/dist_web/klook-affiliate-front/static/web/assets/logo.d108e003.png",
        BRAND: "Klook",
        LINK: "https://www.klook.com/?aid=35799",
      },
      {
        LOGO: "https://book.traveltayo.com/wp-content/uploads/2023/04/Trip.com_logo.svg_.png",
        BRAND: "Trip.com",
        LINK: "https://www.trip.com/?Allianceid=3833889&SID=21965432&ouid=traveltayo",
      },
      {
        LOGO: "http://biyaheko.ph/Content/Images/Biyaheko.png",
        BRAND: "Biyaheko",
        LINK: "https://biyaheko.ph/",
      },
    ],
    BOOKING_STATUSES: [
      { id: 0, color: "warning", code: "pending", label: "Pending" },
      {
        id: 1,
        color: "info",
        code: "processing",
        label: "Processing Payment",
      },
      { id: 2, color: "success", code: "completed", label: "Completed" },
      { id: 3, color: "secondary", code: "on-hold", label: "On Hold" },
      { id: 4, color: "danger", code: "cancelled", label: "Cancelled" },
      { id: 5, color: "primary", code: "refunded", label: "Refunded" },
    ],
  },
  COLOR: {
    primary: "#158892",
    reminder: "#D0771D",
    secondary: "#2bcbba",
    tertiary: "#868e96",
    custom: "#E21F4A",
    subtitle: "#6CABB7",
    customLabelTitle: "#b6b8bb",
    danger: "#c61c36",
    long_weekend: "#B52C44",
    holidays: "#781034",
    festivals: "#208A3E",
    shared_travel_plan: "#D0771D",
    booking: "#B77400",
    quotation: "#0285D1",
  },
  ICON: [
    {
      type: "checklist",
      icon: faCheck,
    },
    {
      type: "itinerary",
      icon: faLocationDot,
    },
    {
      type: "uploaded_booking",
      icon: faUpload,
    },
    {
      type: "my_booking",
      icon: faTicket,
    },
  ],
  DEFAULT_VALUES: {
    TRAVEL_PREFERENCES: [
      "Camping",
      "Hotel",
      "Romantic",
      "Beach",
      "Historic Place",
      "Hidden Gems",
      "Tourist Spots",
      "Hiking",
      "Car",
      "Commute",
    ],
    CHECKLIST_CATEGORIES: [
      {
        value: "essentials",
        label: "Essentials",
        icon: faCompass,
        theme: "info",
      },
      { value: "Food", label: "Food", icon: faUtensils, theme: "success" },
      {
        value: "Toiletries",
        label: "Toiletries",
        icon: faToiletPaper,
        theme: "warning",
      },
      { value: "Clothes", label: "Clothes", icon: faShirt, theme: "danger" },
      {
        value: "Electronics",
        label: "Electronics",
        icon: faMobilePhone,
        theme: "danger",
      },
      { value: "Others", label: "Others", icon: faList, theme: "danger" },
      {
        value: "Travel Documents",
        label: "Travel Documents",
        icon: faIdBadge,
        theme: "danger",
      },
    ],
  },
  SEARCH_TYPES: [
    {
      index: 0,
      value: "activities",
      label: "Activities",
      icon: faHiking,
    },
    {
      index: 1,
      value: "accommodation",
      label: "Accommodation",
      icon: faHotel,
    },
    {
      index: 3,
      value: "bus_tickets",
      label: "Bus Tickets",
      icon: faBusAlt,
    },
    {
      index: 2,
      value: "van_car_rental",
      label: "Car/ Van Rental",
      icon: faCarAlt,
    },
    {
      index: 4,
      value: "ferry_tickets",
      label: "Ferry Tickets",
      icon: faFerry,
    },
    {
      index: 6,
      value: "merchandises",
      label: "Merchandises",
      icon: faShirt,
    },
    {
      index: 5,
      value: "restaurants",
      label: "Restaurants",
      icon: faUtensils,
    },
    {
      index: 7,
      value: "tours",
      label: "Tours",
      icon: faVanShuttle,
    },
  ],
  CONFETTI: {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 170,
    dragFriction: 0.12,
    delay: 2000,
    duration: 4000,
    stagger: 3,
    width: "10px",
    height: "10px",
    zIndex: 10000000000,
    perspective: "100vw",
    colors: ["#158892", "#2bcbba"],
  },
};
