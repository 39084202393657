import React from "react";
import { FontAwesomeIcon, faBoxOpen } from "../../traveltayo-ui-kit";

const Empty = ({ children }) => {
  return (
    <div style={{ textAlign: "center", alignItems: "center", padding: 10 }}>
      <p style={{ marginBottom: 5, fontsize: "1.5rem" }}>
        O-oh! it seems empty
      </p>
      <p style={{ marginBottom: 5, fontSize: "3rem" }}>
        <FontAwesomeIcon icon={faBoxOpen} />
      </p>
      {children}
    </div>
  );
};

export default Empty;
