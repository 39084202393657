import React, { useState } from "react";
import ModalForm from "../../../components/ModalForm";
import { usePlanData } from "../../../../Context/PlanContext";

const DuplicateForm = ({ data }) => {
  const { setOpenDuplicateForm, openDuplicateForm, plan, setViewEvent } = data;
  const { duplicatePlan } = usePlanData();

  const [loading, setLoading] = useState(false);

  return (
    <ModalForm
      open={openDuplicateForm}
      toggle={() => {}}
      bodyPadding="10px 20px"
      loading={loading}
      size="md"
      header={`Are you sure you want to duplicate this ${
        plan.type === "reminder" ? "reminder" : "plan"
      }?`}
      approvedBTNProps={{
        // block: "block",
        display: true,
        size: "md",
        iconPosition: "left",
        label: "YES, Proceed",
        onClick: () =>
          setLoading(true) ||
          duplicatePlan(plan, () => {
            setOpenDuplicateForm(false) || setLoading(false);
            if (plan.type === "reminder") {
              setViewEvent(false);
            }
          }),
      }}
      cancelBTNProps={{
        // block: "block",
        display: true,
        label: "NO",
        size: "md",
        onClick: () => setOpenDuplicateForm(false),
      }}
      deleteBTNProps={{
        display: false,
        theme: "danger",
        onClick: () => "",
      }}
    >
      If you duplicate this{" "}
      {plan.type === "reminder" ? "reminder" : "travel plan"}, only the
      following data will be copied:
      <ul>
        <li>
          {plan.type === "reminder" ? "Reminder" : "Travel"} Details including
          Dates, Name, and Destination
        </li>
        {plan.type === "plan" && (
          <>
            <li>Travel Checklists</li>
            <li>Travel Itinerary</li>
          </>
        )}
      </ul>
    </ModalForm>
  );
};

export default DuplicateForm;
