import React from "react";
import { FormCheckbox, FormTextarea } from "../../../../../../traveltayo-ui-kit";
import useQuotationApp from "../../../../../../Hooks/utils/QuotationApp/useQuotationApp";

const NotificationForm = ({ data }) => {
  const {
    quotation,
    setQuotation,
    editQuotation,
    updatedRates,
    user,
    FORM,
    computedView,
  } = data;
  const { notifMessage } = useQuotationApp();

  return (
    <>
      {updatedRates.length !== 0 &&
        quotation.details.notifications.length !== 0 &&
        editQuotation.username === user.username && (
          <>
          <h6>NOTIFICATIONS</h6>
            <FormCheckbox
              small
              inline
              checked={quotation.details.notifications.initQuote}
              onChange={() =>
                setQuotation((prev) => ({
                  ...prev,
                  details: {
                    ...prev.details,
                    notifications: {
                      ...FORM.details.notifications,
                      initQuote: !prev.details.notifications.initQuote,
                    },
                  },
                }))
              }
            >
              Quote
            </FormCheckbox>
            <FormCheckbox
              small
              inline
              checked={quotation.details.notifications.discountReminder}
              onChange={() =>
                setQuotation((prev) => ({
                  ...prev,
                  details: {
                    ...prev.details,
                    notifications: {
                      ...FORM.details.notifications,
                      discountReminder:
                        !prev.details.notifications.discountReminder,
                    },
                  },
                }))
              }
            >
              Discount
            </FormCheckbox>
            <FormCheckbox
              small
              inline
              checked={quotation.details.notifications.paymentReminder}
              onChange={() =>
                setQuotation((prev) => ({
                  ...prev,
                  details: {
                    ...prev.details,
                    notifications: {
                      ...FORM.details.notifications,
                      paymentReminder:
                        !prev.details.notifications.paymentReminder,
                    },
                  },
                }))
              }
            >
              Payment
            </FormCheckbox>
            <FormCheckbox
              small
              inline
              checked={quotation.details.notifications.paymentSuccess}
              onChange={() =>
                setQuotation((prev) => ({
                  ...prev,
                  details: {
                    ...prev.details,
                    notifications: {
                      ...FORM.details.notifications,
                      paymentSuccess:
                        !prev.details.notifications.paymentSuccess,
                    },
                  },
                }))
              }
            >
              Paid
            </FormCheckbox>
            <FormTextarea
              value={notifMessage(quotation, updatedRates, computedView, user)}
              rows={6}
            />

            <hr />
          </>
        )}
    </>
  );
};

export default NotificationForm;
