import React, { useEffect } from "react";
import Quotations from "../../Components/TourQuotation/Quotations";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { useAppData } from "../../../../../Context/AppContext";

const Index = ({ id }) => {

  const {user} = useAppData()

  useEffect(() => {
    if (user.length !== 0 && user.appMaker === 0) {
      window.location.replace("/");
    }
  }, [user]);

  return (
    <ApplicationContext>
      <Quotations id={id} link=""/>
    </ApplicationContext>
  );
};

export default Index;
