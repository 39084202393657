import React, { useEffect, useRef } from "react";
import { HCAPTCHA } from "../../../Hooks/utils/config";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useAppData } from "../../../Context/AppContext";

const Captcha = ({ token, setToken }) => {
  const {screenWidth} = useAppData()
  const captchaRef = useRef(null);

  const onLoad = () => {
    captchaRef.current.execute();
  };

  useEffect(() => {
    if (token) console.log(`hCaptcha Token: ${token}`);
  }, [token]);

  return (
    <HCaptcha
      sitekey={HCAPTCHA}
      onLoad={() => onLoad()}
      onVerify={setToken}
      ref={captchaRef}
      size={screenWidth ? "compact" : "normal"}
    />
  );
};

export default Captcha;
