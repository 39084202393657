import React, { useState } from "react";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import dateFormat from "dateformat";
import {
  Button,
  ListGroup,
  ListGroupItem,
  FormCheckbox,
  Badge,
  Row,
  Col,
  FontAwesomeIcon,
  faEdit,
  faEye,
} from "../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../Context/AppContext";
import { usePlanData } from "../../../../Context/PlanContext";
import { DEFAULT_VALUES } from "../../../../Hooks/utils/config";

const CheckList = ({ data }) => {
  const { index, checklist, loading, setLoading, selectedID, setSelectedID } =
    data;
  const { isOnline, screenWidth } = useAppData();
  const {
    plan,
    updateChecklistStatus,
    setCheckListNewForm,
    setIsEditingChecklist,
  } = usePlanData();

  const { numberWithCommas } = useCustomFunctions();

  return (
    <ListGroup style={{ marginBottom: 5 }} key={index}>
      <ListGroupItem style={{ padding: "5px 10px" }}>
        <Row>
          <Col md={12} style={{ padding: 0 }}>
            <FormCheckbox
              className="checklist-item"
              checked={checklist.status === "Done"}
              onChange={() =>
                plan.credential === "viewer"
                  ? ""
                  : setLoading(true) ||
                    setSelectedID(checklist.id) ||
                    updateChecklistStatus(
                      checklist.id,
                      plan.checklists,
                      () => setLoading(false) || setSelectedID("")
                    )
              }
              disabled={
                isOnline ? loading || plan.credential === "viewer" : false
              }
            >
              {checklist.category ? (
                <Badge
                  theme={
                    DEFAULT_VALUES.CHECKLIST_CATEGORIES.find(
                      (category) => category.value === checklist.category
                    )
                      ? DEFAULT_VALUES.CHECKLIST_CATEGORIES.find(
                          (category) => category.value === checklist.category
                        ).theme
                      : "info"
                  }
                  style={{ color: "#fff" }}
                >
                  <FontAwesomeIcon
                    icon={
                      DEFAULT_VALUES.CHECKLIST_CATEGORIES.find(
                        (category) => category.value === checklist.category
                      )
                        ? DEFAULT_VALUES.CHECKLIST_CATEGORIES.find(
                            (category) => category.value === checklist.category
                          ).icon
                        : ""
                    }
                  />
                </Badge>
              ) : (
                ""
              )}{" "}
              {parseFloat(checklist.amountPaid) !== 0 ? (
                <Badge theme="warning">
                  &#8369; {numberWithCommas(checklist.amountPaid)}
                </Badge>
              ) : (
                ""
              )}{" "}
              {checklist.status === "Done" ? (
                <strike>{checklist.name}</strike>
              ) : (
                checklist.name
              )}
            </FormCheckbox>
            {plan.credential !== "viewer" && (
              <Button
                theme="light"
                style={{ width: "15%" }}
                onClick={() =>
                  setCheckListNewForm(checklist) || setIsEditingChecklist(true)
                }
                disabled={loading}
              >
                {loading && checklist.id === selectedID ? (
                  screenWidth ? (
                    "..."
                  ) : (
                    "Updating"
                  )
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={plan.access === "edit" ? faEdit : faEye}
                    />{" "}
                    {screenWidth
                      ? ""
                      : plan.access === "edit"
                      ? "Edit"
                      : "View"}
                  </>
                )}
              </Button>
            )}
          </Col>

          {checklist.description ? (
            <Col md={12} style={{ padding: 0 }}>
              <p
                style={{
                  margin: 0,
                  padding: "10px 0px",
                  paddingBottom: "0px",
                  fontSize: "12px",
                }}
              >
                {/* <FontAwesomeIcon icon={faNoteSticky} />{" "} */}
                {checklist.description ? (
                  <ul
                    style={{
                      listStyleType: "square",
                      paddingLeft: 20,
                    }}
                  >
                    {checklist.description
                      .replace(/-/g, "")
                      .split("\n")
                      .filter((list) => list)
                      .map((list) => (
                        <li>{list}</li>
                      ))}
                  </ul>
                ) : (
                  ""
                )}

                {/* {checklist.last_change ? (
                  <>
                    Last Change: {dateFormat(checklist.last_change, "fullDate")}
                  </>
                ) : (
                  ""
                )} */}
              </p>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </ListGroupItem>
    </ListGroup>
  );
};

export default CheckList;
