import React, { useEffect, useState } from "react";
import BookingForm from "../../Components/BookingForm";
import Empty from "../../../../Basic/Empty";
import ImageGallery from "react-image-gallery";
import ShareButtons from "../../../../Basic/ShareButtons";
import { useAppData } from "../../../../../Context/AppContext";
import { APP_NAME, COLOR, FEATURES } from "../../../../../Hooks/utils/config";
import {
  Loading,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  faArrowRight,
  faPlus,
  FontAwesomeIcon,
  PageContainer,
  faArrowLeft,
} from "../../../../../traveltayo-ui-kit";
import { usePlanData } from "../../../../../Context/PlanContext";
import { useBookingsData } from "../../../../../Context/BookingsContext";
import ReviewForm from "./ReviewForm";
import Certified from "./Certified";
import Reviews from "./Reviews";
import { useNavigate } from "@reach/router";
// import "photoswipe/dist/photoswipe.css";

const ProductProfile = ({ id, tag }) => {
  const { user } = useAppData();
  const { getProduct, getProductReviews } = useBookingsData();
  const { setIsAddingNewPlan, setNewPlan, newPlan } = usePlanData();

  const navigate = useNavigate();

  console.log(navigate);

  const [product, setProduct] = useState([]);
  const [product1, setProduct1] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  const [sendReview, setSendReview] = useState(false);

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        getProduct({ id: id, merchantID: 1 }, (res) => {
          setProduct(res.data.length !== 0 ? res.data[0] : []);
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        });
      }, 1500);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    if (product.length !== 0) {
      getProductReviews(
        { id: product.id, merchantID: 1 },
        (res) =>
          // console.log(res) ||
          setReviews(res.data) || setLoading(false)
      );
    }
  }, [product]);

  return (
    <PageContainer
      helmet={{
        title:
          product.length !== 0
            ? `${APP_NAME} | ${
                product.categories[0].slug !== "destination"
                  ? "Details"
                  : "Reviews"
              } | ${product.name}`
            : APP_NAME,
        url: window.location.href,
        image: product.length !== 0 ? product.images[0].src : "",
        description: product.short_description,
      }}
      rightSideBar={
        product.length !== 0 &&
        product?.categories[0].slug === "destination" && (
          <Certified data={{ product, merchant: 1, id }} />
        )
      }
      noAds={FEATURES.NO_ADS}
    >
      {product.length !== 0 && (
        <>
          <ReviewForm
            data={{
              reviews,
              setReviews,
              product,
              merchant: 1,
              loading,
              setLoading,
              sendReview,
              setSendReview,
            }}
          />
        </>
      )}
      <div>
        <Row style={{ padding: 0 }}>
          {loading ? (
            <Col md={12} style={{ padding: 0 }}>
              <Loading />
            </Col>
          ) : (
            <>
              {product.length === 0 ? (
                <Col md={12}>
                  <Empty>
                    It seems that this link has expired or become unavailable.
                  </Empty>
                </Col>
              ) : (
                <>
                  {product.length !== 0 && (
                    <BookingForm product={product1} setProduct={setProduct1} />
                  )}

                  <Col md={9} style={{ padding: 0}}>
                    <Button
                      onClick={() => navigate(-1)}
                      theme="secondary"
                      style={{ marginRight: 10 }}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} /> Back
                    </Button>
                  </Col>
                  <Col md={3} style={{ padding: 0, textAlign: "right"}}>
                    <ShareButtons
                      link={`https://traveltayo.com/explore/${tag}/${product.slug}.html`}
                    />
                  </Col>

                  <Col md={12} style={{ padding: 0, paddingBottom: 20 }}>
                    <Card className="main-card">
                      <CardBody style={{ padding: 0 }}>
                        <div
                          style={{
                            width: "100%",
                            height: "300px",
                            backgroundImage: `url(${product.images[0].src})`,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            borderRadius: "0.625rem 0.625rem 0 0",
                          }}
                        />
                      </CardBody>
                      <CardBody style={{ padding: 10 }}>
                        {product &&
                        product.categories[0].slug !== "destination" ? (
                          <>
                            <Button
                              onClick={() =>
                                setProduct1({
                                  ...product,
                                  merchant: { id: 1 },
                                })
                              }
                              backgroundColor={COLOR.primary}
                              size="md"
                              style={{ marginBottom: 20, marginTop: 20 }}
                            >
                              Experience this tour{" "}
                              <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </>
                        ) : (
                          <>
                            {user.length !== 0 ? (
                              <Button
                                backgroundColor={COLOR.primary}
                                style={{ marginBottom: 20 }}
                                onClick={() =>
                                  setIsAddingNewPlan(true) ||
                                  setNewPlan({
                                    ...newPlan,
                                    destination: product.name,
                                    geoData: product.attributes.find(
                                      (a) => a.name === "map"
                                    ),
                                  })
                                }
                              >
                                <FontAwesomeIcon icon={faPlus} /> Start Planning
                                for this destination
                              </Button>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </CardBody>
                      <CardBody style={{ padding: 10 }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.description,
                          }}
                        />

                        <ImageGallery
                          items={product.images.map((image) => ({
                            original: image.src,
                            thumbnail: image.src,
                          }))}
                        />
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={12} style={{ padding: 0 }}>
                    <Reviews data={{ reviews, sendReview, setSendReview }} />
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
      </div>
    </PageContainer>
  );
};

export default ProductProfile;
