import React, { useEffect, useState } from "react";
import {
  ModalForm,
  FormInput,
  FormSelect,
  Button,
  Col,
  Row,
  faSave,
  faPesoSign,
  faUser,
} from "../../..";
import { COLOR } from "../../../../Hooks/utils/config";
import { useAppData } from "../../../../Context/AppContext";
import { usePlanData } from "../../../../Context/PlanContext";

const UpdateBudget = (props) => {
  const { user } = useAppData();
  const { plan, updateBudget, buddies, modifyBuddyList, getBuddiesPerPlan } =
    usePlanData();

  const [loading, setLoading] = useState(false);

  const [budgetForm, setBudgetForm] = useState({
    id: plan.reference,
    budget: plan.budget,
    split_type: plan.split_type,
  });

  const form = {
    username: "",
    credential: "",
    reference: plan.reference,
    for_update: false,
    email: plan.email,
  };

  const [newBuddy, setNewBuddy] = useState([]);

  const [planCredential, setPlanCredential] = useState("");

  useEffect(() => {
    if (buddies.length !== 0) {
      setPlanCredential(
        buddies.find((buddy) => buddy.username === user.username).credential
      );
    }
  }, [buddies, user]);

  return (
    <ModalForm
      open={props.isEditingBudget}
      toggle={() => {}}
      bodyPadding="10px 20px"
      loading={loading}
      approvedBTNProps={{
        // block: "block",
        display: props.isEditingBudget,
        size: "sm",
        iconPosition: "left",
        icon: faSave,
        label: "SAVE",
        onClick: () =>
          setLoading(true) ||
          updateBudget(
            budgetForm,
            () => setLoading(false) || props.setEditingBudget(false)
          ),
      }}
      cancelBTNProps={{
        // block: "block",
        display: true,
        size: "sm",
        onClick: () =>
          props.setEditingBudget(false) ||
          setBudgetForm({
            id: plan.reference,
            budget: plan.budget,
            split_type: plan.split_type,
          }) || setNewBuddy([]),
      }}
      deleteBTNProps={{
        display: false,
      }}
    >
      <FormInput
        group={true}
        icon={faPesoSign}
        type="number"
        label="TOTAL BUDGET"
        placeholder="enter your total budget"
        value={budgetForm.budget}
        onChange={(e) =>
          setBudgetForm({ ...budgetForm, budget: e.target.value })
        }
      />
      {/* <FormSelect
        group={true}
        icon={faBalanceScale}
        label="EXPENSE SPLIT TYPE"
        value={budgetForm.split_type}
        onChange={(e) =>
          setBudgetForm({ ...budgetForm, split_type: e.target.value })
        }
      >
        <option value="">SELECT ONE</option>
        <option value="manual">Manually Split</option>
        <option value="equal">Equally Split</option>
      </FormSelect> */}
      <hr />
      <Row style={{ padding: 0 }}>
        <Col md={12} style={{ paddingBottom: 15, paddingLeft: 0 }}>
          Travel Buddies
        </Col>
        {buddies.map((buddy) => (
          <>
            <Col md={5} xl={5} xs={5} style={{ paddingLeft: 0 }}>
              <FormInput
                group={true}
                icon={faUser}
                type="text"
                // label="USERNAME"
                placeholder="enter your buddy username"
                value={
                  newBuddy.id === buddy.id ? newBuddy.username : buddy.username
                }
                onChange={(e) =>
                  setNewBuddy({ ...newBuddy, username: e.target.value })
                }
                disabled={
                  buddy.credential === "owner" || newBuddy.id !== buddy.id || loading
                }
              />
            </Col>
            <Col md={4} xl={4} xs={4}>
              <FormSelect
                // group={true}
                // icon={faIdCard}
                // label="ROLE"
                value={
                  newBuddy.id === buddy.id
                    ? newBuddy.credential
                    : buddy.credential
                }
                onChange={(e) =>
                  setNewBuddy({ ...newBuddy, credential: e.target.value })
                }
                disabled={
                  buddy.credential === "owner" || newBuddy.id !== buddy.id || loading
                }
              >
                <option value="">SELECT ONE</option>
                {buddy.credential === "owner" && (
                  <option value="owner">Owner</option>
                )}
                <option value="editor">Editor</option>
                <option value="viewer">Viewer</option>
                <option value="remove">Remove</option>
              </FormSelect>
            </Col>
            <Col md={3} xl={3} xs={3}>
              {["editor", "owner"].includes(planCredential) &&
              newBuddy.length === 0 &&
              buddy.username !== user.username &&
              plan.username !== buddy.username ? (
                <Button
                  size="md"
                  onClick={() =>
                    setNewBuddy({
                      ...buddy,
                      email: plan.email,
                      for_update: true,
                    })
                  }
                  backgroundColor={COLOR.primary}
                  disabled={loading}
                >
                  Edit
                </Button>
              ) : (
                <>
                  {buddy.credential !== "owner" &&
                    newBuddy.id &&
                    newBuddy.id === buddy.id && (
                      <Button
                        size="md"
                        onClick={() =>
                          setLoading(true) ||
                          modifyBuddyList(newBuddy, () =>
                            getBuddiesPerPlan(
                              plan,
                              () => setLoading(false) || setNewBuddy([])
                            )
                          )
                        }
                        backgroundColor={COLOR.primary}
                        disabled={
                          (buddies.find(
                            (buddy) => buddy.username === newBuddy.username
                          ) &&
                          newBuddy.username === "" &&
                          newBuddy.credential === "") || loading
                        }
                      >
                        {loading ? "Updating.." : "Update"}
                      </Button>
                    )}
                </>
              )}
            </Col>
          </>
        ))}
        {newBuddy.length !== 0 && !newBuddy.id ? (
          <>
            <Col md={5} style={{ paddingLeft: 0 }}>
              <FormInput
                group={true}
                icon={faUser}
                type="text"
                placeholder="enter your buddy username"
                value={newBuddy.username}
                onChange={(e) =>
                  setNewBuddy({ ...newBuddy, username: e.target.value })
                }
              />
            </Col>
            <Col md={4}>
              <FormSelect
                value={newBuddy.credential}
                onChange={(e) =>
                  setNewBuddy({ ...newBuddy, credential: e.target.value })
                }
              >
                <option value="">SELECT ONE</option>
                <option value="editor">Editor</option>
                <option value="viewer">Viewer</option>
              </FormSelect>
            </Col>
            <Col md={3}>
              <Button
                size="md"
                backgroundColor={COLOR.primary}
                onClick={() =>
                  modifyBuddyList(newBuddy, () =>
                    getBuddiesPerPlan(plan, () => setNewBuddy([]))
                  )
                }
                disabled={
                  buddies.find(
                    (buddy) => buddy.username === newBuddy.username
                  ) ||
                  newBuddy.username === "" ||
                  newBuddy.credential === "" || loading
                }
              >
                Add
              </Button>
            </Col>
          </>
        ) : (
          ""
        )}
        {newBuddy.length === 0 && (
          <Col md={12} style={{ paddingLeft: 0 }}>
            <Button
              size="sm"
              onClick={() => setNewBuddy(form)}
              backgroundColor={COLOR.primary}
              disabled={loading}
            >
              Add New Buddy
            </Button>
          </Col>
        )}
      </Row>
    </ModalForm>
  );
};

export default UpdateBudget;
