import React, { useEffect } from "react";
import { API, HEADERS } from "../../../Hooks/utils/config";
import axios from "axios";

const VerfiyAccount = ({ email, reference }) => {
  useEffect(() => {

    if (email && reference) {
      axios
        .post(
          `${API.NEW_URL}/users/confirm_email`,
          {
            email: email,
            reference: reference,
          },
          HEADERS
        )
        .then(function (response) {
          window.location.replace("/login");
        })
        .catch(function (error) {
          // handle error

          window.location.replace("/login");
        })
        .then(function () {
          // always executed
        });
    }
  }, [email, reference]);

  return <div>Please wait while we verify your</div>;
};

export default VerfiyAccount;
