import React from "react";
import Calendar from "./Calendar";
import { PlanContext } from "../../../Context/PlanContext";
import { DashboardContext } from "../../../Context/DashboardContext";
import { BookingsContext } from "../../../Context/BookingsContext";
import { ApplicationContext } from "../../../Context/ApplicationContext";

const Index = () => {
  return (
    <BookingsContext>
      <PlanContext>
        <ApplicationContext>
          <DashboardContext>
            {/* <PlanForm /> */}
            <Calendar />
          </DashboardContext>
        </ApplicationContext>
      </PlanContext>
    </BookingsContext>
  );
};

export default Index;
