import React, { useEffect, useState } from "react";
import {
  ModalForm,
  FormUpload,
  Button,
  faCalendar,
  faSave,
  faPlus,
  faPaperPlane,
  faBell,
  faTrash,
  faAngleDown,
  faAngleUp,
  faPesoSign,
  Col,
  Collapse,
  FormCheckbox,
  FormGroup,
  Row,
  FormInput,
  Badge,
  Loading,
  FontAwesomeIcon,
} from "../../..";
import { useAppData } from "../../../../Context/AppContext";
import {
  COLOR,
  DEFAULT_VALUES,
  FEATURES,
  PLATFORM,
} from "../../../../Hooks/utils/config";
import { usePlanData } from "../../../../Context/PlanContext";
import { useBookingsData } from "../../../../Context/BookingsContext";
import {
  faClose,
  faLocationDot,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const PlanForm = () => {
  const { user, setIsDeleting } = useAppData();

  const { getProducts } = useBookingsData();

  const {
    resetPlanForm,
    removePlan,
    updateDetails,
    addTravelPlan,
    isAddingNewPlan,
    setIsAddingNewPlan,
    isEditingPlan,
    setIsEditingPlan,
    newPlan,
    setNewPlan,
    getPlans,
  } = usePlanData();

  const description_limit = newPlan.description.length >= 150;
  const isReminder = newPlan.type === "reminder";

  const [loading, setLoading] = useState(false);

  const [openPref, setOpenPref] = useState(false);
  const [searching, setSearching] = useState(false);

  const [destinations, setDestinations] = useState([]);
  const [noResult, setNoResult] = useState(false);

  return (
    <ModalForm
      open={isAddingNewPlan || isEditingPlan}
      toggle={() => {}}
      header={
        isReminder
          ? PLATFORM.CREATE_PLAN_WIDGET.REMINDER
          : PLATFORM.CREATE_PLAN_WIDGET.PLAN
      }
      bodyPadding="10px 20px"
      loading={loading}
      approvedBTNProps={{
        // block: "block",
        display: newPlan.name !== "" && newPlan.start && newPlan.end,
        size: isEditingPlan ? "sm" : "md",
        iconPosition: "left",
        icon: isReminder ? faBell : isEditingPlan ? faSave : faPlus,
        label: isReminder
          ? isEditingPlan
            ? "Save as Reminder"
            : "Add New Reminder"
          : isEditingPlan
          ? "Save as Plan"
          : "Add New Plan",
        onClick: () =>
          isEditingPlan
            ? setLoading(true) ||
              updateDetails(
                newPlan,
                () =>
                  getPlans(
                    () =>
                      setIsEditingPlan(false) ||
                      setLoading(false) ||
                      resetPlanForm()
                  ) || setDestinations([])
              )
            : setLoading(true) ||
              addTravelPlan(
                newPlan,
                () =>
                  setIsEditingPlan(false) ||
                  setIsAddingNewPlan(false) ||
                  setLoading(false) ||
                  resetPlanForm() ||
                  setDestinations([])
              ),
      }}
      cancelBTNProps={{
        // block: "block",
        display: true,
        size: isEditingPlan ? "sm" : "md",
        onClick: () =>
          isEditingPlan
            ? setIsEditingPlan(false) || resetPlanForm() || setDestinations([])
            : setIsAddingNewPlan(false) ||
              resetPlanForm() ||
              setDestinations([]),
      }}
      deleteBTNProps={{
        display: isEditingPlan,
        icon: faTrash,
        theme: "danger",
        onClick: () =>
          setLoading(true) ||
          removePlan(newPlan.id, () =>
            isReminder
              ? getPlans(
                  () =>
                    setIsEditingPlan(false) ||
                    setIsDeleting(false) ||
                    setLoading(false) ||
                    resetPlanForm()
                )
              : window.location.pathname === "/"
              ? getPlans(
                  () =>
                    setIsEditingPlan(false) ||
                    setIsDeleting(false) ||
                    setLoading(false) ||
                    resetPlanForm()
                )
              : window.location.replace("/plans")
          ),
      }}
    >
      {loading && !newPlan.generateAI ? (
        <Loading static={true} />
      ) : loading && newPlan.generateAI ? (
        <Loading>
          Please wait while AI generating your checklist, itineraries and budget
        </Loading>
      ) : (
        ""
      )}
      {!loading && (
        <>
          {FEATURES.TRAVEL_PLAN_APP && (
            <FormCheckbox
              checked={newPlan.type === "reminder"}
              onChange={() =>
                setNewPlan({
                  ...newPlan,
                  type: newPlan.type === "reminder" ? "plan" : "reminder",
                })
              }
            >
              {isEditingPlan ? "Turn this into a Reminder" : "Reminder Only"}
            </FormCheckbox>
          )}

          {isEditingPlan && !isReminder ? (
            <FormUpload
              label="UPLOAD COVER IMAGE"
              action={(e) => setNewPlan({ ...newPlan, cover: e })}
              loading={loading}
              setLoading={setLoading}
              acceptFiles={"image/*"}
            />
          ) : (
            ""
          )}
          <FormInput
            group={true}
            icon={faPaperPlane}
            label={isReminder ? "REMINDER NAME" : "TRAVEL PLAN NAME"}
            placeholder="enter your plan name"
            value={newPlan.name}
            onChange={(e) => setNewPlan({ ...newPlan, name: e.target.value })}
          />

          <FormInput
            group={true}
            icon={faCalendar}
            label={isReminder ? "DATE" : "TRAVEL DATE"}
            inputs={[
              {
                placeholderText: "departure date",
                className: "formgroup-left-input form-control",
                type: "date",
                selected: newPlan.start,
                onChange: (e) =>
                  setNewPlan({
                    ...newPlan,
                    start: e,
                    end: new Date(e) > new Date(newPlan.end) ? e : newPlan.end,
                  }),
                minDate: new Date(),
              },
              {
                placeholderText: "return date",
                className: "formgroup-right-input  form-control",
                type: "date",
                selected: newPlan.end,
                onChange: (e) => setNewPlan({ ...newPlan, end: e }),
                minDate: new Date(newPlan.start) || new Date(),
              },
            ]}
          />

          <div className="dropdown show" style={{ width: "100%" }}>
            <FormInput
              group={true}
              icon={faLocationDot}
              label="DESTINATION (Optional)"
              placeholder="enter your destination"
              value={newPlan.destination}
              onChange={(e) =>
                setNewPlan({
                  ...newPlan,
                  destination: e.target.value,
                  geoData: [],
                }) ||
                setDestinations([]) ||
                setNoResult(false)
              }
              onKeyPress={(event) => {
                if (
                  event.key === "Enter" &&
                  FEATURES.TRAVEL_PLAN_APP &&
                  FEATURES.EXPLORE_PAGE
                ) {
                  setSearching(true) ||
                    getProducts(
                      {
                        search: newPlan.destination,
                      },
                      (products) =>
                        setDestinations(products) ||
                        setSearching(false) ||
                        setNoResult(products.length === 0)
                    );
                }
              }}
              inputBTN={
                FEATURES.TRAVEL_PLAN_APP && FEATURES.EXPLORE_PAGE ? (
                  <Button
                    theme="secondary"
                    disabled={searching || newPlan.destination === ""}
                    onClick={() =>
                      destinations.length === 0 &&
                      FEATURES.TRAVEL_PLAN_APP &&
                      FEATURES.EXPLORE_PAGE
                        ? setSearching(true) ||
                          getProducts(
                            {
                              search: newPlan.destination,
                            },
                            (products) =>
                              setDestinations(products) ||
                              setSearching(false) ||
                              setTimeout(() => {
                                setNoResult(
                                  newPlan.destination !== "" &&
                                    destinations.length === 0
                                );
                              }, 1500)
                          )
                        : setDestinations([])
                    }
                  >
                    {searching ? (
                      "Searching..."
                    ) : (
                      <FontAwesomeIcon
                        icon={destinations.length !== 0 ? faClose : faSearch}
                      />
                    )}
                  </Button>
                ) : (
                  ""
                )
              }
            />

            {newPlan.destination !== "" &&
              destinations.length !== 0 &&
              FEATURES.TRAVEL_PLAN_APP &&
              FEATURES.EXPLORE_PAGE && (
                <div
                  className="dropdown-menu show"
                  aria-hidden="false"
                  tabIndex="-1"
                  role="menu"
                  style={{ width: "100%" }}
                >
                  {destinations.map((product) => (
                    <div
                      type="button"
                      tabindex="0"
                      className="dropdown-item"
                      style={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}
                    >
                      <Row>
                        <Col
                          md={9}
                          onClick={() =>
                            setNewPlan({
                              ...newPlan,
                              destination: product.name,
                              geoData: product.attributes.find(
                                (a) => a.name === "map"
                              ),
                            }) || setDestinations([])
                          }
                        >
                          {product.name}
                        </Col>
                        <Col
                          md={3}
                          style={{
                            margin: 0,
                            textDecoration: "none",
                            color: COLOR.primary,
                            fontWeight: 500,
                          }}
                          onClick={() =>
                            window.open(
                              `/explore/page/${product.merchant.id}/${product.slug}/`,
                              "_blank"
                            )
                          }
                        >
                          Reviews
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              )}

            {noResult && (
              <Badge
                style={{
                  fontSize: "12px",
                  lineHeight: "normal",
                  // textAlign: "left",
                  width: "100%",
                  marginBottom: 5,
                }}
                theme="info"
              >
                Your destination is not yet available on our directory. <br />{" "}
                Please send us a message so we can add and promote the place.
              </Badge>
            )}
          </div>

          {user.ai_tool === 1 &&
            !isEditingPlan &&
            newPlan.type !== "reminder" &&
            newPlan.destination !== "" && (
              <FormCheckbox
                checked={newPlan.generateAI}
                onChange={() =>
                  setNewPlan({
                    ...newPlan,
                    generateAI: newPlan.generateAI ? false : true,
                  }) || setOpenPref(true)
                }
              >
                <p style={{ fontSize: "12px", margin: 0, display: "inline" }}>
                  Automatically generate checklist, itinerary, & estimated
                  budget based on destination, travel days and preferences. It
                  may takes time.
                </p>
              </FormCheckbox>
            )}

          {newPlan.type === "reminder" || !newPlan.generateAI ? (
            <FormInput
              label={`Short Description (Optional) ${newPlan.description.length}/150`}
              inputType="textarea"
              placeholder="enter your description"
              value={newPlan.description}
              onChange={(e) =>
                description_limit
                  ? setNewPlan({
                      ...newPlan,
                      description: e.target.value.slice(0, 150),
                    })
                  : setNewPlan({ ...newPlan, description: e.target.value })
              }
              invalid={description_limit}
            />
          ) : (
            <FormGroup>
              <a
                href="/#"
                style={{
                  margin: 0,
                  textDecoration: "none",
                  color: COLOR.primary,
                  fontWeight: 500,
                }}
                onClick={() => setOpenPref(!openPref)}
              >
                Travel Preferences & Description{" "}
                <FontAwesomeIcon icon={openPref ? faAngleUp : faAngleDown} />
              </a>
              <Collapse open={openPref}>
                <FormInput
                  label={`Short Description (Optional) ${newPlan.description.length}/150`}
                  inputType="textarea"
                  placeholder="enter your description"
                  value={newPlan.description}
                  onChange={(e) =>
                    description_limit
                      ? setNewPlan({
                          ...newPlan,
                          description: e.target.value.slice(0, 150),
                        })
                      : setNewPlan({ ...newPlan, description: e.target.value })
                  }
                  invalid={description_limit}
                />

                <Row style={{ padding: 0, fontSize: "13px" }}>
                  {DEFAULT_VALUES.TRAVEL_PREFERENCES.map((preference) => (
                    <Col
                      md={4}
                      xs={6}
                      sm={6}
                      style={{ padding: 0, paddingRight: 5 }}
                    >
                      <FormCheckbox
                        checked={newPlan.preferences.includes(preference)}
                        onChange={() =>
                          setNewPlan({
                            ...newPlan,
                            preferences: newPlan.preferences.includes(
                              preference
                            )
                              ? newPlan.preferences.filter(
                                  (p) => p !== preference
                                )
                              : [...newPlan.preferences, preference],
                          })
                        }
                        inline
                      >
                        {preference}
                      </FormCheckbox>
                    </Col>
                  ))}
                </Row>
              </Collapse>
            </FormGroup>
          )}
        </>
      )}
    </ModalForm>
  );
};

export default PlanForm;
