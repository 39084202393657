import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import PlanChecklist from "../Components/Pages/Plans/Plan/PlanChecklist";
import PlanItinerary from "../Components/Pages/Plans/Plan/PlanItinerary";
import PlanBookings from "../Components/Pages/Plans/Plan/PlanBookings";
import useEditPlan from "../Hooks/utils/Plans/useEditPlan";
import PlanBudget from "../Components/Pages/Plans/Plan/PlanBudget";
import useTravelPlansAPI from "../Hooks/api/useTravelPlansAPI";
import {
  faCheck,
  faMap,
  faMoneyBill,
  faPlaneDeparture,
  faUsers,
} from "../traveltayo-ui-kit";
import { useAppData } from "./AppContext";
import { useBookingsData } from "./BookingsContext";
// import Chat from "../Components/Pages/Plans/Components/Chat"

const Context = createContext();

export function usePlanData() {
  return useContext(Context);
}

export const PlanContext = ({ children }) => {
  const {
    getLoginSession,
    user,
    auth_login,
    isOnline,
    isAddingNewPlan,
    setIsAddingNewPlan,
    newPlan,
    setNewPlan,
    newPlanForm,
    isEditingPlan, setIsEditingPlan
  } = useAppData();

  const {
    getUserBookingsPerPlan,
    getUserUploadedBookingsPerPlan,
    uploadedbookingsPerPlan,
    bookingsPerPlan,
  } = useBookingsData();

  const {
    addTravelPlan,
    removePlan,
    plans,
    plan,
    setPlan,
    getPlan,
    updatePlan,
    sendInvites,
    allPlans,
    getPlans,
    //Minor edits
    aiGenerateItinerary,
    updateDetails,
    updateReminderToPlan,
    updateChecklist,
    updateItinerary,
    updateBudget,
    buddies,
    getBuddiesPerPlan,
    modifyBuddyList,
    duplicatePlan,
  } = useTravelPlansAPI({ getLoginSession, user, auth_login, isOnline });

  const {
    insertNewChecklist,
    updateChecklistValues,
    updateChecklistStatus,
    removeChecklistItem,
    /*Itinerary*/
    regenerateItinerary,
    insertNewItinerary,
    updateItineraryValues,
    removeItineraryItem,
  } = useEditPlan(plan, updateChecklist, updateItinerary);

  //TAB Menu
  const [currentTab, setCurrentTab] = useState(0);
  const MENU_TABS = [
    {
      index: 0,
      icon: faCheck,
      action: () => setCurrentTab(0),
      label: "Checklist",
      className: "menu-tab-first",
      component: <PlanChecklist />,
    },
    {
      index: 1,
      icon: faMap,
      action: () => setCurrentTab(1),
      label: "Itinerary",
      component: <PlanItinerary />,
    },
    {
      index: 2,
      icon: faPlaneDeparture,
      action: () => setCurrentTab(2),
      label: "Bookings & Files",
      // className: "menu-tab-last",
      component: <PlanBookings />,
    },
    {
      index: 3,
      icon: faMoneyBill,
      action: () => setCurrentTab(3),
      label: "Budget",
      className: "menu-tab-last",
      component: <PlanBudget />,
    },
    // {
    //   index: 4,
    //   icon: faUsers,
    //   action: () => setCurrentTab(4),
    //   label: "Chat",
    //   className: "menu-tab-last",
    //   component: <Chat />,
    // },
  ];

  //Checklists
  const [isAddingNewChecklist, setIsAddingNewChecklist] = useState(false);
  const [isEditingChecklist, setIsEditingChecklist] = useState(false);

  let newChecklistForm = {
    id: (Math.random() + 1).toString(36).substring(7),
    name: "",
    category: "",
    owner: "All",
    status: "Not Done",
    username: user.username,
    description: "",
    last_edit: user.username,
    last_change: new Date(),
    isPaid: false,
    amountPaid: 0,
    paidBy: "",
  };

  const [checkListNewForm, setCheckListNewForm] = useState(newChecklistForm);

  const resetChecklistForm = () => {
    setCheckListNewForm(newChecklistForm);
  };

  //ITINERARIES
  const [isAddingNewItineraries, setIsAddingNewItineraries] = useState(false);
  const [isEditingItineraries, setIsEditingItineraries] = useState(false);

  let newItinerariesForm = {
    id: (Math.random() + 1).toString(36).substring(7),
    day: 0,
    plans: [],
    description: "",
    title: "",
    end: new Date(),
    start: new Date(),
    category: "route",
    location: plan.length !== 0 ? plan.name[1] : "",
    interval: 5,
    last_edit: user.username,
    last_change: new Date(),
    isPaid: false,
    isIncBudget: false,
    amountPaid: 0,
    paidBy: "",
  };

  const [itinerariesNewForm, setItinerariesNewForm] =
    useState(newItinerariesForm);

  const resetItinerariesForm = () => {
    setItinerariesNewForm(newItinerariesForm);
  };

  

  const resetPlanForm = () => {
    setNewPlan(newPlanForm);
  };

  const [plansFilter, setPlansFilter] = useState("");

  const [editPlan, setEditPlan] = useState([]);

  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    if (plan && plan.checklists && plan.itinerary) {
      setExpenses(
        plan.checklists
          .filter(
            (checklist) => checklist.isPaid
            //  && checklist.status === "Done"
          )
          .map((checklist) => ({
            ...checklist,
            type: "checklist",
          }))
          .concat(
            plan.itinerary
              .filter((itin) => itin.isIncBudget)
              .map((itin) => ({ ...itin, name: itin.title, type: "itinerary" }))
          )
          .map((expense) => ({
            ...expense,
            id: `${expense.id}-${expense.type}`,
          }))
      );
    }

    if (plan.length !== 0) {
      getUserBookingsPerPlan(plan.reference);
      getUserUploadedBookingsPerPlan(plan.reference);
    }
  }, [plan]);

  useEffect(() => {
    if (uploadedbookingsPerPlan || bookingsPerPlan) {
      setExpenses(
        expenses.concat(
          uploadedbookingsPerPlan
            .filter((booking) => booking.isPaid)
            .map((booking) => ({
              ...booking,
              name: booking.reference,
            }))
            .concat(
              bookingsPerPlan
                .filter((booking) => booking.status === "completed")
                .map((booking) => ({
                  ...booking,
                  name: `Booking # ${booking.order_number}`,
                  amountPaid: parseFloat(booking.details.product.total),
                }))
            )
        )
      );
    }
  }, [uploadedbookingsPerPlan, bookingsPerPlan]);

  const budgetComputation = (expenses, plan) => {
    let filteredExpenses = expenses.filter(
      (obj, index) => expenses.findIndex((item) => item.id === obj.id) === index
    );
    let totalExpense = filteredExpenses.reduce(
      (a, b) => parseFloat(a) + parseFloat(b.amountPaid),
      0
    );
    let paidExpenses = filteredExpenses.filter((expense) =>
      expense.type !== "checklist"
        ? expense.isPaid
        : expense.type === "checklist" && expense.status === "Done"
    );
    let unpaidExpenses = filteredExpenses.filter(
      (expense) =>
        (expense.type === "checklist" && expense.status === "Not Done") ||
        !expense.isPaid
    );

    return {
      totalExpense: totalExpense,
      budgetLeft: plan.budget - totalExpense,
      budgetLeftPercentage: (totalExpense / plan.budget) * 100,
      paidExpenses: paidExpenses,
      totalPaidExpenses: paidExpenses.reduce(
        (a, b) => parseFloat(a) + parseFloat(b.amountPaid),
        0
      ),
      unpaidExpenses: unpaidExpenses,
      totalUnpaidExpenses: unpaidExpenses.reduce(
        (a, b) => parseFloat(a) + parseFloat(b.amountPaid),
        0
      ),
    };
  };

  const budgetDetails = useMemo(
    () => budgetComputation(expenses, plan),
    [expenses, plan]
  );

  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <Context.Provider
      value={{
        MENU_TABS,
        currentTab,
        setCurrentTab,
        isDeleting,
        setIsDeleting,

        /*CHECKLISTS*/
        updateChecklistValues,
        updateChecklistStatus,
        removeChecklistItem,
        isAddingNewChecklist,
        setIsAddingNewChecklist,
        isEditingChecklist,
        setIsEditingChecklist,
        insertNewChecklist,
        checkListNewForm,
        setCheckListNewForm,
        resetChecklistForm,

        /*ITINERARIES*/
        regenerateItinerary,
        isAddingNewItineraries,
        setIsAddingNewItineraries,
        isEditingItineraries,
        setIsEditingItineraries,
        itinerariesNewForm,
        setItinerariesNewForm,
        resetItinerariesForm,
        insertNewItinerary,
        updateItineraryValues,
        removeItineraryItem,

        //Deatials
        expenses,
        totalExpense: budgetDetails.totalExpense,
        addTravelPlan,
        removePlan,
        plans,
        plan,
        setPlan,
        getPlan,
        updatePlan,
        sendInvites,
        allPlans,
        getPlans,
        //Minor edits
        aiGenerateItinerary,
        updateDetails,
        updateReminderToPlan,
        updateChecklist,
        updateItinerary,
        updateBudget,
        buddies,
        getBuddiesPerPlan,
        modifyBuddyList,
        duplicatePlan,
        budgetDetails,

        newPlan,
        setNewPlan,
        resetPlanForm,
        isAddingNewPlan,
        setIsAddingNewPlan,
        isEditingPlan,
        setIsEditingPlan,
        plansFilter,
        setPlansFilter,
        editPlan,
        setEditPlan,
      }}
    >
      {children}
    </Context.Provider>
  );
};
