import React from "react";
import {
  Alert as MyAlert,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "shards-react";
import { useAppData } from "../../Context/AppContext";
import ModalForm from "./ModalForm";

const Alert = ({ children, ...props }) => {
  const { alertStatus } = useAppData();

  let alerts = [
    {
      status: 1,
      theme: "danger",
      message: "Username or Password doesn't match!",
    },
    {
      status: 2,
      theme: "danger",
      message: "Access key is not valid!",
    },
    {
      status: 3,
      theme: "danger",
      message: "Someone logged in you account in another browser!",
    },
    {
      status: 4,
      theme: "success",
      message:
        "Password Reset Link Successfully Sent! Please check your email.",
    },
    {
      status: 5,
      theme: "danger",
      message: "Failed to send a password reset link!",
    },
    {
      status: 6,
      theme: "success",
      message: "Password Successfully changed. Please Login Now!",
    },
    {
      status: 7,
      theme: "success",
      message:
        "Creation of your account was successfully made. Please check your email to verify your account.",
    },
    {
      status: 8,
      theme: "danger",
      message:
        "Failed to create your account. Please contact us immediately! Email us at support@traveltayo.com or Message us via Facebook Messenger",
    },
    {
      status: 9,
      theme: "success",
      message:
        "We have successfully received your review! <br/><br/>Please wait for us to check and approve your review. Rest assured that all reviews will be published, whether they are good or bad. <br/> <br/>Thank you so much for taking the time to send us this feedback!",
    },
  ];

  return (
    <>
      {children ? (
        <>
          <MyAlert {...props}>{children}</MyAlert>
        </>
      ) : (
        <>
          {alerts
            .filter((alert) => alert.status === alertStatus)
            .map((alert) => (
              <>
                {alert.status === 7 ? (
                  <>
                    <ModalForm
                      open={alert.status === 7}
                      toggle={() => {}}
                      bodyPadding="20px 20px"
                      loading={false}
                      size="md"
                      header=""
                      style={{ textAlign: "center" }}
                      approvedBTNProps={{
                        // block: "block",
                        display: true,
                        size: "md",
                        iconPosition: "left",
                        label: "Home",
                        onClick: () => window.location.replace("/"),
                      }}
                      cancelBTNProps={{
                        // block: "block",
                        display: false,
                      }}
                      deleteBTNProps={{
                        display: false,
                      }}
                    >
                      <h5>SUCCESSFULL!</h5>
                      👋 {alert.message}
                    </ModalForm>
                  </>
                ) : (
                  ""
                )}

                {alert.status === 8 ? (
                  <ModalForm
                    open={alert.status === 8}
                    toggle={() => {}}
                    bodyPadding="20px 20px"
                    loading={false}
                    size="md"
                    header=""
                    style={{ textAlign: "center" }}
                    approvedBTNProps={{
                      // block: "block",
                      display: true,
                      size: "md",
                      iconPosition: "left",
                      label: "Home",
                      onClick: () => window.location.replace("/"),
                    }}
                    cancelBTNProps={{
                      // block: "block",
                      display: false,
                    }}
                    deleteBTNProps={{
                      display: false,
                    }}
                  >
                    <h5 style={{ color: "red" }}>UNSUCCESSFULL!!</h5>
                  {alert.message}
                  </ModalForm>
                ) : (
                  ""
                )}

                {![8, 7].includes(alert.status) ? (
                  <MyAlert theme={alert.theme} key={alert.status} style={{textAlign: "center"}}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: alert.message,
                      }}
                    />
                  </MyAlert>
                ) : (
                  ""
                )}
              </>
            ))}
        </>
      )}
    </>
  );
};

export default Alert;
