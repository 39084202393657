import {
  faCalendar,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  FormCheckbox,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormInput as SFormInput,
} from "shards-react";
import { COLOR } from "../../../../../Hooks/utils/config";
import { Button, FormInput } from "../../../../../traveltayo-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as ReverseIcon } from "../../../../../Assets/reverseIcon.svg";
import { useState } from "react";
import { useAppData } from "../../../../../Context/AppContext";

const BookingWidget = (props) => {
  const { screenWidth } = useAppData();

  const { setForm, form, destinations, origins, schedules, search } =
    props.data;

  const [searchForm, setSearchForm] = useState({
    destination: "",
    origin: "",
  });

  const [dSearching, setDSearching] = useState(false);
  const [oSearching, setOSearching] = useState(false);

  const [isRTAvailable, setRTAvailable] = useState(false);

  useEffect(() => {
    setRTAvailable(
      schedules.filter(
        (sched) =>
          sched.destination === form.origin && sched.origin === form.destination
      ).length !== 0
    );
  }, [form]);

  useEffect(() => {
    setForm({
      ...form,
      roundTrip: isRTAvailable ? form.roundTrip : false,
    });
  }, [isRTAvailable]);

  return (
    <Card className="main-card">
      <CardBody>
        <Row>
          <Col md={7} sm={12} xs={12} style={{ marginBottom: 10 }}>
            <label style={{display: "block"}}>Select your destination</label>
            <>
              <div
                style={{
                  display: "inline-block",
                  width: screenWidth ? "83%" : "43%",
                }}
                className="dropdown show"
              >
                <FormGroup>
                  <SFormInput
                    style={{
                      width: "100%",
                      display: "inline",
                    }}
                    value={searchForm.origin || form.origin}
                    onChange={(e) =>
                      setSearchForm({
                        ...searchForm,
                        origin: e.target.value,
                      }) || setForm({ ...form, origin: "" })
                    }
                    onClick={() => setOSearching(true) || setDSearching(false)}
                    placeholder="From"
                  />
                </FormGroup>

                <Modal open={oSearching} toggle={() => ""} size="lg">
                  <ModalHeader>Select your point of origin</ModalHeader>
                  <ModalBody style={{ maxHeight: "80vh", overflow: "auto" }}>
                    <Row>
                      {origins
                        .filter((origin) =>
                          searchForm.origin !== ""
                            ? origin
                                .toLowerCase()
                                .includes(searchForm.origin.toLowerCase())
                            : origin
                        )
                        .map((origin) => (
                          <Col md={4}>
                            <div
                              type="button"
                              tabindex="0"
                              className="dropdown-item"
                              style={{
                                padding: 10,
                                color: COLOR.primary,
                              }}
                              onClick={() =>
                                setForm({
                                  ...form,
                                  origin: origin,
                                  destination: "",
                                  roundTrip: false,
                                }) ||
                                setSearchForm({
                                  ...searchForm,
                                  origin: "",
                                }) ||
                                setOSearching(false)
                              }
                            >
                              <FontAwesomeIcon icon={faLocationDot} /> {origin}
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={() => setOSearching(false)} theme="light">
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              <ReverseIcon
                style={{
                  transform: screenWidth ? "" : "rotate(90deg)",
                  marginLeft: 5,
                  marginRight: 5,
                }}
                onClick={() =>
                  isRTAvailable
                    ? setForm({
                        ...form,
                        destination: form.origin,
                        origin: form.destination,
                        roundTrip: isRTAvailable ? form.roundTrip : false,
                      })
                    : ""
                }
              />
              <div
                style={{
                  display: "inline-block",
                  width: screenWidth ? "100%" : "43%",
                  marginTop: screenWidth ? 5 : 0,
                }}
                className="dropdown show"
              >
                {/* <label
                  style={{
                    textTransform: "uppercase",
                    marginBottom: "0.1rem",
                    fontSize: "11px",
                    display: "block",
                  }}
                >
                  Destination (to)
                </label> */}
                <SFormInput
                  style={{
                    width: "100%",
                    display: "inline",
                  }}
                  value={searchForm.destination || form.destination}
                  onChange={(e) =>
                    setSearchForm({
                      ...searchForm,
                      destination: e.target.value,
                    }) || setForm({ ...form, destination: "" })
                  }
                  onClick={() => setOSearching(false) || setDSearching(true)}
                  placeholder="To"
                  disabled={form.origin === ""}
                />

                {searchForm.destination !== "" || dSearching ? (
                  <Modal open={dSearching} toggle={() => ""} size="lg">
                    <ModalHeader>Select your destination</ModalHeader>
                    <ModalBody style={{ maxHeight: "80vh", overflow: "auto" }}>
                      <Row>
                        {destinations
                          .filter((destination) =>
                            searchForm.destination !== ""
                              ? destination
                                  .toLowerCase()
                                  .includes(
                                    searchForm.destination.toLowerCase()
                                  )
                              : destination
                          )
                          .map((destination) => (
                            <Col md={4}>
                              <div
                                type="button"
                                tabindex="0"
                                className="dropdown-item"
                                style={{
                                  padding: 10,
                                  color: COLOR.primary,
                                }}
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    destination: destination,
                                    roundTrip: isRTAvailable
                                      ? form.roundTrip
                                      : false,
                                  }) ||
                                  setSearchForm({
                                    ...searchForm,
                                    destination: "",
                                  }) ||
                                  setDSearching(false)
                                }
                              >
                                <FontAwesomeIcon icon={faLocationDot} />{" "}
                                {destination}
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => setDSearching(false)}
                        theme="light"
                      >
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>
                ) : (
                  ""
                )}
              </div>
            </>
          </Col>

          <Col md={5}>
            {isRTAvailable ? (
              <FormCheckbox
                checked={form.roundTrip}
                onChange={() =>
                  setForm({ ...form, roundTrip: !form.roundTrip })
                }
                toggle
                small
                disabled={
                  schedules.filter(
                    (sched) =>
                      sched.destination === form.origin &&
                      sched.origin === form.destination
                  ).length === 0
                }
              >
                Round Trip?
              </FormCheckbox>
            ) : form.origin !== "" && form.destination !== "" ? (
              "No round trip schedule available"
            ) : (
              "Travel Date"
            )}
            {form.roundTrip ? (
              <FormInput
                group={true}
                icon={faCalendar}
                // label={form.roundTrip ? "TRAVEL DATE" : "CHECK-IN DATE"}
                size={"md"}
                inputs={[
                  {
                    placeholderText: "departure date",
                    className: "formgroup-left-input form-control",
                    type: "date",
                    dateFormat: "MMM dd, yyyy",
                    selected: form.departure_date,
                    onChange: (e) => {
                      setForm({
                        ...form,
                        departure_date: e,
                        return_date:
                          e > form.return_date ? e : form.return_date,
                      });
                    },
                    minDate: new Date().setDate(new Date().getDate() + 2),
                  },
                  {
                    placeholderText: "return date",
                    className: "formgroup-right-input  form-control",
                    type: "date",
                    dateFormat: "MMM dd, yyyy",
                    selected: form.return_date,
                    onChange: (e) => {
                      setForm({
                        ...form,
                        return_date: e,
                      });
                    },
                    minDate: new Date(form.departure_date) || new Date(),
                    disable: form.roundTrip,
                  },
                ]}
              />
            ) : (
              <FormInput
                group={true}
                icon={faCalendar}
                // label="TRAVEL DATE"
                size={"md"}
                placeholderText="departure date"
                formClassName="formgroup-departure"
                className="formgroup-departure-input form-control"
                type="date"
                dateFormat="MMMM dd, yyyy"
                selected={form.departure_date}
                onChange={(e) => {
                  setForm({
                    ...form,
                    departure_date: e,
                    return_date: e > form.return_date ? e : form.return_date,
                  });
                }}
                minDate={new Date().setDate(new Date().getDate() + 2)}
                disabled={form.destination === "" || form.origin === ""}
              />
            )}
          </Col>
          <Col md={3}>
            <FormGroup>
              {/* <label style={{ marginBottom: "01.rem", fontSize: "11px" }}>
                I hope you'll find something!
              </label> */}
              <Button
                backgroundColor={COLOR.primary}
                block
                onClick={() => search(form, true)}
                disabled={form.destination === "" && form.origin === ""}
                size="md"
              >
                Search
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default BookingWidget;
