import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import {
  ModalForm,
  Row,
  Col,
  FontAwesomeIcon,
  faCalendar,
  faLocationDot,
  faBook,
  faPlus,
  faEdit,
  faCheckSquare,
  Button,
  faPaperPlane,
  AddToCalendarButton,
  DuplicateForm,
  faBed,
  faCopy,
  faHotel,
  faUser,
  faAt,
} from "../..";
import { useAppData } from "../../../Context/AppContext";
import { useNavigate } from "@reach/router";
import { usePlanData } from "../../../Context/PlanContext";
import { useDashboardData } from "../../../Context/DashboardContext";
import useCustomFunctions from "../../../Hooks/api/useCustomFunctions";

const ViewCalendarEvent = () => {
  const { isOnline, user } = useAppData();
  const {
    editPlan,
    setIsEditingPlan,
    setIsAddingNewPlan,
    setNewPlanSelectedDates,
    newPlan,
    setNewPlan,
    plan,
    getPlan,
  } = usePlanData();

  console.log(editPlan);

  const { viewEvent, setViewEvent } = useDashboardData();
  const { ValidURL } = useCustomFunctions();

  const navigate = useNavigate();

  const [openDuplicateForm, setOpenDuplicateForm] = useState(false);

  const isLongWeekend = editPlan.event_type === "long-weekend";
  const isHoliday = editPlan.event_type === "holiday";
  const isFestivals = editPlan.event_type === "festival";
  const isReminder = editPlan.type === "reminder" && editPlan.length !== 0;
  const isTravelPlan = editPlan.type === "plan" && editPlan.length !== 0;
  const isBooking = editPlan.type === "my_booking";
  const isQuotation = editPlan.event_type === "quotation";

  useEffect(() => {
    if (isTravelPlan) {
      getPlan(editPlan.reference);
    }
  }, [isTravelPlan, editPlan]);

  // console.log(editPlan);

  return (
    <>
      <ModalForm
        open={viewEvent}
        toggle={() => setViewEvent(!viewEvent)}
        // header="With Header Modal"
        bodyPadding="0"
        approvedBTNProps={{
          display:
            isTravelPlan ||
            (isReminder && isOnline) ||
            (isBooking && editPlan.plan_reference !== "") ||
            isQuotation,
          label: isReminder
            ? "Edit Details"
            : isTravelPlan || isBooking || isQuotation
            ? "View Details"
            : "Create Plan",
          iconPosition: "left",
          icon:
            isTravelPlan || isBooking || isQuotation
              ? faPaperPlane
              : isReminder
              ? faEdit
              : faPlus,
          onClick: () =>
            isReminder
              ? setIsEditingPlan(true) ||
                setViewEvent(!viewEvent) ||
                setNewPlan({
                  ...editPlan,
                  start: editPlan.travel_date[0],
                  end: editPlan.travel_date[1],
                  name: editPlan.name[0],
                  destination: editPlan.name[1],
                  description: editPlan.description,
                  type: editPlan.name[2],
                  geoData: editPlan.geoData,
                })
              : isTravelPlan
              ? navigate(`/plan/${editPlan.reference}`)
              : isBooking
              ? navigate(`/plan/${editPlan.plan_reference}`)
              : isQuotation
              ? navigate(
                  editPlan.app_id === user.POTB
                    ? `/potb-quote-maker/${editPlan.id}`
                    : `/apps/${editPlan.app_id}/${editPlan.id}`
                )
              : setIsAddingNewPlan(true) ||
                setViewEvent(!viewEvent) ||
                setNewPlanSelectedDates([editPlan.start, editPlan.trueEnd]) ||
                setNewPlan({
                  ...newPlan,
                  start: new Date(editPlan.start),
                  end: new Date(editPlan.trueEnd),
                }),
        }}
        cancelBTNProps={{
          display: true,
          label: "Close",
          onClick: () => setViewEvent(!viewEvent),
        }}
        deleteBTNProps={{
          display: false,
        }}
        footerComponents={
          <>
            {(isReminder || isTravelPlan) && plan.length !== 0 && (
              <>
                <DuplicateForm
                  data={{
                    setViewEvent,
                    setOpenDuplicateForm,
                    openDuplicateForm,
                    plan,
                  }}
                />
                <Button
                  onClick={() => setOpenDuplicateForm(true)}
                  theme="light"
                >
                  <FontAwesomeIcon icon={faCopy} /> Duplicate
                </Button>
              </>
            )}
            {(isFestivals || isHoliday || isBooking || isReminder) && (
              <>
                <AddToCalendarButton event={editPlan} />
              </>
            )}
          </>
        }
      >
        {editPlan && editPlan.length !== 0 ? (
          <Row style={{ margin: 0, padding: 0 }}>
            {/* Header Cover */}
            {isBooking && editPlan.ticket && (
              <iframe
                src={editPlan.ticket}
                style={{
                  width: "100%",
                  height: "300px",
                  border: 0,
                  borderRadius: "0.365rem 0.365rem 0 0",
                }}
                title={editPlan.name[0]}
              ></iframe>
            )}

            {isTravelPlan && (
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  borderRadius: "0.3rem 0.3rem 0 0",
                  background: `url(${editPlan.cover})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    borderRadius: "0.3rem 0.3rem 0 0",
                    padding: "10px",
                  }}
                >
                  <Row>
                    <Col md={12} style={{ padding: 0 }}>
                      {isTravelPlan && plan.length !== 0 && (
                        <Button
                          theme="light"
                          onClick={() =>
                            setIsEditingPlan(true) ||
                            setViewEvent(!viewEvent) ||
                            setNewPlan({
                              ...plan,
                              start: plan.travel_date[0],
                              end: plan.travel_date[1],
                              name: plan.name[0],
                              destination: plan.name[1],
                              description: plan.description,
                              type: plan.name[2],
                              geoData: plan.geoData,
                            })
                          }
                          style={{ marginRight: 5 }}
                        >
                          <FontAwesomeIcon icon={faEdit} /> Edit
                        </Button>
                      )}
                      {(!isFestivals || !isHoliday) && (
                        <AddToCalendarButton event={editPlan} />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            )}

            {/* Event Name */}
            {/* {!isFestivals || !isHoliday || !isTravelPlan ? (
              <Col md={12} style={{ padding: "10px 20px" }}>
                <AddToCalendarButton event={editPlan} />
              </Col>
            ) : ""} */}

            <Col md={12} style={{ padding: "10px 20px" }}>
              <p className="event-title-label">
                {isReminder && "REMINDER"}
                {isTravelPlan && "TRAVEL PLAN"}
                {isHoliday && editPlan.comment.toUpperCase()}
                {isFestivals && editPlan.category.toUpperCase()}
                {isBooking && editPlan.status.toUpperCase()}
                {isQuotation && "QUOTATION"}
              </p>
              <h4 className="event-main-title">
                {(isTravelPlan || isReminder) && editPlan.name[0]}
                {(isBooking || isQuotation || isFestivals || isHoliday) &&
                  editPlan.name}
              </h4>

              <p className="event-main-dates">
                <FontAwesomeIcon icon={faCalendar} />{" "}
                {(dateFormat(editPlan.start, "paddedShortDate") ===
                  dateFormat(editPlan.trueEnd, "paddedShortDate") &&
                  (isFestivals || isHoliday)) ||
                isTravelPlan ||
                isReminder ||
                isBooking ||
                isQuotation ? (
                  dateFormat(editPlan.start, "fullDate")
                ) : (
                  <>
                    {dateFormat(editPlan.start, "mmmm d")} -{" "}
                    {dateFormat(editPlan.trueEnd, "mmmm d, yyyy")}{" "}
                  </>
                )}
              </p>

              <p className="event-main-location">
                {(isTravelPlan || isReminder) && editPlan.name[1] !== "" && (
                  <>
                    <FontAwesomeIcon icon={faLocationDot} /> {editPlan.name[1]}
                  </>
                )}

                {isQuotation && (
                  <>
                    <FontAwesomeIcon icon={faLocationDot} />{" "}
                    {editPlan.destination}
                  </>
                )}

                {isFestivals && (
                  <>
                    <FontAwesomeIcon icon={faLocationDot} /> {editPlan.location}
                  </>
                )}

                {isHoliday && (
                  <>
                    <FontAwesomeIcon icon={faLocationDot} />{" "}
                    {editPlan.description}
                  </>
                )}
              </p>

              {isQuotation && (
                <>
                  <p className="event-main-location">
                    <FontAwesomeIcon icon={faUser} /> {editPlan.pax} persons
                  </p>

                  <p className="event-main-location">
                    <FontAwesomeIcon icon={faAt} /> {editPlan.username}
                  </p>
                  {/* <p className="event-main-location">
                    <FontAwesomeIcon icon={faHotel} />{" "}
                    {editPlan.hotel.split(" | ")[0]}
                  </p>
                  <p style={{ marginTop: 10, marginBottom: 1 }}>Rooms</p>
                  {editPlan.room.map((r) => (
                    <p
                      className="event-main-location"
                      style={{ paddingLeft: 15 }}
                    >
                      <FontAwesomeIcon icon={faBed} /> {r.ROOM} for {r.roomPax}{" "}
                      pax
                    </p>
                  ))} */}
                </>
              )}

              {isTravelPlan && editPlan.checklists.length !== 0 && (
                <>
                  <FontAwesomeIcon icon={faCheckSquare} />{" "}
                  {
                    editPlan.checklists.filter(
                      (check) => check.status === "Done"
                    ).length
                  }
                  /{editPlan.checklists.length} checklist are completed
                </>
              )}

              {(isTravelPlan || isReminder) && editPlan.description !== "" && (
                <>
                  {editPlan.description.split("\n").length <= 1 ? (
                    <p className="event-main-location">
                      <FontAwesomeIcon icon={faBook} />{" "}
                      {ValidURL(editPlan.description) ? (
                        <a href={editPlan.description} target="_blank">
                          {editPlan.description}
                        </a>
                      ) : (
                        editPlan.description
                      )}
                    </p>
                  ) : (
                    <ul className="event-main-description">
                      {editPlan.description.split("\n").map((d) => (
                        <li>
                          {ValidURL(d) ? (
                            <a href={d} target="_blank">
                              {d}
                            </a>
                          ) : (
                            d
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}

              {isFestivals && (
                <>
                  <p className="event-main-description">
                    {editPlan.description}
                  </p>
                </>
              )}
            </Col>
          </Row>
        ) : (
          ""
        )}
      </ModalForm>
    </>
  );
};

export default ViewCalendarEvent;
