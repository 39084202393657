import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import QuotationForm from "../../Components/TourQuotation/QuotationForm";
import ComputationView from "../../Components/TourQuotation/ComputationView";
import {
  Loading,
  FontAwesomeIcon,
  faArrowLeft,
  faDownload,
  faEdit,
  faPrint,
  Button,
  Alert,
  Card,
  CardBody,
  CardImg,
  Col,
  Row,
  PageContainer,
  Badge,
} from "../../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../../Context/AppContext";
import { toPng } from "html-to-image";
import { APP_NAME, COLOR, FEATURES } from "../../../../../Hooks/utils/config";
import { useNavigate } from "@reach/router";
import { useApplicationData } from "../../../../../Context/ApplicationContext";
import useQuotationApp from "../../../../../Hooks/utils/QuotationApp/useQuotationApp";
import useCustomFunctions from "../../../../../Hooks/api/useCustomFunctions";
import FlightDetailsForm from "./QuoteForm/FlightDetailsForm";
import FlightTicket from "./ViewForm/FlightTicket";
// import CONFIRMED_STAMP from "../../../../../Assets/confirmed.png";
// import LOGO from "../../../../../Assets/logo.png";

const Quotation = ({ app_id, id, link }) => {
  const { user, getLoginSession } = useAppData();

  const { getApplicationByID, application, quotation, getQuotationsPerID } =
    useApplicationData();

  const { checkExpiredQuotation, getRates } = useQuotationApp();

  const ref = useRef(null);

  const navigate = useNavigate();

  const [rates, setRates] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [editQuotation, setEditQuotation] = useState([]);

  const [loading, setLoading] = useState(true);
  const [expiredQuote, setExpiredQuote] = useState(true);

  useEffect(() => {
    getApplicationByID({ id: app_id }, () =>
      getQuotationsPerID({ id, user, otp: getLoginSession[1] })
    );
  }, [user, getLoginSession, app_id, id]);

  useEffect(() => {
    setLoading(true);
    if (quotation.length !== 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [quotation]);

  useEffect(() => {
    if (application.length !== 0) {
      getRates(
        { ...application, rates: application.rate_link },
        setRates,
        setLoading
      );
    }
  }, [application]);

  useEffect(() => {
    if (quotation.length !== 0 && rates.length !== 0) {
      setExpiredQuote(checkExpiredQuotation(rates, quotation));
    }
  }, [quotation, rates]);

  const donwloadQuotation = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    toPng(ref.current, { cacheBust: false })
      .then((dataUrl) => {
        // console.log(dataUrl);
        const link = document.createElement("a");
        link.download = `${quotation.details.name.replace(/\s+/g, "-")}-${
          quotation.details.destination
        }-${quotation.details.bookingDetails.days}D${
          quotation.details.bookingDetails.night
        }N.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref, quotation]);

  const AIRLINES = [
    { name: "Cebu Pacific", logo: "/airlines/cebpac.png", color: "#F7D81D" },
    { name: "Philippine Airline", logo: "", color: "" },
    { name: "AirAsia", logo: "", color: "" },
    { name: "Sunlight Air", logo: "", color: "" },
    { name: "Royal Air", logo: "", color: "" },
  ];

  return (
    <PageContainer
      helmet={{ title: APP_NAME + " | Quotation" }}
      noAds={FEATURES.NO_ADS}
      // rightSideBar={
      //   <Card>
      //     <CardBody style={{ padding: 10 }}>
      //       <ComputationView
      //         data={{
      //           updatedRates: quotation.details.updatedRates,
      //           details: quotation.details,
      //           computedView: quotation.computedView,
      //           bookingDetails: quotation.bookingDetails,
      //           // customerView: true,
      //         }}
      //       />
      //     </CardBody>
      //   </Card>
      // }
    >
      {openForm && (
        <QuotationForm
          formData={{ id, openForm, setOpenForm, editQuotation, link: link }}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <Row style={{ padding: 0 }}>
          {quotation.length !== 0 ? (
            <Col md={12}>
              <>
                <Button
                  onClick={() => navigate(-1)}
                  theme="secondary"
                  style={{ marginRight: 10, marginBottom: 10 }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Back
                </Button>
                <Button
                  onClick={() =>
                    setEditQuotation(quotation) || setOpenForm(true)
                  }
                  backgroundColor={COLOR.primary}
                  style={{ marginRight: 10, marginBottom: 10 }}
                >
                  <FontAwesomeIcon icon={faEdit} /> Edit Quotation
                </Button>
                <Button
                  onClick={() => donwloadQuotation()}
                  backgroundColor={COLOR.secondary}
                  style={{ marginRight: 10, marginBottom: 10 }}
                >
                  <FontAwesomeIcon icon={faDownload} /> Download Quotation
                </Button>

                <ReactToPrint
                  trigger={() => (
                    <Button
                      backgroundColor={COLOR.secondary}
                      style={{ marginRight: 10, marginBottom: 10 }}
                    >
                      <FontAwesomeIcon icon={faPrint} /> Print
                    </Button>
                  )}
                  content={() => ref.current}
                />

                {expiredQuote && (
                  <Alert
                    theme="warning"
                    style={{
                      color: "#3f2c00",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    <strong>ACTION REQUIRED: </strong>Some of the details in
                    your quotation, such as the destination, hotel & travel
                    dates, have been altered or are currently unavailable.
                    <br />
                    Kindly make the necessary adjustments promptly.
                  </Alert>
                )}

                {quotation.details?.approvalDetails?.isApproved ? (
                  <Alert
                    theme="success"
                    style={{
                      color: "#3f2c00",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    <strong>BOOKING APPROVED: </strong>
                    <br />
                    Your booking request has been approved. Please check the
                    details of your quotation for more information and check you
                    email to view your booking service voucher.
                  </Alert>
                ) : quotation.approval_request === 1 ? (
                  <Alert
                    theme="info"
                    style={{
                      color: "#3f2c00",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    <strong>FINAL QUOTATION REQUESTED: </strong>
                    <br />
                    You have requested final quotation for this package. Please
                    wait for admin feedback and response. Contact us immediately
                    for detail changes and other concerns.
                  </Alert>
                ) : (
                  ""
                )}

                <div ref={ref}>
                  <Card className="main-card">
                    {/* <CardHeader>{`Quotation for ${quotation.details.name} on ${quotation.details.destination} ${quotation.details.days}D${quotation.details.night}N`}</CardHeader> */}
                    <CardBody style={{ paddingBottom: 10 }}>
                      <Row>
                        <Col md={3}>
                          {quotation.username === user.username && (
                            <img
                              src={application.agency_data.logo}
                              style={{ width: "100%" }}
                            />
                          )}
                        </Col>
                        <Col md={3}></Col>
                        <Col
                          md={6}
                          style={{ fontSize: 11, textAlign: "right" }}
                        >
                          BOOKING REFERENCE
                          <br />
                          {quotation.computedView.amountPaid === 0 ? (
                            <Badge theme="warning" style={{ fontSize: 15 }}>
                              {quotation.reference}
                            </Badge>
                          ) : quotation.computedView.amountPaid !== 0 &&
                            quotation.computedView.isFullyPaid ? (
                            <Badge theme="success" style={{ fontSize: 15 }}>
                              {quotation.reference}
                            </Badge>
                          ) : (
                            <Badge theme="success" style={{ fontSize: 15 }}>
                              {quotation.reference}
                            </Badge>
                          )}
                          {/* {quotation.details.computedView.isFullyPaid && (
                            <img src={CONFIRMED_STAMP} style={{ width: 100 }} />
                          )} */}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardBody style={{ paddingTop: 10 }}>
                      <h5 style={{ textAlign: "center", marginBottom: 20 }}>
                        {!quotation.bookingDetails.paymentDetails.isPaid && (
                          <>
                            QUOTATION DETAILS FOR
                            <br />{" "}
                          </>
                        )}
                        {quotation.details.destination}{" "}
                        {quotation.bookingDetails.hotel.split("|")[1]}
                      </h5>
                      <ComputationView
                        data={{
                          application,
                          editQuotation: quotation,
                          updatedRates: quotation.details.updatedRates,
                          details: quotation.details,
                          computedView: quotation.computedView,
                          bookingDetails: quotation.bookingDetails,
                          customerView: true,
                        }}
                      />
                      {!quotation.computedView.isFullyPaid && (
                        <Row>
                          <Col
                            md={12}
                            style={{
                              fontWeight: 600,
                              marginBottom: 5,
                              fontSize: "13px",
                            }}
                          >
                            REMARKS
                          </Col>
                          <Col
                            md={12}
                            style={{ marginBottom: 5, fontSize: "13px" }}
                          >
                            <ol>
                              {application.terms
                                .split("\n")
                                .concat(
                                  quotation.username === user.username
                                    ? application.agency_data.termsAndConditions.split(
                                        "\n"
                                      )
                                    : []
                                )
                                .filter((detail) => detail !== "")
                                .map((detail) => (
                                  <li>{detail}</li>
                                ))}
                            </ol>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                    {!quotation.bookingDetails.paymentDetails?.isPaid &&
                      quotation.username === user.username && (
                        <CardImg
                          src={application.agency_data.paymentOption}
                          bottom
                        />
                      )}
                  </Card>

                  {quotation.id && (
                    <>
                      <p style={{ pageBreakAfter: "always", margin: 0 }}></p>
                      <FlightTicket quotation={quotation} />
                    </>
                  )}
                </div>
              </>
            </Col>
          ) : (
            ""
          )}
        </Row>
      )}
    </PageContainer>
  );
};

export default Quotation;
