import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  FormInput,
  ModalForm,
  faMoneyBill,
  faSave,
  faTrash,
  Loading,
} from "../../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../../Context/AppContext";
import { COLOR } from "../../../../../Hooks/utils/config";
import { useApplicationData } from "../../../../../Context/ApplicationContext";
import useQuotationApp from "../../../../../Hooks/utils/QuotationApp/useQuotationApp";
import ComputationView from "./ComputationView";
import AdminForm from "./QuoteForm/AdminForm";
import NotificationForm from "./QuoteForm/NotificationForm";
import PassengerForm from "./QuoteForm/PassengerForm";
import PaymentDetailsForm from "./QuoteForm/PaymentDetailsForm";
import CustomDetailsForm from "./QuoteForm/CustomDetailsForm";
import TourDetailsForm from "./QuoteForm/TourDetailsForm";
import FlightDetailsForm from "./QuoteForm/FlightDetailsForm";
import TravelPackageForm from "./QuoteForm/TravelPackageForm";
import BasicDetails from "./QuoteForm/BasicDetails";
import ServiceFeeForm from "./QuoteForm/ServiceFeeForm";
import CustomerForm from "../Customers/CustomerForm";

const QuotationForm = ({ formData }) => {
  const { id, openForm, setOpenForm, editQuotation, link } = formData;
  const { user, getLoginSession } = useAppData();

  const {
    application,
    getQuotationsPerID,
    deleteQuotation,
    addQuotation,
    updateQuotation,
  } = useApplicationData();

  const {
    getRates,
    getRemainingPax,
    getTotalRoomPax,
    checkExpiredQuotation,
    generateUpdatedRates,
    generateComputedView,
  } = useQuotationApp();

  const FORM = {
    app_id: id,
    details: {
      name: "",
      customer_details: {},
      contactDetails: "",
      pax: 2,
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
      destination: "",
      flightDetails: {
        flightFareIncluded: false,
        sameAirline: true,
        //departure details
        departureSelectedAirline: "",
        departureTerminal: "",
        departureFare: 0,
        departureGuestCount: 1,
        departureCODE: "",
        departureETD: "",
        departureETA: "",
        //retrun details
        returnSelectedAirline: "",
        returnTerminal: "",
        returnFare: 0,
        returnGuestCount: 1,
        returnCODE: "",
        returnETD: "",
        returnETA: "",
        //PNR's
        departureBookingPNR: "",
        returnBookingPNR: "",
      },
      notifications: {
        initQuote: false,
        discountReminder: false,
        paymentReminder: false,
        paymentSuccess: false,
        bookingConfirmation: false,
      },
      approvalDetails: {
        isApproved: false,
        serviceVoucherCode: "",
        bookingRemarks: "",
        ticketDetails: "",
        amountPaid: 0,
      },
    },
    bookingDetails: {
      days: 3,
      night: 2,
      hotel: "",
      selectedRoom: "",
      selectedRoomPax: "",
      room: [],
      roomPax: 0,
      serviceFee: 0,
      discount: 0,
      replaceItinerary: false,
      customItinerary: "",
      replaceInclusions: false,
      customInclusions: "",
      tourDetails: {
        additionalTour: false,
        tours: [],
      },
      passengers: [
        {
          id: (Math.random() + 1).toString(36).substring(7),
          name: "",
          age: "",
          gender: "",
          nationality: "",
        },
      ],
      paymentDetails: {
        isPaid: false,
        amountPaid: 0,
        paymentMethod: "",
        paymentReference: "",
        notes: "",
        paymentLink: "",
      },
    },
  };

  const [quotation, setQuotation] = useState(FORM);
  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewComputation, setViewComputation] = useState(true);
  const [remainingPax, setRemainingPax] = useState(0);
  const [totalRoomPax, setTotalRoomPax] = useState(0);
  const [updatedRates, setUpdatedRates] = useState(rates);
  const [computedView, setComputedView] = useState(editQuotation.computedView);
  const [expiredQuote, setExpiredQuote] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [travelPackages, setTravelPackages] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [quoteLocked, setQuoteLocked] = useState(true);

  useEffect(() => {
    if (editQuotation.length !== 0) {
      setQuotation(editQuotation);
      setComputedView(editQuotation.computedView);
    }
  }, [editQuotation]);

  useEffect(() => {
    if (application && application.length !== 0) {
      getRates({ rates: application.rate_link }, setRates, setLoading);
    }
  }, [application]);

  useEffect(() => {
    setRemainingPax(getRemainingPax(quotation));
    setTotalRoomPax(getTotalRoomPax(quotation));
  }, [quotation]);

  useEffect(() => {
    if (quotation.length !== 0 && rates.length !== 0) {
      setExpiredQuote(checkExpiredQuotation(rates, quotation));

      setTravelPackages(
        rates
          .filter((rate) => rate.DESTINATION === quotation.details.destination)
          .filter((rate) => parseInt(rate.PAX) <= quotation.details.pax)
          .filter(
            (rate) => parseInt(rate.DAYS) <= quotation.bookingDetails.days
          )
          .map((rate) => rate.value)
          .filter(
            (v, i) =>
              rates
                .filter(
                  (rate) => rate.DESTINATION === quotation.details.destination
                )
                .filter((rate) => parseInt(rate.PAX) <= quotation.details.pax)
                .filter(
                  (rate) => parseInt(rate.DAYS) <= quotation.bookingDetails.days
                )
                .map((rate) => rate.value)
                .indexOf(v) == i
          )
          .sort()
      );

      setAvailableRooms(
        rates.filter(
          (rate) =>
            rate.DESTINATION === quotation.details.destination &&
            rate.value === quotation.bookingDetails.hotel
        )
      );
    }
  }, [quotation, rates]);

  useEffect(() => {
    if (expiredQuote && editQuotation.length !== 0) {
      setTimeout(() => {
        setQuotation((prev) => ({
          ...prev,
          bookingDetails: {
            ...prev.bookingDetails,
            room: [],
            hotel: "",
          },
        }));
      }, 2000);
    }
  }, [expiredQuote, editQuotation]);

  useEffect(() => {
    if (quotation.length !== 0 && rates.length !== 0) {
      setUpdatedRates(generateUpdatedRates(rates, quotation));
    }
  }, [quotation, rates, expiredQuote]);

  useEffect(() => {
    if (quotation.length !== 0 && updatedRates.length !== 0) {
      setComputedView(generateComputedView(updatedRates, quotation));
    }
  }, [quotation, updatedRates]);

  useEffect(() => {
    setQuoteLocked(
      quotation.details.approvalDetails.isApproved &&
        editQuotation.username === user.username
    );
  }, [quotation, editQuotation]);

  useEffect(() => {
    setDestinations(
      rates
        .map((rate) => rate.DESTINATION)
        .filter((v, i) => rates.map((rate) => rate.DESTINATION).indexOf(v) == i)
        .sort()
    );
  }, [rates]);

  const updateData = (data) => {
    updateQuotation(
      {
        details: {
          ...quotation.details,
          updatedRates: updatedRates,
        },
        bookingDetails: quotation.bookingDetails,
        computedView: computedView,
        id: editQuotation.id,
        application,
        approval_request: data.approval_request,
      },
      () =>
        getQuotationsPerID({
          id: editQuotation.id,
          user,
          otp: getLoginSession[1],
        }) ||
        setOpenForm(false) ||
        setLoading(false)
    );
  };

  // console.log(rates);
  // console.log(quotation.bookingDetails);
  // console.log(updatedRates);
  // console.log(computedView?.extraNightRates);
  // console.log(computedView);
  // console.log(editQuotation.details);
  // console.log(quotation.details);

  const [openCustomerForm, setOpenCustomerForm] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState([]);

  return (
    <ModalForm
      open={openForm}
      toggle={() => {}}
      header={`${quoteLocked ? "(LOCKED) " : ""}Quotation Form`}
      className="quoration-form"
      bodyPadding="10px"
      loading={loading}
      size={viewComputation ? "lg" : "md"}
      approvedBTNProps={{
        // block: "block",
        display:
          (quotation.details.name !== "" ||
            quotation.details.destination !== "" ||
            quotation.bookingDetails.hotel !== "" ||
            quotation.bookingDetails.room.length !== 0) &&
          quotation.details.customer_details &&
          quotation.details.customer_details.name,
        size: "sm",
        iconPosition: "left",
        icon: faSave,
        label: editQuotation.length !== 0 ? "Update" : "Save",
        onClick: () =>
          setLoading(true) || editQuotation.length !== 0
            ? updateData({ approval_request: editQuotation.approval_request })
            : addQuotation(
                {
                  details: {
                    ...quotation.details,
                    updatedRates: updatedRates,
                  },
                  bookingDetails: quotation.bookingDetails,
                  computedView: computedView,
                  application,
                  link: link,
                },
                () => ""
              ),
      }}
      cancelBTNProps={{
        // block: "block",
        display: true,
        size: "sm",
        onClick: () => setOpenForm(false),
      }}
      deleteBTNProps={{
        display:
          editQuotation.length !== 0 &&
          !quoteLocked &&
          editQuotation.username === user.username,
        icon: faTrash,
        theme: "danger",
        onClick: () =>
          setLoading(true) ||
          deleteQuotation({ id: editQuotation.id }, () =>
            window.location.replace(
              link !== "" ? `/potb-quote-maker` : `/apps/${application.id}`
            )
          ),
      }}
      footerComponents={
        <>
          {application.owner !== user.username &&
            editQuotation.length !== 0 &&
            editQuotation.approval_request === 0 && (
              <Button
                theme="dark"
                backgroundColor={COLOR.primary}
                onClick={() => updateData({ approval_request: 1 })}
              >
                REQUEST FOR FINAL QUOTE
              </Button>
            )}

          {application.owner !== user.username &&
            editQuotation.length !== 0 &&
            editQuotation.approval_request !== 0 &&
            !quoteLocked && (
              <Button
                theme="dark"
                backgroundColor={COLOR.danger}
                onClick={() => updateData({ approval_request: 0 })}
                disabled={quoteLocked}
              >
                CANCEL QUOTE REQUEST
              </Button>
            )}
        </>
      }
    >
      <CustomerForm
        data={{
          openCustomerForm,
          setOpenCustomerForm,
          editCustomerData,
          setEditCustomerData,
        }}
      />
      {loading ? (
        <Loading static={true} />
      ) : (
        <Row>
          <Col md={viewComputation ? 6 : 12}>
            <BasicDetails
              data={{
                quotation,
                setQuotation,
                quoteLocked,
                openCustomerForm,
                setOpenCustomerForm,
                editQuotation,
                setEditCustomerData,
              }}
            />

            <TravelPackageForm
              data={{
                quotation,
                setQuotation,
                quoteLocked,
                availableRooms,
                travelPackages,
                totalRoomPax,
                remainingPax,
                rates,
                destinations,
              }}
            />

            <hr />
            <TourDetailsForm
              data={{
                quotation,
                setQuotation,
                quoteLocked,
                updatedRates,
                editQuotation,
              }}
            />

            <FlightDetailsForm
              data={{ quotation, setQuotation, quoteLocked, updatedRates }}
            />

            <hr />
            <CustomDetailsForm
              data={{ quotation, setQuotation, quoteLocked }}
            />

            <ServiceFeeForm
              data={{ quotation, setQuotation, user, editQuotation }}
            />

            {editQuotation.username === user.username && (
              <>
                <hr />
                <PassengerForm
                  data={{ quotation, setQuotation, quoteLocked }}
                />
                <hr />
                <PaymentDetailsForm data={{ quotation, setQuotation }} />
              </>
            )}
          </Col>
          <Col md={viewComputation ? 6 : 12}>
            <ComputationView
              data={{
                application,
                editQuotation,
                updatedRates,
                computedView,
                customerView: false,
                details: quotation.details,
                bookingDetails: quotation.bookingDetails,
              }}
            />

            <NotificationForm
              data={{
                quotation,
                editQuotation,
                setQuotation,
                user,
                application,
                updatedRates,
                FORM,
                computedView,
              }}
            />

            <AdminForm
              data={{
                quotation,
                editQuotation,
                setQuotation,
                user,
                application,
              }}
            />
          </Col>
        </Row>
      )}
    </ModalForm>
  );
};

export default QuotationForm;
