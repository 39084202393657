import React from "react";
import {
  Button,
  FontAwesomeIcon,
  faEdit,
  Alert,
  Col,
  Collapse,
  Row,
  AppCard,
  Pagination,
  faTrash,
  
} from "../../../../traveltayo-ui-kit";
import { Droppable } from "react-drag-and-drop";
import { useApplicationData } from "../../../../Context/ApplicationContext";

const FolderContainer = ({ data }) => {
  const {
    selectedFolder,
    setEditFolder,
    setOpenFolderForm,
    setLoading,
    initLoading
  } = data;

  const {
    applications,
    getApplications,
    folders,
    folderizeApp,
    removeFolderizedApp,
  } = useApplicationData();

  return (
    <>
      <Droppable
        types={["app"]} // <= allowed drop types
        onDrop={(d) =>
          JSON.parse(d.app).folder_id === selectedFolder
            ? ""
            : setLoading(true) ||
              folderizeApp(
                {
                  folder_id: selectedFolder,
                  app_id: JSON.parse(d.app).id,
                },
                () => getApplications(() => initLoading())
              )
        }
        style={{ display: "inline-block", width: "100%" }}
      >
        <Collapse open={selectedFolder !== ""}>
          <Row
            style={{
              marginTop: 20,
              border: "solid 1px #E9ECEF",
              paddingTop: 20,
              paddingBottom: 20,
              borderRadius: ".625rem",
            }}
          >
            <Col md={12} style={{ paddingBottom: 10 }}>
              <Button
                onClick={() =>
                  setEditFolder(
                    folders.find((folder) => folder.id === selectedFolder)
                  ) || setOpenFolderForm(true)
                }
                theme="light"
              >
                {folders.find((folder) => folder.id === selectedFolder).name}{" "}
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </Col>
            <Col md={12}>
              <Row>
                <Pagination
                  list={applications
                    .sort((a, b) => a.priority - b.priority)
                    .filter((app) => app.folder_id === selectedFolder)}
                  Card={AppCard}
                  colCount={2}
                  cardType="card"
                />
              </Row>
            </Col>

            <Col md={12}></Col>
            <Col
              md={
                applications
                  .sort((a, b) => a.priority - b.priority)
                  .filter((app) => app.folder_id === selectedFolder).length !==
                0
                  ? 9
                  : 12
              }
            >
              <Alert
                theme="light"
                style={{
                  textAlign: "center",
                  margin: 0,
                  marginTop: 10,
                }}
              >
                Please Drag and Drop your apps to add in this folder.
              </Alert>
            </Col>

            {applications
              .sort((a, b) => a.priority - b.priority)
              .filter((app) => app.folder_id === selectedFolder).length !==
              0 && (
              <Col md={3}>
                <Droppable
                  types={["app"]} // <= allowed drop types
                  onDrop={(d) =>
                    setLoading(true) ||
                    removeFolderizedApp(
                      {
                        folder_id: selectedFolder,
                        app_id: JSON.parse(d.app).id,
                      },
                      () => getApplications(() => initLoading())
                    )
                  }
                  style={{
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  <Alert
                    theme="danger"
                    style={{
                      textAlign: "center",
                      margin: 0,
                      marginTop: 10,
                    }}
                  >
                    Trash Can <FontAwesomeIcon icon={faTrash} />
                  </Alert>
                </Droppable>
              </Col>
            )}
          </Row>
        </Collapse>
      </Droppable>
    </>
  );
};

export default FolderContainer;
