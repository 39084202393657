import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  PasswordInput,
  PageContainer,
  CardBody,
} from "../../../traveltayo-ui-kit";
import { useAppData } from "../../../Context/AppContext";
import { APP_LINK, APP_NAME, COLOR } from "../../../Hooks/utils/config";
import { useNavigate } from "@reach/router";
import Container from "./Container";
import HCaptcha from "./HCaptcha";

const ResetPassword = ({ token1 }) => {
  const { changePassword } = useAppData();
  const navigate = useNavigate();

  const [viewPass, setViewPass] = useState(false);
  const [form, setForm] = useState({
    email: "",
    otp: "",
    newPass: "",
    confirmPass: "",
  });

  useEffect(() => {
    let tokens = window.atob(token1).split("/");
    // console.log(tokens);
    if (tokens.length !== 0) {
      setForm({
        email: tokens[0],
        otp: tokens[1],
      });
    }
  }, [token1]);

  const [token, setToken] = useState(null);

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Password Reset",
        url: APP_LINK + "/reset-password",
      }}
      noAds={true}
    >
      <Container>
        <CardBody>
          <h3>Assigned New Password</h3>
          <p style={{ color: "#7D7D7D", fontSize: "12px" }}>
            Please save your password.
          </p>

          <PasswordInput
            viewPass={viewPass}
            setViewPass={setViewPass}
            value={form.newPass}
            onChange={(e) => setForm({ ...form, newPass: e.target.value })}
          />

          {form.newPass && (
            <PasswordInput
              viewPass={viewPass}
              setViewPass={setViewPass}
              value={form.confirmPass}
              onChange={(e) =>
                setForm({ ...form, confirmPass: e.target.value })
              }
            />
          )}

          {form.newPass === form.confirmPass && form.newPass ? (
            <FormGroup>
              {token ? (
                <Button
                  backgroundColor={COLOR.primary}
                  style={{ width: "100%", fontSize: "15px" }}
                  onClick={() => changePassword(form, navigate)}
                  disabled={
                    form.newPass !== form.confirmPass || form.newPass === ""
                  }
                >
                  Reset Password
                </Button>
              ) : (
                <HCaptcha setToken={setToken} />
              )}
            </FormGroup>
          ) : (
            ""
          )}
        </CardBody>
      </Container>
    </PageContainer>
  );
};

export default ResetPassword;
