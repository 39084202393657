import React from "react";
import { FormInput, faMoneyBill } from "../../../../../../traveltayo-ui-kit";
import { faPercent } from "@fortawesome/free-solid-svg-icons";

const ServiceFeeForm = ({ data }) => {
  const { quotation, setQuotation, user, editQuotation } = data;
  return (
    <>
      {editQuotation.username === user.username && (
        <>
          <hr />
          <h6>ADDITIONAL FEE & DISCOUNT</h6>
          <FormInput
            group={true}
            icon={faMoneyBill}
            label="ADDITIONAL SERVICE FEE"
            value={quotation.bookingDetails.serviceFee}
            type="number"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                bookingDetails: {
                  ...prev.bookingDetails,
                  serviceFee: e.target.value === "" ? 0 : e.target.value,
                },
              }))
            }
            // disabled={quoteLocked}
          />
          <FormInput
            group={true}
            icon={faPercent}
            label="ADDITIONAL DISCOUNT"
            value={quotation.bookingDetails.discount}
            type="number"
            onChange={(e) =>
              setQuotation((prev) => ({
                ...prev,
                bookingDetails: {
                  ...prev.bookingDetails,
                  discount: e.target.value === "" ? 0 : e.target.value,
                },
              }))
            }
            // disabled={quoteLocked}
          />
        </>
      )}
    </>
  );
};

export default ServiceFeeForm;
