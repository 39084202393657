import React from "react";
import { Badge, Col, ListGroupItem, Row } from "shards-react";
import {
  Button,
  FontAwesomeIcon,
  faEdit,
} from "../../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../../Hooks/utils/config";

const ListItem = ({ data, setOpenAppForm, setEditApplication }) => {
  const list = data;
  return (
    <ListGroupItem>
      <Row>
        <Col md={3} xs={3} sm={3}>
          {list.name}
        </Col>
        <Col md={3} xs={3} sm={3}>
          {list.contact_details}
        </Col>
        <Col md={4} xs={2} sm={2}>
          {list.destinations.length !== 0 &&
            list.destinations.map((destination) => (
              <Badge style={{ backgroundColor: COLOR.primary }}>
                {destination.value}
              </Badge>
            ))}
        </Col>
        <Col md={2} xs={2} sm={2}>
          <Button
            backgroundColor={COLOR.primary}
            onClick={() => setEditApplication(list) || setOpenAppForm(true)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default ListItem;
