import React from "react";
import Profile from "./Profile";
import { useAppData } from "../../../../Context/AppContext";
import { Fade, PageContainer } from "../../../../traveltayo-ui-kit";
import { APP_NAME, FEATURES } from "../../../../Hooks/utils/config";

const Index = () => {
  const { user } = useAppData();
  return (
    <>
      <PageContainer helmet={{ title: APP_NAME + " | Settings" }} noAds={FEATURES.NO_ADS}>
        {user && user.length !== 0 ? (
          <Fade>
            <div style={{ padding: 0 }}>
              <Profile />
            </div>
          </Fade>
        ) : (
          "Loading..."
        )}
      </PageContainer>
    </>
  );
};

export default Index;
