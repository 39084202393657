import React, { useEffect, useState } from "react";
import {
  Button,
  FormInput,
  FormTextarea,
  FormUploadFile,
  ListGroup,
  ListGroupItem,
  faAt,
  faCalendar,
  faUser,
  Alert,
  ModalForm,
} from "../../../../../traveltayo-ui-kit";
import StarRatings from "react-star-ratings";
import { useBookingsData } from "../../../../../Context/BookingsContext";
import { useAppData } from "../../../../../Context/AppContext";
import { COLOR } from "../../../../../Hooks/utils/config";
import dateFormat from "dateformat";

const ReviewForm = ({ data }) => {
  const {
    reviews,
    setReviews,
    product,
    merchant,
    loading,
    setLoading,
    sendReview,
    setSendReview,
  } = data;
  const { user, decryptMessage, setAlertStatus } = useAppData();
  const { getProductReviews, addReviews } = useBookingsData();

  const FORM = {
    content: "",
    rating: 5,
    imageProof: "",
    date_of_visit: new Date(),
    email: "",
    name: "",
  };
  const [review, setReview] = useState(FORM);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [success, setSuccess] = useState(false);

  return (
    <>
      {reviews.filter((review) => review.reviewer === user.username).length ===
        0 &&
      product.categories[0].slug === "destination" &&
      product.reviews_allowed ? (
        <ModalForm
          open={sendReview}
          toggle={() => {}}
          header={success ? "Successful!" : "Review Form"}
          bodyPadding="10px 20px"
          loading={loading || uploadLoading}
          approvedBTNProps={{
            // block: "block",
            display: review.content !== "" || review.imageProof !== "",
            label: "Send Review",
            onClick: () =>
              setLoading(true) ||
              addReviews(
                {
                  product_id: product.id,
                  review: `<p>${review.content}</p><p>${dateFormat(
                    review.date_of_visit,
                    "fullDate"
                  )}</p><img src="${review.imageProof}" width="100%"/>`,
                  reviewer: user.length !== 0 ? user.username : review.name,
                  reviewer_email:
                    user.length !== 0
                      ? decryptMessage(user.email)
                      : review.email,
                  rating: review.rating,
                  status: "hold",
                  merchantID: merchant,
                },
                () =>
                  setLoading(true) ||
                  getProductReviews(
                    {
                      id: product.id,
                      merchantID: merchant,
                    },
                    (res) =>
                      setReview(FORM) ||
                      setReviews(res.data) ||
                      setLoading(false) ||
                      setSuccess(true) ||
                      setAlertStatus(9)
                  )
              ),
          }}
          cancelBTNProps={{
            // block: "block",
            display: true,
            onClick: () => setSendReview(false) || setReview(FORM),
            label: success ? "Close" : "Cancel"
          }}
          deleteBTNProps={{
            display: false,
          }}
        >
          {success ? (
            <Alert />
          ) : (
            <>
              <label style={{ padding: 0, display: "block" }}>
                Please give us feedback
              </label>
              <StarRatings
                rating={review.rating}
                starRatedColor="#ffb400"
                changeRating={(e) => setReview({ ...review, rating: e })}
                numberOfStars={5}
                name="rating"
                starDimension="20px"
                starSpacing="5px"
              />
              <FormTextarea
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
                value={review.content}
                onChange={(e) =>
                  setReview({
                    ...review,
                    content: e.target.value,
                  })
                }
                placeholder="tell us something about your experience"
              />
              <FormUploadFile
                label="UPLOAD PROOF OF VISIT HERE - Images(JPG, JPEG, PNG)"
                action={(e) =>
                  setReview({
                    ...review,
                    imageProof: e,
                  })
                }
                loading={uploadLoading}
                setLoading={setUploadLoading}
                acceptFiles={""}
                uploadError={uploadError}
                setUploadError={setUploadError}
              />
              {user.length === 0 ? (
                <>
                  <FormInput
                    group={true}
                    icon={faUser}
                    label={"FULL NAME"}
                    type="text"
                    size={"md"}
                    placeholder="full name"
                    value={review.name}
                    onChange={(e) =>
                      setReview({
                        ...review,
                        name: e.target.value,
                      })
                    }
                  />
                  <FormInput
                    group={true}
                    icon={faAt}
                    label={"EMAIL ADDRESS"}
                    type="text"
                    size={"md"}
                    placeholder="email address"
                    value={review.email}
                    onChange={(e) =>
                      setReview({
                        ...review,
                        email: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                ""
              )}

              <FormInput
                group={true}
                icon={faCalendar}
                label={"DATE OF VISIT"}
                type="date"
                size={"md"}
                placeholderText="departure date"
                formClassName="formgroup-departure"
                className="formgroup-departure-input form-control"
                dateFormat="MMMM dd, yyyy"
                selected={review.date_of_visit}
                onChange={(e) =>
                  setReview({
                    ...review,
                    date_of_visit: e,
                  })
                }
              />
            </>
          )}
        </ModalForm>
      ) : (
        <></>
      )}
    </>
  );
};

export default ReviewForm;
