import React from "react";
import Explore from "./Explore";
import { BookingsContext } from "../../../Context/BookingsContext";
import { PlanContext } from "../../../Context/PlanContext";
import { ApplicationContext } from "../../../Context/ApplicationContext";

const Index = ({tag}) => {
  return (
    <BookingsContext>
      <PlanContext>
        <ApplicationContext>
          <Explore tag={tag}/>
        </ApplicationContext>
      </PlanContext>
    </BookingsContext>
  );
};

export default Index;
