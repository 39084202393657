import React, { useEffect } from "react";
import { PageContainer } from "../../../traveltayo-ui-kit";
import useQueryURL from "../../../Hooks/api/useQueryURL";
import { useAppData } from "../../../Context/AppContext";
import { APP_LINK, APP_NAME, FEATURES } from "../../../Hooks/utils/config";

const LoginQR = ({ data }) => {
  const { getQuery } = useQueryURL();

  const redirect = getQuery("redirect");
  const { user, loginByAccessKey, valitateToken } = useAppData();

  useEffect(() => {
    if (user.length !== 0) {
      window.location.replace("/");
    }
  }, [user]);

  useEffect(() => {
    if (valitateToken(data)) {
      loginByAccessKey({ accessKey: data }, redirect);
    } else {
      window.location.replace("/login");
    }
  }, [data]);

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Login by QR",
        url: APP_LINK + "/login",
      }}
      noAds={FEATURES.NO_ADS}
    >
      <h3>Hello Again!</h3>
      <p style={{ color: "#7D7D7D", fontSize: "12px" }}>
        Start planning your trips, Schedule your flights and Book your tickets
        in one place.
      </p>
    </PageContainer>
  );
};

export default LoginQR;
