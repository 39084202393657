import React, { useEffect, useState } from "react";
import Empty from "../../Basic/Empty";
import FileView from "../Plans/Components/FileView";
import BookingCard from "./Components/BookingCard";
import { useAppData } from "../../../Context/AppContext";
import { APP_LINK, APP_NAME, FEATURES } from "../../../Hooks/utils/config";
import {
  Loading,
  Card,
  CardBody,
  PageContainer,
} from "../../../traveltayo-ui-kit";
import { useBookingsData } from "../../../Context/BookingsContext";

const Bookings = () => {
  const { auth_login } = useAppData();

  const { getAllBookingsUser, userBookings, addReviews } =
    useBookingsData();

  const [selectedID, setSelectedID] = useState("");
  const [ticket, setTicket] = useState("");
  const [ticketID, setTicketID] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllBookingsUser(() =>
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    );
  }, [auth_login]);

  useEffect(() => {
    if (!FEATURES.BOOKING_SYSTEM) {
      window.location.replace("/");
    }
  }, []);

  return (
    <PageContainer
      helmet={{
        title: APP_NAME + " | Bookings",
        url: APP_LINK + "/bookings",
      }}
      noAds={FEATURES.NO_ADS}
    >
      <div style={{ padding: 0 }}>
        {ticket.length !== 0 && (
          <FileView
            data={{
              ticket: ticket,
              setTicket: setTicket,
            }}
          />
        )}

        {loading ? (
          <Loading />
        ) : (
          <>
            {userBookings.length === 0 ? (
              <Empty />
            ) : (
              <>
                <Card className="main-card">
                  <CardBody style={{ padding: 0 }}>
                    <BookingCard
                      // orders={orderData}
                      bookings={userBookings}
                      setSelectedID={setSelectedID}
                      selectedID={selectedID}
                      setTicket={setTicket}
                      label="My Bookings"
                      ticketID={ticketID}
                      setTicketID={setTicketID}
                      addReviews={addReviews}
                      getAllBookingsUser={getAllBookingsUser}
                    />
                  </CardBody>
                </Card>
              </>
            )}
          </>
        )}
      </div>
    </PageContainer>
  );
};

export default Bookings;
