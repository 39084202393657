import React from "react";
import {
  FormCheckbox,
  FormInput,
  FormSelect,
  faPlaneArrival,
  faPlaneDeparture,
} from "../../../../../../traveltayo-ui-kit";
import {
  faIdBadge,
  faPlaneCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

const FlightDetailsForm = ({ data }) => {
  const { quotation, setQuotation, quoteLocked, updatedRates } = data;
  return (
    <>
      {quotation.details.destination !== "" && updatedRates.length !== 0 && (
        <>
          <hr />
          <FormCheckbox
            toggle
            small
            onChange={() =>
              // quoteLocked
              //   ? ""
              //   :
              setQuotation((prev) => ({
                ...prev,
                details: {
                  ...prev.details,
                  flightDetails: {
                    ...prev.details.flightDetails,
                    flightFareIncluded:
                      !prev.details.flightDetails.flightFareIncluded,
                    departureTerminal: updatedRates[0].DEPARTURE_TERMINAL,
                    returnTerminal: updatedRates[0].RETURN_TERMINAL,
                  },
                },
              }))
            }
            checked={quotation.details.flightDetails.flightFareIncluded}
            // disabled={quoteLocked}
          >
            Is Ticket Fare Included? (Airfare/ Boat/ Ferry/Bus)
          </FormCheckbox>

          <FormCheckbox
            toggle
            small
            onChange={() =>
              // quoteLocked
              //   ? ""
              //   :
              setQuotation((prev) => ({
                ...prev,
                details: {
                  ...prev.details,
                  flightDetails: {
                    ...prev.details.flightDetails,
                    sameAirline: !prev.details.flightDetails.sameAirline,
                  },
                },
              }))
            }
            checked={quotation.details.flightDetails.sameAirline}
            // disabled={quoteLocked}
          >
            Booked Together with same airline?
          </FormCheckbox>

          {quotation.details.flightDetails.flightFareIncluded && (
            <>
              <FormSelect
                group={true}
                icon={faPlaneCircleCheck}
                label="DEPARTURE AIRLINE"
                value={quotation.details.flightDetails.departureSelectedAirline}
                onChange={(e) =>
                  setQuotation((prev) => ({
                    ...prev,
                    details: {
                      ...prev.details,
                      flightDetails: {
                        ...prev.details.flightDetails,
                        departureSelectedAirline: e.target.value,
                      },
                    },
                  }))
                }
                disabled={quoteLocked}
              >
                <option value="">Select One</option>
                {[
                  "Cebu Pacific",
                  "AirAsia",
                  "PAL",
                  "Sunlight Air",
                  "Royal Air",
                ].map((airline) => (
                  <option value={airline}>{airline}</option>
                ))}
              </FormSelect>

              <FormInput
                group={true}
                label="DEPARTURE DETAILS (TERMINAL - FARE COST - GUEST COUNT)"
                icon={faPlaneDeparture}
                inputs={[
                  {
                    placeholder: "Terminal Details",
                    type: "text",
                    className: "formgroup-left-input form-control",
                    value: quotation.details.flightDetails.departureTerminal,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            departureTerminal: e.target.value,
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                  {
                    placeholder: "Fare Cost",
                    type: "number",
                    className: "formgroup-middle-input form-control",
                    value: quotation.details.flightDetails.departureFare,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            departureFare: parseFloat(e.target.value),
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                  {
                    placeholder: "Guest Count",
                    type: "number",
                    className: "formgroup-right-input  form-control",
                    value: quotation.details.flightDetails.departureGuestCount,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            departureGuestCount: parseFloat(e.target.value),
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                ]}
              />

              <FormInput
                group={true}
                label="DEPARTURE FLIGHT DETAILS (CODE - ETD - ETA)"
                icon={faPlaneDeparture}
                inputs={[
                  {
                    placeholder: "Flight Code",
                    type: "text",
                    className: "formgroup-left-input form-control",
                    value: quotation.details.flightDetails.departureCODE,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            departureCODE: e.target.value,
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                  {
                    placeholder: "Departure Time",
                    type: "text",
                    className: "formgroup-middle-input form-control",
                    value: quotation.details.flightDetails.departureETD,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            departureETD: e.target.value,
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                  {
                    placeholder: "Arrival Time",
                    type: "text",
                    className: "formgroup-right-input  form-control",
                    value: quotation.details.flightDetails.departureETA,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            departureETA: e.target.value,
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                ]}
              />

              {/* Return Details */}

              <FormInput
                group={true}
                icon={faIdBadge}
                label="BOOKING PNR"
                value={quotation.details.flightDetails.departureBookingPNR}
                placeholder="booking pnr"
                onChange={(e) =>
                  setQuotation((prev) => ({
                    ...prev,
                    details: {
                      ...prev.details,
                      flightDetails: {
                        ...prev.details.flightDetails,
                        departureBookingPNR: e.target.value,
                      },
                    },
                  }))
                }
                // disabled={quoteLocked}
              />

              {!quotation.details.flightDetails.sameAirline && (
                <>
                  <FormSelect
                    group={true}
                    icon={faPlaneCircleCheck}
                    label="RETURN AIRLINE"
                    value={
                      quotation.details.flightDetails.returnSelectedAirline
                    }
                    onChange={(e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            returnSelectedAirline: e.target.value,
                          },
                        },
                      }))
                    }
                    disabled={quoteLocked}
                  >
                    <option value="">Select One</option>
                    {[
                      "Cebu Pacific",
                      "AirAsia",
                      "PAL",
                      "Sunlight Air",
                      "Royal Air",
                    ].map((airline) => (
                      <option value={airline}>{airline}</option>
                    ))}
                  </FormSelect>
                </>
              )}

              <FormInput
                group={true}
                label="RETURN DETAILS (TERMINAL - FARE COST - GUEST COUNT)"
                icon={faPlaneArrival}
                inputs={[
                  {
                    placeholder: "Terminal Details",
                    type: "text",
                    className: "formgroup-left-input form-control",
                    value: quotation.details.flightDetails.returnTerminal,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            returnTerminal: e.target.value,
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                  {
                    placeholder: "Fare Cost",
                    type: "number",
                    className: "formgroup-middle-input form-control",
                    value: quotation.details.flightDetails.returnFare,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            returnFare: parseFloat(e.target.value),
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                  {
                    placeholder: "Guest Count",
                    type: "number",
                    className: "formgroup-right-input  form-control",
                    value: quotation.details.flightDetails.returnGuestCount,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            returnGuestCount: parseFloat(e.target.value),
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                ]}
              />
              <FormInput
                group={true}
                label="DEPARTURE FLIGHT DETAILS (CODE - ETD - ETA)"
                icon={faPlaneDeparture}
                inputs={[
                  {
                    placeholder: "Flight Code",
                    type: "text",
                    className: "formgroup-left-input form-control",
                    value: quotation.details.flightDetails.returnCODE,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            returnCODE: e.target.value,
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                  {
                    placeholder: "Departure Time",
                    type: "text",
                    className: "formgroup-middle-input form-control",
                    value: quotation.details.flightDetails.returnETD,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            returnETD: e.target.value,
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                  {
                    placeholder: "Arrival Time",
                    type: "text",
                    className: "formgroup-right-input  form-control",
                    value: quotation.details.flightDetails.returnETA,
                    onChange: (e) =>
                      setQuotation((prev) => ({
                        ...prev,
                        details: {
                          ...prev.details,
                          flightDetails: {
                            ...prev.details.flightDetails,
                            returnETA: e.target.value,
                          },
                        },
                      })),
                    // disabled: quoteLocked,
                  },
                ]}
              />
              {!quotation.details.flightDetails.sameAirline && (
                <FormInput
                  group={true}
                  icon={faIdBadge}
                  label="BOOKING PNR"
                  value={quotation.details.flightDetails.returnBookingPNR}
                  placeholder="booking pnr"
                  onChange={(e) =>
                    setQuotation((prev) => ({
                      ...prev,
                      details: {
                        ...prev.details,
                        flightDetails: {
                          ...prev.details.flightDetails,
                          returnBookingPNR: e.target.value,
                        },
                      },
                    }))
                  }
                  // disabled={quoteLocked}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FlightDetailsForm;
