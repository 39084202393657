import React,{ useState } from "react";
import { Col, Row, CopyToClipboard } from "../../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../../Hooks/utils/config";

const SuccessDetails = (props) => {
  const { data } = props;

  const [copied, setCopied] = useState(false)
  return (
    <Row>
      <Col md={12} style={{ textAlign: "center" }}>
        <h5>Hoooray!!!!</h5>
        <p>
          Your booking is successful. Please wait for our email notification
          regarding on your booking and the payment link.
        </p>
        <CopyToClipboard text={data.number} onCopy={() => setCopied(true)}>
          <h5 style={{ border: `1px solid ${COLOR.primary}`, padding: 10 }}>
            {data.number} {copied && "(copied!)"}
          </h5>
        </CopyToClipboard>

        <span style={{ fontSize: "12px" }}>
          Please Copy and keep this Order ID, encase you want to follow up your
          booking.
        </span>
      </Col>
    </Row>
  );
};

export default SuccessDetails;
