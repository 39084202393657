import React, { useEffect, useState } from "react";
import Empty from "../../../../Basic/Empty";
import ReserverDetails from "../../Components/BookingForm/ReserverDetails";
import SuccessDetails from "../../Components/BookingForm/SuccessDetails";
import BookingWidget from "./BookingWidget";
import ReviewDetails from "./ReviewDetails";
import ScheduleList from "./ScheduleList";
import useQueryURL from "../../../../../Hooks/api/useQueryURL";
import { useAppData } from "../../../../../Context/AppContext";
import { COLOR, FEATURES } from "../../../../../Hooks/utils/config";
import {
  Button,
  Loading,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Alert,
  faArrowLeft,
  faArrowRight,
  faReply,
  FontAwesomeIcon,
  PageContainer,
} from "../../../../../traveltayo-ui-kit";
import { useNavigate } from "@reach/router";

const Index = () => {
  const { user, decryptMessage } = useAppData();
  const { getQuery } = useQueryURL();

  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    fetch("/api/biyaheko.json")
      .then((res) => res.json())
      .then((res) => setSchedules(res));
  }, []);

  const [destinations, setDestinations] = useState([]);
  const [origins, setOrigins] = useState([]);

  const [form, setForm] = useState({
    destination: getQuery("destination") || "",
    origin: getQuery("origin") || "",
    departure_date: new Date().setDate(new Date().getDate() + 2),
    return_date: new Date().setDate(new Date().getDate() + 2),
    roundTrip: getQuery("roundTrip") === "1" || false,
    schedules: {
      departure: [],
      return: [],
    },
  });

  const passengerForm = {
    id: (Math.random() + 2).toString(36).substring(7),
    username: "",
    full_name: "",
    gender: "",
    age: "",
    type: "regular",
    email: "",
    phone: "",
    passenger: true,
  };

  const [passengers, setPassenger] = useState([]);
  const [reserver, setReserver] = useState({ ...form, passenger: false });

  useEffect(() => {
    setPassenger([passengerForm]);
    if (user.length !== 0) {
      setReserver({
        id: (Math.random() + 2).toString(36).substring(7),
        username: user.username,
        full_name: decryptMessage(user.full_name),
        gender: "",
        age: "",
        type: "regular",
        email: decryptMessage(user.email),
        phone: decryptMessage(user.mobile_number),
        passenger: false,
      });
    } else {
      setReserver({
        id: (Math.random() + 2).toString(36).substring(7),
        username: "",
        full_name: "",
        gender: "",
        age: "",
        type: "regular",
        email: "",
        phone: "",
        passenger: false,
      });
    }
  }, [user]);

  useEffect(() => {
    setDestinations(
      schedules
        .filter((destination) => destination.origin === form.origin)
        .map((sched) => sched.destination)
        .filter(
          (v, i) =>
            schedules
              .filter((destination) => destination.origin === form.origin)
              .map((sched) => sched.destination)
              .indexOf(v) == i
        )
    );
  }, [schedules, form]);

  useEffect(() => {
    setOrigins(
      schedules
        .map((sched) => sched.origin)
        .filter(
          (v, i) => schedules.map((sched) => sched.origin).indexOf(v) == i
        )
    );
  }, [schedules]);

  const [loading, setLoading] = useState(false);
  const [departureResults, setDepartureResults] = useState([]);
  const [returnResults, setReturnResults] = useState([]);

  const navigate = useNavigate();

  const search = (form, url) => {
    setLoading(true);
    if (form.roundTrip) {
      setDepartureResults(
        schedules.filter(
          (sched) =>
            sched.origin === form.origin &&
            sched.destination === form.destination
        )
      );
      setReturnResults(
        schedules.filter(
          (sched) =>
            sched.origin === form.destination &&
            sched.destination === form.origin
        )
      );

      setForm({ ...form, schedules: { departure: [], return: [] } });
      setStep("");

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } else {
      setDepartureResults(
        schedules.filter(
          (sched) =>
            sched.origin === form.origin &&
            sched.destination === form.destination
        )
      );

      setReturnResults([]);
      setStep("");

      setForm({ ...form, schedules: { departure: [], return: [] } });
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }

    if (url) {
      navigate("/booking/ticket");
    }
  };

  useEffect(() => {
    if (getQuery("destination") && getQuery("origin")) {
      search(
        {
          ...form,
          destination: getQuery("destination"),
          origin: getQuery("origin"),
        },
        false
      );
    }
  }, [schedules]);

  const [sucessDetails, setSuccessDetails] = useState([]);
  const [copied, setCopied] = useState(false);
  const [step, setStep] = useState("");

  useEffect(() => {
    if(!FEATURES.BUS_BOOKING){
      window.location.replace("/")
    }
  })

  return (
    <PageContainer noAds={FEATURES.NO_ADS}>
      <Row>
        <Col
          md={12}
          style={{ textAlign: "center", padding: 10, color: COLOR.primary }}
        >
          <Alert
            theme="info"
            style={{ fontSize: "0.7rem", margin: 0, fontWeight: 500 }}
          >
            Note: You can book with or without an account. Please provide active
            contact details so we can send you the payment link for your
            booking. Our booking hours are from 9:00 AM to 6:00 PM only. All
            bookings made after those hours will be processed the next day.
          </Alert>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <>
          {sucessDetails.length === 0 ? (
            <div
              style={{
                padding: 10,
                height:
                  departureResults.length === 0 || sucessDetails.length !== 0
                    ? "80vh"
                    : "auto",
              }}
            >
              {!["review", "passenger-details"].includes(step) && (
                <BookingWidget
                  data={{
                    form,
                    setForm,
                    destinations,
                    origins,
                    schedules,
                    search,
                  }}
                />
              )}
              {departureResults.length === 0 && (
                <Card className="main-card" style={{ marginTop: 20 }}>
                  <CardBody>
                    <Empty>
                      Pleasse slect your destination and place of origin to
                      search for available schedule
                    </Empty>
                  </CardBody>
                </Card>
              )}

              <>
                {departureResults.length !== 0 && step === "" ? (
                  <ScheduleList
                    data={{
                      departureResults,
                      returnResults,
                      form,
                      setForm,
                      setStep,
                    }}
                  />
                ) : (
                  ""
                )}
              </>

              {departureResults.length !== 0 &&
              step === "passenger-details" &&
              (form.roundTrip
                ? form.schedules.departure.length !== 0 &&
                  form.schedules.return.length !== 0
                : form.schedules.departure.length !== 0) ? (
                <>
                  <Card className="main-card" style={{ marginTop: 20 }}>
                    <CardBody>
                      <ReserverDetails
                        data={{
                          reserver,
                          setReserver,
                          passengers,
                          setPassenger,
                          form: passengerForm,
                        }}
                      />
                    </CardBody>
                  </Card>
                  <Button
                    theme="light"
                    style={{ marginTop: 20, marginRight: 20 }}
                    size="md"
                    onClick={() => setStep("")}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> BACK
                  </Button>

                  {reserver.full_name !== "" &&
                    passengers[passengers.length - 1].age !== "" &&
                    passengers[passengers.length - 1].type !== "" &&
                    passengers[passengers.length - 1].gender !== "" && (
                      <Button
                        backgroundColor={COLOR.primary}
                        style={{ marginTop: 20 }}
                        size="md"
                        onClick={() => setStep("review")}
                      >
                        PROCEED <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    )}
                </>
              ) : (
                ""
              )}

              {step === "review" &&
              reserver.full_name !== "" &&
              reserver.email !== "" &&
              reserver.phone !== "" &&
              passengers[passengers.length - 1].age !== "" &&
              passengers[passengers.length - 1].type !== "" &&
              passengers[passengers.length - 1].gender !== "" &&
              (form.roundTrip
                ? form.schedules.departure.length !== 0 &&
                  form.schedules.return.length !== 0
                : form.schedules.departure.length !== 0) ? (
                <>
                  <Card className="main-card" style={{ marginTop: 20 }}>
                    <CardHeader>Review your Booking Details</CardHeader>
                    <CardBody>
                      <ReviewDetails
                        form={{
                          travelDetails: form,
                          passengers,
                          reserver,
                          sucessDetails,
                          setSuccessDetails,
                          loading,
                          setLoading,
                        }}
                      />
                    </CardBody>
                  </Card>
                  <Button
                    theme="light"
                    style={{ marginTop: 20 }}
                    size="md"
                    onClick={() => setStep("passenger-details")}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} /> BACK
                  </Button>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div
              style={{
                padding: 10,
                textAlign: "center",
                height: "60vh",
                paddingTop: "5%",
              }}
            >
              <SuccessDetails data={sucessDetails} />
              <Button
                style={{ margin: 20 }}
                theme="secondary"
                onClick={() => window.location.reload()}
              >
                Reload Page <FontAwesomeIcon icon={faReply} />
              </Button>
            </div>
          )}
        </>
      )}
    </PageContainer>
  );
};

export default Index;
