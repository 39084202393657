import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Col,
  Row,
  Loading,
  CardImg,
  PageContainer,
} from "../../../../traveltayo-ui-kit";
import { useNavigate } from "@reach/router";
import Empty from "../../../Basic/Empty";
import "../Styles/wordpress.css"
import { FEATURES } from "../../../../Hooks/utils/config";

const Index = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [media, setMedia] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`https://book.traveltayo.com/wp-json/wp/v2/posts`)
      .then(function (response) {
        // handle success
        setBlogs(response.data);

        axios
          .get(
            `https://book.traveltayo.com/wp-json/wp/v2/media?include=${response.data
              .map((d) => d.featured_media)
              .join()}`
          )
          .then(function (response) {
            // handle success
            setMedia(response.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch(function (error) {
            // handle error
            console.log("error");
          })
          .then(function () {
            // always executed
          });
      })
      .catch(function (error) {
        // handle error
        console.log("error");
      })
      .then(function () {
        // always executed
      });
  }, []);

  useEffect(() => {
    if (!FEATURES.BLOG_PAGE) {
      window.location.replace("/");
    }
  }, []);

  return (
    <PageContainer noAds={FEATURES.NO_ADS}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {blogs.length !== 0 && media.length !== 0 ? (
            <div style={{  padding: 0 }}>
              <Row style={{ margin: 0 }}>
                {blogs.map((blog) => (
                  <Col sm={6} xs={6} md={3} style={{ padding: 10}}>
                    <Card
                      className="main-card blog-card"
                      onClick={() => navigate(`/blog/${blog.slug}`)}
                      style={{ height: "100%" }}
                    >
                      {media.filter((m) => m.id === blog.featured_media)
                        .length !== 0 && (
                        <CardImg
                          src={
                            media.find((m) => m.id === blog.featured_media)
                              .source_url
                          }
                          top
                        />
                      )}
                      <CardBody style={{ padding: 10, fontSize: 12 }}>
                        {blog.title.rendered}
                      </CardBody>
                      <CardBody style={{ padding: 10 }}>
                        <p style={{ fontSize: "0.65rem", margin: 0 }}>
                          {blog.excerpt.rendered
                            .replace("<p>", "")
                            .replace("</p>")
                            .slice(0, 100)}
                          ... Read More
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Empty />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default Index;
