import React from "react";
import { Col, ModalForm, Row, faTrash } from "../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../Context/AppContext";
import { useBookingsData } from "../../../../Context/BookingsContext";
import { usePlanData } from "../../../../Context/PlanContext";
import ImageGallery from "react-image-gallery";

const FileView = ({ data }) => {
  const { ticket, setTicket, loading, setLoading, doneDeleting } = data;
  const { isOnline, setIsDeleting } = useAppData();
  const { removeUploadedBookings } = useBookingsData();
  const { plan } = usePlanData();

  return (
    <ModalForm
      open={ticket.length !== 0}
      className="view-ticket"
      toggle={() => ""}
      bodyPadding="0px"
      bodyStyles={{ height: "70vh" }}
      approvedBTNProps={{
        display: false,
      }}
      loading={loading}
      cancelBTNProps={{
        display: true,
        size: "md",
        label: "Close",
        onClick: () => setTicket([]),
      }}
      deleteBTNProps={{
        display:
          ticket.type === "uploaded_booking" &&
          isOnline &&
          plan.credential !== "viewer",
        icon: faTrash,
        theme: "danger",
        onClick: () =>
          setLoading(true) ||
          removeUploadedBookings(
            { ticket_id: ticket.id1, reference: plan.reference },
            () =>
              doneDeleting() ||
              setLoading(false) ||
              setIsDeleting(false) ||
              setTicket([])
          ),
      }}
    >
      {["data:image/jpeg", "data:image/jpg", "data:image/png"].includes(
        ticket.ticket.split(";")[0]
      ) ? (
        <Row>
          <Col md={7} style={{ padding: 10 }}>
            <ImageGallery
              items={[
                {
                  original: ticket.ticket,
                  thumbnail: ticket.ticket,
                },
              ]}
              showPlayButton={false}
              showThumbnails={false}
            />
          </Col>
        </Row>
      ) : (
        // <img src={ticket.ticket} style={{ with: "100%" }} />
        <iframe
          src={ticket.ticket}
          width="100%"
          height="98%"
          style={{
            border: "0px solid #fff",
            borderRadius: "0.365rem 0.365rem 0 0",
          }}
        />
      )}
    </ModalForm>
  );
};

export default FileView;
