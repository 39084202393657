import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import dateFormat from "dateformat";
import {
  Row,
  Col,
  Fade,
  ListGroup,
  ListGroupItem,
  Button,
} from "../../../../../traveltayo-ui-kit";
import { useNavigate } from "@reach/router";
import useCustomFunctions from "../../../../../Hooks/api/useCustomFunctions";

const BookingDetails = (props) => {
  const { product, travelDetails } = props.data;
  const [reviews, setReviews] = useState([]);

  const { numberWithCommas } = useCustomFunctions();

  const navigate = useNavigate();

  return (
    <Fade>
      <Row>
        {travelDetails.variant.length !== 0 ? (
          <>
            <Col md={12}>
              <h6>Product Details</h6>
            </Col>
            <Col md={12}>
              <p style={{ margin: 0 }}>
                <b>Selected Package:</b> {travelDetails.departureSchedule}
              </p>
              <p style={{ margin: 0 }}>
                <b>Price:</b> &#8369;{" "}
                {numberWithCommas(travelDetails.variant.price)} per person
              </p>
              {travelDetails.variant.manage_stock && (
                <p style={{ margin: 0 }}>
                  <b>Slots Left:</b> &#8369;{" "}
                  {travelDetails.variant.stock_quantity} slots
                </p>
              )}
              <p>
                <b>Minimun number of guest:</b>{" "}
                {travelDetails.variant.download_limit} person/s
              </p>
            </Col>
            <Col md={12}>
              {travelDetails.variant.description !== "" && (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: travelDetails.variant.description,
                    }}
                  />
                  <hr />
                </>
              )}
            </Col>
          </>
        ) : (
          <Col md={12}>Please select the number of guest</Col>
        )}

        <Col md={12} style={{ marginBottom: 10 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: product.description,
            }}
          />
          {/* <Button
            theme="light"
            onClick={() => navigate(`/explore/offers/${product.slug}`)}
            style={{ display: "block", marginTop: 20 }}
          >
            View Product Page
          </Button> */}
        </Col>
        <Col md={12}>
          <ListGroup>
            {reviews.map((review) => (
              <ListGroupItem>
                <p className="event-title-label">@{review.reviewer}</p>
                <StarRatings
                  rating={review.rating}
                  starRatedColor="#ffb400"
                  numberOfStars={5}
                  name="rating"
                  starDimension="20px"
                  starSpacing="5px"
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: review.review,
                  }}
                />
                <p className="event-title-label">
                  {dateFormat(new Date(review.date_created), "fullDate")}
                </p>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Fade>
  );
};

export default BookingDetails;
