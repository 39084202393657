import React, { useEffect, useState } from "react";
import {
  Col,
  FormCheckbox,
  FormGroup,
  Row,
  FormInput,
  FormUpload,
  ModalForm,
  faSave,
  faTrash,
} from "../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../Context/AppContext";
import { useApplicationData } from "../../../../Context/ApplicationContext";

const NewAppForm = ({
  openAppForm,
  setOpenAppForm,
  editApplication,
  setEditApplication,
}) => {
  const { user } = useAppData();

  const { getApplications, addNewApp, updateAppByID, deleteAppByID } =
    useApplicationData();

  let FORM = {
    name: "",
    description: "",
    link: "",
    brand: "",
    rate_link: "",
    payment_options: "",
    contact_details: "",
    terms: "",
    priority: 1,
    isQuotationMaker: true,
  };

  const [newAppForm, setNewAppForm] = useState(FORM);
  const [loading, setLoading] = useState(false);

  const APPS_FILTERS = [
    "Hotels",
    "Flights",
    "Activities",
    "Cars",
    "Buses",
    "Boats",
    "Ferries",
    "Tours",
    "Trains",
    "Insurances",
    "Vans",
    "Attractions",
    "Travel Reviews",
    "Weather Updates",
  ];

  const [services, setServices] = useState([]);

  useEffect(() => {
    if (editApplication.length !== 0) {
      setNewAppForm(editApplication);
      setServices(editApplication.offers);
    }
  }, [editApplication, openAppForm]);

  useEffect(() => {
    setNewAppForm({ ...newAppForm, description: services.join() });
  }, [services]);

  useEffect(() => {
    setNewAppForm((prev) => ({
      ...prev,
      isQuotationMaker: user.role === "agent",
    }));
  }, [user]);

  return (
    <>
      <ModalForm
        open={openAppForm}
        toggle={() => {}}
        header=""
        bodyPadding="10px 20px"
        loading={loading}
        size={
          newAppForm.isQuotationMaker || newAppForm.isQuotationMaker === 1
            ? "lg"
            : "md"
        }
        approvedBTNProps={{
          // block: "block",
          display: newAppForm.name !== "",
          size: "sm",
          iconPosition: "left",
          icon: faSave,
          label: newAppForm.id ? "Update" : "Save",
          onClick: () =>
            setLoading(true) || newAppForm.id
              ? updateAppByID(
                  newAppForm,
                  getApplications(
                    () =>
                      setOpenAppForm(false) ||
                      setLoading(false) ||
                      setNewAppForm(FORM)
                  )
                )
              : addNewApp(newAppForm, () =>
                  getApplications(
                    () =>
                      setOpenAppForm(false) ||
                      setLoading(false) ||
                      setNewAppForm(FORM)
                  )
                ),
        }}
        cancelBTNProps={{
          // block: "block",
          display: true,
          size: "sm",
          onClick: () =>
            setNewAppForm(FORM) ||
            setEditApplication([]) ||
            setOpenAppForm(false),
        }}
        deleteBTNProps={{
          display: newAppForm.id,
          icon: faTrash,
          theme: "danger",
          onClick: () =>
            setLoading(true) ||
            deleteAppByID(newAppForm, () => window.location.replace("/apps")),
        }}
      >
        {loading ? (
          "Please wait while we save your changes.."
        ) : (
          <Row>
            <Col md={newAppForm.isQuotationMaker ? 6 : 12}>
              <FormInput
                label="NAME"
                value={newAppForm.name}
                onChange={(e) =>
                  setNewAppForm({
                    ...newAppForm,
                    name: e.target.value,
                    link: newAppForm.isQuotationMaker
                      ? e.target.value
                          .toLowerCase()
                          .replace(/\s+/g, "-")
                          .replace(/[\W]/g, "")
                      : newAppForm.link,

                  })
                }
                placeholder="enter app name"
              />

              {user.role === "admin" && (
                <FormGroup>
                  <FormCheckbox
                    toggle
                    small
                    checked={newAppForm.isQuotationMaker}
                    onChange={() =>
                      setNewAppForm({
                        ...newAppForm,
                        isQuotationMaker: !newAppForm.isQuotationMaker,
                      })
                    }
                  >
                    This is a quotation maker app
                  </FormCheckbox>
                </FormGroup>
              )}

              {newAppForm.isQuotationMaker ? (
                <FormInput
                  label="DESCRIPTION"
                  value={newAppForm.description}
                  onChange={(e) =>
                    setNewAppForm({
                      ...newAppForm,
                      description: e.target.value,
                    })
                  }
                  placeholder="enter short description"
                />
              ) : (
                <FormGroup>
                  <label style={{ display: "block" }}>
                    Services offer by this app:
                  </label>
                  {APPS_FILTERS.map((FILTER) => (
                    <FormCheckbox
                      inline
                      checked={services.find((f) => f === FILTER)}
                      onChange={() =>
                        setServices(
                          services.find((f) => f === FILTER)
                            ? services.filter((f) => f !== FILTER)
                            : [...services, FILTER]
                        )
                      }
                    >
                      {FILTER}
                    </FormCheckbox>
                  ))}
                </FormGroup>
              )}

              {!newAppForm.isQuotationMaker ? (
                <FormInput
                  label="WEBSITE LINK"
                  value={newAppForm.link}
                  onChange={(e) =>
                    setNewAppForm({ ...newAppForm, link: e.target.value })
                  }
                  placeholder="enter redirect website link"
                />
              ) : (
                <FormInput
                  label="GOOGLE SHEET RATES LINK"
                  value={newAppForm.rate_link}
                  onChange={(e) =>
                    setNewAppForm({ ...newAppForm, rate_link: e.target.value })
                  }
                  placeholder="enter rates link using google sheet"
                />
              )}

              <FormInput
                label="BRAND NAME"
                value={newAppForm.brand}
                onChange={(e) =>
                  setNewAppForm({ ...newAppForm, brand: e.target.value })
                }
                placeholder="enter brand name"
              />
              <FormInput
                label="LOGO LINK"
                value={newAppForm.logo}
                onChange={(e) =>
                  setNewAppForm({ ...newAppForm, logo: e.target.value })
                }
                placeholder="enter logo link"
              />
              {/* <FormUpload
                label="LOGO"
                value={newAppForm.logo}
                placeholder="enter folder name"
                action={(e) => setNewAppForm({ ...newAppForm, logo: e })}
                loading={loading}
                setLoading={setLoading}
                acceptFiles={"image/*"}
              /> */}

              {/* {newAppForm.isQuotationMaker && (
                <FormUpload
                  label="PAYMENT OPTION IMAGE"
                  value={newAppForm.payment_options}
                  placeholder="enter folder name"
                  action={(e) =>
                    setNewAppForm({ ...newAppForm, payment_options: e })
                  }
                  loading={loading}
                  setLoading={setLoading}
                  acceptFiles={"image/*"}
                />
              )} */}
            </Col>
            {newAppForm.isQuotationMaker && (
              <Col md={6}>
                <FormInput
                  label="CONTACT DETAILS"
                  inputType="textarea"
                  placeholder="enter your agency contact details"
                  value={newAppForm.contact_details}
                  onChange={(e) =>
                    setNewAppForm({
                      ...newAppForm,
                      contact_details: e.target.value,
                    })
                  }
                  rows={4}
                />
                <FormInput
                  label="TERMS & CONDITIONS"
                  inputType="textarea"
                  placeholder="enter your terms & conditions separated by lines"
                  value={newAppForm.terms}
                  onChange={(e) =>
                    setNewAppForm({ ...newAppForm, terms: e.target.value })
                  }
                  rows={6}
                />
                {newAppForm.logo && (
                  <img
                    src={newAppForm.logo}
                    style={{ height: "30px", marginBottom: 20 }}
                  />
                )}
                {/* {newAppForm.payment_options && (
                  <img
                    src={newAppForm.payment_options}
                    style={{ width: "100%" }}
                  />
                )} */}
              </Col>
            )}
            {user.role === "admin" && !newAppForm.id && (
              <Col md={12} style={{ marginTop: 10 }}>
                <FormGroup>
                  <FormCheckbox
                    checked={newAppForm.shareToAll}
                    onChange={() =>
                      setNewAppForm({
                        ...newAppForm,
                        shareToAll: !newAppForm.shareToAll,
                      })
                    }
                  >
                    Share this app to all users
                  </FormCheckbox>
                </FormGroup>
              </Col>
            )}

            <Col md={12}>
              {newAppForm.logo && !newAppForm.isQuotationMaker && (
                <img
                  src={newAppForm.logo}
                  style={{ height: "30px", marginTop: 20 }}
                />
              )}
            </Col>
          </Row>
        )}
      </ModalForm>
    </>
  );
};

export default NewAppForm;
