import React from "react";
import dateFormat from "dateformat";
import useCustomFunctions from "../../../../../Hooks/api/useCustomFunctions";
import { Col, Row } from "../../../../../traveltayo-ui-kit";

const ReviewDetails = (props) => {
  const { product, reserver, passengers, travelDetails } = props.data;

  const { numberWithCommas } = useCustomFunctions();

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col md={12}>
          <h6>Reserver Details</h6>
        </Col>
        <Col md={4}>{reserver.full_name}</Col>
        <Col md={4}>{reserver.email}</Col>
        <Col md={4}>{reserver.phone}</Col>
      </Row>
      {travelDetails.departureSchedule !== "GROUP(10 pax or more)" && (
        <Row style={{ marginBottom: 20 }}>
          <Col md={12}>
            <h6>Guest Details</h6>
          </Col>
          {passengers.map((passenger, index) => (
            <>
              <Col md={3}>Guest #{index + 1}</Col>
              <Col md={3}>{passenger.full_name}</Col>
              <Col md={1}>{passenger.age}</Col>
              <Col md={1}>{passenger.gender[0].toUpperCase()}</Col>
              <Col md={2}>{passenger.type.toUpperCase()}</Col>
            </>
          ))}
        </Row>
      )}

      <Row style={{ marginBottom: 20 }}>
        <Col md={12}>
          <h6>Travel Details</h6>
        </Col>
        {["tours"].includes(product.categories[0].slug) ? (
          <>
            <Col md={12}>
              <Row>
                <Col md={6} style={{ fontWeight: "500" }}>
                  Tour Type:
                </Col>
                <Col md={6}>{travelDetails.departureSchedule}</Col>
                <Col md={6} style={{ fontWeight: "500" }}>
                  Departure Date:
                </Col>
                <Col md={6}>
                  {dateFormat(travelDetails.departure, "fullDate")}
                </Col>
                <Col md={6} style={{ fontWeight: "500" }}>
                  Guest Count:
                </Col>
                <Col md={6}>{passengers.length}</Col>

                <Col md={6} style={{ fontWeight: "500" }}>
                  Price:
                </Col>
                <Col md={6}>
                  &#8369;{numberWithCommas(travelDetails.variant.price)}
                </Col>

                <Col md={6} style={{ fontWeight: "500" }}>
                  Total:
                </Col>
                <Col md={6}>
                  &#8369;
                  {numberWithCommas(
                    travelDetails.variant.price * passengers.length
                  )}
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <Col md={12}>
            <Row>
              <Col md={6} style={{ fontWeight: "500" }}>
                Departure Schedule:
              </Col>
              <Col md={6}>{travelDetails.departureSchedule}</Col>
              <Col md={6} style={{ fontWeight: "500" }}>
                Departure Date:
              </Col>
              <Col md={6}>
                {dateFormat(travelDetails.departure, "fullDate")}
              </Col>

              {travelDetails.roundTrip && (
                <>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    Round Trip?:
                  </Col>
                  <Col md={6}>{travelDetails.roundTrip ? "YES" : "NO"}</Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    Return Schedule:
                  </Col>
                  <Col md={6}>{travelDetails.returnSchedule}</Col>
                  <Col md={6} style={{ fontWeight: "500" }}>
                    Return Date:
                  </Col>
                  <Col md={6}>
                    {dateFormat(travelDetails.return, "fullDate")}
                  </Col>
                </>
              )}

              <Col md={6} style={{ fontWeight: "500" }}>
                Guests Count:
              </Col>
              <Col md={6}>
                {travelDetails.roundTrip
                  ? passengers.length * 2
                  : passengers.length}
              </Col>

              <Col md={6} style={{ fontWeight: "500" }}>
                Price:
              </Col>
              <Col md={6}>&#8369; {numberWithCommas(product.price)}</Col>

              <Col md={6} style={{ fontWeight: "500" }}>
                Total:
              </Col>
              <Col md={6}>
                &#8369;{" "}
                {numberWithCommas(
                  product.price *
                    (travelDetails.roundTrip
                      ? passengers.length * 2
                      : passengers.length)
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <Row style={{ marginBottom: 20 }}>
        <Col md={12}>
          <h6>Product Details</h6>
        </Col>
        <Col md={12}>
          <div
            dangerouslySetInnerHTML={{
              __html: product.description,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ReviewDetails;
