import React from 'react'
import { BookingsContext } from '../../../../Context/BookingsContext'
import { PlanContext } from '../../../../Context/PlanContext'
import Notifications from './Notifications'

const Index = () => {
  return (
    <Notifications/>
  )
}

export default Index