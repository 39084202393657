import React, { useState } from "react";
import QRCode from "react-qr-code";
import dateFormat from "dateformat";
import Empty from "../../../Basic/Empty";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import { useAppData } from "../../../../Context/AppContext";
import {
  Button,
  FormInput,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormInput as Input,
  ListGroup,
  ListGroupItem,
  faAt,
  faEnvelope,
  faKey,
  faUser,
  CopyToClipboard,
  faCopy,
  FontAwesomeIcon,
} from "../../../../traveltayo-ui-kit";
import { FEATURES } from "../../../../Hooks/utils/config";

const Profile = () => {
  const { user, decryptMessage, screenWidth, walletTransactions } =
    useAppData();

  const { numberWithCommas } = useCustomFunctions();

  const [copied, setCopied] = useState(false);

  return (
    <Row>
      <Col md={12} style={{ marginBottom: 20 }}>
        <Card className="main-card">
          <CardHeader>Profile</CardHeader>
          <CardBody>
            <FormInput
              group={true}
              icon={faAt}
              label="USERNAME"
              placeholder="enter your username"
              value={user.username}
              disabled
            />
            <FormInput
              group={true}
              icon={faUser}
              label="FULL NAME"
              placeholder="enter your full name"
              value={decryptMessage(user.full_name)}
              disabled
            />
            <FormInput
              group={true}
              icon={faEnvelope}
              label="EMAIL ADDRESS"
              placeholder="enter your email address"
              value={decryptMessage(user.email)}
              disabled
            />

            {FEATURES.ALLOW_ACCESS_KEY && (
              <Input
                group={true}
                icon={faKey}
                type="password"
                label="ACCESS KEY"
                placeholder="enter your email address"
                value={user.accessKey}
                inputBTN={
                  <CopyToClipboard
                    text={user.accessKey}
                    onCopy={() => setCopied(true)}
                  >
                    <Button theme="secondary">
                      {copied ? "Copied!" : "Copy"}
                    </Button>
                  </CopyToClipboard>
                }
              />
            )}
          </CardBody>
        </Card>

        {FEATURES.TRAVEL_POINTS && (
          <Card className="main-card" style={{ marginTop: 10 }}>
            <CardHeader>Travel Points History</CardHeader>
            <CardBody>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col md={2} xs={2} sm={2}>
                      Trans ID
                    </Col>
                    <Col md={3} xs={3} sm={3}>
                      Amount
                    </Col>
                    <Col md={4} xs={4} sm={4}>
                      Details
                    </Col>
                    <Col md={3} xs={3} sm={3}>
                      Date
                    </Col>
                  </Row>
                </ListGroupItem>
                {walletTransactions.length !== 0 ? (
                  walletTransactions.map((wallet) => (
                    <ListGroupItem>
                      <Row>
                        <Col md={2} xs={2} sm={2}>
                          {wallet.transaction_id}
                        </Col>
                        <Col
                          md={3}
                          xs={3}
                          sm={3}
                          style={{
                            color: wallet.type === "debit" ? "red" : "",
                          }}
                        >
                          {wallet.amount}
                        </Col>
                        <Col md={4} xs={4} sm={4} style={{ fontSize: "10px" }}>
                          {wallet.details}
                        </Col>
                        <Col md={3} xs={3} sm={3}>
                          {dateFormat(new Date(wallet.date), "longDate")}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))
                ) : (
                  <ListGroupItem>
                    <Empty />
                  </ListGroupItem>
                )}
              </ListGroup>
            </CardBody>
          </Card>
        )}
      </Col>
      <Col md={6}>
        {FEATURES.TRAVEL_POINTS && (
          <Card
            className="main-card"
            style={{ marginTop: screenWidth ? 10 : 0, marginBottom: 10 }}
          >
            {/* <CardHeader>Travel Points</CardHeader> */}
            <CardBody style={{ textAlign: "right" }}>
              <h5>
                {numberWithCommas(
                  walletTransactions.reduce((a, b) => a + b.amount, 0)
                )}{" "}
                travel points
              </h5>
            </CardBody>
            {/* <CardFooter>
            <p style={{ fontSize: "10px", lineHeight: "normal", margin: 0 }}>
              You can earn travel points from completed & submitted travel
              plans, destination reviews and bookings
            </p>
          </CardFooter> */}
          </Card>
        )}

        {FEATURES.ALLOW_ACCESS_KEY && (
          <Card
            className="main-card"
            style={{ marginTop: screenWidth ? 10 : 0 }}
          >
            <CardBody style={{ textAlign: "center" }}>
              <QRCode
                value={`https://app.traveltayo.com/login/qr/${user.accessKey}`}
                level={"L"}
                fgColor="#055258"
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              />
              <label
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  margin: "5px auto",
                  display: "block",
                }}
              >
                Please SCAN the QRCode to login
              </label>
            </CardBody>
          </Card>
        )}
      </Col>
      <Col md={6}>
        {FEATURES.TRAVEL_POINTS && (
          <Card
            className="main-card"
            style={{ marginTop: screenWidth ? 10 : 0, marginBottom: 10 }}
          >
            {/* <CardHeader>Travel Points</CardHeader> */}
            <CardBody style={{ textAlign: "right" }}>
              <h5>
                {numberWithCommas(
                  walletTransactions.reduce((a, b) => a + b.amount, 0)
                )}{" "}
                travel points
              </h5>
            </CardBody>
            {/* <CardFooter>
            <p style={{ fontSize: "10px", lineHeight: "normal", margin: 0 }}>
              You can earn travel points from completed & submitted travel
              plans, destination reviews and bookings
            </p>
          </CardFooter> */}
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default Profile;
