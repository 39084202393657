import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import {
  Button,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from "../../../../../traveltayo-ui-kit";
import Empty from "../../../../Basic/Empty";
import { COLOR } from "../../../../../Hooks/utils/config";
import { useAppData } from "../../../../../Context/AppContext";

const Reviews = ({ data }) => {
  const { sendReview, setSendReview, reviews } = data;
  const { user} = useAppData()
  const [viewMore, setViewMore] = useState("");
  return (
    <ListGroup className="card main-card">
      <ListGroupItem>
        <ListGroupItemHeading style={{ fontWeight: 12 }}>
          Reviews
        </ListGroupItemHeading>
      </ListGroupItem>
      {reviews.length === 0 ? (
        <ListGroupItem>
          <Empty>
            {user.length === 0 ? (
              <>
                <Button
                  backgroundColor={COLOR.primary}
                  style={{ marginTop: 10 }}
                  onClick={() =>
                    window.location.replace(
                      `/login?redirect=${window.location.href}`
                    )
                  }
                  size="md"
                  block
                >
                  Login to send your review about this place
                </Button>
              </>
            ) : (
              <>
                {reviews.filter((review) => review.reviewer === user.username)
                  .length ===
                  0 &&(
                    <Button
                      backgroundColor={COLOR.primary}
                      style={{ marginTop: 10 }}
                      onClick={() => setSendReview(true)}
                      size="md"
                      block
                    >
                      Leave a review
                    </Button>
                  )}
              </>
            )}
          </Empty>
        </ListGroupItem>
      ) : (
        <>
          {reviews.map((review) => (
            <ListGroupItem>
              <Row
                style={{
                  overflow: "auto",
                  position: "sticky",
                  paddingTop: 0,
                }}
              >
                <Col md={12} style={{ padding: 0, paddingRight: 5 }}>
                  <StarRatings
                    rating={review.rating}
                    starRatedColor="#ffb400"
                    numberOfStars={5}
                    name="rating"
                    starDimension="15px"
                    starSpacing="3px"
                  />
                  <p className="event-title-label">@{review.reviewer} </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${review.review.slice(
                        0,
                        viewMore === review.id ? review.review.length : 100
                      )} ${viewMore === review.id ? "" : "..."}`,
                    }}
                    className="review-content"
                  />
                  <Button
                    theme="light"
                    size="sm"
                    onClick={() =>
                      setViewMore(viewMore === review.id ? "" : review.id)
                    }
                  >
                    {viewMore === review.id ? "Hide" : "Read More"}
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          ))}
        </>
      )}
    </ListGroup>
  );
};

export default Reviews;
