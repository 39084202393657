import {
  faClose,
  faEnvelope,
  faHashtag,
  faList,
  faLocation,
  faMapLocationDot,
  faPhone,
  faPlus,
  faSave,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  FontAwesomeIcon,
  FormGroup,
  FormInput,
  ModalForm,
} from "../../../../../traveltayo-ui-kit";
import { COLOR } from "../../../../../Hooks/utils/config";
import { useApplicationData } from "../../../../../Context/ApplicationContext";

const CustomerForm = ({ data }) => {
  const {
    openCustomerForm,
    setOpenCustomerForm,
    editCustomerData,
    setEditCustomerData,
  } = data;

  const {
    application,
    updateAgencyDetailsByID,
    getApplicationByID,
  } = useApplicationData();

  const FORM = {
    id: (Math.random() + 1).toString(36).substring(7),
    name: "",
    email: "",
    contact_details: "",
    destinations: [],
    destination: "",
    sources: [],
    source: ""
  };

  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState(FORM);

  const [customers, setCustomers] = useState([]);
  useEffect(() => {
    if (application.length !== 0) {
      setCustomers(application.customers);
    }
  }, [application]);

  useEffect(() => {
    if (editCustomerData.length !== 0) {
      setCustomerData((prev) => ({ ...prev, ...editCustomerData }));
    }
  }, [editCustomerData]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <ModalForm
      open={openCustomerForm}
      toggle={() => {}}
      header={`Customer Details - ID#: ${customerData.id}`}
      bodyPadding="10px 20px"
      loading={loading}
      size={"md"}
      approvedBTNProps={{
        // block: "block",
        display: true,
        size: "sm",
        iconPosition: "left",
        icon: faSave,
        label: editCustomerData.length !== 0 ? "Update" : "Save",
        onClick: () =>
          setLoading(true) ||
          updateAgencyDetailsByID(
            {
              app_id: application.id,
              agency_id: application.agency_id,
              customers: [
                ...customers.filter(
                  (customer) => customer.id !== customerData.id
                ),
                customerData,
              ],
            },
            () =>
              getApplicationByID({ id: application.id }, () =>
                setTimeout(() => {
                  setLoading(false);
                  setOpenCustomerForm(false);
                  setEditCustomerData([]);
                  setCustomerData(FORM);
                }, 1000)
              )
          ),
      }}
      cancelBTNProps={{
        // block: "block",
        display: true,
        size: "sm",
        onClick: () =>
          setOpenCustomerForm(false) ||
          setEditCustomerData([]) ||
          setCustomerData(FORM),
      }}
      deleteBTNProps={{
        display: true,
        onClick: () =>
          setLoading(true) ||
          updateAgencyDetailsByID(
            {
              app_id: application.id,
              agency_id: application.agency_id,
              customers: customers.filter(
                (customer) => customer.id !== customerData.id
              ),
            },
            () =>
              getApplicationByID({ id: application.id }, () =>
                setTimeout(() => {
                  setLoading(false);
                  setOpenCustomerForm(false);
                  setEditCustomerData([]);
                  setCustomerData(FORM);
                }, 1000)
              )
          ),
      }}
    >
      <FormInput
        group={true}
        icon={faUser}
        label="CUSTOMER NAME"
        value={customerData.name}
        placeholder="customer name"
        // disabled={application.owner !== user.username}
        onChange={(e) =>
          setCustomerData((prev) => ({ ...prev, name: e.target.value }))
        }
      />

      <FormInput
        group={true}
        icon={faPhone}
        label="CUSTOMER CONTACT DETAILS"
        value={customerData.contact_details}
        placeholder="customer contact details"
        // disabled={application.owner !== user.username}
        onChange={(e) =>
          setCustomerData((prev) => ({
            ...prev,
            contact_details: e.target.value,
          }))
        }
      />

      <FormInput
        group={true}
        icon={faEnvelope}
        label="CUSTOMER EMAIL ADDRESS"
        value={customerData.email}
        placeholder="customer email address"
        // disabled={application.owner !== user.username}
        onChange={(e) =>
          setCustomerData((prev) => ({ ...prev, email: e.target.value }))
        }
      />

      <FormGroup>
        <FormInput
          group={true}
          icon={faMapLocationDot}
          label="DESTINATION"
          value={customerData.destination}
          placeholder="destination name"
          // disabled={application.owner !== user.username}
          onChange={(e) =>
            setCustomerData((prev) => ({
              ...prev,
              destination: e.target.value,
            }))
          }
          inputBTN={
            <Button
              onClick={() =>
                setCustomerData((prev) => ({
                  ...prev,
                  destinations: [
                    ...prev.destinations,
                    {
                      id: (Math.random() + 1).toString(36).substring(7),
                      value: customerData.destination,
                    },
                  ],
                  destination: "",
                }))
              }
              backgroundColor={COLOR.primary}
              disabled={loading || customerData.destination === ""}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          }
        />

        {customerData.destinations.map((destination) => (
          <Badge
            onClick={() =>
              setCustomerData((prev) => ({
                ...prev,
                destinations: prev.destinations.filter(
                  (d) => d.id !== destination.id
                ),
              }))
            }
            style={{ backgroundColor: COLOR.primary, marginRight: 3 }}
          >
            {destination.value} <FontAwesomeIcon icon={faClose} />
          </Badge>
        ))}
      </FormGroup>

      <FormGroup>
        <FormInput
          group={true}
          icon={faList}
          label="LEAD SOURCE"
          value={customerData.source}
          placeholder="lead source"
          // disabled={application.owner !== user.username}
          onChange={(e) =>
            setCustomerData((prev) => ({
              ...prev,
              source: e.target.value,
            }))
          }
          inputBTN={
            <Button
              onClick={() =>
                setCustomerData((prev) => ({
                  ...prev,
                  sources: [
                    ...prev.sources,
                    {
                      id: (Math.random() + 1).toString(36).substring(7),
                      value: customerData.source,
                    },
                  ],
                  source: "",
                }))
              }
              backgroundColor={COLOR.primary}
              disabled={loading || customerData.source === ""}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          }
        />

        {customerData.sources.map((source) => (
          <Badge
            onClick={() =>
              setCustomerData((prev) => ({
                ...prev,
                sources: prev.sources.filter(
                  (d) => d.id !== source.id
                ),
              }))
            }
            style={{ backgroundColor: COLOR.primary, marginRight: 3 }}
          >
            {source.value} <FontAwesomeIcon icon={faClose} />
          </Badge>
        ))}
      </FormGroup>
    </ModalForm>
  );
};

export default CustomerForm;
