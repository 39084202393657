import { useState } from "react";
import axios from "axios";
import { API } from "../utils/config";

function useNotifications(settings) {
  const { getLoginSession, user, auth_login } = settings;
  const { HEADERS } = API;

  const [notifications, setNotifications] = useState([]);

  function getNotifications(actions = () => "") {
    if (user.length !== 0) {
      axios
        .post(
          `${API.NEW_URL}/notifications/get_notifications`,
          {
            email: user.email,
            otp: getLoginSession[1],
            username: user.username,
          },
          HEADERS
        )
        .then(function (response) {
          // console.log(response);
          if (response.data.length !== 0) {
            setNotifications(response.data);
          }
          actions();
        })
        .catch(function (error) {
          // console.log("error");
        })
        .then(function () {});
    }
  }

  function markAsReadNotif(data, action) {
    axios
      .post(
        `${API.NEW_URL}/notifications/mark_as_read`,
        {
          id: data.id,
          email: user.email,
          otp: getLoginSession[1],
          username: user.username,
        },
        HEADERS
      )
      .then(function (response) {
        // console.log(response);
        if (response.data.length !== 0) {
          action();
        }
      })
      .catch(function (error) {
        // console.log("error");
      })
      .then(function () {});
  }

  return { notifications, getNotifications, markAsReadNotif };
}

export default useNotifications;
