import React from 'react'
import { BookingsContext } from '../../../../../Context/BookingsContext'
import ManageBooking from './ManageBooking'

const Index = () => {
  return (
    <BookingsContext>
        <ManageBooking/>
    </BookingsContext>
  )
}

export default Index