import React from "react";
import {
  CardBody,
  Row,
  Col as MyCol,
  ListGroup,
  ListGroupItem,
  Badge as MyBadge,
  ListGroupItemHeading,
  FontAwesomeIcon,
  Button,
  faArrowRight,
} from "../../../../traveltayo-ui-kit";
import { COLOR, ICON } from "../../../../Hooks/utils/config";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import dateFormat from "dateformat";

const ExpensesList = ({ list, type, setTicket }) => {
  const { numberWithCommas } = useCustomFunctions();

  const Col = ({ children, ...props }) => {
    return (
      <MyCol
        md={props.size}
        xs={props.size}
        sm={props.size}
        {...props}
        style={{ padding: 0, ...props.style }}
      >
        {children}
      </MyCol>
    );
  };

  const Badge = ({ children, ...props }) => {
    return (
      <MyBadge
        {...props}
        pill
        style={{ fontSize: 9, marginLeft: 5, marginBottom: 5 }}
      >
        {children}
      </MyBadge>
    );
  };

  return (
    <>
      {list.map((file) => (
        <ListGroupItem style={{ padding: 0 }}>
          <Row>
            <Col size={1}>
              <img
                src={
                  type === "booking"
                    ? file.details.product.image.src
                    : file.ticket
                }
                style={{ width: "100%", height: "100%" }}
              />
            </Col>
            <Col size={10}>
              <Badge theme="info">
                {type === "booking"
                  ? file.details.product.name
                  : file.reference}
              </Badge>
              {type === "booking" ? (
                <>
                  <Badge theme="success">
                    {numberWithCommas(file.details.product.price)}
                  </Badge>
                  {file.status === "pending" && (
                    <Badge theme="warning">Pending</Badge>
                  )}
                </>
              ) : (
                file.payment_details.amountPaid !== 0 && (
                  <Badge theme="success">
                    {numberWithCommas(file.payment_details.amountPaid)}
                  </Badge>
                )
              )}
              <p
                style={{
                  margin: 0,
                  // marginTop: 5,
                  marginLeft: 5,
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                {type === "booking"
                  ? dateFormat(
                      file.details.product.meta_data.find(
                        (m) => m.key === "Departure Date"
                      ).display_value,
                      "fullDate"
                    )
                  : dateFormat(file.timestamp, "fullDate")}
              </p>
            </Col>
            {type === "booking" && file.status === "pending" ? (
              ""
            ) : (
              <Col size={1}>
                <Button
                  theme="light"
                  block
                  style={{ height: "100%", borderRadius: "inherit" }}
                  onClick={() => setTicket(file)}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </Col>
            )}
          </Row>
        </ListGroupItem>
      ))}
    </>
  );
};

export default ExpensesList;
