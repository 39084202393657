import React, { Suspense, useState } from "react";
import Empty from "../../../Basic/Empty";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";
import dateFormat from "dateformat";
import {
  Button,
  Fade,
  ListGroup,
  ListGroupItem,
  FormCheckbox,
  Badge,
  Row,
  Col,
  FontAwesomeIcon,
  faPlus,
  faEdit,
  faEye,
  CheckListForm,
  Loading,
} from "../../../../traveltayo-ui-kit";
import { useAppData } from "../../../../Context/AppContext";
import { usePlanData } from "../../../../Context/PlanContext";
import { DEFAULT_VALUES } from "../../../../Hooks/utils/config";
import CheckList from "../Components/CheckList";

const PlanChecklist = () => {
  const { isOnline, screenWidth } = useAppData();
  const {
    plan,
    updateChecklistStatus,
    setIsAddingNewChecklist,
    setCheckListNewForm,
    setIsEditingChecklist,
  } = usePlanData();

  const { numberWithCommas } = useCustomFunctions();

  const [loading, setLoading] = useState("");
  const [selectedID, setSelectedID] = useState("");

  return (
    <Suspense fallback={<Loading />}>
      <Fade>
        {plan.length !== 0 ? (
          <>
            <CheckListForm />
            {plan.checklists.length === 0 ? (
              <Empty>
                <Button
                  theme="light"
                  onClick={() => setIsAddingNewChecklist(true)}
                  disabled={plan.access !== "edit"}
                >
                  <FontAwesomeIcon icon={faPlus} /> First Checklist
                </Button>
              </Empty>
            ) : (
              <div style={{ marginBottom: 15 }}>
                <Button
                  theme="light"
                  onClick={() => setIsAddingNewChecklist(true)}
                  disabled={plan.access !== "edit"}
                >
                  <FontAwesomeIcon icon={faPlus} /> New Checklist
                </Button>
              </div>
            )}

            {plan.checklists.length !== 0 ? (
              <>
                {plan.checklists
                  .filter((checklist) => checklist.status === "Not Done")
                  .map((checklist) => ({
                    ...checklist,
                    last_change: checklist.last_change
                      ? checklist.last_change
                      : new Date(),
                  }))
                  .sort(
                    (a, b) =>
                      new Date(a.last_change).getTime() -
                      new Date(b.last_change).getTime()
                  )
                  .map((checklist, index) => (
                    <CheckList
                      data={{
                        index,
                        checklist,
                        loading,
                        setLoading,
                        selectedID,
                        setSelectedID,
                      }}
                    />
                  ))}

                {plan.checklists
                  .filter((checklist) => checklist.status === "Done")
                  .map((checklist) => ({
                    ...checklist,
                    last_change: checklist.last_change
                      ? checklist.last_change
                      : new Date(),
                  }))
                  .sort(
                    (a, b) =>
                      new Date(a.last_change).getTime() -
                      new Date(b.last_change).getTime()
                  )
                  .map((checklist, index) => (
                    <CheckList
                      data={{
                        index,
                        checklist,
                        loading,
                        setLoading,
                        selectedID,
                        setSelectedID,
                      }}
                    />
                  ))}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </Fade>
    </Suspense>
  );
};

export default PlanChecklist;
