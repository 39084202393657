import React from "react";
import Applications from "./Applications";
import { ApplicationContext } from "../../../Context/ApplicationContext";

const Index = () => {
  return (
    <ApplicationContext>
      <Applications />
    </ApplicationContext>
  );
};

export default Index;
