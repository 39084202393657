import React, { Suspense, useEffect } from "react";
import { Col, Row, ImageGallery, Helmet, Loading, Fade, PlanForm } from "../..";
import { COLOR, FEATURES, PLATFORM } from "../../../Hooks/utils/config";
import { useAppData } from "../../../Context/AppContext";
import { Link } from "@reach/router";
import Ads from "./Ads";
import HeaderMenu from "../../../Components/Basic/Menu/HeaderMenu";
import SideMenu from "../../../Components/Basic/Menu/SideMenu";
import { BookingsContext } from "../../../Context/BookingsContext";
import { PlanContext } from "../../../Context/PlanContext";

const PageContainer = ({ children, ...props }) => {
  const {
    announcements,
    getAnnouncements,
    auth_login,
    screenSizes,
    setIsAddingNewPlan,
  } = useAppData();

  const { xSmall, small, medium, large } = screenSizes;

  useEffect(() => {
    getAnnouncements();
  }, []);

  const windowViewSize = () => {
    if (small || medium) {
      return 12;
    } else if (props.noAds && props.noSideBar) {
      return 10;
    } else if (!props.noAds && props.noSideBar) {
      return 10;
    } else if (!props.noAds && auth_login) {
      return 10;
    } else if (props.noAds && !auth_login) {
      return 12;
    } else if (props.noAds && auth_login) {
      return 12;
    } else if (!props.noAds && !auth_login) {
      return 10;
    } else {
      return 10;
    }
  };

  return (
    <Suspense fallback={<Loading />}>
      {props.helmet && <Helmet data={props.helmet} />}
      {/* {medium || large || !auth_login ? (
        <HeaderMenu setIsAddingNewPlan={setIsAddingNewPlan} />
      ) : (
        ""
      )} */}

      {auth_login && (
        <>
          {["/", "plans", "/plan"].includes(window.location.pathname) ||
          `/${window.location.pathname.split("/")[1]}` === "/plan" ? (
            <PlanForm />
          ) : (
            <BookingsContext>
              <PlanContext>
                <PlanForm />
              </PlanContext>
            </BookingsContext>
          )}
        </>
      )}
      <Fade>
        <HeaderMenu setIsAddingNewPlan={setIsAddingNewPlan} />
        {props.headerComponents && <>{props.headerComponents}</>}

        <Row
          style={{
            marginLeft: medium || large ? 0 : "2%",
            marginRight: medium || large ? 0 : "2%",
            padding: medium || large ? 0 : "",
            paddingTop: medium || large ? 0 : 20,
          }}
        >
          {/* {!props.noSideBar && (
            <>
              {medium || large || !auth_login ? (
                ""
              ) : (
                <Col md={2} className="sidebar-menu-container">
                  <SideMenu />
                </Col>
              )}
            </>
          )} */}

          {props.sideMenu && (
            <Col md={2} style={{paddingRight: 10, paddingLeft: 10, paddingTop: 10}}>
              {props.sideMenu}
            </Col>
          )}

          <Col
            md={windowViewSize()}
            xs={12}
            sm={12}
            style={{
              padding: medium || large ? 0 : 0,
            }}
          >
            {children}
          </Col>

          {(props.rightSideBar || !props.noAds) && (
            <Col md={medium ? 12 : 2} xs={12} sm={12}>
              <Row style={{ padding: 0 }}>
                <Col lg={12} md={6} xs={6} sm={6} style={{ padding: 0 }}>
                  {props.rightSideBar && (
                    <div style={{ marginBottom: 20 }}>{props.rightSideBar}</div>
                  )}

                  <Ads />
                </Col>
                {!props.noAds && (
                  <Col lg={12} md={6} xs={6} sm={6} style={{ marginTop: 0 }}>
                    {FEATURES.ANNOUNCEMENT_PANEL &&
                      announcements.length !== 0 && (
                        <ImageGallery
                          items={
                            announcements.length !== 0
                              ? announcements.map((announcement) => ({
                                  original: announcement.src,
                                  thumbnail: announcement.src,
                                }))
                              : []
                          }
                          lazyLoad={true}
                          autoPlay={true}
                        />
                      )}
                    {props.myAnnouncements}
                  </Col>
                )}
              </Row>
            </Col>
          )}

          <Col
            md={12}
            style={{
              // backgroundColor: COLOR.primary,
              padding: 20,
              paddingTop: 100,
              // color: "#fff",
              bottom: 0,
              height: "100%",
              paddingBottom: 100,
            }}
          >
            {FEATURES.FOOTER_DETAILS && (
              <>
                <hr />
                <Row>
                  {PLATFORM.ABOUT_US_PAGE.SHOW || PLATFORM.BLOG_PAGE.SHOW ? (
                    <Col
                      md={2}
                      style={{ marginBottom: medium || large ? 20 : 0 }}
                    >
                      {PLATFORM.ABOUT_US_PAGE.SHOW && (
                        <>
                          {PLATFORM.ABOUT_US_PAGE.LINK !== "" ? (
                            <a
                              href={PLATFORM.ABOUT_US_PAGE.LINK}
                              style={{
                                textDecoration: "none",
                                color: COLOR.primary,
                                display: "block",
                              }}
                              target="_blank"
                            >
                              About Us
                            </a>
                          ) : (
                            <Link
                              to="/blog/about-us"
                              style={{
                                textDecoration: "none",
                                color: COLOR.primary,
                                display: "block",
                              }}
                            >
                              About Us
                            </Link>
                          )}
                        </>
                      )}

                      {PLATFORM.BLOG_PAGE.SHOW && (
                        <>
                          {PLATFORM.BLOG_PAGE.LINK !== "" ? (
                            <a
                              href={PLATFORM.BLOG_PAGE.LINK}
                              style={{
                                textDecoration: "none",
                                color: COLOR.primary,
                                display: "block",
                              }}
                              target="_blank"
                            >
                              Blogs
                            </a>
                          ) : (
                            <Link
                              to="/blogs"
                              style={{
                                textDecoration: "none",
                                color: COLOR.primary,
                                display: "block",
                              }}
                            >
                              Blogs
                            </Link>
                          )}
                        </>
                      )}
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col md={10}>
                    <p style={{ fontWeight: "bold", color: COLOR.primary }}>
                      Affiliate Parters
                    </p>
                    {PLATFORM.AFFILLIATES.map((AFFILLIATE) => (
                      <a
                        href={AFFILLIATE.LINK}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <img
                          src={AFFILLIATE.LOGO}
                          alt={AFFILLIATE.BRAND}
                          style={{
                            width: medium || large ? "120px" : "120px",
                            marginRight: 20,
                            marginBottom: 20,
                          }}
                        />
                      </a>
                    ))}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Fade>
    </Suspense>
  );
};

export default PageContainer;
