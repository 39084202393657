import React from "react";
import { Card, CardBody } from "../../../../../traveltayo-ui-kit";
import QRCode from "react-qr-code";
import { PLATFORM } from "../../../../../Hooks/utils/config";

const Certified = ({ data }) => {
  const { product, merchant, id } = data;
  return (
    <>
      {product.length !== 0 && product.categories[0].slug === "destination" && (
        <Card style={{ marginTop: 0, marginBottom: 20 }} className="main-card">
          <CardBody style={{ textAlign: "center", padding: 10 }}>
            {/* <h6>Certified Destination By</h6> */}

            <QRCode
              value={`https://app.traveltayo.com/share/explore/page/${merchant}/${id}`}
              level={"L"}
              fgColor="#055258"
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            />

            <h6 style={{ margin: 5, fontSize: 10, lineHeight: "normal" }}>
              {product.name}
            </h6>
            {/* <p style={{ fontWeight: 400, fontSize: "10px" }}>
              Please scan the QR Code
            </p> */}
            <img
              src={PLATFORM.LOGO.NORMAL}
              style={{
                display: "block",
                width: "60%",
                margin: "0 auto",
                marginBottom: 10,
              }}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default Certified;
