import React from "react";
import {
  CardBody,
  Row,
  Col as MyCol,
  ListGroup,
  ListGroupItem,
  Badge,
  ListGroupItemHeading,
  FontAwesomeIcon,
} from "../../../../traveltayo-ui-kit";
import { COLOR, ICON } from "../../../../Hooks/utils/config";
import useCustomFunctions from "../../../../Hooks/api/useCustomFunctions";

const ExpensesList = ({ label, list, total }) => {
  const { numberWithCommas } = useCustomFunctions();

  const Col = ({ children, ...props }) => {
    return (
      <MyCol {...props} style={{ padding: 0, ...props.style }}>
        {children}
      </MyCol>
    );
  };

  return (
    <CardBody style={{ padding: 10 }}>
      <ListGroup>
        <ListGroupItem style={{padding: "5px 10px"}}>
          <ListGroupItemHeading style={{fontSize: 12}}>{label}</ListGroupItemHeading>
        </ListGroupItem>
        {list.map((expense) => (
          <ListGroupItem style={{padding: "5px 10px"}}>
            <Row>
              <Col md={2} xs={2} sm={2} style={{ paddingLeft: 0 }}>
                <Badge
                  style={{
                    fontSize: 9,
                    margin: 0,
                    padding: 5,
                    backgroundColor: COLOR.primary,
                  }}
                >
                  <FontAwesomeIcon
                    icon={ICON.find((i) => i.type === expense.type).icon}
                    style={{ fontSize: 9 }}
                  />
                </Badge>
              </Col>
              <Col md={6} xs={6} sm={6} style={{ paddingLeft: 0, fontSize: 9 }}>
                <p style={{ fontSize: 9, margin: 0 }}>
                  {expense.name.slice(0, 25)}
                  {expense.name.length > 25 ? "..." : ""}
                </p>
              </Col>
              <Col
                md={1}
                xs={1}
                sm={1}
                style={{ paddingLeft: 0, textAlign: "right" }}
              >
                <p
                  style={{
                    fontSize: 9,
                    margin: 0,
                    fontWeight: "bold",
                  }}
                >
                  &#8369;
                </p>
              </Col>
              <Col
                md={3}
                xs={3}
                sm={3}
                style={{ paddingLeft: 0, textAlign: "right" }}
              >
                <p
                  style={{
                    fontSize: 9,
                    margin: 0,
                    fontWeight: "bold",
                  }}
                >
                  {numberWithCommas(expense.amountPaid)}
                </p>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
        <ListGroupItem  style={{padding: "5px 10px"}}>
          <Row>
            <Col
              md={7}
              xs={7}
              sm={7}
              style={{ paddingLeft: 0, textAlign: "left" }}
            >
              <p
                style={{
                  fontSize: "11px",
                  margin: 0,
                  fontWeight: "bold",
                }}
              >
                SUB TOTAL
              </p>
            </Col>
            <Col
              md={1}
              xs={1}
              sm={1}
              style={{ paddingLeft: 0, textAlign: "right" }}
            >
              <p
                style={{
                  fontSize: "11px",
                  margin: 0,
                  fontWeight: "bold",
                }}
              >
                &#8369;
              </p>
            </Col>
            <Col
              md={4}
              xs={4}
              sm={4}
              style={{ paddingLeft: 0, textAlign: "right" }}
            >
              <p
                style={{
                  fontSize: "11px",
                  margin: 0,
                  fontWeight: "bold",
                }}
              >
                {numberWithCommas(total.toFixed(2))}
              </p>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  );
};

export default ExpensesList;
