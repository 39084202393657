import React, { Suspense } from "react";
import Quotation from "../../Components/TourQuotation/Quotation";
import { ApplicationContext } from "../../../../../Context/ApplicationContext";
import { Loading } from "../../../../../traveltayo-ui-kit";

const Index = ({ app_id, id }) => {
  return (
    <Suspense fallback={<Loading />}>
      <ApplicationContext>
        <Quotation app_id={app_id} id={id} link="" />
      </ApplicationContext>
    </Suspense>
  );
};

export default Index;
