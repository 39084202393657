import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import Empty from "../../../Basic/Empty";
import { useAppData } from "../../../../Context/AppContext";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Collapse,
  Loading,
  PageContainer,
} from "../../../../traveltayo-ui-kit";
import { APP_NAME, COLOR, FEATURES } from "../../../../Hooks/utils/config";

const Notifications = () => {
  const { user, notifications, markAsReadNotif, getNotifications } =
    useAppData();

  const [selectedID, setSelectedID] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getNotifications(() => setLoading(false));
  }, [user]);

  return (
    <PageContainer helmet={{ title: APP_NAME + " | Notifications" }} noAds={FEATURES.NO_ADS}>
      <Row style={{ padding: 0 }}>
        {loading ? (
          <Col md={12}>
            <Loading />
          </Col>
        ) : (
          <Col md={12} style={{ padding: 0 }}>
            <Card className="main-card" style={{ marginBottom: 10 }}>
              <CardHeader style={{ padding: 10 }}>
                <h4 style={{ fontSize: 15 }}>My Notifications</h4>
              </CardHeader>
              {notifications.length !== 0 ? (
                <>
                  {notifications
                    .sort((a, b) => b.id - a.id)
                    .map((notif, index) => (
                      <CardBody
                        style={{
                          borderBottom: `${
                            notifications.length === index + 1 ? 0 : 1
                          }px solid #E9ECEF`,
                          padding: 10,
                        }}
                      >
                        <Row>
                          <Col md={2} xs={2} sm={2}>
                            {notif.sender}
                          </Col>
                          <Col md={3} xs={3} sm={3}>
                            {notif.title}
                          </Col>
                          <Col md={5} xs={5} sm={5}>
                            {dateFormat(notif.timestamp, "fullDate")}
                          </Col>
                          <Col md={2} xs={2} sm={2}>
                            {notif.status !== 0 ? (
                              <Button
                                onClick={() =>
                                  setSelectedID(
                                    selectedID === notif.id ? "" : notif.id
                                  )
                                }
                                backgroundColor={COLOR.primary}
                              >
                                {selectedID === notif.id ? "Close" : "Open"}
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  setSelectedID(
                                    selectedID === notif.id ? "" : notif.id
                                  ) ||
                                  markAsReadNotif(notif, () =>
                                    getNotifications()
                                  )
                                }
                                backgroundColor={COLOR.primary}
                              >
                                {selectedID === notif.id ? "Close" : "Read"}
                              </Button>
                            )}
                          </Col>
                          <Col md={12}>
                            <Collapse open={selectedID === notif.id}>
                              <div className="p-3 mt-3 border rounded">
                                <span>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: notif.message,
                                    }}
                                  />
                                </span>
                              </div>
                            </Collapse>
                          </Col>
                        </Row>
                      </CardBody>
                    ))}
                </>
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
        )}
      </Row>
    </PageContainer>
  );
};

export default Notifications;
