import React from "react";
import { PLATFORM } from "../../../Hooks/utils/config";
import { Modal, ModalBody } from "shards-react";

const Loading = ({ children, ...props }) => {
  return (
    <>
      {props.static ? (
        <div style={{ textAlign: "center", padding: "20%" }}>
          <img
            src={ PLATFORM.LOGO.PARTNER_LOGO || PLATFORM.LOGO.LOADING_ICON}
            alt="loading"
            style={{ width: "150px", margin: "0 auto" }}
          />
          <p>Loading ...</p>
          {children && <p>{children}</p>}
        </div>
      ) : (
        <Modal open={true} toggle={() => ""} style={{ zIndex: 99999999, padding: "10%" }}>
          <ModalBody style={{ padding: "20%" }}>
            <div style={{ textAlign: "center" }}>
              <img
                src={PLATFORM.LOGO.PARTNER_LOGO || PLATFORM.LOGO.LOADING_ICON}
                alt="loading"
                style={{ width: "150px", margin: "0 auto" }}
              />
              <p>Loading ...</p>
              {children && <p>{children}</p>}
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default Loading;
