import React from "react";
import {
  FormGroup,
  FormInput as Input,
  FormTextarea as TextArea,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";

const FormInput = (props) => {
  return (
    <>
      {props.group ? (
        <FormGroup
          style={{ ...props.formGroup, marginBottom: 5 }}
          className={props.formClassName}
        >
          {props.label && (
            <label
              htmlFor={
                props.label
                  ? `#${props.label.toLowerCase().replace(/[^A-Z0-9]/gi, "")}`
                  : ""
              }
              style={{ marginBottom: "0.1rem", fontSize: "11px" }}
            >
              {props.label}
            </label>
          )}
          <InputGroup
            size={props.size}
            seamless
            className={
              props.inputs &&
              props.inputs.map((input) => input.type === "date").length !== 0
                ? "dateSelector"
                : ""
            }
          >
            {props.icon ? (
              <InputGroupAddon type="prepend">
                <InputGroupText>
                  <FontAwesomeIcon icon={props.icon} />
                </InputGroupText>
              </InputGroupAddon>
            ) : (
              ""
            )}
            {props.inputs ? (
              props.inputs.map((input) =>
                input.type === "date" ? (
                  <>
                    <DatePicker
                      {...input}
                      selected={new Date(input.selected)}
                      withPortal={input.withPortal || "withPortal"}
                      dateFormat={input.dateFormat || "MMMM dd, yyyy"}
                      // className="datePicker-input"
                      // holidays={input.holidays}
                    />
                  </>
                ) : (
                  <Input {...input} />
                )
              )
            ) : (
              <>
                {props.type === "date" ? (
                  <DatePicker
                    {...props}
                    selected={new Date(props.selected)}
                    withPortal={props.withPortal || "withPortal"}
                    dateFormat={props.dateFormat || "MMMM dd, yyyy"}
                  />
                ) : (
                  <Input {...props} />
                )}
              </>
            )}
            {props.inputBTN && (
              <InputGroupAddon type="append">{props.inputBTN}</InputGroupAddon>
            )}
          </InputGroup>
          {props.hintText ? (
            <span style={{ fontSize: "12px" }}>{props.hintText}</span>
          ) : (
            ""
          )}
        </FormGroup>
      ) : (
        <FormGroup>
          <label
            htmlFor={
              props.label
                ? `#${props.label.toLowerCase().replace(/[^A-Z0-9]/gi, "")}`
                : ""
            }
            style={{ marginBottom: "0.1rem", fontSize: "11px" }}
          >
            {props.label}
          </label>
          {props.inputType === "textarea" ? (
            <TextArea {...props} />
          ) : (
            <Input {...props} />
          )}
          {props.hintText ? (
            <span style={{ fontSize: "12px", color: props.hintColor }}>
              {props.hintText}
            </span>
          ) : (
            ""
          )}
        </FormGroup>
      )}
    </>
  );
};

export default FormInput;
