import React, { useEffect, useState } from "react";
import {
  Col,
  ListGroup,
  ListGroupItem,
  Pagination,
  Row,
} from "../../../../../traveltayo-ui-kit";
import ListItem from "./ListItem";
import Empty from "../../../../Basic/Empty";
import { useApplicationData } from "../../../../../Context/ApplicationContext";

const Lists = ({ data }) => {
  const {
    openCustomerForm,
    setOpenCustomerForm,
    editCustomerData,
    setEditCustomerData,
  } = data;
  const { application } = useApplicationData();
  const [search, setSearch] = useState("");
  const [openToggle, setOpenToggle] = useState(false);
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const [customers, setCustomers] = useState([]);
  useEffect(() => {
    if (application.length !== 0) {
      setCustomers(application.customers);
    }
  }, [application]);

  return (
    <>
      {customers.length !== 0 && application.length !==0? (
        <ListGroup>
          <ListGroupItem>
            <Row style={{ fontWeight: 600 }}>
              <Col md={3} xs={3} sm={3}>
                Name
              </Col>
              <Col md={3} xs={3} sm={3}>
                Contact
              </Col>
              <Col md={4} xs={2} sm={2}>
                Desinations
              </Col>
              <Col md={2} xs={2} sm={2}>
                Actions
              </Col>
            </Row>
          </ListGroupItem>
          <Pagination
            list={customers}
            itemCount={customers.length}
            // actions={get}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setEditApplication={setEditCustomerData}
            Card={ListItem}
            setOpenAppForm={setOpenCustomerForm}
            itemsPerPage={itemsPerPage}
            cardType="list2"
          />
        </ListGroup>
      ) : (
        <Empty />
      )}
    </>
  );
};

export default Lists;
