import React, { useEffect, useState } from "react";
import moment from "moment";
import dateFormat from "dateformat";
import CalendarToolBar from "./CalendarToolBar";
import ViewCalendarEvent from "./ViewCalendarEvent";
import { COLOR } from "../../../Hooks/utils/config";
import {
  Calendar as MyCalendar,
  momentLocalizer,
  Views,
} from "react-big-calendar";
import { usePlanData } from "../../../Context/PlanContext";
import { useDashboardData } from "../../../Context/DashboardContext";
import { Card, CardBody } from "../../../traveltayo-ui-kit";

const localizer = momentLocalizer(moment);

const Index = () => {
  const { calendarEvents, eventFilter, calendarYear, setViewEvent } =
    useDashboardData();

  const { setEditPlan, getPlan, getSharedPlan } = usePlanData();

  const eventStyleGetter = (event) => {
    let style = {
      backgroundColor:
        event.plan_status === "Completed" || event.status === "Done"
          ? "#80808082"
          : event.event_type === "holiday"
          ? COLOR.holidays
          : event.event_type === "long-weekend"
          ? COLOR.long_weekend
          : event.event_type === "festival"
          ? COLOR.festivals
          : event.type === "reminder"
          ? COLOR.reminder
          : event.type === "my_booking"
          ? COLOR.booking
          : event.event_type === "quotation"
          ? COLOR.quotation
          : COLOR.primary,
      fontSize: "13px",
      fontWeight: "500",
      color:
        event.plan_status === "Completed" || event.status === "Done"
          ? "#ffffff9e"
          : "#fff",
    };

    return { style: style };
  };

  const dayStyleGetter = (event) => {
    let style = {
      backgroundColor: new Date(event) < new Date() ? "#e6e6e6 !important" : "",
    };

    let className = new Date(event) < new Date() ? "rbc-off-range-bg" : "";

    return { style: style, className: className };
  };

  const handleSelectEvent = (event) => {
    // setSelectedEvent(event);
    setViewEvent(true);
    setEditPlan(event);
    if (event.event_type === "travel_plan") {
      getPlan(event.reference);
    } else if (event.event_type === "shared_travel_plan") {
      getSharedPlan(event.reference);
    }
  };

  const [events, setEvents] = useState(calendarEvents);

  useEffect(() => {
    if (calendarEvents) {
      setEvents(
        calendarEvents
          .filter((event) => dateFormat(event.start, "yyyy") === calendarYear)
          .filter((event) =>
            eventFilter.length === 0
              ? ""
              : (eventFilter.includes(event.event_type) &&
                  event.type !== "reminder") ||
                eventFilter.includes(event.type)
          )
      );
    }
  }, [eventFilter, calendarEvents]);

  const { setIsAddingNewPlan, setNewPlan, newPlan } = usePlanData();

  return (
    <Card className="main-card" style={{ height: "100%" }}>
      <CardBody style={{ padding: 10 }}>
        {calendarEvents && (
          <>
            <ViewCalendarEvent />
            <MyCalendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: "100%", width: "50wh", backgroundColor: "#fff" }}
              onSelectEvent={handleSelectEvent}
              views={[Views.MONTH]}
              eventPropGetter={eventStyleGetter}
              dayPropGetter={dayStyleGetter}
              components={{
                toolbar: CalendarToolBar,
              }}
              selectable={true}
              onSelectSlot={(event) =>
                setIsAddingNewPlan(true) ||
                setNewPlan({
                  ...newPlan,
                  start: event.start,
                  end: event.start,
                })
              }
              popup={true}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default Index;
